import Button from 'components/Button'
import Can from 'components/Can'
import { IssueStatus } from 'constants/issues'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import { getOpenIssues } from 'store/selectors/kaseIssues'

interface Props {
  /**
   * If true, the "Create new issue" button will be hidden
   */
  disabled?: boolean
  /**
   * If true, this component will not be rendered
   */
  hidden?: boolean
  /**
   * The status filter for issues. If it's IssuesStatus.Open, we'll display a
   * "Create new issue" button if the user has the right permissions. Otherwise,
   * we won't show anything.
   */
  issueFilter: IssueStatus
  /**
   * Callback triggered when the "Create new issue" button is pressed
   */
  createIssue: () => void
}

/**
 * This component displays displays a "Create new issue" button if:
 * - we're showing open issues
 * - it's not hidden
 *
 * If there are no open issues, we will display an empty slate
 */
const CreateNewIssueSlate: FunctionComponent<Props> = ({
  createIssue,
  disabled,
  issueFilter,
  hidden
}) => {
  const openIssues = useSelector((state: RootState) => getOpenIssues(state))

  // We're showing the resolved issues, so no need to add a new issue
  if (issueFilter === IssueStatus.Resolved) {
    return null
  }

  if (hidden) {
    return null
  }

  // There are no open issues
  if (openIssues.length === 0) {
    return (
      <div className="rounded p-6 flex items-center border-2 justify-center my-6 border-dashed bg-gray-50 border-gray-200">
        <div className="text-center space-y-4">
          <p className="text-gray-700 text-sm">
            There are no open issues for this application.
          </p>
          <Can
            perform="issue:create"
            yes={
              <Button
                variant="primary"
                onClick={createIssue}
                disabled={disabled}
              >
                Create an issue
              </Button>
            }
          />
        </div>
      </div>
    )
  }

  return (
    <Can
      perform="issue:create"
      yes={
        <Button variant="primary" onClick={createIssue} disabled={disabled}>
          Add new issue
        </Button>
      }
    />
  )
}

export default CreateNewIssueSlate
