import React, { FunctionComponent, useEffect } from 'react'
import { AdminUser } from 'types/users'
import AdminRow from 'pages/admins/AdminRow'
import Table from 'components/tables/Table'
import { fetchRoles } from 'api/roles'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

interface Props {
  admins: AdminUser[]
  onCancelCreate: () => void
}

const AdminsTable: FunctionComponent<Props> = ({ admins, onCancelCreate }) => {
  const roles = useSelector((state: RootState) => state.adminRoles.roles)

  useEffect(() => {
    fetchRoles()
  }, [])

  if (!admins) {
    return <div>loading</div>
  }
  return (
    <div className="w-full shadow sm:rounded-lg overflow-auto">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID</Table.Header>
            <Table.Header>Full Name</Table.Header>
            <Table.Header>Role</Table.Header>
            <Table.Header>Email</Table.Header>
            <Table.Header>Trello Member ID</Table.Header>
            <Table.Header>Calendly Link</Table.Header>
            <Table.Header></Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {admins.map((admin) => (
            <AdminRow
              key={admin.id}
              admin={admin}
              isCreateMode={!admin.id}
              onCancelCreate={onCancelCreate}
              roles={roles}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default AdminsTable
