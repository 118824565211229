import React, { FunctionComponent } from 'react'
import {
  capitalize,
  formatIfDate,
  getTimeFromDateString
} from '../../utils/format_utils'
import { AuditLog } from 'types/auditLog'

import cx from 'classnames'

interface Props {
  log: AuditLog
  className?: string
}

const KaseAuditLog: FunctionComponent<Props> = ({ className, log }) => {
  return (
    <div className={className}>
      <div className="border border-gray-300 mt-4 p-4">
        <strong>Item ID:</strong> {log.attributes.item_id}
        <br />
        <strong>Event:</strong> {log.attributes.event}
        <br />
        <strong>Type:</strong> {log.attributes.item_type}
        <br />
        <strong>User:</strong> {log.attributes.user}
        <br />
        <strong>Timestamp:</strong> {formatIfDate(log.attributes.created_at)}{' '}
        {getTimeFromDateString(log.attributes.created_at)}
        <br />
        <strong>Changes:</strong>
        <div
          className={cx('overflow-scroll border border-gray-200')}
          style={{ maxHeight: '200px' }}
        >
          {Object.entries(log.attributes.object_changes).map(
            ([key, value], index) => (
              <div key={`kase-audit-log-changes-${index}`}>
                <strong>{capitalize(key)}:</strong> {value[0]} → {value[1]}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default KaseAuditLog
