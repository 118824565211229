// These match what come from the BackEnd in the LawyerInteraction.status field
export enum LawyerInteractionStatus {
  NA = 'not_applicable',
  UNAVAILABLE = 'unavailable',
  SCHEDULED = 'scheduled',
  NOT_SCHEDULED = 'not_scheduled',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no_show'
}

// These are the values you will see on the UI
export const LawyerInteractionStatusMessage = {
  [LawyerInteractionStatus.NA]: 'Not Applicable',
  [LawyerInteractionStatus.UNAVAILABLE]: 'Kase is not ready for this call yet',
  [LawyerInteractionStatus.NOT_SCHEDULED]: 'Not Scheduled',
  [LawyerInteractionStatus.SCHEDULED]: 'Scheduled',
  [LawyerInteractionStatus.COMPLETED]: 'Complete',
  [LawyerInteractionStatus.CANCELLED]: 'The call was cancelled',
  [LawyerInteractionStatus.NO_SHOW]: 'The customer was a no show'
}
