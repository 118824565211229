import React, { useEffect } from 'react'

export const QuantitySelect = ({
  disabled,
  label,
  maxQuantity,
  onChange
}: {
  disabled: boolean
  label: string
  maxQuantity: number
  onChange: (value: number) => void
}) => {
  const [value, setValue] = React.useState(0)
  const [oldValue, setOldValue] = React.useState(0)

  useEffect(() => {
    if (value !== oldValue) {
      onChange(value)
      setOldValue(value)
    }
  }, [value])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked ? 1 : 0
    setValue(newValue)
  }

  if (maxQuantity === 1) {
    return (
      <div className="flex items-center">
        <input
          type="checkbox"
          disabled={disabled}
          value={value}
          onChange={handleCheckboxChange}
          className="w-8 text-center"
        />
        <span className="font-medium text-gray-500">{label}</span>
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <div className="bg-white border border-gray-300">
        <button
          disabled={disabled || value === 0}
          onClick={() => setValue(Number(value) - 1)}
          className="px-2 py-1 border-right border-gray-300 rounded-l"
        >
          -
        </button>
        <input
          disabled={true}
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          className="w-8 text-center"
        />
        <button
          disabled={disabled || value === maxQuantity}
          onClick={() => setValue(Number(value) + 1)}
          className="px-2 py-1 border-left border-gray-300 rounded-r"
        >
          +
        </button>
      </div>
      <span className="ml-2 font-medium text-gray-500">{label}</span>
    </div>
  )
}
