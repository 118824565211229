import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KaseStateEvent } from 'types/kaseStateEvent'

const kaseStateEventsSlice = createSlice({
  name: 'kase',
  initialState: [] as KaseStateEvent[],
  reducers: {
    setStatesAndEvents: (
      state: KaseStateEvent[],
      action: PayloadAction<KaseStateEvent[]>
    ) => action.payload
  }
})

export const { setStatesAndEvents } = kaseStateEventsSlice.actions

export default kaseStateEventsSlice.reducer
