import React, { FunctionComponent, useState, FormEvent } from 'react'

import { resolveTask } from 'api/tasks'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import Button from 'components/Button'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import BlankModal from 'components/modals/BlankModal'
import TextArea from 'components/forms/TextArea'
import TaskStatusIndicator from 'pages/issues/update_task_options/TaskStatusIndicator'
import ExclamationIcon from 'components/icons/ExclamationIcon'
import { getLinkToMpdf } from 'utils/format_link'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { getCurrentOpenTask } from 'store/selectors/kaseTasks'

interface Props {
  kaseId: string
}

const ResolveWithoutIssues: FunctionComponent<Props> = ({ kaseId }) => {
  const { setGlobalError } = useGlobalError()
  const currentOpenTask = useSelector((state: RootState) =>
    getCurrentOpenTask(state)
  )
  const canFinishTask = currentOpenTask?.attributes?.metadata?.mpdfs_ready

  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false)
  const [showResolveTaskModal, setShowResolveTaskModal] = useState(false)
  const [resolutionSummary, setResolutionSummary] = useState('')

  const confirmResolveTask = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    resolveTask({
      kaseId,
      taskId: currentOpenTask?.id,
      resolutionSummary
    })
      .catch((error) => {
        setGlobalError(error)
      })
      .finally(() => {
        setShowResolveTaskModal(false)
      })
  }

  const taskIndicatorTitle = canFinishTask ? (
    'No issues created'
  ) : (
    <>Generate MPDFs to finish without issues {getLinkToMpdf(kaseId)}</>
  )

  return (
    <>
      {/* Modal to confirm resolving task without issues is intentional */}
      <ConfirmationModal
        isOpen={showConfirmActionModal}
        title="Finish without issues"
        description={
          <>
            You're about to move this application forward without entering any
            issues. <span className="font-bold">This happens very rarely!</span>{' '}
            Are you sure you want to proceed?
          </>
        }
        confirmLabel="Yes, continue"
        cancelLabel="No, go back"
        onConfirm={() => {
          setShowConfirmActionModal(false)
          setShowResolveTaskModal(true)
        }}
        onRequestClose={() => setShowConfirmActionModal(false)}
      />
      {/* Modal to resolve task without issues */}
      <BlankModal
        isOpen={showResolveTaskModal}
        onRequestClose={() => setShowResolveTaskModal(false)}
      >
        <div className="p-6">
          <h3 className="text-lg text-gray-800 leading-6 font-medium mb-2">
            Complete QA task
          </h3>
          <form onSubmit={confirmResolveTask}>
            <div>
              <p className="text-sm text-gray-600 leading-5 mb-2">
                Add a summary to explain your findings and confirm that no
                issues were found. This will be added to the card in Trello.
              </p>
              <TextArea
                placeholder="Add summary (required)"
                value={resolutionSummary}
                onChange={(event) => setResolutionSummary(event.target.value)}
              />
            </div>
            <div className="mt-6 space-x-3">
              <Button
                type="submit"
                variant="primary"
                disabled={!resolutionSummary}
              >
                Complete task
              </Button>
              <Button onClick={() => setShowResolveTaskModal(false)}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </BlankModal>

      <TaskStatusIndicator
        icon={<ExclamationIcon className="mr-2" />}
        title={taskIndicatorTitle}
        className="text-gray-500"
      />

      <Button
        className="mr-4"
        variant="primary"
        disabled={!canFinishTask}
        onClick={() => setShowConfirmActionModal(true)}
      >
        Finish without issues
      </Button>
    </>
  )
}

export default ResolveWithoutIssues as FunctionComponent<Props>
