import React, { FunctionComponent } from 'react'

import { IDocumentSet } from 'types/documents'

interface Props {
  documentList: IDocumentSet[]
  setCurrentView: (test: string) => void
}

const DocumentList: FunctionComponent<Props> = ({
  documentList,
  setCurrentView
}) => {
  return (
    <div className="pl-8 pr-4 grid-col1 border-r border-gray-200 py-10 sticky overflow-y-auto">
      <div className="text-xs leading-snug">
        {documentList.map((docSet) => {
          return (
            <div key={docSet.id}>
              <p
                className="font-bold mb-2 hover:underline cursor-pointer"
                onClick={() => setCurrentView(docSet.name)}
              >
                {docSet.title}
              </p>
              <ul className="mb-6 space-y-2">
                {docSet.document_requests.map((docReq) => (
                  <li
                    key={docReq.id}
                    onClick={() => setCurrentView(docReq.name)}
                    className="hover:underline cursor-pointer"
                  >
                    {docReq.title}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DocumentList as FunctionComponent<Props>
