import React, { FunctionComponent, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import { assignTask } from 'api/tasks'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { mostRecentMessageIsFromCustomer } from '../utils'
import { Issue } from 'types/issues'

import Button from 'components/Button'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import TaskStatusIndicator from 'pages/issues/update_task_options/TaskStatusIndicator'
import ExclamationCircleIcon from 'components/icons/ExclamationCircleIcon'
import BadgeCheckIcon from 'components/icons/BadgeCheckIcon'
import ClockIcon from 'components/icons/ClockIcon'
import { useTaskAssignedToCustomer } from 'hooks/useTaskAssignedToCustomer'

interface Props {
  openIssues: Issue[]
  kaseId: string
  currentOpenTaskId?: string
}

const AssignTaskToCustomer: FunctionComponent<Props> = ({
  openIssues,
  kaseId,
  currentOpenTaskId
}) => {
  const { setGlobalError } = useGlobalError()
  const [showModal, setShowModal] = useState(false)
  const taskAssignedToCustomer = useTaskAssignedToCustomer()

  const customerId = useSelector(
    (state: RootState) => state.kaseData?.attributes?.user_id
  )
  const messages = useSelector((state: RootState) => state.kaseIssues.messages)

  // Disable resolve or assign to customer if issues need action
  const someIssuesMissingResponse = useMemo(() => {
    if (openIssues.length === 0) {
      return false
    }

    // If any open issues do not have admin message reply,
    // task cannot be returned to customer
    return openIssues.some((issue) =>
      mostRecentMessageIsFromCustomer({
        issue,
        messages: messages.byId,
        customerId
      })
    )
    // Updates if issue status changes, admin adds reply
  }, [openIssues, messages.allIds.length])

  const confirmAssignTask = () => {
    if (!currentOpenTaskId || !customerId) {
      return
    }

    assignTask({
      kaseId,
      taskId: currentOpenTaskId,
      assigneeId: customerId
    })
      .catch((error) => {
        setGlobalError(error)
      })
      .finally(() => {
        setShowModal(false)
      })
  }

  const getTaskIndicatorAttributes = () => {
    if (taskAssignedToCustomer) {
      return {
        icon: <ClockIcon />,
        title: 'Waiting on Customer',
        className: 'text-gray-500'
      }
    } else if (someIssuesMissingResponse) {
      return {
        icon: <ExclamationCircleIcon />,
        title: 'Some issues need action',
        className: 'text-red-700'
      }
    } else {
      return {
        icon: <BadgeCheckIcon />,
        title: 'Issues ready to send',
        className: 'text-green-700'
      }
    }
  }

  const { icon, title, className } = useMemo(getTaskIndicatorAttributes, [
    openIssues,
    taskAssignedToCustomer
  ])

  return (
    <>
      <TaskStatusIndicator icon={icon} title={title} className={className} />
      <ConfirmationModal
        isOpen={showModal}
        title="Assign task to customer"
        description="Is the return ready for the customer to view? This will trigger an automated email to the customer."
        confirmLabel="Yes, assign task"
        onConfirm={confirmAssignTask}
        onRequestClose={() => setShowModal(false)}
      />

      <Button
        variant="outline"
        disabled={
          openIssues.length === 0 ||
          someIssuesMissingResponse ||
          !currentOpenTaskId ||
          taskAssignedToCustomer
        }
        onClick={() => setShowModal(true)}
      >
        Finish & send review
      </Button>
    </>
  )
}

export default AssignTaskToCustomer as FunctionComponent<Props>
