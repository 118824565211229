import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import cx from 'classnames'

import { getCustomerFirstNamesFromKase } from 'utils/kase_utils'
import { AdminUsersShape } from 'store/reducers/adminUsers'

import { Message } from 'types/message'
import { KaseData } from 'types/kaseData'

import ThreadPanelMessageContainer from './ThreadPanelMessageContainer'
import ThreadPanelReply from 'components/ThreadPanelReply'
import TabCountLabel from 'components/tabs/TabCountLabel'
import Tab from 'components/tabs/Tab'
import Can from 'components/Can'
import ConfirmationModal from 'components/modals/ConfirmationModal'

export interface ThreadPanelProps {
  /**
   * A list of AdminUsers as key-value pairs. Used to display information about
   * admin users in messages.
   */
  admins?: AdminUsersShape['byId']
  customerInfo: KaseData
  /**
   * An array of messages to display in the panel
   */
  messages?: Message[]
  /**
   * Set this to `true` to show the panel.
   */
  isOpen?: boolean
  /**
   * If `true`, a text area will be displayed to allow users to reply.
   */
  allowReplies?: boolean
  /**
   * Callback triggered after the user types a message and clicks "Save Reply"
   */
  onSaveReply?: (reply: string) => void
  /**
   * Callback triggered when the Close button is clicked
   */
  onClose: () => void
  /**
   * Callback triggered when editing a message. Should return a Promise.
   */
  submitMessageEdits?: (data: { id: string; text: string }) => Promise<Message>
}

function getUserDetailsForDisplay(
  messageCreatorId: string,
  customerInfo: KaseData,
  admins: AdminUsersShape['byId'] = {}
) {
  const firstNames = getCustomerFirstNamesFromKase(customerInfo)
  const initials = firstNames.reduce((initials, name) => {
    return (initials += name.charAt(0))
  }, '')

  const creatorIsBoundless =
    messageCreatorId !== customerInfo.attributes.user_id

  return {
    name: creatorIsBoundless
      ? admins[messageCreatorId]?.attributes.full_name || 'Boundless'
      : firstNames.join(' & '),
    initials: creatorIsBoundless ? 'B' : initials,
    isAdmin: creatorIsBoundless
  }
}

export enum ThreadPanelState {
  /**
   * The panel is in its default state
   */
  Idle,
  /**
   * The user is currently editing an existing message
   */
  EditingMessage,
  /**
   * The user is currently deleting an existing message
   * TODO this isn't implemented yet
   */
  DeletingMessage,
  /**
   * The user is adding a new reply
   */
  AddingMessage
}

const ThreadPanelNew: FunctionComponent<ThreadPanelProps> = (props) => {
  const {
    admins,
    allowReplies = false,
    customerInfo,
    isOpen = false,
    messages = [],
    onClose,
    onSaveReply,
    submitMessageEdits
  } = props
  const [panelState, setPanelState] = useState(ThreadPanelState.Idle)
  const [showDiscardMessageModal, setShowDiscardMessageModal] = useState(false)
  const threadedCommsRef = useRef(null)
  const lastMessageId = messages[messages?.length - 1]?.id

  // Scroll to last message
  useEffect(() => {
    if (isOpen && lastMessageId) {
      document.getElementById(`message-${lastMessageId}`)?.scrollIntoView()
    }
  }, [isOpen, lastMessageId])

  const submitReply = (reply: string) => {
    if (onSaveReply != null) {
      onSaveReply(reply)
      setPanelState(ThreadPanelState.Idle)
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'A reply was submitted but there is no callback to handle it.'
      )
    }
  }

  const threadPanelReply = () => {
    return (
      <>
        {allowReplies && (
          <ThreadPanelReply
            // Prevent adding a new reply if message is being edited or deleted
            disabled={
              panelState !== ThreadPanelState.Idle &&
              panelState !== ThreadPanelState.AddingMessage
            }
            panelIsOpen={isOpen}
            submitReply={submitReply}
            setThreadPanelState={(panelState: ThreadPanelState) =>
              setPanelState(panelState)
            }
          />
        )}
      </>
    )
  }

  const messageBeingEdited =
    panelState === ThreadPanelState.EditingMessage ||
    panelState === ThreadPanelState.AddingMessage

  const onClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      threadedCommsRef.current &&
      threadedCommsRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    closePanel()
  }

  const confirmDiscardMessage = () => {
    onClose()
    setShowDiscardMessageModal(false)
  }

  const closePanel = () => {
    if (messageBeingEdited) {
      setShowDiscardMessageModal(true)
      return
    }

    onClose()
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showDiscardMessageModal}
        title="Discard message"
        dangerousOperation
        description="Are you sure you want to close the thread panel? Your unsaved messages will be discarded. This action is not reversible."
        confirmLabel="Yes, discard message"
        onConfirm={confirmDiscardMessage}
        onRequestClose={() => setShowDiscardMessageModal(false)}
      />
      <div
        onClick={onClickOutside}
        className={cx('z-10', {
          fixed: isOpen
        })}
      >
        <div data-test="thread-panel-overlay" />
        <aside
          ref={threadedCommsRef}
          style={{ right: '41.67%' }}
          className={cx(
            'flex flex-col bg-white shadow mt-32 w-1/3 overflow-hidden fixed inset-y-0 transform transition-transform duration-200',
            {
              'translate-x-full': !isOpen,
              'translate-x-0': isOpen
            }
          )}
        >
          <div className="flex items-center justify-between px-6 border-t border-b border-gray-200">
            <Tab isActive>
              Messages
              <TabCountLabel>{messages?.length}</TabCountLabel>
            </Tab>
            <button
              className="text-2xl leading-none w-6 h-6 flex items-center justify-center text-gray-700 hover:text-blue-700"
              type="button"
              name="Close the messages panel"
              onClick={closePanel}
            >
              &times;
            </button>
          </div>
          <div className="flex-grow px-6 divide-y divide-gray-100 overflow-auto">
            {messages.map((message, index) => {
              const userDetails = getUserDetailsForDisplay(
                message.attributes.creator_id,
                customerInfo,
                admins
              )

              return (
                <ThreadPanelMessageContainer
                  key={message.id}
                  message={message}
                  userName={userDetails.name}
                  userInitials={userDetails.initials}
                  userIsAdmin={userDetails.isAdmin}
                  isEditable={
                    index !== 0 && panelState === ThreadPanelState.Idle
                  }
                  onMessageEditStart={() =>
                    setPanelState(ThreadPanelState.EditingMessage)
                  }
                  onMessageEditComplete={() =>
                    setPanelState(ThreadPanelState.Idle)
                  }
                  submitMessageEdits={submitMessageEdits}
                />
              )
            })}
          </div>
          <Can perform="message:create" yes={threadPanelReply()} />
        </aside>
      </div>
    </>
  )
}

export default ThreadPanelNew
