import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
}

const Header: FunctionComponent<Props> = ({ children, className }) => {
  const classes = cx(
    'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider',
    {
      [className as any]: !!className
    }
  )

  return <div className={classes}>{children}</div>
}

export default Header as FunctionComponent<Props>
