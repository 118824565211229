import { IMasterPDFPage } from 'types/masterPdf'
import { MasterPDF } from 'types/kaseOverview'
import { updateMasterPDF } from 'store/reducers/kaseOverview'
import store from 'store/root'
import server from './server'

export async function getInitialPages(kaseId: number, parent?: string | null) {
  let url = `/api/v1/admin/kases/${kaseId}/master_pdfs/new`

  if (parent) {
    url += `?parent_id=${parent}`
  }

  return server
    .get(url)
    .then((response) => response)
    .catch((error) => error)
}

export async function create(
  kaseId: number,
  watermark: boolean,
  masterPagesList: IMasterPDFPage[],
  adminNote?: string,
  createdBy?: string
) {
  const url = `/api/v1/admin/kases/${kaseId}/master_pdfs`

  return server
    .post(url, {
      admin_note: adminNote,
      created_by: createdBy,
      is_watermarked: watermark,
      kase_pdf_files: masterPagesList
    })
    .then((response) => response)
    .catch((error) => error)
}

export async function createUpload(
  kaseId: number,
  masterPdfParams: {
    created_by?: string
    file_size: number
    file_s3_direct_path: string
  }
) {
  const url = `/api/v1/admin/kases/${kaseId}/master_pdf_uploads`

  return server
    .post(url, {
      master_pdf: masterPdfParams
    })
    .then((response) => response)
    .catch((error) => error)
}

export async function updateMasterPDFWithNote({
  admin_note,
  id,
  kase_id
}: Partial<MasterPDF>) {
  const url = `/api/v1/admin/kases/${kase_id}/master_pdfs/${id}`
  return server
    .patch(url, { admin_note })
    .then((response) => {
      store.dispatch(updateMasterPDF(response.data))
    })
    .catch((error) => error)
}

export async function getDirectUrl(kaseId: number, mpdfId: number) {
  const url = `/api/v1/admin/kases/${kaseId}/master_pdfs/${mpdfId}/direct_url`

  return server
    .get(url)
    .then((response) => response)
    .catch((error) => error)
}

export const downloadLink = (kaseId: number, mpdfId: number) =>
  `${process.env.API_ORIGIN}/api/v1/admin/kases/${kaseId}/master_pdfs/${mpdfId}/download`
