import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KaseData } from 'types/kaseData'

const initialState: Partial<KaseData> = {}

const kaseDataSlice = createSlice({
  name: 'kaseData',
  initialState,
  reducers: {
    setKaseData: (state, action: PayloadAction<KaseData>) => action.payload
  }
})

export const { setKaseData } = kaseDataSlice.actions

export default kaseDataSlice.reducer
