import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Role } from 'types/role'
import { Permission, PermissionSettings } from 'types/permissions'

interface RolesPayloadShape {
  roles: { [key: string]: Role }
  permissions: { [key: string]: Permission }
}

interface PermissionsPayloadShape {
  permissions: { [key: string]: Permission }
}

export interface RolesShape {
  roles: {
    byId: { [key: string]: Role }
    allIds: string[]
  }
  permissions: {
    byId: { [key: string]: Permission }
    allIds: string[]
  }
  permissionSettings: Record<string, []>
}

const initialState: RolesShape = {
  roles: {
    byId: {},
    allIds: []
  },
  permissions: {
    byId: {},
    allIds: []
  },
  permissionSettings: {}
}

const RolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state: RolesShape, action: PayloadAction<RolesPayloadShape>) => {
      const roles = action.payload.roles
      const permissions = action.payload.permissions

      state.roles.byId = roles || {}
      state.roles.allIds = roles ? Object.keys(roles) : []

      state.permissions.byId = permissions || {}
      state.permissions.allIds = permissions ? Object.keys(permissions) : []
    },
    setRole: (state: RolesShape, action: PayloadAction<Role>) => {
      const role = action.payload
      state.roles.byId[role.id] = role
      state.roles.allIds = Object.keys(state.roles.byId)
    },
    setPermissions: (
      state: RolesShape,
      action: PayloadAction<PermissionsPayloadShape>
    ) => {
      const permissions = action.payload.permissions

      state.permissions.byId = permissions || {}
      state.permissions.allIds = permissions ? Object.keys(permissions) : []
    },
    setPermissionSettings: (
      state: RolesShape,
      action: PayloadAction<PermissionSettings>
    ) => {
      state.permissionSettings = action.payload
    }
  }
})

export const {
  setRoles,
  setRole,
  setPermissions,
  setPermissionSettings
} = RolesSlice.actions

export default RolesSlice.reducer
