import server from 'api/server'
import { FeatureFlags } from 'types/index'

export async function fetchFeatureFlags(): Promise<FeatureFlags> {
  return server
    .get<FeatureFlags>('/api/v1/features')
    .then((response) => response.data)
    .catch((error) => error)
}

export default fetchFeatureFlags
