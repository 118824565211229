import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

/**
 * A hook to look up if a specific feature is enabled or not
 * @param feature The name of the feature to use
 *
 * @example
 * import { useFeature } from 'hooks/useFeatureFlags'
 * import { FEATURES } from 'constants/featureFlags'
 *
 * export const NavBarComponent = () => {
 *   const isTestFeatureEnabled = useFeature(FEATURES.TEST_FEATURE)
 *   return <div>This feature is {isTestFeatureEnabled ? 'enabled' : 'disabled'}</div>
 * }
 */
export const useFeature = (feature: string) => {
  const { featureFlags } = useSelector(
    (state: RootState) => state.global.featureFlags
  )
  return featureFlags[feature] || false
}
