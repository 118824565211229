import React, { FunctionComponent, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { signOut } from 'api/authentication'
import ROUTES from 'constants/routes'

enum SignOutState {
  SigningOut,
  SignedOut
}

/**
 * This page signs the user out and then redirects to the sign-in page.
 */
const SignOutScreen: FunctionComponent = () => {
  const [signOutState, setSignOutState] = useState<SignOutState>(
    SignOutState.SigningOut
  )

  useEffect(() => {
    signOut().finally(() => setSignOutState(SignOutState.SignedOut))
  }, [])

  // If we're signed out, redirect to the sign-in page
  if (signOutState === SignOutState.SignedOut) {
    return <Redirect to={ROUTES.SIGN_IN} />
  }

  return (
    <div className="min-h-screen bg-gray-100 sm:flex sm:flex-col sm:justify-center">
      <div className="h-screen sm:h-auto bg-white sm:rounded text-center p-6 sm:mx-auto sm:w-full sm:max-w-md shadow border-t-4 border-indigo-500">
        <p className="text-indigo-700">Signing you out...</p>
      </div>
    </div>
  )
}

export default SignOutScreen
