import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import KaseLog from './KaseLog'
import SelectField, { SelectOption } from 'components/forms/SelectField'
import { formatKaseEvent } from './utils'
import { Log } from 'types/kaseOverview'

interface Props {
  className?: string
  height?: number
}

const KaseLogs: FunctionComponent<Props> = ({ className, height }) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const [filterOption, setFilterOption] = useState('All')
  const [filterOptions, setFilterOptions] = useState<SelectOption[]>([])
  const [logs, setLogs] = useState<Log[]>([])

  useEffect(() => {
    const filterOptions: SelectOption[] = [
      { value: filterOption, label: filterOption }
    ]

    kaseOverview.logs.forEach((log) => {
      const option = { value: log.type, label: formatKaseEvent(log.type) }
      if (
        !filterOptions.some(
          (filterOption) => filterOption.value === option.value
        )
      ) {
        filterOptions.push(option)
      }
    })

    setFilterOptions(filterOptions)
    setLogs(kaseOverview.logs)
  }, [kaseOverview.logs])

  const onFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const filter = event.target.value
    let logs = []
    setFilterOption(filter)

    if (filter === 'All') {
      logs = kaseOverview.logs
    } else {
      logs = kaseOverview.logs.filter((log) => log.type === filter)
    }

    setLogs(logs)
  }

  return (
    <div style={{ height: height }} className={cx(className, 'px-6')}>
      <div className="flex items-baseline">
        <h2 className="font-bold text-base">Case Log</h2>
        <div className="ml-8 w-64">
          <SelectField
            id="case-log-type-selector"
            label="Show"
            inline={true}
            options={filterOptions}
            onChange={onFilterChange}
            value={filterOption}
          />
        </div>
      </div>
      {logs.map((log, index) => (
        <KaseLog key={`kase-logs-${index}`} log={log} />
      ))}
    </div>
  )
}

export default KaseLogs
