import React, { FunctionComponent } from 'react'
import cx from 'classnames'

export type IconWrapperProps = React.SVGAttributes<SVGSVGElement> & {
  className?: string
  alt?: string
}

const IconWrapperOutline: FunctionComponent<IconWrapperProps> = ({
  className,
  children,
  alt,
  ...otherProps
}) => {
  return (
    <svg
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
      className={cx('h-6 w-6 mr-2', className)}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      {alt && <title>{alt}</title>}
      {children}
    </svg>
  )
}

export default IconWrapperOutline
