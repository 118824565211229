import React, { FunctionComponent, ReactNode } from 'react'

interface ExplicitProps {
  body: ReactNode
  className?: string
  footer?: ReactNode
  header?: ReactNode
}

const IssueCard: FunctionComponent<ExplicitProps> = ({
  body,
  className,
  footer,
  header
}) => {
  return (
    <div className={`my-4 ${className}`}>
      {header}
      <div className="rounded-b-sm bg-white border border-gray-200 border-t-0">
        <div className="px-4 py-5">{body}</div>
        {footer && <footer className="mt-4 px-4 pb-4">{footer}</footer>}
      </div>
    </div>
  )
}

export default IssueCard as FunctionComponent<ExplicitProps>
