import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { DocumentStates, DocumentStateValues } from 'constants/documents'
import { IDocument } from 'types/documents'

interface Props {
  status: DocumentStates
  document: IDocument
}

const DocumentState: FunctionComponent<Props> = ({ status }) => {
  const classes = cx({
    'text-red-600': status === DocumentStates.REJECTED,
    'text-green-500': status === DocumentStates.APPROVED,
    'text-gray-400': status === DocumentStates.DISMISSED
  })
  return <span className={classes}>{DocumentStateValues[status]}</span>
}

export default DocumentState as FunctionComponent<Props>
