import React, { FunctionComponent, HTMLAttributes } from 'react'
import cx from 'classnames'

interface Props {
  border?: boolean // TODO: Update storybook docs
  colSpan?: number
}

const TableCell: FunctionComponent<
  HTMLAttributes<HTMLTableCellElement> & Props
> = ({ children, className, border = true, ...otherProps }) => (
  <td
    className={cx(
      className,
      { 'border-t border-gray-200': border },
      'text-sm leading-5 px-6 py-4 align-top'
    )}
    {...otherProps}
  >
    {children}
  </td>
)

export default TableCell
