import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import cx from 'classnames'

import Button from 'components/Button'
import SelectField from 'components/forms/SelectField'

import { KaseReviewField } from 'types/kaseReviewData'
import { IDocument, IDocumentSet, IDocumentRequest } from 'types/documents'

import { formatAnswer, formatTitle } from '../FormatQuestionHelpers'
import { ICreateIssueParams } from 'types/issues'

interface Props {
  kaseState: string
  documentSets: IDocumentSet[]
  handleClickCancelButton: () => void
  handleClickSaveButton: (createIssueParams: ICreateIssueParams) => void
  selectedDocuments?: IDocument[]
  selectedDocRequests?: IDocumentRequest[]
  selectedReviewFields?: KaseReviewField[]
  setShow: (i: boolean) => void
  show: boolean
}

const KaseCreateIssuePanel: FunctionComponent<Props> = ({
  documentSets,
  handleClickCancelButton,
  handleClickSaveButton,
  selectedDocuments,
  selectedDocRequests,
  selectedReviewFields,
  setShow,
  show
}) => {
  const [description, setDescription] = useState('')
  const [associatedDocumentId, setAssociatedDocumentId] = useState(0)

  const hidePanel = () => {
    setShow(false)
    setDescription('')
  }

  const handleSaveIssue = () => {
    const answersIssuesData: { path: string; page_id: number }[] = []
    const addressHistoriesIssuesData: { address_history_id: number }[] = []
    const employmentHistoriesIssuesData: {
      employment_history_id: number
    }[] = []

    selectedReviewFields?.forEach((field) => {
      if (field.field_type === 'question' || field.field_type === 'path') {
        answersIssuesData.push({
          path: field.field_path,
          page_id: field.page_id
        })
      } else if (field.field_type === 'address_history') {
        addressHistoriesIssuesData.push({
          address_history_id: field.field_value.id
        })
      } else if (field.field_type === 'employment_history') {
        employmentHistoriesIssuesData.push({
          employment_history_id: field.field_value.id
        })
      }
    })

    let documentsIssuesData
    if (selectedDoc && selectedDoc.value !== 0) {
      if (selectedDoc.key === 'documentRequest') {
        documentsIssuesData = [{ document_request_id: selectedDoc.value }]
      } else {
        documentsIssuesData = [{ document_id: selectedDoc.value }]
      }
    }

    handleClickSaveButton({
      description,
      addressHistoriesIssuesData,
      employmentHistoriesIssuesData,
      answersIssuesData,
      documentsIssuesData
    })

    hidePanel()
  }

  const handleAssociatedDocumentChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault()
    setAssociatedDocumentId(parseInt(event.target.value))
  }

  const docSelectOptions = [
    { value: 0, key: '', label: 'No Document Selected' }
  ]

  if (documentSets && documentSets.length) {
    documentSets.map((documentSet) => {
      documentSet.document_requests.map((documentRequest) => {
        docSelectOptions.push({
          value: documentRequest.id,
          key: 'documentRequest',
          label: documentRequest.title
        })
        documentRequest.parts.map((part) =>
          part.contents.map((doc) => {
            docSelectOptions.push({
              value: doc.document.id,
              key: 'document',
              label: doc.document.file_name
            })
          })
        )
      })
    })
  }

  // Only show the drop down menu to add an associated document if
  // the Kase is in Ops QA or Ops QA followup. This should also only
  // be shown if we are looking at the create issues panel from the
  // form review tool if there is a selected review field.
  const showDocAssociationDropdown =
    selectedReviewFields && selectedReviewFields.length

  const selectedDoc = docSelectOptions.find((option) => {
    return option.value === associatedDocumentId
  })

  const getFormattedAnswer = (reviewField: KaseReviewField) => {
    const { field_value } = reviewField

    if (reviewField.field_type === 'address_history') {
      const {
        street,
        city,
        province,
        postal_code,
        country_code
      } = field_value.address
      return `${street} ${city}, ${province} ${postal_code} ${country_code}`
    } else if (reviewField.field_type === 'employment_history') {
      const {
        employer_name,
        end_date,
        job_title,
        start_date,
        unemployed
      } = field_value
      if (unemployed) {
        return `Unemployed or Retired, ${start_date} to ${
          end_date || 'Current'
        }`
      }
      return `${job_title} ${employer_name}, ${start_date} to ${
        end_date || 'Current'
      }`
    } else {
      return formatAnswer(field_value)
    }
  }

  return (
    <div
      className={cx('bg-white', {
        hidden: !show
      })}
    >
      <div className="mx-4">
        <div className="flex justify-between mt-4 mb-6">
          <h1 className="font-bold">New Issue</h1>
          <Button
            variant="plain"
            onClick={() => {
              hidePanel()
              handleClickCancelButton()
            }}
          >
            Cancel
          </Button>
        </div>
        {selectedReviewFields?.map((field, index) => {
          return (
            <div key={index}>
              <h3 className="text-sm text-gray-700 mb-2">
                {formatTitle(field.field_name)}
              </h3>
              <h4 className="mb-4">{getFormattedAnswer(field)}</h4>
            </div>
          )
        })}
        {selectedDocuments?.map((doc, index) => {
          return (
            <div key={index}>
              <h3 className="text-sm text-gray-700 mb-2">
                {formatTitle(doc.file_name)}
              </h3>
            </div>
          )
        })}
        {selectedDocRequests?.map((docRequest, index) => {
          return (
            <div key={index}>
              <h4 className="mb-4">{formatTitle(docRequest.title)}</h4>
            </div>
          )
        })}
        <h2 className="text-sm text-gray-700 mb-4">
          Issue Description and Resolution
        </h2>
        <textarea
          id="createIssueTextArea"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full h-56 mb-4 border border-solid border-gray-700"
        />
        {!!showDocAssociationDropdown && (
          <div>
            <h3 className="text-sm text-gray-700 mb-2">
              Select an associated document:
            </h3>
            <div className="flex justify-center mb-4">
              <SelectField
                id="doc-selector"
                onChange={handleAssociatedDocumentChange}
                options={docSelectOptions}
                value={associatedDocumentId}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <Button
            id="saveIssueButton"
            variant="primary"
            className="capitalize"
            onClick={() => handleSaveIssue()}
          >
            Save Issues
          </Button>
        </div>
      </div>
    </div>
  )
}

export default KaseCreateIssuePanel
