import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react'
import SelectField from 'components/forms/SelectField'

import TextInput from 'components/forms/TextInput'
import _debounce from 'lodash/debounce'

interface Props {
  onFilterChange: (includeAdmin: string, emailSearch: string) => void
}

const USER_OPTIONS = [
  {
    label: 'Registered Customers',
    value: 'registered_customers'
  },
  {
    label: 'All Customers',
    value: 'customers'
  },
  {
    label: 'All Customers and Admin Users',
    value: 'all'
  }
]

const UserListFilters: FunctionComponent<Props> = ({ onFilterChange }) => {
  const [includeAdmin, setIncludeAdmin] = useState('registered_customers')
  const [emailSearch, setEmailSearch] = useState('')

  const onIncludeAdminChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const value = event.target.value
    setIncludeAdmin(value)
    onFilterChange(value, emailSearch)
  }

  const onEmailSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailSearch(event.target.value)
    debounceEmailChange(includeAdmin, event.target.value)
  }

  const debounceEmailChange = useMemo(() => {
    return _debounce(onFilterChange, 300)
  }, [])

  return (
    <div className="flex flex-wrap mb-4">
      <div className="w-80 mr-4">
        <SelectField
          label="User Option"
          onChange={(event) => onIncludeAdminChange(event)}
          options={USER_OPTIONS}
          value={includeAdmin}
        />
      </div>

      <div className="w-80 mr-4">
        <TextInput
          id="email-search"
          label="Search"
          placeholder="Search Email"
          onChange={onEmailSearchChange}
        />
      </div>
    </div>
  )
}

export default UserListFilters
