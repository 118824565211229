import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KaseState } from 'types/kaseState'

const kaseStatesSlice = createSlice({
  name: 'kaseState',
  initialState: [] as KaseState[],
  reducers: {
    setKaseStates: (state, action: PayloadAction<KaseState[]>) => action.payload
  }
})

export const { setKaseStates } = kaseStatesSlice.actions

export default kaseStatesSlice.reducer
