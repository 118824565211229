import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  FormEvent
} from 'react'
import cx from 'classnames'
import { Message } from 'types/message'
import { todayOrFormattedDate } from 'utils/format_dates'
import Button from './Button'
import TextArea from './forms/TextArea'
import Label from './forms/Label'
import Can from 'components/Can'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

export interface ThreadPanelMessageProps {
  /**
   * The message to display
   */
  message: Message
  /**
   * The name of the user(s). We recommend naming both the bene and sponsor,
   * if applicable.
   * @example "Olivia and Ijeoma"
   * @example "Jannah"
   */
  userName: string
  /**
   * The initials of the user(s).
   * @example "OI"
   */
  userInitials?: string
  /**
   * Set this to true if the user is a Boundless administrator
   */
  userIsAdmin?: boolean
  /**
   * If true, the message will be editable.
   */
  isEditable?: boolean
  /**
   * If the message is editable, this callback will be triggered when the user
   * begins editing a message.
   */
  onMessageEditStart?: () => void
  /**
   * If the message is editable, this callback will be triggered when the user
   * has finished editing a message.
   */
  onMessageEditComplete?: () => void
  /**
   * If the message is editable, this callback will be triggered when the user
   * submits changes to the message.
   */
  submitMessageEdits?: (data: { id: string; text: string }) => Promise<Message>
}

const ThreadPanelMessage: FunctionComponent<ThreadPanelMessageProps> = ({
  message,
  userName,
  userInitials,
  userIsAdmin,
  isEditable,
  onMessageEditStart,
  onMessageEditComplete,
  submitMessageEdits
}) => {
  const currentUser = useSelector((state: RootState) => state.currentUser)
  const [isEditing, setIsEditing] = useState(false)
  const [messageText, setMessageText] = useState(message.attributes.text)
  const messageAttributes = message.attributes

  const startEditingMessage = () => {
    // Notify the ThreadPanel that this process is beginning
    if (onMessageEditStart) {
      onMessageEditStart()
    }

    // Show the edit controls
    setIsEditing(true)

    // Reset the message text
    setMessageText(messageAttributes.text)
  }

  const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(event.currentTarget.value)
  }

  const cancelEdits = () => {
    // Notify the ThreadPanel that we're done editing
    if (onMessageEditComplete) {
      onMessageEditComplete()
    }

    // Hide the edit UI
    setIsEditing(false)
  }

  const submitEdits = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Construct the data we're sending to the ThreadPanel
    const data = {
      id: message.id,
      issueId: message.attributes.issue_id,
      text: messageText
    }

    // Submit the changes. The container should figure out data updates
    if (submitMessageEdits != null) {
      submitMessageEdits(data).then(() => {
        setIsEditing(false)

        if (onMessageEditComplete) {
          onMessageEditComplete()
        }
      })
    }
  }

  const editMessageButton = () => {
    return (
      <>
        {isEditable && (
          <div className="flex-grow text-right">
            <Button
              slim
              name="Edit this reply"
              onClick={startEditingMessage}
              disabled={isEditing}
            >
              Edit
              <Button.Icon placement="after">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clipRule="evenodd"
                  />
                </svg>
              </Button.Icon>
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <div key={message.id} className="py-6" id={`message-${message.id}`}>
      <div className="flex items-center mb-2">
        <div
          className={cx(
            'w-8 h-8 rounded-full text-white flex items-center justify-center text-sm',
            {
              'bg-blue-700': userIsAdmin,
              'bg-pink-700': !userIsAdmin
            }
          )}
        >
          {userInitials}
        </div>
        <div className="pl-3">
          <div
            className="text-sm leading-tight font-medium text-gray-800"
            data-user-id={messageAttributes.creator_id}
          >
            {userName}
          </div>
          <div className="text-xs leading-tight text-gray-600">
            {todayOrFormattedDate(messageAttributes.created_at)}
          </div>
        </div>

        <Can
          perform="message:update"
          data={{
            userId: currentUser.id,
            messageCreatorId: messageAttributes.creator_id
          }}
          yes={editMessageButton()}
        />
      </div>

      {isEditing ? (
        <form className="space-y-4" onSubmit={submitEdits}>
          <div>
            <Label htmlFor="message-editor">Edit message</Label>
            <TextArea
              id="message-editor"
              value={messageText}
              onChange={onMessageChange}
            />
          </div>
          <div className="space-x-3">
            <Button type="submit">Save message</Button>
            <Button variant="plain" onClick={cancelEdits}>
              Cancel
            </Button>
          </div>
        </form>
      ) : (
        <p className="text-sm text-gray-800 whitespace-pre-wrap">
          {messageAttributes.text}
        </p>
      )}
    </div>
  )
}

export default ThreadPanelMessage
