import React, {
  FunctionComponent,
  AnchorHTMLAttributes,
  MouseEventHandler
} from 'react'
import cx from 'classnames'

export type HyperlinkItem = {
  blank?: boolean
  label: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const Hyperlink: FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  className,
  children,
  href,
  target,
  onClick
}) => {
  return (
    <a
      className={cx('text-blue-600 hover:text-blue-500', {
        [className as string]: !!className
      })}
      href={href}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default Hyperlink
