import React, { FunctionComponent, useEffect } from 'react'
import cx from 'classnames'
import Hyperlink from 'components/Hyperlink'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import Table from 'components/tables/Table'
import ReviewAndSupportLinks from './ReviewAndSupportLinks'

export interface KaseToolsProps {
  className?: string
  setHeight?: (height: number) => void
  accountHolderEmail?: string
  onClickRegistration?: (
    userId: string | number,
    email: string,
    kaseId: string
  ) => void
  completed_eligibility?: boolean
  registered?: boolean
  kaseState?: string
  setShowMagicLinkModal: (show: boolean) => void
}

const KaseTools: FunctionComponent<KaseToolsProps> = ({
  className,
  setHeight,
  accountHolderEmail,
  onClickRegistration,
  setShowMagicLinkModal,
  completed_eligibility,
  registered,
  kaseState
}) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const { account_holder_id } = kaseOverview.contact_info
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const masqToken = kaseOverview.masquerade_token

  useEffect(() => {
    const height = document.getElementById('kase-tools-section')?.clientHeight
    if (setHeight && height) {
      setHeight(height)
    }
  }, [kaseOverview.form_names])

  const getDownloadUrl = (pdf: string) => {
    return `${process.env.API_ORIGIN}/api/v1/admin/kases/${kaseId}/download_pdf?pdf_name=${pdf}`
  }

  return (
    <div
      id="kase-tools-section"
      className={cx(className, 'grid grid-cols-2 px-6')}
    >
      <div className="col-span-1">
        {account_holder_id && (
          <ReviewAndSupportLinks
            accountHolderId={account_holder_id}
            accountHolderEmail={accountHolderEmail}
            completedEligibility={completed_eligibility}
            handleOnClickRegistration={onClickRegistration}
            setShowMagicLinkModal={setShowMagicLinkModal}
            kaseId={kaseId}
            kaseState={kaseState}
            masqueradeToken={masqToken}
            registered={registered}
          />
        )}
      </div>
      <div className="col-span-1">
        <h3 className="font-bold text-base">Application Form PDFs</h3>
        <Table shadow={false}>
          <Table.Body className="bg-gray-100">
            {kaseOverview.form_names
              ? kaseOverview.form_names.map((label, index) => {
                  return (
                    <Table.Row key={`${index}-${label}`} className="mt-6">
                      <Table.Cell
                        border={false}
                        className="pl-0 pr-2 uppercase"
                      >
                        {label}
                      </Table.Cell>
                      <Table.Cell border={false} className="pl-0 pr-2">
                        <Hyperlink href={getDownloadUrl(label)} target="_blank">
                          Download
                        </Hyperlink>
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              : null}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default KaseTools
