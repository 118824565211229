import React, { FunctionComponent, useEffect, useState } from 'react'
import { AdminUser } from 'types/users'
import TextInput from 'components/forms/TextInput'
import Button from 'components/Button'
import { saveAdmin, updateAdmin } from 'api/adminUsers'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import TableRow from 'components/tables/TableRow'
import { Role } from 'types/role'
import CheckboxField from 'components/forms/CheckboxField'
import { fetchRoles } from 'api/roles'
import TableData from 'components/tables/TableData'

interface Props {
  admin: AdminUser
  isCreateMode: boolean
  onCancelCreate: () => void
  roles: {
    byId: { [key: string]: Role }
    allIds: string[]
  }
}
const AdminRow: FunctionComponent<Props> = ({
  admin,
  isCreateMode,
  onCancelCreate,
  roles
}) => {
  const [inEditMode, setInEditMode] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [rolesList, setRolesList] = useState<Role[]>([])
  const [fullName, setFullName] = useState('')
  const [adminRoles, setAdminRoles] = useState<Role[]>([])
  const [selectedAdminRoles, setSelectedAdminRoles] = useState<Role[]>([])
  const [email, setEmail] = useState('')
  const [trelloId, setTrelloId] = useState('')
  const [calendlyLink, setCalendlyLink] = useState('')

  const { setGlobalError } = useGlobalError()

  useEffect(() => {
    setInEditMode(isCreateMode)
  }, [])

  useEffect(() => {
    const adminRoles = admin.relationships
      ? admin.relationships.roles.data.map((role) => roles.byId[role.id])
      : []

    setRolesList(roles.allIds.map((id) => roles.byId[id]))
    setAdminRoles(adminRoles)
    setSelectedAdminRoles(adminRoles)
  }, [roles])

  const setAdminAttributes = () => {
    const adminRoles = admin.relationships
      ? admin.relationships.roles.data.map((role) => roles.byId[role.id])
      : []

    setAdminRoles(adminRoles)
    setSelectedAdminRoles(adminRoles)
    setCalendlyLink(admin.attributes.calendly_link || '')
    setEmail(admin.attributes.email)
    setFullName(admin.attributes.full_name)
    setTrelloId(admin.attributes.trello_member_id || '')
  }

  const onEdit = () => {
    setAdminAttributes()
    setInEditMode(true)
  }

  const onSave = (): Promise<void> => {
    setDisabled(true)
    const adminAttributes = {
      full_name: fullName,
      email: email,
      trello_member_id: trelloId,
      calendly_link: calendlyLink
    }

    const adminRelationships = {
      roles: {
        data: selectedAdminRoles.map((role) => {
          return {
            type: 'roles',
            id: role.id
          }
        })
      }
    }

    if (isCreateMode) {
      return saveAdmin(adminAttributes, adminRelationships)
        .then(() => fetchRoles())
        .catch((error) => setGlobalError(error))
        .finally(() => setDisabled(false))
    } else {
      return updateAdmin(admin.id, adminAttributes, adminRelationships)
        .then(() => fetchRoles())
        .catch((error) => setGlobalError(error))
        .finally(() => {
          setInEditMode(false)
          setDisabled(false)
        })
    }
  }

  const onAdminRoleCheckboxChecked = (checkedRole: Role) => {
    const isChecked = selectedAdminRoles.some(
      (role) => role.id === checkedRole.id
    )

    if (!isChecked) {
      setSelectedAdminRoles([...selectedAdminRoles, checkedRole])
    } else {
      setSelectedAdminRoles([
        ...selectedAdminRoles.filter((role) => role.id !== checkedRole.id)
      ])
    }
  }

  const onCancel = () => {
    isCreateMode && onCancelCreate()
    // reset the inEditMode state value
    setInEditMode(false)
    // reset the admin attributes
    setAdminAttributes()
  }

  if (inEditMode) {
    return (
      <TableRow data-test={`admin-user-${admin.id}`}>
        <TableData className="w-0">{admin.id}</TableData>
        <TableData>
          <TextInput
            value={fullName}
            onChange={(event) => setFullName(event.target.value)}
          />
        </TableData>
        <TableData>
          {rolesList.map((role) => (
            <CheckboxField
              key={`roles-checkbox-${role.id}`}
              label={role.attributes.name}
              checked={selectedAdminRoles.some(
                (adminRole) => adminRole.id === role.id
              )}
              onChange={() => onAdminRoleCheckboxChecked(role)}
            />
          ))}
        </TableData>
        <TableData>
          <TextInput
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </TableData>
        <TableData>
          <TextInput
            value={trelloId}
            onChange={(event) => setTrelloId(event.target.value)}
          />
        </TableData>
        <TableData>
          <TextInput
            value={calendlyLink}
            onChange={(event) => setCalendlyLink(event.target.value)}
          />
        </TableData>
        <TableData>
          <Button
            variant="outline"
            className="mr-3"
            onClick={onSave}
            data-test="save-admin-button"
            disabled={disabled}
          >
            Save
          </Button>
          <Button
            variant="neutral"
            className="mr-3"
            onClick={onCancel}
            data-test="cancel-save-admin-button"
          >
            Cancel
          </Button>
        </TableData>
      </TableRow>
    )
  } else {
    return (
      <TableRow data-test={`admin-user-${admin.id}`}>
        <TableData className="w-0">{admin.id}</TableData>
        <TableData>{admin.attributes.full_name}</TableData>
        <TableData>
          {adminRoles.map((role) => role?.attributes?.name).join(',')}
        </TableData>
        <TableData>{admin.attributes.email}</TableData>
        <TableData>{admin.attributes.trello_member_id}</TableData>
        <TableData
          className="overflow-hidden truncate"
          style={{ maxWidth: '1rem' }}
        >
          {admin.attributes.calendly_link}
        </TableData>
        <TableData>
          <Button
            variant="plain"
            onClick={onEdit}
            data-test="edit-admin-button"
          >
            Edit
          </Button>
        </TableData>
      </TableRow>
    )
  }
}

export default AdminRow
