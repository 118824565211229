import React, { FunctionComponent } from 'react'
import Alert from 'components/Alert'

interface Props {
  className: string
}

const NoAccessError: FunctionComponent<Props> = ({ className }) => {
  return (
    <Alert
      className={className}
      title={`You do not have access to this page. Contact your admin for more info.`}
      variant="error"
    ></Alert>
  )
}

export default NoAccessError as FunctionComponent<Props>
