import React, { FunctionComponent, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Cropper from 'cropperjs'

import Button from 'components/Button'
import RotateIcon from 'components/icons/RotateIcon'
import CropIcon from 'components/icons/CropIcon'

import { IDocument } from 'types/documents'
import { keyIsEnter } from 'utils/keyboard'

enum UiStatus {
  NoChanges,
  PendingChanges,
  SavingChanges
}

interface Props {
  doc: IDocument
  documentUrl: string
  isImage: boolean
  onDocumentReplaced: (file: File, replacedDocId: number) => void
}

const DocumentViewRender: FunctionComponent<Props> = ({
  doc,
  documentUrl,
  isImage,
  onDocumentReplaced
}) => {
  const history = useHistory()
  const imageRef = useRef(null)
  const cropperInstance = useRef<Cropper>()
  const [uiStatus, setUiStatus] = useState<UiStatus>(UiStatus.NoChanges)
  const [cropToolActive, toggleCropTool] = useState<boolean>(false)
  const [croppedImageURI, setCroppedImageURI] = useState<string>()
  const mimeType = doc.file_type

  const inMPDFContext = history.location.pathname.includes('mpdf')

  useEffect(() => {
    if (cropperInstance.current) {
      cropperInstance.current.destroy()
    }

    if (imageRef.current) {
      cropperInstance.current = new Cropper(imageRef.current, {
        checkCrossOrigin: false,
        autoCrop: !!croppedImageURI, // Show the cropper tools by default
        autoCropArea: 1, // Default cropping area size (ratio)
        zoomable: true,
        toggleDragModeOnDblclick: false,
        cropBoxResizable: true,
        cropBoxMovable: true,
        dragMode: croppedImageURI ? 'crop' : 'move',
        guides: false,
        modal: true, // Black overlay above the image when crop tool is on
        center: false, // The crosshair in the middle
        highlight: false, // A white overlay on top of the cropped area
        viewMode: 2,
        background: false // Hide the grid background
      })
    }

    if (!croppedImageURI) {
      setUiStatus(UiStatus.NoChanges)
    }

    return () => {
      // Remove all internal event listeners
      cropperInstance.current?.destroy()
    }
  }, [documentUrl, croppedImageURI])

  const cropImage = (event: KeyboardEvent) => {
    if (cropToolActive && keyIsEnter(event)) {
      const croppedImage = cropperInstance.current?.getCroppedCanvas()

      setUiStatus(UiStatus.PendingChanges)
      setCroppedImageURI(croppedImage?.toDataURL(mimeType))
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', cropImage)

    return () => {
      document.removeEventListener('keydown', cropImage)
    }
  })

  const getContainerData = () => cropperInstance.current?.getContainerData()

  const getCanvasData = () => cropperInstance.current?.getCanvasData()

  const fitImageToContainer = () => {
    // Fit to the container
    cropperInstance.current?.zoomTo(0)

    // Center the canvas
    const canvas = getCanvasData()
    const container = getContainerData()
    if (canvas && container) {
      cropperInstance.current?.setCanvasData(
        Object.assign({}, canvas, {
          left: (container.width - canvas.width) * 0.5,
          top: (container.height - canvas.height) * 0.5
        })
      )
    }
  }

  const addCropperView = (showCropper: boolean) => () => {
    const currentCropper = cropperInstance.current

    toggleCropTool(showCropper)

    if (showCropper) {
      currentCropper?.setDragMode('crop')
    } else {
      currentCropper?.setDragMode('move')
      currentCropper?.clear()
    }
  }

  const resetImage = () => {
    cropperInstance.current?.reset() // resets any rotate info
    setCroppedImageURI(undefined)
    setUiStatus(UiStatus.NoChanges)
    toggleCropTool(false)
  }

  const rotateAndCenterImage = (degrees: number) => {
    setUiStatus(UiStatus.PendingChanges)
    cropperInstance.current?.rotate(degrees)
    fitImageToContainer()
  }

  const rotateCounterClockwise = () => {
    rotateAndCenterImage(-90)
  }

  const rotateClockwise = () => {
    rotateAndCenterImage(90)
  }

  const saveChanges = () => {
    setUiStatus(UiStatus.SavingChanges)

    cropperInstance.current?.getCroppedCanvas().toBlob((blob: Blob | null) => {
      if (blob) {
        const updatedFile = new File([blob], doc.file_name, {
          type: mimeType
        })

        onDocumentReplaced(updatedFile, doc.id)
        setUiStatus(UiStatus.NoChanges)
      }
    })
  }

  const downloadImage = () => {
    const imageUrl = cropperInstance.current
      ?.getCroppedCanvas()
      .toDataURL('image/jpeg')

    const link = document.createElement('a')
    link.download = doc.file_name
    link.href = imageUrl
    link.click()
  }

  const saveButtonLabel =
    uiStatus === UiStatus.SavingChanges ? 'Saving' : 'Save Changes'

  return (
    <div className="col-span-3">
      {isImage ? (
        <div className="col-span-3">
          {!inMPDFContext && (
            <div className="flex justify-center mb-4">
              <div className="space-x-3">
                <Button
                  variant={cropToolActive ? 'primary' : 'outline'}
                  onClick={addCropperView(!cropToolActive)}
                >
                  <Button.Icon placement="before">
                    <CropIcon />
                  </Button.Icon>
                  Crop
                </Button>
                <Button variant="outline" onClick={rotateCounterClockwise}>
                  <Button.Icon placement="before">
                    <RotateIcon style={{ transform: 'scaleX(-1)' }} />
                  </Button.Icon>
                  Rotate Left
                </Button>
                <Button variant="outline" onClick={rotateClockwise}>
                  <Button.Icon placement="before">
                    <RotateIcon />
                  </Button.Icon>
                  Rotate Right
                </Button>
              </div>
              <div className="ml-16 space-x-3">
                <Button variant="plain" onClick={downloadImage}>
                  Download Image
                </Button>
                <Button variant="plain" onClick={fitImageToContainer}>
                  Reset Zoom
                </Button>
                <Button
                  variant="plain"
                  disabled={uiStatus !== UiStatus.PendingChanges}
                  onClick={resetImage}
                >
                  Reset Image
                </Button>
                <Button
                  variant="primary"
                  disabled={uiStatus !== UiStatus.PendingChanges}
                  onClick={saveChanges}
                >
                  {saveButtonLabel}
                </Button>
              </div>
            </div>
          )}
          <div className="col-span-3">
            <img
              ref={imageRef}
              src={croppedImageURI || documentUrl}
              crossOrigin="anonymous"
              style={{ width: '100%' }}
              className="mx-auto"
            />
          </div>
        </div>
      ) : (
        <iframe src={documentUrl} width="100%" height="100%" />
      )}
    </div>
  )
}

export default DocumentViewRender as FunctionComponent<Props>
