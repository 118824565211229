/**
 * Removes slashes from the beginning and end of a URL
 */
export function trimSlashesFromString(text: string) {
  // In today's episode of "Copy-and-pasting from StackOverflow":
  return text.replace(/^\/+|\/+$/g, '')
}

export function pathMatchesUrl(path: string, currentUrl: string) {
  if (path === currentUrl) {
    return true
  }

  const pathParts = trimSlashesFromString(path).split('/')
  const urlParts = trimSlashesFromString(currentUrl).split('/')

  if (pathParts.length > urlParts.length) {
    return false
  }

  for (let index = 0; index < pathParts.length; index++) {
    if (
      !pathParts[index].startsWith(':') &&
      pathParts[index] !== urlParts[index]
    ) {
      return false
    }
  }

  return true
}
