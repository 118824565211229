/**
 * All features that are used in admin should be added here.
 */
export const FEATURES = {
  /**
   *  @enabled insert date FF turned on and which ENV
   *  @added insert date FF added
   *  @remove insert date targeted for removal and/or relevant card
   */
  // FEATURE_FLAG: 'feat_flag'
  /**
   *  @enabled 7/7/23 for dev and review environments
   *  @added 7/7/23
   *  @remove https://boundless-immigration.atlassian.net/browse/LTV-1268
   */
  POST_PAY_CART: 'feat__post_pay_cart'
}
