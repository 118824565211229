import server from 'api/server'

export async function registerUser(
  userId: string | number,
  email: string,
  kaseId: string
) {
  return server.patch(`/api/v1/admin/kases/${kaseId}/users/${userId}`, {
    user: {
      email: email,
      guest: false
    },
    register_user: true
  })
}

export async function sendMagicLink(email: string) {
  return server.post(`/api/v1/admin/users/magic_link`, {
    email: email
  })
}
