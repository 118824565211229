import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd'

import { getRouteForDocumentReviewTool } from 'constants/routes'
import { create, getInitialPages } from 'api/masterPdf'
import { IMasterPDFPage } from 'types/masterPdf'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { RootState } from 'store/root'

import MainLayout from 'layouts/MainLayout'
import SubNavigation from 'components/SubNavigation'
import LoadingSpinner from 'components/LoadingSpinner'
import Hyperlink from 'components/Hyperlink'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import MPDFSidebarSection from './MPDFSidebarSection'
import MPDFDroppable from './dnd/MPDFDroppable'
import { IDocument } from 'types/documents'
import { ReadOnlyDocumentView } from 'pages/document_review/DocumentView'
import { fetchFileUrl } from 'api/documentReview'

/* This is the MPDFTool that ops will use to generate MPDFs from a list of files */
/* You can view this tool at /mpdf/:kaseId */
/* You can also navigate here from the overview page (/overview/:kaseId) at the bottom by clicking */
/* the 'New Master PDF' button */
// TODO: Document all functions
const MPDFTool: FunctionComponent = () => {
  const [currentDeleteFile, setCurrentDeleteFile] = useState<IMasterPDFPage>()
  const [currentDeleteFileIndex, setCurrentDeleteFileIndex] = useState(0)
  const [
    currentDeleteFileDroppableId,
    setCurrentDeleteFileDroppableId
  ] = useState('')
  const [documentInView, setDocumentInView] = useState<IDocument>()
  const [documentUrl, setDocumentUrl] = useState('')
  const [addWatermark, setAddWatermark] = useState(false)
  const [isFormsOnly, setIsFormsOnly] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)
  const [isSuccessfullyCreated, setIsSuccessfullyCreated] = useState(false)
  const [selectedFileKeys, setSelectedFileKeys] = useState<string[]>([])
  const [usedFiles, setUsedFiles] = useState<IMasterPDFPage[]>([])
  const [unusedFiles, setUnusedFiles] = useState<IMasterPDFPage[]>([])
  const [draggedFileKey, setDraggedFileKey] = useState('')

  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const { setGlobalError } = useGlobalError()

  const search = useLocation().search
  const newFromMpdfId = new URLSearchParams(search).get('new_from_mpdf')
  const currentUser = useSelector((state: RootState) => state.currentUser)

  useEffect(() => {
    setIsLoading(true)

    getInitialPages(parseInt(kaseId), newFromMpdfId)
      .then((response) => {
        const usedFiles = getFilesWithKeys(response.data.used)
        const unusedFiles = getFilesWithKeys(response.data.unused)

        setUsedFiles(usedFiles)
        setUnusedFiles(unusedFiles)
        return response
      })
      .catch((error) => {
        setGlobalError(error)
        return error
      })
      .finally(() => setIsLoading(false))

    window.addEventListener('click', onWindowClick)
    window.addEventListener('keydown', onWindowKeyDown)

    return () => {
      window.removeEventListener('click', onWindowClick)
      window.removeEventListener('keydown', onWindowKeyDown)
    }
  }, [])

  useEffect(() => {
    if (documentInView) {
      setIsLoading(true)

      fetchFileUrl(kaseId, documentInView.id)
        .then((resp: any) => setDocumentUrl(resp.data.url))
        .catch((error) => {
          setDocumentUrl('')
          setGlobalError(error)
        })
        .finally(() => setIsLoading(false))
    }
  }, [documentInView])

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return
    }
    unselectAll()
  }

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return
    }
    if (event.key === 'Escape') {
      unselectAll()
    }
  }

  const unselectAll = () => {
    setSelectedFileKeys([])
  }

  const getFilesWithKeys = (files: IMasterPDFPage[]) => {
    return files.map((file, index) => {
      // We need additional random parts in case New From This had duplicate options
      let key =
        `pdf-${file.pdf_file_option.id}-key` +
        '-starting-index-' +
        index +
        Math.random()

      // Add document id to prevent duplicate keys if its of type document
      if (file.document) {
        key += `-docId-${file.document.id}`
      }

      return {
        ...file,
        key
      }
    })
  }

  const onDragStart = (start: DragStart) => {
    const draggedFileKey = start.draggableId
    const selected = selectedFileKeys.find((key) => key === draggedFileKey)

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll()
    }

    setDraggedFileKey(draggedFileKey)
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    setDraggedFileKey('')

    // If they drag it nowhere do nothing
    if (!destination) {
      return
    }

    // If they dragged it back to the same place do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // Find out which column we are dragging to and from
    const startIsUsedFiles = source.droppableId === 'used' ? true : false
    const finishIsUsedFiles = destination.droppableId === 'used' ? true : false

    const start = startIsUsedFiles ? usedFiles : unusedFiles
    const finish = finishIsUsedFiles ? usedFiles : unusedFiles

    // newStartFileArray is a copy of the start array that we will manipulate and use it to replace what's
    // in state
    const newStartFileArray = Array.from(start)
    const draggedFiles =
      selectedFileKeys.length > 0
        ? selectedFileKeys.reduce((files: IMasterPDFPage[], key) => {
            const foundFile = start.find((file) => file.key === key)
            if (foundFile) {
              files.push(foundFile)
            }
            return files
          }, [])
        : [start[source.index]]

    // Sort the files by their original index to maintain their order after dragging
    draggedFiles.sort((fileA, fileB) => {
      const indexA = start.findIndex(
        (file) => file.pdf_file_option.id === fileA.pdf_file_option.id
      )
      const indexB = start.findIndex(
        (file) => file.pdf_file_option.id === fileB.pdf_file_option.id
      )
      return indexA - indexB
    })

    // Delete the selected files from the array
    draggedFiles.forEach((file) => {
      const deleteIndex = newStartFileArray.findIndex(
        (fileFromNewArr) => fileFromNewArr.key === file.key
      )
      newStartFileArray.splice(deleteIndex, 1)
    })

    // Calculate destination offset
    const dragged = start[source.index]
    const destinationIndexOffset: number = draggedFiles.reduce(
      (previous: number, current: IMasterPDFPage): number => {
        if (current.key === dragged.key) {
          return previous
        }

        const index = start.indexOf(current)

        if (index >= destination.index) {
          return previous
        }

        // the selected item is before the destination index
        // we need to account for this when inserting into the new location
        return previous + 1
      },
      0
    )

    // Dragging into the same list
    if (start === finish) {
      // Replace the selected files at the new position
      newStartFileArray.splice(
        destination.index - destinationIndexOffset,
        0,
        ...draggedFiles
      )

      // Replace new list data in the state
      if (startIsUsedFiles) {
        setUsedFiles(newStartFileArray)
      } else {
        setUnusedFiles(newStartFileArray)
      }
    }
    // Moving from one list to another
    else {
      const newFinishFileArray = Array.from(finish)
      newFinishFileArray.splice(destination.index, 0, ...draggedFiles)

      // Replace new list data in the state
      if (startIsUsedFiles) {
        setUsedFiles(newStartFileArray)
        setUnusedFiles(newFinishFileArray)
      } else {
        setUnusedFiles(newStartFileArray)
        setUsedFiles(newFinishFileArray)
      }
    }
  }

  const onCopyFileBtnClicked = (
    file: IMasterPDFPage,
    index: number,
    droppableId: string
  ) => {
    // Make a copy of the file and give it a new key
    // Attach random number at the end to prevent duplicate keys
    // (Files dont have key properties initially)
    const newFile = {
      ...file,
      key:
        file.pdf_file_option.id +
        '-copy-inserted-at-index-' +
        index +
        Math.random()
    }

    let newFileList

    // Insert copy of file into list
    if (droppableId === 'used') {
      newFileList = Array.from(usedFiles)
      newFileList.splice(index, 0, newFile)
      setUsedFiles(newFileList)
    } else {
      newFileList = Array.from(unusedFiles)
      newFileList.splice(index, 0, newFile)
      setUnusedFiles(newFileList)
    }
  }

  const onDeleteFileBtnClicked = (index: number, droppableId: string) => {
    setCurrentDeleteFileIndex(index)
    setCurrentDeleteFileDroppableId(droppableId)

    if (droppableId === 'used') {
      setCurrentDeleteFile(usedFiles[index])
    } else {
      setCurrentDeleteFile(unusedFiles[index])
    }

    setShowDeleteFileModal(true)
  }

  /**
   * Sets the clicked file as the selected file key
   *
   * @param key - The key of the file that was clicked
   */
  const toggleSelection = (key: string) => {
    setSelectedFileKeys([key])
  }

  const isLastSelectedFileInList = (fileList: IMasterPDFPage[]) => {
    const lastSelectedFileIndex = fileList.findIndex(
      (file) => file.key === selectedFileKeys[selectedFileKeys.length - 1]
    )

    return lastSelectedFileIndex > -1
  }

  /**
   * Adds/removes the clicked file in the selected file array
   *
   * @param key - The key of the file that was clicked
   */
  const toggleSelectionInGroup = (key: string, fileList: IMasterPDFPage[]) => {
    let newSelectedFileKeys = Array.from(selectedFileKeys)

    // Check if last selected file and selected file are in the same list
    if (isLastSelectedFileInList(fileList)) {
      // Remove the id if it was already selected, else add it to the array
      if (newSelectedFileKeys.includes(key)) {
        const index = newSelectedFileKeys.indexOf(key)
        newSelectedFileKeys.splice(index, 1)
      } else {
        newSelectedFileKeys = [...newSelectedFileKeys, key]
      }

      setSelectedFileKeys([...newSelectedFileKeys])
    }
    // Otherwise, clear the selection and add just the one file
    else {
      unselectAll()
      toggleSelection(key)
    }
  }

  const onDeleteFileModalConfirmBtnClicked = (
    index: number,
    droppableId: string
  ) => {
    let newFileList

    if (droppableId === 'used') {
      newFileList = Array.from(usedFiles)
      newFileList.splice(index, 1)
      setUsedFiles(newFileList)
    } else {
      newFileList = Array.from(unusedFiles)
      newFileList.splice(index, 1)
      setUnusedFiles(newFileList)
    }

    setShowDeleteFileModal(false)
  }

  const onViewDocumentBtnClicked = (document: IMasterPDFPage) => {
    setDocumentInView(document.document)
  }

  const onCreateMasterPdfBtnClick = (adminNote: string) => {
    setIsLoading(true)
    setIsSuccessfullyCreated(false)

    let mpdfFileList = usedFiles

    if (isFormsOnly) {
      mpdfFileList = []
      usedFiles.forEach((usedFile) => {
        const isDocTypeForm = ['form', 'form-addendum', 'static'].includes(
          usedFile.pdf_file_option.pdf_file_type
        )
        if (isDocTypeForm) {
          mpdfFileList.push(usedFile)
        }
      })
    }

    create(
      parseInt(kaseId),
      addWatermark,
      mpdfFileList,
      adminNote,
      currentUser.fullName
    )
      .then((response) => {
        setIsSuccessfullyCreated(true)
        return response
      })
      .catch((error) => {
        setIsSuccessfullyCreated(false)
        setGlobalError(error)
        return error
      })
      .finally(() => setIsLoading(false))
  }

  /**
   * Adds multiple files to the selected files from the last selected file to the shift clicked file
   *
   * @param key - The key of the file that was clicked
   * @param fileList - The list of files that the selection is pulled from
   */
  const multiSelectTo = (key: string, fileList: IMasterPDFPage[]) => {
    // Check if last selected file and selected file are in the same list
    if (isLastSelectedFileInList(fileList)) {
      const lastSelectedFileIndex = fileList.findIndex(
        (file) => file.key === selectedFileKeys[selectedFileKeys.length - 1]
      )
      const toFileIndex = fileList.findIndex((file) => file.key === key)

      const startIndex =
        lastSelectedFileIndex < toFileIndex
          ? lastSelectedFileIndex
          : toFileIndex
      const endIndex =
        lastSelectedFileIndex > toFileIndex
          ? lastSelectedFileIndex
          : toFileIndex

      const fileKeys = fileList
        .slice(startIndex, endIndex + 1)
        .map((file) => file.key)

      addselectedFileKeys([...fileKeys])
    }
    // Otherwise, clear the selection and add just the one file
    else {
      unselectAll()
      toggleSelection(key)
    }
  }

  const addselectedFileKeys = (keys: string[]) => {
    const addedKeys: string[] = []
    keys.forEach((key) => {
      if (!selectedFileKeys.includes(key)) {
        addedKeys.push(key)
      }
    })

    setSelectedFileKeys([...selectedFileKeys, ...addedKeys])
  }

  if (isLoading) {
    return (
      <div className="mt-48">
        <LoadingSpinner />
      </div>
    )
  }

  if (documentInView) {
    return (
      <ReadOnlyDocumentView
        backButtonLabel="Back to MPDF Tool"
        document={documentInView}
        documentUrl={documentUrl}
        onBackButtonClicked={() => setDocumentInView(undefined)}
      />
    )
  }

  return (
    <MainLayout subNavigation={<SubNavigation context="mpdf" />}>
      <ConfirmationModal
        isOpen={showDeleteFileModal}
        title={`Delete duplicate of ${currentDeleteFile?.pdf_file_option.name}`}
        description="Are you sure you want to delete this file? This will delete the duplicated file and the original file will remain."
        confirmLabel="Yes, delete this file"
        onConfirm={() =>
          onDeleteFileModalConfirmBtnClicked(
            currentDeleteFileIndex,
            currentDeleteFileDroppableId
          )
        }
        onRequestClose={() => setShowDeleteFileModal(false)}
      />
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className="grid grid-cols-11 h-full">
          <div className="col-span-4 ml-3 p-3">
            <h1 className="font-bold">Files</h1>
            <div
              className="border border-gray-300 flex flex-col flex-grow items-center mt-3 pt-4"
              style={{ height: '635px' }}
            >
              <div style={{ width: '90%' }}>
                <Hyperlink href={getRouteForDocumentReviewTool(kaseId)}>
                  Upload additional documents
                </Hyperlink>
              </div>
              <hr className="mt-4 w-11/12" />
              {/* Set the height so that the droppable area always fills the container */}
              {/* Probably a better way to do this then setting manual height but couldn't get flex grow to work for some reason */}
              <div style={{ height: '552px', width: '90%' }}>
                <MPDFDroppable
                  draggedFileKey={draggedFileKey}
                  droppableId="unused"
                  fileList={unusedFiles}
                  multiSelectTo={(id) => multiSelectTo(id, unusedFiles)}
                  onCopyFileBtnClicked={onCopyFileBtnClicked}
                  onDeleteFileBtnClicked={onDeleteFileBtnClicked}
                  toggleSelectionInGroup={(id) =>
                    toggleSelectionInGroup(id, unusedFiles)
                  }
                  onViewDocumentBtnClicked={onViewDocumentBtnClicked}
                  selectedFileKeys={selectedFileKeys}
                />
              </div>
            </div>
          </div>
          <div className="col-span-4 p-3">
            <h1 className="font-bold">MPDF</h1>
            <div
              className="border border-gray-300 flex flex-col flex-grow items-center mt-3 pt-4"
              style={{ height: '635px' }}
            >
              {/* Set the height so that the droppable area always fills the container */}
              <div style={{ height: '100%', width: '90%' }}>
                <MPDFDroppable
                  draggedFileKey={draggedFileKey}
                  droppableId="used"
                  fileList={usedFiles}
                  multiSelectTo={(id) => multiSelectTo(id, usedFiles)}
                  onCopyFileBtnClicked={onCopyFileBtnClicked}
                  onDeleteFileBtnClicked={onDeleteFileBtnClicked}
                  toggleSelectionInGroup={(id) =>
                    toggleSelectionInGroup(id, usedFiles)
                  }
                  onViewDocumentBtnClicked={onViewDocumentBtnClicked}
                  selectedFileKeys={selectedFileKeys}
                />
              </div>
            </div>
          </div>
          <MPDFSidebarSection
            addWatermark={addWatermark}
            isFormsOnly={isFormsOnly}
            isSuccessfullyCreated={isSuccessfullyCreated}
            onCreateMasterPdfBtnClick={onCreateMasterPdfBtnClick}
            onIsAddWatermarkChecked={() => setAddWatermark(!addWatermark)}
            onIsFormsOnlyChecked={() => setIsFormsOnly(!isFormsOnly)}
          />
        </div>
      </DragDropContext>
    </MainLayout>
  )
}

export default MPDFTool
