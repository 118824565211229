import React, { FunctionComponent, HTMLAttributes } from 'react'

const TableHead: FunctionComponent<HTMLAttributes<HTMLTableSectionElement>> = ({
  children,
  ...otherProps
}) => {
  return <thead {...otherProps}>{children}</thead>
}

export default TableHead
