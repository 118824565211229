import Button from 'components/Button'
import BlankModal from 'components/modals/BlankModal'
import Table from 'components/tables/Table'
import React, { FunctionComponent } from 'react'
import { AdminUser } from 'types/users'
import { Task } from 'types/task'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

interface Props {
  show: boolean
  onRequestClose: () => void
  adminUsers: AdminUser[]
  selectedTasks: Task[]
  handleTaskAssignModalAssignButtonClick: (adminId: string) => void
}

const TasksAssignModal: FunctionComponent<Props> = ({
  show,
  onRequestClose,
  adminUsers,
  selectedTasks,
  handleTaskAssignModalAssignButtonClick
}) => {
  const tasks = useSelector((state: RootState) => state.kaseTasks)

  /**
   * Returns the string for the number of assigned tasks to a user
   *
   * @remarks
   * This will break up each task type and return each task type amount separated by commas
   * e.g. '3 Data Reviews, 5 Document Reviews'
   *
   * @param user - The AdminUser that we are constructing the string for
   * @returns The string that says how many tasks are assigned to the user
   *
   * @beta
   */
  const getAssignedTasksString = (user: AdminUser) => {
    const userTasks = user.relationships.tasks.data
    // Check if tasks has been initialized to avoid race condition
    if (userTasks.length > 0 && Object.keys(tasks.byId).length > 0) {
      const taskTypes: { [key: string]: number } = {}

      userTasks.forEach((userTask) => {
        // 'action' in the task attributes is what describes what kind of task it is
        // TODO: Does the back end only return tasks that have not been resolved?
        const type = tasks.byId[userTask.id]?.attributes.action
        // Increment type by 1, or initialize the value to 1
        if (type) {
          taskTypes[type] = taskTypes[type] + 1 || 1
        }
      })

      const assignedTasksString = Object.keys(taskTypes)
        .map((taskType) => {
          return `${taskTypes[taskType]} ${taskType} tasks`
        })
        .join(',')

      return assignedTasksString
    } else {
      return '0 assigned tasks'
    }
  }

  return (
    <BlankModal
      isOpen={show}
      onRequestClose={onRequestClose}
      modalWidth="40rem"
    >
      <div className="p-4">
        <h1 className="text-xl mb-4">
          Assign {selectedTasks.length} reviews to...
        </h1>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header className="w-3/12">User</Table.Header>
              <Table.Header className="w-6/12">Workload</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {adminUsers.map((user) => {
              return (
                <Table.Row key={user.id}>
                  <Table.Cell>{user.attributes.full_name}</Table.Cell>
                  <Table.Cell>{getAssignedTasksString(user)}</Table.Cell>
                  <Table.Cell>
                    <Button
                      variant="default"
                      onClick={() =>
                        handleTaskAssignModalAssignButtonClick(user.id)
                      }
                    >
                      Assign
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    </BlankModal>
  )
}

export default TasksAssignModal as FunctionComponent<Props>
