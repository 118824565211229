import React, { FunctionComponent } from 'react'
import QuestionElement from 'components/question-element'
import { QuestionnairePage } from 'types/questionnaire'
import ReactMarkdown from 'react-markdown'

interface Props {
  handleQuestionAnswer: (
    value: string,
    key: string,
    answerId: string | null
  ) => void
  questionPages: QuestionnairePage[]
  emailErrorMessage?: string
}

const SinglePageQuestionnaire: FunctionComponent<Props> = ({
  handleQuestionAnswer,
  questionPages,
  emailErrorMessage
}) => {
  const formatQuestionText = (text: string) => {
    let str = text.replace('()', '')
    if (str.charAt(str.length - 1) === ']') {
      str = str.split('[')[0]
    }
    str = str.replace('[', '').replace(']', '')
    return <ReactMarkdown>{str}</ReactMarkdown>
  }

  const renderSimpleJit = (text: string, key: string) => (
    <ReactMarkdown className="text-blue-700" key={key}>
      {text}
    </ReactMarkdown>
  )

  return (
    <>
      {questionPages.map((question, index) => {
        const questionPageAttr = question.attributes
        const questionPageType = questionPageAttr.type
        const questionPageName = questionPageAttr.name
        if (
          questionPageType === 'LandingPage' ||
          questionPageType === 'EligibilityReport' ||
          questionPageType === 'IneligiblePage' ||
          questionPageType === 'LoginPage'
        ) {
          return null
        }

        const pageElements = questionPageAttr?.page_elements
        const pageElementQuestions =
          pageElements.length > 0 &&
          pageElements.map((el) => {
            if (el.type === 'question_set') {
              const elementText = el.attributes.markdown
              const elementQuestions = el.attributes.questions
              const questionMap = elementQuestions.map((question) => {
                return question?.attributes?.input_type === 'email' ? (
                  <QuestionElement
                    key={question.id}
                    question={question}
                    handleQuestionAnswer={handleQuestionAnswer}
                    emailErrorMessage={emailErrorMessage}
                  />
                ) : (
                  <QuestionElement
                    key={question.id}
                    question={question}
                    handleQuestionAnswer={handleQuestionAnswer}
                  />
                )
              })

              const jitMap = el.attributes.jit_elements?.map((jit, index) =>
                renderSimpleJit(jit.markdown || '', `${jit.markdown}-${index}`)
              )

              return (
                <div key={el.id}>
                  {formatQuestionText(elementText)} <div>{questionMap}</div>
                  <div>{jitMap}</div>
                </div>
              )
            } else if (el.type === 'page_element') {
              return renderSimpleJit(
                el.attributes.markdown || '',
                `${el.attributes.markdown}-${index}`
              )
            }
          })

        return (
          <div
            key={`${questionPageName}-${index}`}
            className="bg-white rounded p-4 m-2 w-full"
          >
            <span className="font-bold">
              {index}. {questionPageName}
            </span>
            {pageElementQuestions}
            {questionPageAttr.page_jit_elements?.map((jit, index) =>
              renderSimpleJit(jit.markdown || '', `${jit.markdown}-${index}`)
            )}
          </div>
        )
      })}
    </>
  )
}

export default SinglePageQuestionnaire
