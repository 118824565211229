import store from 'store/root'
import server from './server'

import { setStatesAndEvents } from 'store/reducers/kaseStateEvents'
import { fetchOverview } from './overview'

export async function sendStateEvent(
  kaseId: string | number,
  stateEvent: string
) {
  return server
    .post(`/api/v1/admin/kases/${kaseId}/events?event=${stateEvent}`)
    .then(() => {
      fetchOverview(kaseId)
      fetchKaseStatesAndEvents(kaseId)
    })
}

export async function fetchKaseStatesAndEvents(kaseId: string | number) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/events/states_and_events`)
    .then((response) => {
      store.dispatch(setStatesAndEvents(response.data))
    })
}

export async function manuallyTransitionState(
  kaseId: string | number,
  from: string,
  event: string
) {
  return server
    .post(
      `/api/v1/admin/kases/${kaseId}/events/manual?from=${from}&event=${event}`
    )
    .then(() => {
      fetchOverview(kaseId)
      fetchKaseStatesAndEvents(kaseId)
    })
}
