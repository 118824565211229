import React, { FunctionComponent, useState } from 'react'

import { IssueStatus } from 'constants/issues'
import TabCountLabel from 'components/tabs/TabCountLabel'
import TabList from 'components/tabs/TabList'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { getIssueCountByStatus } from 'store/selectors/kaseIssues'

interface Props {
  setCurrentActiveView: (status: IssueStatus) => void
}

const StatusTabs: FunctionComponent<Props> = ({ setCurrentActiveView }) => {
  const issuesByStatus = useSelector((state: RootState) =>
    getIssueCountByStatus(state)
  )

  const [currentTab, setCurrentTab] = useState(0)

  const onTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex)
    setCurrentActiveView(
      tabIndex === 0 ? IssueStatus.Open : IssueStatus.Resolved
    )
  }

  return (
    <TabList
      onTabChange={onTabChange}
      selected={currentTab}
      mobileLabel="Issue status"
      tabs={[
        <>
          Open issues
          <TabCountLabel isActive={currentTab === 0}>
            {issuesByStatus.open}
          </TabCountLabel>
        </>,
        <>
          Resolved issues
          <TabCountLabel isActive={currentTab === 1}>
            {issuesByStatus.resolved}
          </TabCountLabel>
        </>
      ]}
    />
  )
}

export default StatusTabs
