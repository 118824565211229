import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostPayCartOption } from 'types/payment'

const initialState = [] as PostPayCartOption[]

const postPayCartOptionsSlice = createSlice({
  name: 'postPayCartOptions',
  initialState,
  reducers: {
    setPostPayCartOptions: (
      state,
      action: PayloadAction<PostPayCartOption[]>
    ) => action.payload
  }
})

export const { setPostPayCartOptions } = postPayCartOptionsSlice.actions

export default postPayCartOptionsSlice.reducer
