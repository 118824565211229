import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

const PageNotFound: FunctionComponent = ({}) => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900 mb-4">
          Page not found
        </h1>
        <p className="text-center text-gray-800 mb-4">
          The page you're looking for doesn't exist at this URL.
        </p>
        <p className="text-center">
          <Link className="text-blue-600 hover:underline" to="/">
            Go home →
          </Link>
        </p>
      </div>
    </div>
  )
}

export default PageNotFound
