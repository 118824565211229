import React, {
  FunctionComponent,
  FormEvent,
  useState,
  useMemo,
  useEffect
} from 'react'
import { resolveTask } from 'api/tasks'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import Button from 'components/Button'
import BlankModal from 'components/modals/BlankModal'
import TextArea from 'components/forms/TextArea'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { getCurrentOpenTask } from 'store/selectors/kaseTasks'
import TaskStatusIndicator from 'pages/issues/update_task_options/TaskStatusIndicator'
import ExclamationIcon from 'components/icons/ExclamationIcon'
import BadgeCheckIcon from 'components/icons/BadgeCheckIcon'
import { getLinkToMpdf } from 'utils/format_link'

interface Props {
  kaseId: string
}

const ResolveQATask: FunctionComponent<Props> = ({ kaseId }) => {
  const { setGlobalError } = useGlobalError()
  const currentOpenTask = useSelector((state: RootState) =>
    getCurrentOpenTask(state)
  )

  const [showModal, setShowModal] = useState(false)
  const [canFinishTask, setCanFinishTask] = useState(false)

  useEffect(() => {
    setCanFinishTask(
      currentOpenTask?.attributes?.metadata?.mpdfs_ready || false
    )
  }, [currentOpenTask])

  const confirmResolveTask = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const resolutionSummary = formData.get('summary') as string

    resolveTask({ kaseId, taskId: currentOpenTask?.id, resolutionSummary })
      .catch((error) => {
        setGlobalError(error)
      })
      .finally(() => {
        setShowModal(false)
      })
  }

  const getTaskIndicatorAttributes = () => {
    if (!currentOpenTask) {
      return {
        icon: <BadgeCheckIcon />,
        title: 'No tasks to resolve',
        className: 'text-green-700'
      }
    }
    if (canFinishTask) {
      return {
        icon: <BadgeCheckIcon />,
        title: 'Ready for Lawyer Review',
        className: 'text-green-500'
      }
    } else {
      return {
        icon: <ExclamationIcon />,
        title: (
          <>
            Customer & lawyer MPDFs needed
            {getLinkToMpdf(kaseId)}
          </>
        ),
        className: 'text-red-700'
      }
    }
  }

  const { icon, title, className } = useMemo(getTaskIndicatorAttributes, [
    currentOpenTask
  ])

  return (
    <>
      <TaskStatusIndicator icon={icon} title={title} className={className} />
      <BlankModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className="p-6">
          <h3 className="text-lg text-gray-800 leading-6 font-medium mb-2">
            Complete QA task
          </h3>
          <form onSubmit={confirmResolveTask}>
            <div>
              <p className="text-sm text-gray-600 leading-5 mb-2">
                If needed, you can add notes about either the QA or the MPDF
                here. This will be added to the Trello card for use during
                Lawyer Review. It will also trigger an automated email to the
                customer.
              </p>
              <TextArea
                placeholder="Insert notes here (optional)"
                name="summary"
              />
            </div>
            <div className="mt-6 space-x-3">
              <Button type="submit" variant="primary">
                Complete task
              </Button>
              <Button onClick={() => setShowModal(false)}>Cancel</Button>
            </div>
          </form>
        </div>
      </BlankModal>

      <Button
        variant="primary"
        disabled={!canFinishTask}
        onClick={() => setShowModal(true)}
      >
        Send to lawyer review
      </Button>
    </>
  )
}

export default ResolveQATask as FunctionComponent<Props>
