import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/root'
import { getCustomerFirstNamesFromKase } from 'utils/kase_utils'
import { todayOrFormattedDate } from 'utils/format_dates'

import Label from 'components/forms/Label'
import Alert from 'components/Alert'
import { getOpenOrMostRecentQATask } from 'store/selectors/kaseTasks'

const TaskInfo: FunctionComponent = () => {
  const task = useSelector((state: RootState) =>
    getOpenOrMostRecentQATask(state)
  )

  if (!task) {
    return (
      <Alert border className="mb-4" variant="info" title="No open tasks">
        <p>
          This application doesn't have any open tasks. A task is usually
          created when the Trello card is moved to "Ready for QA".
        </p>
      </Alert>
    )
  }

  const customerInfo = useSelector((state: RootState) => state.kaseData)

  const taskMetadata = task.attributes?.metadata || {}

  const lastUpdatedDate = () => {
    if (task.attributes.state === 'resolved') {
      return {
        label: 'Resolved at',
        date: taskMetadata.resolved_at
      }
    }

    return {
      label: 'Last sent to customer',
      date: taskMetadata.last_assigned_to_customer
    }
  }

  const dateData = lastUpdatedDate()

  return (
    <div className="p-4 md:p-8 bg-white mb-6 rounded-b-sm flex flex-col border border-gray-200">
      {taskMetadata.resolution_summary && (
        <div className="mb-6">
          <Label>Resolution summary</Label>
          <p className="text-sm text-gray-700">
            {taskMetadata.resolution_summary}
          </p>
        </div>
      )}
      <div className="sm:flex items-end justify-between space-y-4 sm:space-y-0">
        <div>
          <Label>Customers</Label>
          <p className="text-sm text-gray-700 leading-3">
            {getCustomerFirstNamesFromKase(customerInfo).join(' & ')}
          </p>
        </div>
        <div>
          <Label>{dateData.label}</Label>
          <p className="text-sm text-gray-700 leading-3">
            {todayOrFormattedDate(dateData.date) || 'N/A'}
          </p>
        </div>
        <div>
          <Label>Number of returns</Label>
          <p className="text-sm text-gray-700 leading-3">
            {taskMetadata.number_of_returns || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TaskInfo as FunctionComponent
