import React, { FunctionComponent, useState, useEffect } from 'react'
import MainLayout from 'layouts/MainLayout'
import DocumentReviewFilters from './Filters'
import DocumentReviewTable from './Table'
import {
  fetchDocumentReviews,
  DocumentReviewListFilters
} from 'api/documentReview'
import { KaseDocumentReview } from 'types/documentReview'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

const DocumentReviewDashboard: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const [docReviews, setDocReviews] = useState<KaseDocumentReview[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getDocumentReviewList = (filters?: DocumentReviewListFilters) => {
    setIsLoading(true)
    fetchDocumentReviews(filters)
      .then((docReviews) => {
        setDocReviews(docReviews)
      })
      .catch((error) => {
        // Display the error in the GlobalErrorWrapper
        setGlobalError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(getDocumentReviewList, [])

  const updateFilters = (newFilters: DocumentReviewListFilters) => {
    getDocumentReviewList(newFilters)
  }

  return (
    <MainLayout>
      <div className="p-4">
        <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
          Document Review Dashboard
        </h1>
        <DocumentReviewFilters updateFilters={updateFilters} />
        <DocumentReviewTable
          isLoading={isLoading}
          documentReviews={docReviews}
        />
      </div>
    </MainLayout>
  )
}

export default DocumentReviewDashboard as FunctionComponent
