import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'store/root'
import cx from 'classnames'
import IKaseIndexRouteParams from '../../../utils/IKaseIndexRouteParams'

import { fetchIssues, saveMessage, updateMessage } from 'api/issues'
import { fetchDocumentSetsByKaseId } from 'api/documentReview'
import { fetchOverview } from 'api/overview'
import { fetchAllAdmins } from 'api/adminUsers'
import { fetchKaseData } from 'api/kaseData'

import KaseIssuesList from './KaseIssuesList'
import KaseCreateIssuePanel from './KaseCreateIssuePanel'
import KaseEditIssuePanel from './KaseEditIssuePanel'
import ThreadPanelNew from './ThreadPanelNew'
import StatusTabs from './StatusTabs'

import LoadingSpinner from 'components/LoadingSpinner'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import { Message } from 'types/message'
import { ICreateIssueParams, Issue } from 'types/issues'
import { KaseReviewField } from 'types/kaseReviewData'
import { IDocument, IDocumentRequest } from 'types/documents'

import { IssueStatus } from 'constants/issues'
interface Props {
  handleClickCancelButton: () => void
  handleClickSaveButton: (createIssueParams: ICreateIssueParams) => void
  handleResolveButtonClick: (kaseId: string, issueId: string) => void
  handleSaveEditIssue: (
    kaseId: string,
    issueId: string,
    newDescription: string
  ) => void
  isLoading: boolean
  onClickOutsideIssuePanel: () => void
  // Getting this component to work with both form review and doc review
  selectedDocuments?: IDocument[]
  selectedDocRequests?: IDocumentRequest[]
  selectedReviewFields?: KaseReviewField[]
  setShowCreateIssue: (i: boolean) => void
  show: boolean
  showCreateIssue: boolean
}

const KaseIssuesPanel: FunctionComponent<Props> = ({
  handleClickCancelButton,
  handleClickSaveButton,
  handleResolveButtonClick,
  handleSaveEditIssue,
  isLoading,
  onClickOutsideIssuePanel,
  setShowCreateIssue,
  selectedDocuments,
  selectedDocRequests,
  selectedReviewFields,
  show,
  showCreateIssue
}) => {
  const [showEditIssue, setShowEditIssue] = useState(false)
  const [currentEditIssueId, setCurrentEditIssueId] = useState('0')
  const [showThreadPanel, setShowThreadPanel] = useState(false)

  const [currentIssue, setCurrentIssue] = useState<Issue | null>(null)
  const [currentActiveView, setCurrentActiveView] = useState(IssueStatus.Open)
  const [currentThread, setCurrentThread] = useState<Message[]>([])

  const documentSets = useSelector(
    (store: RootState) => store.documentReviewData.documentSets
  )
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const { setGlobalError } = useGlobalError()

  const adminsMap = useSelector((state: RootState) => state.adminUsers.byId)
  const kaseData = useSelector((state: RootState) => state.kaseData)
  const messages = useSelector((state: RootState) => state.kaseIssues.messages)

  useEffect(() => {
    if (currentIssue != null) {
      // Compile the list of messages for this issue
      const messagesForCurrentIssue: Message[] = []
      messages.allIds.forEach((messageId) => {
        if (messages.byId[messageId].attributes.issue_id === currentIssue.id) {
          messagesForCurrentIssue.push(messages.byId[messageId])
        }
      })

      // Set the list of messages
      setCurrentThread(messagesForCurrentIssue)
    }
  }, [currentIssue, messages])

  useEffect(() => {
    fetchIssues(kaseId).catch((error) => setGlobalError(error)),
      fetchKaseData(kaseId, true).catch((error) => setGlobalError(error)),
      fetchAllAdmins().catch((error) => setGlobalError(error))
    fetchIssues(kaseId).catch((error) => setGlobalError(error))
  }, [kaseId])

  useEffect(() => {
    fetchOverview(kaseId)
    fetchDocumentSetsByKaseId(kaseId)
  }, [kaseId])

  //Hook that alerts clicks outside of this panel
  const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      // Hide panel
      function handleClickOutside(event: MouseEvent) {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          // Don't do this if we're clicking on the view/hide issues button because it causes a weird bug
          (event.target as HTMLElement).id !== 'hide-view-issues-btn'
        ) {
          onClickOutsideIssuePanel()
        }
      }
      // Bind the event listener
      document.addEventListener('mouseup', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mouseup', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const handleEditButtonClick = (issueId: string) => {
    setCurrentEditIssueId(issueId)
    setShowEditIssue(true)
  }

  const openThreadPanel = (issue: Issue) => {
    setShowThreadPanel(!showThreadPanel)
    setCurrentIssue(issue)
  }

  const updateActiveView = (statusClicked: IssueStatus) => {
    setCurrentActiveView(statusClicked)
  }

  const saveNewMessage = (reply: string) => {
    if (!currentIssue) {
      return
    }

    saveMessage({ kaseId, issueId: currentIssue.id, text: reply }).then(
      (message) => {
        // Update the list of messages
        setCurrentThread([...currentThread, message])

        // Scroll to the latest message, if necessary
        setTimeout(() => {
          const addedReply = document.getElementById(`message-${message.id}`)
          addedReply?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      }
    )
  }

  const submitMessageEdits = async (data: {
    id: string
    text: string
    issueId: string
  }) => {
    return updateMessage({
      kaseId,
      messageId: data.id,
      text: data.text,
      issueId: data.issueId
    }).catch((error) => setGlobalError(error))
  }

  return (
    <div ref={wrapperRef}>
      <div
        className={cx(
          'bg-white border-l border-gray-200 border fixed inset-y-0 mt-32 right-0 overflow-x-hidden overflow-y-scroll w-5/12 transform transition-transform duration-200 z-10',
          {
            'translate-x-full': !show,
            'translate-x-0': show
          }
        )}
      >
        <div className="pl-6">
          <StatusTabs setCurrentActiveView={updateActiveView} />
        </div>

        {isLoading ? (
          <div className="mt-48">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <KaseCreateIssuePanel
              show={showCreateIssue}
              setShow={setShowCreateIssue}
              selectedDocuments={selectedDocuments}
              selectedDocRequests={selectedDocRequests}
              selectedReviewFields={selectedReviewFields}
              handleClickSaveButton={handleClickSaveButton}
              handleClickCancelButton={handleClickCancelButton}
              documentSets={documentSets}
            />
            <KaseEditIssuePanel
              show={showEditIssue}
              setShow={setShowEditIssue}
              issueId={currentEditIssueId}
              handleSaveEditIssue={handleSaveEditIssue}
            />
            <KaseIssuesList
              showCreateIssue={showCreateIssue}
              showEditIssue={showEditIssue}
              handleEditButtonClick={handleEditButtonClick}
              handleResolveButtonClick={handleResolveButtonClick}
              showOpenIssues={currentActiveView === IssueStatus.Open}
              openThreadPanel={openThreadPanel}
            />
          </>
        )}
      </div>
      <ThreadPanelNew
        admins={adminsMap}
        messages={currentThread}
        isOpen={showThreadPanel && show}
        onClose={() => setShowThreadPanel(false)}
        customerInfo={kaseData}
        allowReplies={currentIssue?.attributes.status !== IssueStatus.Resolved}
        onSaveReply={saveNewMessage}
        submitMessageEdits={submitMessageEdits}
      />
    </div>
  )
}
export default KaseIssuesPanel
