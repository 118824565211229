import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import SelectField from 'components/forms/SelectField'

import { fetchTaskStateList } from 'api/tasks'

import _isEmpty from 'lodash/isEmpty'

interface Props {
  onFilterAssignmentStatusChange: (isAssigned: boolean | undefined) => void
  onFilterTaskTypeChange: (taskType: string) => void
  tabIndex: number
}

const TASK_ASSIGNMENT_OPTIONS = [
  { label: 'All', value: '' },
  {
    label: 'Unassigned',
    value: 'false'
  },
  {
    label: 'Assigned',
    value: 'true'
  }
]

const TasksFilters: FunctionComponent<Props> = ({
  onFilterAssignmentStatusChange,
  onFilterTaskTypeChange,
  tabIndex
}) => {
  const [taskType, setTaskType] = useState('')
  const [assignType, setAssignType] = useState('')
  const [taskStateList, setTaskStateList] = useState([])

  useEffect(() => {
    if (_isEmpty(taskStateList)) {
      fetchTaskStateList().then((data) => {
        setTaskStateList(data)
      })
    }
  }, [])

  const onTaskChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const value = event.target.value
    setTaskType(value)
    onFilterTaskTypeChange(value)
  }

  const onAssignChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const value = event.target.value
    setAssignType(value)

    // Convert the string value to boolean or undefined
    if (value === 'true') {
      onFilterAssignmentStatusChange(true)
    } else if (value === 'false') {
      onFilterAssignmentStatusChange(false)
    } else {
      onFilterAssignmentStatusChange(undefined)
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-64 mr-4">
        <SelectField
          label="Task type"
          onChange={(event) => onTaskChange(event)}
          options={taskStateList}
          value={taskType}
        />
      </div>

      {tabIndex === 0 && (
        <div className="w-64">
          <SelectField
            label="Assignment status"
            onChange={(event) => onAssignChange(event)}
            options={TASK_ASSIGNMENT_OPTIONS}
            value={assignType}
          />
        </div>
      )}
    </div>
  )
}

export default TasksFilters
