import React, { FunctionComponent, useEffect } from 'react'
import MainLayout from 'layouts/MainLayout'
import { fetchRoles } from 'api/roles'
import { fetchAllAdmins } from 'api/adminUsers'

const Home: FunctionComponent = () => {
  useEffect(() => {
    fetchRoles()
    fetchAllAdmins()
  }, [])

  return (
    <MainLayout>
      <div className="p-4">
        <div className="container mx-auto p-6 bg-white shadow rounded">
          <h1 className="text-3xl text-gray-800 mb-6">
            Welcome to Admin v2 👋
          </h1>
          <p className="text-gray-700 leading-6 font-medium mb-4">
            What is this site?
          </p>
          <p className="text-gray-700 leading-6 mb-4">
            In an effort to improve the efficiency and user-friendliness of our
            Ops tools, the engineering team is slowly moving things over to this
            new site.
          </p>
          <p className="text-gray-700 leading-6 mb-6">
            Our first milestone is to allow our CSOs to do Structured QA
            Returns.
          </p>
          <p className="text-gray-700 leading-6 font-medium mb-4">
            How do I get here?
          </p>
          <p className="text-gray-700 leading-6 mb-4">
            Some links in the "old admin" (on apply.boundless.com) will direct
            you to this site. We require you to log in to both sites (at the
            moment, at least), but we will eventually move all the existing
            tools over here, and you won't have to wonder where to go.
          </p>
          <a href="/new-application" className="text-blue-600">
            Backdoor User Registration and Payment (BURP)
          </a>
        </div>
      </div>
    </MainLayout>
  )
}

export default Home
