import React, { FunctionComponent } from 'react'
import { KaseReviewSection } from 'types/kaseReviewData'

interface Props {
  sections?: KaseReviewSection[]
  currentSection: number
  onSectionClick: (i: number) => void
}

const KaseSidebar: FunctionComponent<Props> = ({
  sections,
  currentSection,
  onSectionClick
}) => {
  return (
    <section id="sidebar">
      {sections &&
        sections.map((section, i) => {
          return (
            <div key={i} className="mb-3">
              <a
                onClick={() => onSectionClick(section.id)}
                className="break-words leading-tight"
                href="#"
              >
                {section.id === currentSection ? '->' : ''} {section.name}
              </a>
            </div>
          )
        })}
    </section>
  )
}

export default KaseSidebar
