import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/root'

import { IssueStatus } from 'constants/issues'

const issuesSelector = (state: RootState) => state.kaseIssues.issues

/**
 * Get an array of all the open issues for the current kase.
 */
export const getOpenIssues = createSelector(issuesSelector, (issues) => {
  if (issues.allIds.length > 0) {
    return Object.values(issues.byId).filter(
      (issue) => issue.attributes.status === IssueStatus.Open
    )
  }

  return []
})

/**
 * Returns the number of issues for the current kase, broken down by IssueStatus
 * @example const { open, resolved } = getIssueCountByStatus(state)
 */
export const getIssueCountByStatus = createSelector(
  issuesSelector,
  (issues) => {
    const countByState = {
      [IssueStatus.Open]: 0,
      [IssueStatus.Resolved]: 0
    }

    Object.values(issues.byId).forEach((issue) => {
      countByState[issue.attributes.status]++
    })

    return countByState
  }
)
