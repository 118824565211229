import React, { FunctionComponent, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { fetchOverview } from 'api/overview'
import { expireStripe } from 'api/payment'
import { getAccountHolderEmail } from 'store/selectors/kaseOverview'
import { RootState } from 'store/root'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'

import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import LoadingSpinner from 'components/LoadingSpinner'
import MainLayout from 'layouts/MainLayout'
import PrePayCart from './PrePayCart'
import PostPayCart from './PostPayCart/PostPayCart'

const Payment: FunctionComponent = () => {
  const { search } = useLocation()
  const { setGlobalError } = useGlobalError()
  const [isLoading, setIsLoading] = useState(true)
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const accountHolderEmail = useSelector((state: RootState) =>
    getAccountHolderEmail(state)
  )
  const { paid } = useSelector((state: RootState) => state.kaseOverview)

  useEffect(() => {
    const queryParams = queryString.parse(search)
    const { session_id, canceled } = queryParams as {
      session_id: string
      canceled: string
    }

    const promiseArray = []

    if (accountHolderEmail === undefined) {
      promiseArray.push(fetchOverview(kaseId))
    }

    // if there is a canceled Stripe session, expire it
    if (canceled && session_id) {
      const sessionId = session_id
      promiseArray.push(expireStripe(kaseId, sessionId))
    }

    Promise.all(promiseArray)
      .then(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        setGlobalError(error)
      })
  }, [])

  const getCartComponent = (paid: boolean) => {
    if (paid) {
      return <PostPayCart kaseId={kaseId} />
    } else {
      return <PrePayCart kaseId={kaseId} />
    }
  }

  return (
    <MainLayout>
      {isLoading ? (
        <LoadingSpinner classNames="mt-48" />
      ) : (
        <div className="p-8 max-w-screen-lg">
          <header className="mb-4">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Payment Tool
            </h1>
            <div className="text-sm my-5">
              <div>
                Application ID: <span className="font-bold">{kaseId}</span>
              </div>
              <div>
                Email: <span className="font-bold">{accountHolderEmail}</span>
              </div>
            </div>
          </header>
          {getCartComponent(paid)}
        </div>
      )}
    </MainLayout>
  )
}

export default Payment as FunctionComponent
