import React, { FunctionComponent } from 'react'
import { PaymentLog } from 'types/kaseOverview'
import { formatIfDate, getTimeFromDateString } from 'utils/format_utils'
import { formatCentsInDollars } from 'utils/format_money'
import Table from 'components/tables/Table'
import TableData from 'components/tables/TableData'
import Hyperlink from 'components/Hyperlink'

interface Props {
  paymentLog: PaymentLog
}

const KasePaymentLog: FunctionComponent<Props> = ({ paymentLog }) => {
  return (
    <div className="mt-6 mb-4">
      <div className="flex justify-between mb-4">
        <div className="text-gray-700">
          {formatCentsInDollars(paymentLog.amount_in_cents)} with{' '}
          {paymentLog.type} on {formatIfDate(paymentLog.created_at)} at{' '}
          {getTimeFromDateString(paymentLog.created_at)}
        </div>
        <div className="text-gray-700">
          Charge ID:{' '}
          <Hyperlink
            href={paymentLog.charge_url}
            className="text-blue-500"
            target="_blank"
          >
            {paymentLog.charge_id}
          </Hyperlink>
        </div>
      </div>

      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>Item</Table.Header>
            <Table.Header>Price</Table.Header>
            <Table.Header>Quantity</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {paymentLog.line_items.map((lineItem) => (
            <Table.Row key={lineItem.id}>
              <TableData>{lineItem.display_name}</TableData>
              <TableData>
                {formatCentsInDollars(lineItem.amount_in_cents)}
              </TableData>
              <TableData>{lineItem.quantity}</TableData>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default KasePaymentLog
