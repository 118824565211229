import React, { FunctionComponent } from 'react'
import LoadingIcon from 'components/icons/LoadingIcon'
import cx from 'classnames'

interface Props {
  classNames?: string
}

const LoadingSpinner: FunctionComponent<Props> = ({ classNames }) => {
  const classes = cx('w-10 text-blue-600 block mx-auto', {
    [classNames as string]: !!classNames
  })

  return <LoadingIcon className={classes} />
}

export default LoadingSpinner
