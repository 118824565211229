/**
 * Takes a number of cents and returns it formatted as a dollar amount.
 * @example formatCentsInDollars(123) // returns $1.23
 * @param cents An amount of cents
 */
export const formatCentsInDollars = (cents: number | string) => {
  let numericValue: number
  if (typeof cents === 'string') {
    numericValue = parseInt(cents, 10)
  } else {
    numericValue = cents
  }

  if (isNaN(numericValue)) {
    return cents
  }

  return (numericValue * 0.01).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency'
  })
}

/**
 * Takes a number of dollars and returns it formatted as a dollar amount.
 * @example formatDollars(1495) // returns $1,495
 * @param dollars An amount of dollars
 */
export const formatDollars = (dollars: number) => {
  return dollars
    .toLocaleString('en-US', {
      currency: 'USD',
      style: 'currency'
    })
    .slice(0, -3)
}
