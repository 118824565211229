import React, { FunctionComponent } from 'react'
import CloseButton from './CloseButton'
import ModalBase, { CloseReason } from './ModalBase'

interface Props {
  /**
   * If true, the modal will be displayed.
   */
  isOpen?: boolean
  /**
   * The maximum width of the modal
   * @default 30rem
   */
  modalWidth?: string | number
  /**
   * Required callback that's triggered when the user is attempting to close the
   * modal
   */
  onRequestClose: (reason: CloseReason) => void
}

const BlankModal: FunctionComponent<Props> = ({
  modalWidth = '30rem',
  onRequestClose,
  children,
  ...otherProps
}) => {
  return (
    <ModalBase
      {...otherProps}
      onRequestClose={onRequestClose}
      style={{ width: modalWidth }}
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <CloseButton onClick={() => onRequestClose(CloseReason.CloseButton)} />
      </div>
      <div className="bg-white rounded-lg shadow-xl">{children}</div>
    </ModalBase>
  )
}

export default BlankModal
