import Button from 'components/Button'
import React, { FunctionComponent } from 'react'

interface Props {
  documentUrl: string
  onBackBtnClicked: () => void
}

/* TODO: Basically the same as document_review/Tool/DocumentView/DocumentViewRender */
/* Backend needs to return the document shape though if we want to reuse that */
const MPDFView: FunctionComponent<Props> = ({
  documentUrl,
  onBackBtnClicked
}) => {
  return (
    <div className="bg-gray-200 h-full px-8 py-8">
      <Button onClick={onBackBtnClicked} variant="plain">
        Back to MPDF Tool
      </Button>
      <div className="mt-6 mx-auto" style={{ height: '90%' }}>
        <div className="col-span-3 h-full">
          <iframe src={documentUrl} width="100%" height="100%" />
        </div>
      </div>
    </div>
  )
}

export default MPDFView as FunctionComponent<Props>
