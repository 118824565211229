import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import BoundlessOnly from './PaymentOptions/BoundlessOnly'
import Bundle from './PaymentOptions/Bundle'
import StripeCheckout from './Checkout/Stripe'
import SplitItCheckout from './Checkout/SplitIt'
import ExtraQuestions from './ExtraQuestions'
import ServiceTierSelect from './ServiceTierSelect'

import {
  CheckoutProps,
  InitiatePaymentPayload,
  ServiceCheckoutDetails
} from 'types/payment'
import Alert from 'components/Alert'
import { fetchCheckoutDetails } from 'api/payment'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import LoadingSpinner from 'components/LoadingSpinner'
import { PageElementQuestion } from 'types/questionnaire'

export enum PaymentOptions {
  BoundlessOnly,
  Bundle
}

interface Props {
  kaseId: string
}

const PrePayCart: FunctionComponent<Props> = ({ kaseId }) => {
  const { setGlobalError } = useGlobalError()

  const checkoutDetails = useSelector(
    (state: RootState) => state.checkoutDetails
  )

  const [extraQuestions, setExtraQuestions] = useState<PageElementQuestion[]>(
    []
  )
  const [numberOfChildrenForAge, setNumberOfChildrenForAge] = useState<number>(
    0
  )
  const [usesExtraQuestions, setUsesExtraQuestions] = useState<boolean>(false)

  const { eligible_to_pay, error_message } = checkoutDetails

  const [
    selectedTierCheckoutDetails,
    setSelectedTierCheckoutDetails
  ] = useState<ServiceCheckoutDetails | null>(null)

  const [selectedTierName, setSelectedTierName] = useState<
    'essential' | 'premium' | null
  >(null)

  useEffect(() => {
    fetchCheckoutDetails(kaseId)
      .catch((error) => setGlobalError(error))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    // This is just setting some of the root level attributes to payments that are
    // currently stored in the service tier level attributes that we need before a
    // service tier is selected. Getting this data from the essential service
    if (checkoutDetails) {
      if ('essential' in checkoutDetails) {
        // kase type uses tiers
        setExtraQuestions(checkoutDetails.essential.extra_questions || [])
        setNumberOfChildrenForAge(
          checkoutDetails.essential.number_of_children_for_age || 0
        )
        setUsesExtraQuestions(
          checkoutDetails.essential.uses_extra_questions || false
        )
        setSelectedTierCheckoutDetails(
          selectedTierName ? checkoutDetails[selectedTierName] : null
        )
      } else {
        // kase type does not use tiers
        setExtraQuestions(checkoutDetails.extra_questions || [])
        setNumberOfChildrenForAge(
          checkoutDetails.number_of_children_for_age || 0
        )
        setUsesExtraQuestions(checkoutDetails.uses_extra_questions || false)
        setSelectedTierCheckoutDetails(checkoutDetails)
      }
    }
  }, [checkoutDetails])

  const hasChildren = numberOfChildrenForAge && numberOfChildrenForAge > 0

  const [
    selectedPaymentOption,
    setSelectedPaymentOption
  ] = useState<PaymentOptions | null>(null)
  const [teenNumberKey, setTeenNumberKey] = useState<string>(
    hasChildren ? '' : 'teen_number_0'
  )
  const [checkoutContent, setCheckoutContent] = useState<CheckoutProps | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(true)

  const isExtraQuestionsComplete =
    extraQuestions?.every(
      (question) => question.attributes.input_value_incomplete === false
    ) && teenNumberKey !== ''

  const onClickPaymentOption = (selection: PaymentOptions) => {
    setSelectedPaymentOption(selection)

    // if no service tier is selected, we should not be able to select a payment option
    // This function shouldn't even be callable if there is no service tier selected
    // but this is a safety check
    if (!selectedTierCheckoutDetails) {
      setGlobalError(
        new Error(
          'Please select a service tier before selecting a payment option'
        )
      )
      return
    }

    // update content in checkout row
    switch (selection) {
      case PaymentOptions.BoundlessOnly:
        setCheckoutContent({
          monthly:
            selectedTierCheckoutDetails.side_by_side_line_items.boundless_only
              .pay_over_time.monthly,
          payInFull:
            selectedTierCheckoutDetails.side_by_side_line_items.boundless_only
              .pay_in_full.total
        })
        break
      case PaymentOptions.Bundle:
        setCheckoutContent({
          monthly:
            selectedTierCheckoutDetails.side_by_side_line_items.with_uscis
              .pay_over_time[teenNumberKey].monthly,
          payInFull:
            selectedTierCheckoutDetails.side_by_side_line_items.with_uscis
              .pay_in_full[teenNumberKey].total
        })
        break
      default:
        break
    }
  }

  const canSelectPaymentOption =
    (!usesExtraQuestions || (usesExtraQuestions && isExtraQuestionsComplete)) &&
    !!selectedTierCheckoutDetails &&
    selectedTierCheckoutDetails.side_by_side_line_items

  const initiatePaymentPayload: InitiatePaymentPayload = {
    include_uscis_fees: Boolean(
      selectedPaymentOption === PaymentOptions.Bundle
    ),
    number_teens: parseInt(teenNumberKey.slice(-1)),
    tier: selectedTierName
  }

  if (isLoading) {
    return <LoadingSpinner classNames="mt-48" />
  }

  if (!eligible_to_pay) {
    return <Alert variant="error" title={error_message} />
  }

  const onClickUpdateServiceTier = () => {
    setSelectedPaymentOption(null)
    setSelectedTierCheckoutDetails(null)
    setSelectedTierName(null)
  }

  return (
    <>
      {'essential' in checkoutDetails && (
        <ServiceTierSelect
          checkoutDetails={checkoutDetails}
          onClickServiceTierOption={(details, name) => {
            setSelectedTierCheckoutDetails(details)
            setSelectedTierName(name)
          }}
          onClickUpdateServiceTier={onClickUpdateServiceTier}
          selectedTier={selectedTierCheckoutDetails}
        />
      )}

      {usesExtraQuestions && !!selectedTierCheckoutDetails && (
        <ExtraQuestions
          checkoutDetails={selectedTierCheckoutDetails}
          setTeenNumberKey={setTeenNumberKey}
        />
      )}

      {canSelectPaymentOption ? (
        <>
          <div className="flex justify-between my-6">
            <BoundlessOnly
              onClickPaymentOption={onClickPaymentOption}
              selectedPaymentOption={selectedPaymentOption}
              checkoutDetails={selectedTierCheckoutDetails}
            />
            {selectedTierCheckoutDetails.total_uscis_fees &&
              selectedTierCheckoutDetails.total_uscis_fees[teenNumberKey] >
                0 && (
                <Bundle
                  onClickPaymentOption={onClickPaymentOption}
                  selectedPaymentOption={selectedPaymentOption}
                  teenNumberKey={teenNumberKey}
                  checkoutDetails={selectedTierCheckoutDetails}
                />
              )}
          </div>
          {selectedPaymentOption !== null && checkoutContent !== null && (
            <div className="flex justify-between my-6">
              <StripeCheckout
                {...checkoutContent}
                initiatePaymentPayload={initiatePaymentPayload}
              />
              <SplitItCheckout
                {...checkoutContent}
                checkoutDetails={selectedTierCheckoutDetails}
                initiatePaymentPayload={initiatePaymentPayload}
              />
            </div>
          )}
        </>
      ) : (
        selectedTierCheckoutDetails?.total && (
          <div className="my-6">
            <StripeCheckout
              canChangeBaseQuantity={
                selectedTierCheckoutDetails.can_change_base_quantity
              }
              payInFull={selectedTierCheckoutDetails.total * 100}
              initiatePaymentPayload={initiatePaymentPayload}
            />
          </div>
        )
      )}
    </>
  )
}

export default PrePayCart
