import React, { FormEvent, FunctionComponent, useState } from 'react'

import MainLayout from 'layouts/MainLayout'
import TextField from 'components/forms/TextField'
import Button from 'components/Button'
import { Redirect } from 'react-router-dom'
import ROUTES from 'constants/routes'

/**
 * This page is a placeholder for the /issues page. I imagine we'll display a
 * dashboard in the future.
 */
const IssuesRoot: FunctionComponent = () => {
  const [kaseId, setKaseId] = useState('')

  const navigateToIssue = (event: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget)
    const kaseId = formData.get('kaseId') as string

    setKaseId(kaseId)
  }

  if (kaseId) {
    return <Redirect to={ROUTES.ISSUES_ROOT + '/' + kaseId} />
  }

  return (
    <MainLayout>
      <div className="max-w-5xl w-full mx-auto p-4">
        <form
          className="p-4 md:p-8 bg-white mb-6 border border-gray-200 sm:rounded-lg"
          onSubmit={navigateToIssue}
        >
          <TextField label="Enter the application ID" name="kaseId" autoFocus />
          <Button className="mt-4" type="submit">
            Go to the Issues page
          </Button>
        </form>
      </div>
    </MainLayout>
  )
}

export default IssuesRoot
