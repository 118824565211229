import { fetchPostPayCartOptions } from 'api/payment'
import LoadingSpinner from 'components/LoadingSpinner'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Table from 'components/tables/Table'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import StripeCheckout from '../Checkout/Stripe'
import { LineItemToFill, PostPayCartOption } from 'types/payment'
import { formatCentsInDollars } from 'utils/format_money'
import Alert from 'components/Alert'
import { PostPayCartLineItemTableRow } from './PostPayCartLineItemTableRow'
import { QuantitySelect } from './QuantitySelect'
import { OverviewInformationItem } from 'types/kaseOverview'

interface Props {
  kaseId: string
}

const PostPayCart: FunctionComponent<Props> = ({ kaseId }) => {
  const { setGlobalError } = useGlobalError()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPostPayCartOptions, setSelectedPostPayCartOptions] = useState<
    PostPayCartOption[]
  >([])
  const [payInFullAmount, setPayInFullAmount] = useState(0)
  const [lineItemsToFill, setLineItemsToFill] = useState<LineItemToFill[]>([])
  const [upgradeTier, setUpgradeTier] = useState<
    'essential' | 'premium' | null
  >()
  const postPayCartOptions: PostPayCartOption[] = useSelector(
    (state: RootState) => state.postPayCartOptions
  )

  const numberOfChildrenApplying = useSelector(
    (state: RootState) =>
      state.kaseOverview?.information.find(
        (info: OverviewInformationItem) => info.key === 'Children Applying'
      )?.value
  )

  // Initialize values for stripe checkout payload
  useEffect(() => {
    fetchPostPayCartOptions(kaseId)
      .catch((error) => {
        setGlobalError(error)
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    let newPayInFullAmount = 0
    const newLineItemsToFill: LineItemToFill[] = []
    selectedPostPayCartOptions.forEach((cartOption) =>
      cartOption.line_items.forEach((lineItem) => {
        newPayInFullAmount += lineItem.amount_in_cents * lineItem.quantity
        newLineItemsToFill.push(lineItem)
      })
    )

    setPayInFullAmount(newPayInFullAmount)
    setLineItemsToFill([...newLineItemsToFill])
  }, [selectedPostPayCartOptions])

  const onCartUpdated = (quantity: number, option: PostPayCartOption) => {
    const newLineItems = option.line_items.map((lineItem) => {
      return {
        ...lineItem,
        quantity
      }
    })

    const selectedProduct = {
      ...option,
      line_items: newLineItems
    }

    // Check if this product already exists in the cart and remove it
    const newSelectedPostPayCartOptions = selectedPostPayCartOptions.filter(
      (option) => option.display_name !== selectedProduct.display_name
    )

    // Add the product to the cart if the quantity is greater than 0
    if (quantity > 0) {
      newSelectedPostPayCartOptions.push(selectedProduct)
    }

    setSelectedPostPayCartOptions(newSelectedPostPayCartOptions)

    // If the product is an upgrade, set the upgrade tier from the first line item
    // (there should always be one line item for upgrades according to a previous comment)
    const lineItem = newLineItems[0]
    if (lineItem?.product_type === 'upgrade' && lineItem?.tier) {
      setUpgradeTier(lineItem.tier)
    }
  }

  const getCartOptionPaymentAmount = (postPayCartOption: PostPayCartOption) => {
    return formatCentsInDollars(
      postPayCartOption.line_items
        .map((lineItem) => lineItem.amount_in_cents)
        .reduce((sum, current) => sum + current, 0)
    )
  }

  if (isLoading) {
    return <LoadingSpinner classNames="mt-48" />
  }

  if (postPayCartOptions.length === 0) {
    return (
      <Alert
        variant="warning"
        title="No add-ons available for this application - either the customer has not completed their questionnaire or there are no add-ons for this application type"
      />
    )
  }

  // The Max Quantity for a cart option is determined by the number of people applying,
  // meaning the beneficiary and however many dependents they have listed in the application.
  // However, this only applies to the work and travel add-ons, all other products have a max quantity of 1
  const maxQuantity = (postPayCartOption: PostPayCartOption): number => {
    const quantifiableProductTypesSubstrings = ['work_permit', 'travel_permit']
    const productType = postPayCartOption.line_items[0]?.product_type

    if (
      quantifiableProductTypesSubstrings.some((substring) =>
        productType.includes(substring)
      )
    ) {
      return parseInt(numberOfChildrenApplying) + 1
    }

    return 1
  }

  return (
    <>
      <h2 className="font-bold text-black text-lg">Select add-ons</h2>
      <div className="mb-4 mt-2">
        {postPayCartOptions.map((postPayCartOption, index) => (
          <Table key={index} classNames="mt-6 mb-0">
            <Table.Head key={index}>
              <Table.Row>
                <Table.Header className="font-bold text-base" colSpan={2}>
                  <QuantitySelect
                    disabled={
                      !!upgradeTier &&
                      postPayCartOption.line_items[0]?.product_type ===
                        'upgrade' &&
                      postPayCartOption.line_items[0]?.tier !== upgradeTier
                    }
                    label={postPayCartOption.display_name}
                    onChange={(quantity) =>
                      onCartUpdated(quantity, postPayCartOption)
                    }
                    maxQuantity={maxQuantity(postPayCartOption)}
                  />
                </Table.Header>
                <Table.Header>
                  <div className="font-bold text-black text-lg">
                    {getCartOptionPaymentAmount(postPayCartOption)}
                  </div>
                </Table.Header>
              </Table.Row>
              <Table.Row>
                <Table.Header className="pl-10" colSpan={2}>
                  Included Fees
                </Table.Header>
                <Table.Header>Amount</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {postPayCartOption.line_items?.map((lineItem, index) => (
                <PostPayCartLineItemTableRow
                  key={index}
                  lineItem={lineItem}
                  breakdown={postPayCartOption.breakdown}
                />
              ))}
            </Table.Body>
          </Table>
        ))}
      </div>

      {selectedPostPayCartOptions.length > 0 && (
        <div className="sticky bottom-0 bg-white p-4 border-t-2 border-black">
          <StripeCheckout
            initiatePaymentPayload={{
              line_items_to_fill: lineItemsToFill,
              tier: upgradeTier
            }}
            isPostPay
            payInFull={payInFullAmount}
          />
        </div>
      )}
    </>
  )
}

export default PostPayCart
