import React, { FunctionComponent } from 'react'
import CheckboxField from 'components/forms/CheckboxField'
import { formatTitle, formatAnswer } from './FormatQuestionHelpers'
import {
  AddressHistories,
  AddressHistoryField,
  EditableAnswer,
  EmploymentHistories,
  KaseReviewField,
  QuestionField,
  isAddressHistoryField,
  isEmploymentHistoryField
} from 'types/kaseReviewData'
import { KaseEditableAnswer } from './KaseEditableAnswer'

// KaseQuestion
interface Props {
  /** Used to determine which answer is being edited, as only one should be edited at a time */
  currentEditAnswerQuestionId: string
  handleReviewFieldCheck: (reviewField: KaseReviewField) => void
  kaseId: string
  reviewField: KaseReviewField
  selectedReviewFields: KaseReviewField[]
  setCurrentEditAnswerQuestionId: (answerQuestionId: string) => void
  submitAnswer: (
    value: string | null,
    questionId: string,
    answerId: string | null
  ) => Promise<void>
  updateKaseReviewData: () => void
}

const KaseQuestion: FunctionComponent<Props> = ({
  currentEditAnswerQuestionId,
  handleReviewFieldCheck,
  kaseId,
  reviewField,
  selectedReviewFields,
  setCurrentEditAnswerQuestionId,
  submitAnswer,
  updateKaseReviewData
}) => {
  const isChecked = (reviewField: KaseReviewField) => {
    return selectedReviewFields.some((field) => field === reviewField)
  }

  const formatEditableAnswers = (editableAnswers: EditableAnswer[]) => {
    return editableAnswers.map((editableAnswer) => {
      const editAnswerQuestionId = editableAnswer.question.id
      return (
        <KaseEditableAnswer
          key={editAnswerQuestionId}
          editableAnswer={editableAnswer}
          isEditing={currentEditAnswerQuestionId === editAnswerQuestionId}
          kaseId={kaseId}
          setCurrentEditAnswerQuestionId={setCurrentEditAnswerQuestionId}
          submitAnswer={submitAnswer}
          updateKaseReviewData={updateKaseReviewData}
        />
      )
    })
  }

  const formatReviewFieldValue = (reviewField: QuestionField) => {
    const { editable_answers, field_value } = reviewField

    return editable_answers.length > 0
      ? formatEditableAnswers(editable_answers)
      : formatAnswer(field_value)
  }

  const getAddressHistoryReviewField = (reviewField: AddressHistories) => {
    return reviewField.address_histories.map(
      (addressRecord: AddressHistoryField, index: number) => {
        const {
          address,
          mailing_address,
          intended_address,
          physical_address_abroad,
          start_date,
          end_date
        } = addressRecord.field_value
        return (
          <div className="flex" key={`address-history-${index}`}>
            {/* Have to do inline style here since current version of tailwind doesnt go above 16 rem */}
            <div className="mb-4 pl-5" style={{ width: '18rem' }}>
              <CheckboxField
                label={`${formatTitle(addressRecord.field_name)}`}
                onChange={() => handleReviewFieldCheck(addressRecord)}
                checked={isChecked(addressRecord)}
              />
            </div>
            <div className="ml-4 text-gray-700">
              <div>
                {mailing_address && <strong>Mailing address</strong>}
                {mailing_address && start_date && <br />}
                {intended_address && (
                  <strong>Beneficiary's intended address</strong>
                )}
                {intended_address && start_date && <br />}
                {physical_address_abroad && (
                  <strong>Beneficiary's physical address abroad</strong>
                )}
                {physical_address_abroad && start_date && <br />}
                {start_date && (
                  <strong>
                    {`${start_date} to ${end_date ? end_date : 'Current'}`}
                  </strong>
                )}
                <br />
                {address.street} <br />
                {address.unit_type && (
                  <>
                    {address.unit_type} {address.unit_number} <br />{' '}
                  </>
                )}
                {address.city} {address.province}, {address.postal_code} <br />
                {address.country_code}
              </div>
            </div>
          </div>
        )
      }
    )
  }

  const getEmploymentHistoryReviewField = (
    reviewField: EmploymentHistories
  ) => {
    return reviewField.employment_histories.map(
      (employmentRecord: any, index: number) => {
        const {
          address,
          start_date,
          end_date,
          employer_name,
          job_title,
          unemployed
        } = employmentRecord.field_value
        return (
          <div className="flex" key={`employment-history-${index}`}>
            <div className="mb-4 pl-5" style={{ width: '18rem' }}>
              <CheckboxField
                label={`${formatTitle(employmentRecord.field_name)}`}
                onChange={() => handleReviewFieldCheck(employmentRecord)}
                checked={isChecked(employmentRecord)}
              />
            </div>
            <div className="ml-4 text-gray-700">
              <div>
                <strong>
                  {`${start_date} to ${end_date ? end_date : 'Current'}`} <br />
                </strong>

                {unemployed ? (
                  <>
                    Unemployed or Retired <br />
                  </>
                ) : (
                  <>
                    {employer_name} <br />
                    {job_title} <br />
                  </>
                )}

                {address && (
                  <>
                    {address.street} <br />
                    {address.unit_type && (
                      <>
                        {address.unit_type} {address.unit_number} <br />{' '}
                      </>
                    )}
                    {address.city} {address.province}, {address.postal_code}{' '}
                    <br />
                    {address.country_code}
                  </>
                )}
              </div>
            </div>
          </div>
        )
      }
    )
  }

  const getReviewField = () => {
    if (isAddressHistoryField(reviewField)) {
      return <>{getAddressHistoryReviewField(reviewField)}</>
    } else if (isEmploymentHistoryField(reviewField)) {
      return <>{getEmploymentHistoryReviewField(reviewField)}</>
    } else {
      return (
        <div className="flex">
          <div className="mb-4 pl-5 w-1/3">
            <CheckboxField
              label={formatTitle(reviewField.field_name)}
              onChange={() => handleReviewFieldCheck(reviewField)}
              checked={isChecked(reviewField)}
            />
          </div>
          <div className="ml-4 text-gray-700 w-2/3">
            {formatReviewFieldValue(reviewField)}
          </div>
        </div>
      )
    }
  }

  return getReviewField()
}

export default KaseQuestion
