import React, { FunctionComponent } from 'react'

import Button from 'components/Button'

interface Props {
  docRequestTitle?: string
  docRequestPartTitle?: string
  docFileName?: string
  docIndex: number
  numDocuments: number
  onBackBtnClicked: () => void
  onNextBtnClicked: () => void
  onPrevBtnClicked: () => void
}

const DocumentViewNavigation: FunctionComponent<Props> = ({
  docRequestTitle,
  docRequestPartTitle,
  docFileName,
  docIndex,
  numDocuments,
  onBackBtnClicked,
  onNextBtnClicked,
  onPrevBtnClicked
}) => {
  return (
    <div className="flex items-center justify-between">
      <Button onClick={onBackBtnClicked} variant="plain">
        Back
      </Button>
      <h1 className="text-sm font-medium">
        {docRequestTitle} {'>'} {docRequestPartTitle}{' '}
        {docRequestPartTitle && '> '}
        {docFileName}
      </h1>
      <div>
        <span className="text-sm font-normal text-gray-500">
          Document {docIndex + 1} of {numDocuments}
        </span>
        <Button
          className="ml-4"
          disabled={docIndex === 0}
          onClick={onPrevBtnClicked}
          variant="plain"
        >
          &lt; Prev
        </Button>
        <Button
          className="ml-4"
          disabled={docIndex === numDocuments - 1}
          onClick={onNextBtnClicked}
          variant="plain"
        >
          Next &gt;
        </Button>
      </div>
    </div>
  )
}

export default DocumentViewNavigation as FunctionComponent<Props>
