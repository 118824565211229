import React, { FunctionComponent, useState } from 'react'
import { useParams } from 'react-router'
import { initiateStripe, expireStripe } from 'api/payment'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Button from 'components/Button'
import Card from 'components/Card'
import CheckIcon from 'components/icons/CheckIcon'
import ClipboardCopyIcon from 'components/icons/ClipboardCopyIcon'
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import LoadingIcon from 'components/icons/LoadingIcon'
import { formatCentsInDollars } from 'utils/format_money'
import { InitiatePaymentPayload } from 'types/payment'

interface Props {
  canChangeBaseQuantity?: boolean
  initiatePaymentPayload: InitiatePaymentPayload
  isPostPay?: boolean
  payInFull: number
}

const StripeCheckout: FunctionComponent<Props> = ({
  canChangeBaseQuantity = false,
  initiatePaymentPayload,
  isPostPay = false,
  payInFull
}) => {
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()

  const [isCustomerLink, setIsCustomerLink] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stripeSessionId, setStripeSessionId] = useState()
  const [productQuantity, setProductQuantity] = useState(1)

  const onClickPaymentButton = (createCustomerLink: boolean) => {
    setIsLoading(true)
    if (createCustomerLink) {
      setIsCustomerLink(true)
    } else {
      setIsCustomerLink(false)
    }

    // if there is a previous Stripe session, expire it
    if (stripeSessionId) {
      const sessionId = stripeSessionId
      expireStripe(kaseId, sessionId)
    }

    initiateStripe(kaseId, {
      ...initiatePaymentPayload,
      link_for_customer: createCustomerLink,
      base_app_quantity: productQuantity
    })
      .then(({ data }) => {
        setStripeSessionId(data.sessionId)
        if (createCustomerLink) {
          navigator.clipboard.writeText(data.sessionUrl)
          setIsLoading(false)
        } else {
          window.window.location.href = data.sessionUrl
        }
      })
      .catch((error) => {
        setGlobalError(error)
      })
  }

  const getButtonText = () => {
    if (isLoading && isCustomerLink) return 'Generating Stripe Link'
    if (!isLoading && isCustomerLink) return 'Link Copied to Clipboard'
    return 'Copy Stripe Link to Clipboard'
  }

  const getButtonIcon = () => {
    if (isLoading && isCustomerLink) return <LoadingIcon />
    if (!isLoading && isCustomerLink) return <CheckIcon />
    return <ClipboardCopyIcon />
  }

  if (!isPostPay) {
    return (
      <Card className="w-1/2 mr-3">
        <Card.Header>Checkout with Stripe</Card.Header>

        <Card.Body>
          {canChangeBaseQuantity && (
            <div className="flex justify-between">
              <p>Quantity</p>
              <div>
                <Button
                  disabled={productQuantity === 1}
                  onClick={() => setProductQuantity(productQuantity - 1)}
                >
                  -
                </Button>
                <span className="mx-3">{productQuantity}</span>
                <Button onClick={() => setProductQuantity(productQuantity + 1)}>
                  +
                </Button>
              </div>
            </div>
          )}
          <p className="mb-3">Pay in full</p>
          <p>
            <span className="font-bold text-lg">
              {formatCentsInDollars(payInFull * productQuantity)}
            </span>{' '}
            one time
          </p>
        </Card.Body>
        <Card.Body className="text-center">
          <Button variant="primary" onClick={() => onClickPaymentButton(false)}>
            <Button.Icon placement="before">
              {isLoading && !isCustomerLink ? (
                <LoadingIcon />
              ) : (
                <ExternalLinkIcon />
              )}
            </Button.Icon>
            Continue to Stripe Checkout
          </Button>
        </Card.Body>
        <Card.Body className="text-center">
          <Button onClick={() => onClickPaymentButton(true)}>
            <Button.Icon placement="before">{getButtonIcon()}</Button.Icon>
            {getButtonText()}
          </Button>
        </Card.Body>
      </Card>
    )
  } else {
    return (
      <Card>
        <Card.Body
          noBorder
          className="flex flex-row items-center justify-between"
        >
          <div>
            <Button
              variant="primary"
              onClick={() => onClickPaymentButton(false)}
            >
              <Button.Icon placement="before">
                {isLoading && !isCustomerLink ? (
                  <LoadingIcon />
                ) : (
                  <ExternalLinkIcon />
                )}
              </Button.Icon>
              Continue to Stripe Checkout
            </Button>
            <Button className="ml-4" onClick={() => onClickPaymentButton(true)}>
              <Button.Icon placement="before">{getButtonIcon()}</Button.Icon>
              {getButtonText()}
            </Button>
          </div>
          <div className="pr-20">
            <p className="mb-3 text-lg">Pay in full</p>
            <p>
              <span className="font-bold text-lg">
                {formatCentsInDollars(payInFull * productQuantity)}
              </span>{' '}
              one time
            </p>
          </div>
        </Card.Body>
      </Card>
    )
  }
}

export default StripeCheckout
