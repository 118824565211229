import server from 'api/server'

export async function fetchStateDeptCountries() {
  return server
    .get(`/api/v1/countries?state_only=true`)
    .then((response) => {
      const countries = response.data
      return countries.reduce((allCountries: any, country: any) => {
        return {
          ...allCountries,
          [country.code]: country.name
        }
      }, {})
    })
    .catch((error) => error)
}

export default fetchStateDeptCountries
