import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableCell from './TableCell'
import TableEditableCell from './TableEditableCell'
import TableEditableSelectCell from './TableEditableSelectCell'
import TableBody from './TableBody'
import TableHead from './TableHead'

interface Props {
  classNames?: string
  isLoading?: boolean
  rounded?: boolean // TODO: Add this to storybook docs
  shadow?: boolean // TODO: Add this to storybook docs
}

type TableComponentShape = FunctionComponent<Props> & {
  Body: typeof TableBody
  Cell: typeof TableCell
  EditableCell: typeof TableEditableCell
  EditableSelectCell: typeof TableEditableSelectCell
  Head: typeof TableHead
  Header: typeof TableHeader
  Row: typeof TableRow
}

const Table: TableComponentShape = ({
  children,
  classNames,
  isLoading,
  rounded = true,
  shadow = true
}) => {
  // We use overflow-x: auto and overflow-y: hidden to make the table rounded
  // while allowing users to scroll horizontally if needed

  const classes = {
    table: cx('min-w-full', {
      [classNames as string]: !!classNames
    }),
    wrapper: cx('min-w-full overscroll-x-auto overflow-y-hidden relative', {
      shadow: shadow,
      'sm:rounded-lg': rounded
    })
  }

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className="absolute inset-0 bg-white opacity-75 flex items-center justify-center">
          Loading...
        </div>
      )}
      <table className={classes.table}>{children}</table>
    </div>
  )
}

Table.Body = TableBody
Table.Cell = TableCell
Table.Header = TableHeader
Table.Head = TableHead
Table.Row = TableRow
Table.EditableCell = TableEditableCell
Table.EditableSelectCell = TableEditableSelectCell

export default Table
