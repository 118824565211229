import React, { FunctionComponent, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import BlankModal from 'components/modals/BlankModal'
import Button from 'components/Button'
import Card from 'components/Card'
import InformationCircleIcon from 'components/icons/InformationCircleIcon'
import LineItems from './LineItems'

import { PaymentOptions } from 'pages/payment/PrePayCart'
import { formatCentsInDollars } from 'utils/format_money'
import { ServiceCheckoutDetails } from 'types/payment'

interface Props {
  onClickPaymentOption: (selection: PaymentOptions) => void
  selectedPaymentOption: PaymentOptions | null
  teenNumberKey: string
  checkoutDetails: ServiceCheckoutDetails
}

const PaymentOptionSelect: FunctionComponent<Props> = ({
  checkoutDetails,
  onClickPaymentOption,
  selectedPaymentOption,
  teenNumberKey
}) => {
  const {
    fee_breakdown_contextual_help,
    side_by_side_line_items: {
      with_uscis: { pay_in_full, pay_over_time }
    },
    has_applied_discounts,
    split_it_max_months
  } = checkoutDetails
  const [isShowingModal, setIsShowingModal] = useState(false)

  return (
    <Card
      className="w-1/2 ml-3"
      disabled={selectedPaymentOption === PaymentOptions.BoundlessOnly}
      selected={selectedPaymentOption === PaymentOptions.Bundle}
    >
      <Card.Header>Bundle Boundless & Government Fees</Card.Header>
      <Card.Body>
        Pay your Boundless service and government application fees together now,
        no payments needed later
      </Card.Body>
      <Card.Body>
        <h3 className="text-base font-bold mb-2">Pay in full</h3>
        <LineItems
          items={pay_in_full[teenNumberKey].line_items}
          discounts={pay_in_full[teenNumberKey].discounts}
          hasAppliedDiscounts={has_applied_discounts}
        />
        <div className="flex justify-between font-bold mt-2">
          <h3>Total</h3>
          <p className="text-right">
            {formatCentsInDollars(pay_in_full[teenNumberKey].total)}
          </p>
        </div>
      </Card.Body>
      <Card.Body>
        <h3 className="text-base font-bold mb-2">Pay monthly</h3>
        <LineItems
          items={pay_over_time[teenNumberKey].line_items}
          discounts={pay_over_time[teenNumberKey].discounts}
          hasAppliedDiscounts={has_applied_discounts}
        />
        <div className="flex justify-between font-bold mt-2">
          <h3>As little as</h3>
          <p className="text-right">
            {formatCentsInDollars(pay_over_time[teenNumberKey].monthly)}/month
            <br />
            <span className="text-sm font-normal">
              for {split_it_max_months} months, or{' '}
              {formatCentsInDollars(pay_over_time[teenNumberKey].total)} total
            </span>
          </p>
        </div>
      </Card.Body>
      <Card.Body>
        <Button
          onClick={() => setIsShowingModal(!isShowingModal)}
          variant="plain"
          className="mt-3"
        >
          <Button.Icon placement="before">
            <InformationCircleIcon />
          </Button.Icon>
          View Government Fee Breakdown
        </Button>
        <BlankModal
          isOpen={isShowingModal}
          modalWidth="auto"
          onRequestClose={() => setIsShowingModal(false)}
        >
          <div className="p-4">
            <ReactMarkdown className="prose mb-4">
              {fee_breakdown_contextual_help?.object[teenNumberKey] || ''}
            </ReactMarkdown>
            <Button onClick={() => setIsShowingModal(false)}>Close</Button>
          </div>
        </BlankModal>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button
          variant="primary"
          onClick={() => onClickPaymentOption(PaymentOptions.Bundle)}
          className="relative z-2"
        >
          Select Bundle
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default PaymentOptionSelect as FunctionComponent<Props>
