/**
 * This file is where we initialize the Redux store.
 */

import { configureStore } from '@reduxjs/toolkit'

// Import the reducers
import adminUsersReducer from 'store/reducers/adminUsers'
import checkoutReducer from 'store/reducers/checkoutDetails'
import currentUserReducer from 'store/reducers/currentUser'
import documentReviewDataReducer from 'store/reducers/documentReviewData'
import globalReducer from 'store/reducers/global'
import kaseDataReducer from 'store/reducers/kaseData'
import kaseIssuesReducer from 'store/reducers/kaseIssues'
import kaseOverviewReducer from 'store/reducers/kaseOverview'
import kaseReviewDataReducer from 'store/reducers/kaseReviewData'
import kaseStateEvents from 'store/reducers/kaseStateEvents'
import kaseTasksReducer from 'store/reducers/kaseTasks'
import reviewTypesReducer from 'store/reducers/reviewType'
import rolesReducer from 'store/reducers/roles'
import kaseStateReducer from 'store/reducers/kaseStates'
import kaseAuditLog from 'store/reducers/kaseAuditLog'
import kaseFilters from 'store/reducers/kaseFiltersReducer'
import postPayCartOptionsReducer from 'store/reducers/postPayCartOptions'

/**
 * The root Redux store, initialized with some smart defaults
 * @see https://redux-starter-kit.js.org/api/configureStore
 */
const store = configureStore({
  reducer: {
    // Reducers go here
    global: globalReducer,
    adminRoles: rolesReducer,
    adminUsers: adminUsersReducer,
    checkoutDetails: checkoutReducer,
    currentUser: currentUserReducer,
    documentReviewData: documentReviewDataReducer,
    kaseData: kaseDataReducer,
    kaseIssues: kaseIssuesReducer,
    kaseOverview: kaseOverviewReducer,
    kaseReviewData: kaseReviewDataReducer,
    kaseStateEvents: kaseStateEvents,
    kaseTasks: kaseTasksReducer,
    reviewTypes: reviewTypesReducer,
    kaseStates: kaseStateReducer,
    kaseAuditLog: kaseAuditLog,
    kaseFilters: kaseFilters,
    postPayCartOptions: postPayCartOptionsReducer
  }
})

/**
 * The type of the store's reducers
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-state-type
 */
export type RootState = ReturnType<typeof store.getState>

/**
 * The type of the store's dispatch method
 * @see https://redux.js.org/recipes/usage-with-typescript#typing-configurestore
 */
export type StoreDispatch = typeof store.dispatch

export default store
