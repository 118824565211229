import React, { FunctionComponent } from 'react'
import CheckboxField from 'components/forms/CheckboxField'
import { Permission } from 'types/permissions'

interface Props {
  permission: Permission
  isChecked: boolean
  onPermissionCheck: (permissionId: string, isChecked: boolean) => void
}

const PermissionCheckBoxField: FunctionComponent<Props> = ({
  permission,
  isChecked,
  onPermissionCheck
}) => {
  return (
    <div className="flex flex-grow w-1/4">
      <CheckboxField
        label={permission.attributes.name}
        defaultChecked={isChecked}
        onChange={() => onPermissionCheck(permission.id, !isChecked)}
      />
    </div>
  )
}

export default PermissionCheckBoxField
