import { KaseKind } from 'constants/kaseData'
import { KaseData } from 'types/kaseData'

export function getCustomerFirstNamesFromKase(kase: KaseData) {
  if (kase.attributes.category === KaseKind.NATZ) {
    return [kase.attributes.applicant.first_name]
  }

  return [
    kase.attributes.beneficiary.first_name,
    kase.attributes.sponsor.first_name
  ]
}
