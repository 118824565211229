import React, { FunctionComponent, useState, FormEvent } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import ROUTES from 'constants/routes'

import Label from 'components/forms/Label'
import TextAreaField from 'components/forms/TextAreaField'
import Button from 'components/Button'
import TextField from 'components/forms/TextField'
import PermissionCheckBoxField from 'pages/roles/PermissionCheckboxField'

import { RolePermissions, PermissionIdentifier } from 'types/role'

interface ExplicitProps {
  /** Permissions object if any */
  currentPermissions?: PermissionIdentifier[]
  /** Current role name if any */
  currentName?: string
  /** Current role description if any */
  currentDesc?: string
  handleSubmit: (
    attributes: { name: string; description: string },
    permissions: RolePermissions
  ) => void
}

const RoleForm: FunctionComponent<ExplicitProps> = ({
  handleSubmit,
  currentPermissions = [],
  currentName = '',
  currentDesc = ''
}) => {
  const [roleName, setRoleName] = useState(currentName)
  const [roleDescription, setRoleDescription] = useState(currentDesc)
  const [rolePermissions, setRolePermissions] = useState<string[]>(
    currentPermissions.map((permission) => permission.id)
  )

  const permissions = useSelector(
    (state: RootState) => state.adminRoles.permissions
  )

  const handlePermissionsCheck = (permissionId: string, isChecked: boolean) => {
    if (isChecked) {
      setRolePermissions([...rolePermissions, permissionId])
    } else {
      setRolePermissions([
        ...rolePermissions.filter((id) => id !== permissionId)
      ])
    }
  }

  const roleHasPermission = (permissionId: string) => {
    return rolePermissions.some((id) => id === permissionId)
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault

    // Set up permissions object that will be sent to server
    const permissions = {
      permissions: {
        data: Object.values(rolePermissions).map<PermissionIdentifier>(
          (permissionId) => {
            return { type: 'permissions', id: permissionId }
          }
        )
      }
    }

    const attributes = { name: roleName, description: roleDescription }

    handleSubmit(attributes, permissions)
  }

  return (
    <div className="p-4">
      <div className="flex justify-center">
        <div className="bg-white shadow p-6 rounded-sm w-6/12">
          <header>
            <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
              Edit Role
            </h1>
          </header>
          <form onSubmit={onSubmit}>
            <div className="space-y-6">
              <TextField
                label="Name"
                id="roleName"
                onChange={(e) => {
                  setRoleName(e.target.value)
                }}
                value={roleName}
              />

              <TextAreaField
                id="roleDescription"
                label="Description"
                autoFocus
                placeholder="Enter a description of the role"
                onChange={(e) => {
                  setRoleDescription(e.target.value)
                }}
                value={roleDescription}
              />
              <div>
                <Label>Permissions</Label>
                <div className="flex flex-wrap mt-4 mb-8">
                  {permissions.allIds.map((permissionId: string) => (
                    <PermissionCheckBoxField
                      key={permissionId}
                      permission={permissions.byId[permissionId]}
                      isChecked={roleHasPermission(permissionId)}
                      onPermissionCheck={handlePermissionsCheck}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <Button
                variant="primary"
                type="submit"
                disabled={!roleName}
                className="mr-3"
              >
                Save Changes
              </Button>
              <Link
                to={`${ROUTES.ROLES_ROOT}`}
                className="text-blue-600 hover:text-blue-500 active:text-blue-700"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RoleForm as FunctionComponent<ExplicitProps>
