import React, { FunctionComponent } from 'react'
import { Kase } from 'types/kases'

import Table from 'components/tables/Table'
import LoadingSpinner from 'components/LoadingSpinner'
import { Link } from 'react-router-dom'

interface Props {
  kases: Kase[]
  isLoading: boolean
}

const KasesTable: FunctionComponent<Props> = ({ kases, isLoading }) => {
  if (isLoading) {
    return (
      <div className="mt-48">
        <LoadingSpinner />
      </div>
    )
  }
  return (
    <div className="w-full shadow sm:rounded-lg overflow-auto">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID</Table.Header>
            <Table.Header>Account Name</Table.Header>
            <Table.Header>Process</Table.Header>
            <Table.Header>State</Table.Header>
            <Table.Header>Account Email</Table.Header>
            <Table.Header>Link</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {kases.map((kase) => (
            <Table.Row key={kase.id}>
              <Table.Cell>{kase.id}</Table.Cell>
              <Table.Cell>{kase.attributes.account_name}</Table.Cell>
              <Table.Cell>{kase.attributes.kind}</Table.Cell>
              <Table.Cell>{kase.attributes.state}</Table.Cell>
              <Table.Cell>{kase.attributes.account_email}</Table.Cell>
              <Table.Cell>
                <Link
                  to={`/overview/` + kase.id}
                  className="inline-flex items-center text-blue-600 hover:text-blue-800"
                >
                  View Kase
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default KasesTable
