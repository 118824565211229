import server from 'api/server'
import {
  setComment,
  setKaseOverview,
  updateCommentsAfterDelete,
  updateCommentsAfterPin
} from 'store/reducers/kaseOverview'
import store from 'store/root'

export async function fetchOverview(kaseId: number | string | undefined) {
  return server.get(`/api/v1/admin/kases/${kaseId}`).then((response) => {
    const kaseOverview = {
      ...response.data.data.attributes,
      kaseId: kaseId
    }
    store.dispatch(setKaseOverview(kaseOverview))

    return response
  })
}

//TODO figure out kase editing with new api structure
export async function updateKaseOverview(
  kaseId: number | string | undefined,
  key: any,
  value: any
) {
  const params = {
    id: kaseId,
    kases: {
      [key]: value
    }
  }

  return server
    .patch(`/api/v1/admin/kases/${kaseId}`, params)
    .then((response) => {
      const kaseOverview = {
        ...response.data.data.attributes,
        kaseId: kaseId
      }
      store.dispatch(setKaseOverview(kaseOverview))
      return response
    })
}

export async function postComment(kaseId: string, content: string) {
  const kase_comment = {
    content
  }

  return server
    .post(`/api/v1/admin/kases/${kaseId}/kase_comments`, { kase_comment })
    .then((response) => {
      store.dispatch(setComment(response.data.data))
    })
}

export async function deleteComment(kaseId: string, commentId: number) {
  const kase_comment = {
    deleted: true
  }

  // We want a record of the comments even when deleted
  // So we're just updating the deleted flag to delete a comment
  return server
    .put(`/api/v1/admin/kases/${kaseId}/kase_comments/${commentId}`, {
      kase_comment
    })
    .then((response) => {
      store.dispatch(updateCommentsAfterDelete(response.data.data))
    })
}

export async function updateKaseTag(kaseId: string, changeTag: string) {
  return server
    .patch(`/api/v1/admin/kases/${kaseId}/change_kase_tag`, {
      new_tag: changeTag
    })
    .then((response) => {
      const kaseOverview = {
        ...response.data.data.attributes,
        kaseId: kaseId
      }
      store.dispatch(setKaseOverview(kaseOverview))
      return response
    })
}

export async function pinComment(
  kaseId: string,
  commentId: number,
  pin: boolean
) {
  const kase_comment = {
    pinned: pin
  }

  return server
    .put(`/api/v1/admin/kases/${kaseId}/kase_comments/${commentId}`, {
      kase_comment
    })
    .then((response) => {
      store.dispatch(updateCommentsAfterPin(response.data.data))
    })
}
