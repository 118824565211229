import React, { FunctionComponent } from 'react'
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd'
import cx from 'classnames'
import DuplicateIcon from 'components/icons/DuplicateIcon'
import Button from 'components/Button'
import LibraryIcon from 'components/icons/LibraryIcon'
import { IMasterPDFPage } from 'types/masterPdf'
import DocumentTextIcon from 'components/icons/DocumentTextIcon'
import TrashIcon from 'components/icons/TrashIcon'

interface Props {
  draggedFileKey: string
  file: IMasterPDFPage
  index: number
  isSelected: boolean
  keyName: string
  multiSelectTo: (key: string) => void
  numSelectedFiles: number
  onCopyFileBtnClicked: (file: IMasterPDFPage, index: number) => void
  onDeleteFileBtnClicked: (index: number) => void
  toggleSelectionInGroup: (id: string) => void
  onViewDocumentBtnClicked: (document: IMasterPDFPage) => void
}

const MPDFDraggable: FunctionComponent<Props> = ({
  draggedFileKey,
  file,
  index,
  isSelected,
  keyName,
  multiSelectTo,
  numSelectedFiles,
  onCopyFileBtnClicked,
  onDeleteFileBtnClicked,
  toggleSelectionInGroup,
  onViewDocumentBtnClicked
}) => {
  const isFileTypeForm = ['form', 'form-addendum', 'static'].includes(
    file.pdf_file_option.pdf_file_type
  )

  const draggableLabel = isFileTypeForm
    ? file.pdf_file_option.name
    : file.document.file_name

  const fileKey = file.key

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    // In place of deprecated platform
    // Could add experimental userAgentData using what's suggested here
    // https://stackoverflow.com/questions/71392342/property-useragentdata-does-not-exist-on-type-navigator
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => event.shiftKey

  const performAction = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      // This removes the text selection when cmd + clicking
      document?.getSelection()?.removeAllRanges()
      toggleSelectionInGroup(fileKey)
      return
    }

    if (wasMultiSelectKeyUsed(event)) {
      // This removes the text selection when shift + clicking
      document?.getSelection()?.removeAllRanges()
      multiSelectTo(fileKey)
      return
    }
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    snapshot: DraggableStateSnapshot
  ) => {
    // already used
    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.key !== 'Enter') {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    performAction(event)
  }

  // Using onClick as it will be correctly preventing if there was a drag
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // 0 represents the left mouse click button
    const primaryButton = 0

    if (event.defaultPrevented) {
      return
    }

    if (event.button !== primaryButton) {
      return
    }

    // marking the event as used
    event.preventDefault()

    performAction(event)
  }

  const viewDocumentBtnClicked = (file: IMasterPDFPage) => {
    // If files are being selected, don't open the document to prevent accidentally opening them
    // when you want to select them
    if (numSelectedFiles > 0) {
      return
    }

    onViewDocumentBtnClicked(file)
  }

  return (
    <Draggable key={keyName} draggableId={keyName} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(event, snapshot)
          }
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={cx('flex justify-between mt-4 p-4', {
              'bg-white border border-black': isFileTypeForm,
              'bg-gray border border-gray-300': !isFileTypeForm,
              'bg-teal-100': isSelected,
              'bg-indigo-100 border-blue-800 text-indigo-900':
                snapshot.isDragging
            })}
            onClick={onClick}
          >
            <span className="overflow-hidden w-4/5 whitespace-no-wrap">
              {isFileTypeForm ? (
                <LibraryIcon className="inline" />
              ) : (
                <DocumentTextIcon className="text-gray-500 inline" />
              )}
              {file.document !== null ? (
                <Button
                  onClick={() => viewDocumentBtnClicked(file)}
                  variant="plain"
                >
                  {draggableLabel}
                </Button>
              ) : (
                <span className="text-blue-600 inline-flex items-center text-sm leading-5 font-medium rounded-sm whitespace-no-wrap focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                  {draggableLabel}
                </span>
              )}
            </span>
            <span>
              {/* Can only delete file with a key that contains special text which is appended
              if it's a duplicate /*/}
              {file.key && file.key.includes('-copy-inserted-at-index-') && (
                <Button
                  onClick={() => onDeleteFileBtnClicked(index)}
                  variant="plain"
                >
                  <Button.Icon>
                    <TrashIcon />
                  </Button.Icon>
                </Button>
              )}
              {draggedFileKey === fileKey && numSelectedFiles > 0 ? (
                <div className="text-sm bg-gray-400 rounded-full h-8 w-8 flex items-center justify-center">
                  {numSelectedFiles}
                </div>
              ) : (
                <Button
                  onClick={() => onCopyFileBtnClicked(file, index)}
                  variant="plain"
                >
                  <Button.Icon>
                    <DuplicateIcon />
                  </Button.Icon>
                </Button>
              )}
            </span>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default MPDFDraggable
