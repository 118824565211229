import { KaseIssuesShape } from 'store/reducers/kaseIssues'
import { IDocument, IDocumentRequest } from 'types/documents'

export function docHasOpenIssues(doc: IDocument, kaseIssues: KaseIssuesShape) {
  return kaseIssues.documents_issues.allIds.some((id) => {
    const docIssue = kaseIssues.documents_issues.byId[id].attributes
    return (
      docIssue.document_id === doc.id &&
      kaseIssues.issues.byId[docIssue.issue_id].attributes.status === 'open'
    )
  })
}

export function docRequestHasOpenIssues(
  docReq: IDocumentRequest,
  kaseIssues: KaseIssuesShape
) {
  const docRequestHasOpenIssue = kaseIssues.documents_issues.allIds.some(
    (id) => {
      const docReqIssue = kaseIssues.documents_issues.byId[id].attributes
      return (
        docReqIssue.document_request_id === docReq.id &&
        kaseIssues.issues.byId[docReqIssue.issue_id].attributes.status ===
          'open'
      )
    }
  )

  if (docRequestHasOpenIssue) {
    return true
  } else {
    return docReq.parts.some((part) =>
      part.contents.some((document) =>
        docHasOpenIssues(document.document, kaseIssues)
      )
    )
  }
}
