import React, { FunctionComponent, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import { signOut } from 'api/authentication'
import routes from 'constants/routes'
import DevMenu from 'components/DevMenu'
import MobileNav from 'components/nav/MobileNav'
import { RootState } from 'store/root'
import { NAV_LINKS } from 'constants/nav'
import { pathMatchesUrl } from 'utils/match_path'
import PermissionLinkWrapper from 'components/PermissionLinkWrapper'
import Button from '../Button'
import TextInput from '../forms/TextInput'

// React Router has a NavLink component but it doesn't work great with Tailwind
interface CustomNavLinkProps {
  to: string
  exact?: boolean
}

const CustomNavLink: FunctionComponent<CustomNavLinkProps> = ({
  children,
  to,
  exact = false
}) => {
  // Figure out whether the link is active
  const location = useLocation()
  let isActive = pathMatchesUrl(to, location.pathname)
  if (exact) {
    isActive = location.pathname === to
  }

  const classes = cx('block px-3 py-1 rounded md:mr-2 font-medium', {
    'text-white bg-gray-800': isActive,
    'text-gray-200 hover:bg-gray-800 hover:text-white': !isActive
  })

  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  )
}

const TopNavigation: FunctionComponent = () => {
  const history = useHistory()
  const currentUser = useSelector((store: RootState) => store.currentUser)
  const [applicationSearch, setApplicaitonSearch] = useState('')
  const [showMobileNav, setShowMobileNav] = useState(false)

  const showDevMenu = process.env.NODE_ENV === 'development'

  const redirectToApplication = (e: any) => {
    e.preventDefault()
    history.push(`/overview/${applicationSearch}`)
  }

  const onSignOut = () => {
    signOut().then(() => {
      window.location.href = '/sign-in'
    })
  }

  return (
    <nav className="bg-gray-700 py-2 ">
      <div className="h-12 flex items-center justify-between px-8">
        <Link className="text-white py-1 mr-6" to={routes.HOME}>
          Boundless Admin
        </Link>

        {/* Desktop links */}
        <div className="items-center h-full flex-grow hidden md:flex">
          {NAV_LINKS.map((item) => (
            <PermissionLinkWrapper
              key={item.route}
              permission={item.permission}
            >
              <CustomNavLink to={item.route}>{item.label}</CustomNavLink>
            </PermissionLinkWrapper>
          ))}
          <div id="application-search" className="inline-flex">
            <TextInput
              className="flex-2"
              name="application_search"
              onChange={(event) => setApplicaitonSearch(event.target.value)}
              placeholder="Application ID"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  redirectToApplication(event)
                }
              }}
            />
            <div className="flex-1">&nbsp;</div>
            <Button className="flex-1" onClick={redirectToApplication}>
              Search
            </Button>
          </div>
        </div>

        <button
          className="hidden md:block p-2 text-gray-200 hover:text-white"
          type="button"
          onClick={onSignOut}
        >
          Sign Out
        </button>

        {showDevMenu && <DevMenu />}

        {/* Mobile menu */}
        <div className="md:hidden">
          <button
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-600 focus:outline-none focus:bg-blue-600 focus:text-white transition duration-150 ease-in-out"
            aria-label="Main menu"
            aria-expanded={showMobileNav ? 'true' : 'false'}
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <svg
              className={cx('h-6 w-6', {
                block: !showMobileNav,
                hidden: showMobileNav
              })}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
            <svg
              className={cx('h-6 w-6', {
                block: showMobileNav,
                hidden: !showMobileNav
              })}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile links */}
      <MobileNav
        isVisible={showMobileNav}
        onSignOut={onSignOut}
        currentUser={currentUser}
      />
    </nav>
  )
}

export default TopNavigation
