import dayjs from 'dayjs'

// if today returns Saturday, August 17th 2019, 11:03 am
// else returns Today at 11:03 am
export const todayOrFormattedDate = (
  parseableDate?: string | Date | number
) => {
  const date = dayjs(parseableDate)

  if (!date.isValid()) {
    return parseableDate
  }

  const today = dayjs()

  if (date.isSame(today, 'd')) {
    return `Today at ${date.format('h:mma')}`
  }

  return date.format('MMM D YYYY [at] h:mma')
}

export const formattedDate = (parseableDate?: string | Date | number) => {
  const date = dayjs(parseableDate)

  return date.format('MMM D YYYY')
}

export const simpleFormatDateTime = (
  parseableDate?: string | Date | number
) => {
  const date = dayjs(parseableDate)

  if (!date.isValid()) {
    return parseableDate
  }

  return date.format('M/D/YY h:mma')
}
