import { Issue } from 'types/issues'
import { Message } from 'types/message'

export function mostRecentMessageIsFromCustomer({
  issue,
  messages,
  customerId
}: {
  issue: Issue
  messages: { [key: string]: Message }
  customerId?: string
}) {
  const issueMessageIds = issue.relationships?.messages?.data || []

  if (issueMessageIds.length > 0) {
    // We've made the mistake of assuming that messages are in chronological
    // order in the array, which is not always the case!
    // Therefore, we sorted them first
    const messagesInChronologicalOrder = issueMessageIds
      .map((message) => messages[message.id])
      .sort((a, b) => {
        return new Date(a.attributes.created_at) >
          new Date(b.attributes.created_at)
          ? 1
          : -1
      })

    const mostRecentMessage =
      messagesInChronologicalOrder[messagesInChronologicalOrder.length - 1]

    return messages[mostRecentMessage.id].attributes.creator_id === customerId
  }

  // If there are no messages, issue is new and does not require admin response
  return false
}
