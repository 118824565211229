import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import { PageElementQuestion } from 'types/questionnaire'

interface Props {
  question: PageElementQuestion
  onBlur?: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  /**
   * Transform the key and value of the option into a user friendly label
   * Default is to just spit out the value
   */
  options: Record<string, string>
  optionLabelFn?: (key: string, value: string) => string
  selectFieldLabel?: string
  value?: string
}

function SelectFieldQuestionElement({
  onBlur,
  onChange,
  question,
  options,
  optionLabelFn = (_, value) => value,
  selectFieldLabel,
  value
}: Props): JSX.Element {
  const label = question.attributes.text || selectFieldLabel

  return (
    <div key={question?.id}>
      {label && `${label}: `}&nbsp;
      <select
        name={question?.id}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {optionLabelFn(key, value)}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectFieldQuestionElement
