import Button from 'components/Button'
import React, { FunctionComponent, useRef } from 'react'

import DocumentRequest from './DocumentRequest'

import { NameCheckboxIds } from '.'
import { IDocument, IDocumentRequest } from 'types/documents'

interface Props {
  currentView?: string
  document_requests: IDocumentRequest[]
  id: number
  name: string
  onDocumentCheckboxClicked: (docRequest: IDocument) => void
  onDocRequestCheckboxClicked: (docRequest: IDocumentRequest) => void
  onNameCheckboxClicked: (
    documentRequest: IDocumentRequest,
    documentRequestPartId: number
  ) => void
  onUploadFileChanged: (
    file: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => void
  onToggleOtherButtonClicked: () => void
  selectedDocuments: IDocument[]
  selectedDocumentRequests: IDocumentRequest[]
  selectedNameCheckboxIds: NameCheckboxIds[]
  title: string
}

const DocumentSet: FunctionComponent<Props> = ({
  currentView,
  document_requests,
  id,
  name,
  onDocumentCheckboxClicked,
  onDocRequestCheckboxClicked,
  onNameCheckboxClicked,
  onUploadFileChanged,
  onToggleOtherButtonClicked,
  selectedDocuments,
  selectedDocumentRequests,
  selectedNameCheckboxIds,
  title
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isCurrentView = currentView === name

  if (isCurrentView && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div key={id} ref={ref}>
      <div className="flex flex-row justify-between">
        <h2 className="text-base font-bold mb-5">{title}</h2>
        {name.toLowerCase() === 'other' && (
          <Button variant="plain" onClick={onToggleOtherButtonClicked}>
            Hide Request From Customer
          </Button>
        )}
      </div>
      {document_requests.map((document_request) => (
        <DocumentRequest
          key={document_request.id}
          checkboxChecked={selectedDocumentRequests.some(
            (docRequest) => docRequest === document_request
          )}
          currentView={currentView}
          documentRequest={document_request}
          onDocumentCheckboxClicked={onDocumentCheckboxClicked}
          onDocRequestCheckboxClicked={onDocRequestCheckboxClicked}
          onNameCheckboxClicked={onNameCheckboxClicked}
          onUploadFileChanged={onUploadFileChanged}
          selectedDocuments={selectedDocuments}
          selectedNameCheckboxIds={selectedNameCheckboxIds}
        />
      ))}
    </div>
  )
}

export default DocumentSet as FunctionComponent<Props>
