import React, { FunctionComponent, HTMLAttributes, useState } from 'react'
import cx from 'classnames'
import CheckboxField from 'components/forms/CheckboxField'

interface Props {
  onSaveHandler: (keyValue: string, value?: string) => void
  value?: string
  border?: boolean // TODO: Update storybook docs
  input_type?: string
  keyValue?: string
}

const TableEditableCell: FunctionComponent<
  HTMLAttributes<HTMLTableDataCellElement> & Props
> = ({
  keyValue,
  value,
  className,
  onSaveHandler,
  border = true,
  input_type = 'text',
  ...otherProps
}) => {
  const [modifiedValue, setModifiedValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <td
        className={cx(
          className,
          { 'border-t border-gray-200': border },
          'text-sm leading-5 px-6 py-4 bg-white flex w-full relative'
        )}
        {...otherProps}
      >
        <>
          {input_type === 'boolean' ? (
            <CheckboxField
              checked={modifiedValue === 'true'}
              label=""
              onChange={(event) =>
                setModifiedValue(event.target.checked.toString())
              }
            />
          ) : (
            <input
              onChange={(event) => setModifiedValue(event.target.value)}
              value={modifiedValue}
              type={input_type}
              className="outline-none w-4/5"
            />
          )}
          <button
            className="absolute right-0 pr-4"
            onClick={() => {
              if (!!keyValue) {
                onSaveHandler(keyValue, modifiedValue)
                setIsEditing(false)
              }
            }}
          >
            Save
          </button>
        </>
      </td>
    )
  }

  return (
    <td
      className={cx(
        className,
        { 'border-t border-gray-200': border },
        'text-right text-sm leading-5 px-6 py-4 flex justify-between w-full'
      )}
      {...otherProps}
    >
      {value ? (
        input_type === 'url' ? (
          <a
            className="text-blue-700 text-sm block mb-2 hover:text-blue-800"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ) : (
          value
        )
      ) : (
        <span></span>
      )}
      <button onClick={() => setIsEditing(true)}>Edit</button>
    </td>
  )
}

export default TableEditableCell
