import React, { FunctionComponent, ReactNode } from 'react'
import TopNavigation from 'components/TopNavigation'
import GlobalErrorBanner from 'components/errors/GlobalErrorBanner'

interface Props {
  footer?: ReactNode
  sidebar?: ReactNode
  subNavigation?: ReactNode
}

const SidebarLayout: FunctionComponent<Props> = ({
  children,
  footer,
  sidebar,
  subNavigation
}) => {
  return (
    <div className="h-screen flex flex-col">
      <TopNavigation />
      {subNavigation}
      <div className="bg-gray-100 h-screen overflow-hidden grid grid-cols-4 gap-8">
        {sidebar}
        <main className="col-span-3 pt-10 pr-8 overflow-y-auto">
          <GlobalErrorBanner />
          {/* https://github.com/w3c/csswg-drafts/issues/129 */}
          {children}
        </main>
      </div>
      {footer}
    </div>
  )
}

export default SidebarLayout
