import React, { FC, ReactNode } from 'react'
import rules from '../access_rules'
import { PermissionType } from 'types/permissions'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

interface Props {
  /**
   * Action to perform in a "<resource:action>" format
   */
  perform: PermissionType
  /**
   * Data to operate on for determining dynamic rules,
   * e.g.{
   *   userId: <currentUserId>,
   *   messageCreatorId: <creatorId>
   * }
   */
  data?: unknown
  /**
   *  A node to render if permission for action is granted
   */
  yes?: ReactNode
  /**
   * A node to render if action is not permitted
   */
  no?: ReactNode
}

const Can: FC<Props> = ({ perform, data, yes = null, no = null }) => {
  const { roles = ['default'] } = useSelector(
    (state: RootState) => state.currentUser
  )

  const check = () => {
    const permissions = roles.map((role) => rules[role.toLowerCase()])

    if (permissions.length === 0) {
      // role is not present in the rules
      return false
    }

    const staticPermissions = permissions.map((permission) => permission.static)

    if (
      staticPermissions.length > 0 &&
      staticPermissions.some((permission) => permission.includes(perform))
    ) {
      // static rule not provided for action
      return true
    }

    const dynamicPermissions = permissions.map(
      (permission) => permission.dynamic
    )

    if (dynamicPermissions.length > 0) {
      let permissionCondition: any

      dynamicPermissions.forEach((permission) => {
        permissionCondition = permission[perform]
      })

      //const permissionCondition = dynamicPermissions[perform]
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false
      }

      return permissionCondition(data)
    }
    return false
  }

  return <>{check() ? yes : no}</>
}

export default Can
