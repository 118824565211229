import React, { FunctionComponent } from 'react'
// import ReactMarkdown from 'react-markdown'
import { QuestionJitElement } from 'types/questionnaire'

interface Props {
  jit: QuestionJitElement
}

// TODO: This should use ReactMarkdown but for some reason it doesn't play nicely with the specs
export const NewApplicationJit: FunctionComponent<Props> = ({ jit }) => {
  const jitTextColor = (() => {
    switch (jit.type) {
      case 'danger':
        return 'text-red-700'
      case 'success':
        return 'text-green-700'
      default:
        return 'text-blue-700'
    }
  })()

  return (
    <div>
      <div className={jitTextColor}>
        <strong>{jit.header}</strong>
      </div>
      <div className={jitTextColor}>
        <em>{jit.subHeader}</em>
      </div>
      <div className={jitTextColor}>
        <p>{jit.text}</p>
      </div>
    </div>
  )
}
