import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { useParams } from 'react-router'

import { fetchCheckoutDetails } from 'api/payment'
import { sendKaseAnswer } from 'api/kaseData'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Card from 'components/Card'
import SelectField from 'components/forms/SelectField'
import QuestionElement from 'components/question-element'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { ServiceCheckoutDetails } from 'types/payment'

interface Props {
  checkoutDetails: ServiceCheckoutDetails
  setTeenNumberKey: (value: string) => void
}

const Checkout: FunctionComponent<Props> = ({
  checkoutDetails,
  setTeenNumberKey
}) => {
  const {
    extra_questions,
    number_of_children_for_age,
    total_uscis_fee_range
  } = checkoutDetails
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const [isLoading, setIsLoading] = useState(false)

  const handleQuestionAnswer = (
    value: string,
    key: string,
    answerId: string | null
  ) => {
    setIsLoading(true)
    sendKaseAnswer(kaseId, key, value, answerId)
      .then(() => fetchCheckoutDetails(kaseId))
      .catch((error) => {
        setGlobalError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Move the questions to the backend to follow our questionnaire
  // architecture a little bit better. This works fine as a v1.
  const EXTRA_QUESTIONS: { [key: string]: string } = {
    beneficiary_visa_type: 'What U.S. visa is the beneficiary currently on?',
    mbgc_k1_married_within_90_days:
      'Did the sponsor and beneficiary get married within 90 days of the beneficiary’s most recent U.S. entry?',
    children_for_age: 'How many applying children are over 14 years of age?'
  }

  const getChildOptions = () => {
    const childrenArray = number_of_children_for_age
      ? new Array(number_of_children_for_age + 1)
      : []
    return [...childrenArray].map((_, index) => {
      return {
        label: index,
        value: `teen_number_${index}`
      }
    })
  }

  const onChangeTeenSelect = (event: ChangeEvent<HTMLSelectElement>) =>
    setTeenNumberKey(event.target.value)

  return (
    <Card className="my-6" disabled={isLoading}>
      <Card.Header>Determine Exact Goverment Fees</Card.Header>
      <Card.Body>
        <p>
          Your government fees range from <b>{total_uscis_fee_range}</b>. Answer
          these questions to learn your exact fee amount.
        </p>
      </Card.Body>
      {extra_questions?.map((question) => {
        return (
          <Card.Body key={question.id}>
            <div className="flex">
              <div className="w-1/2 mr-3">
                <p>{EXTRA_QUESTIONS[question.attributes.key]}</p>
              </div>
              <div className="w-1/2 ml-3">
                <QuestionElement
                  key={question.id}
                  question={question}
                  handleQuestionAnswer={handleQuestionAnswer}
                />
              </div>
            </div>
          </Card.Body>
        )
      })}
      {!!number_of_children_for_age && number_of_children_for_age > 0 && (
        <Card.Body>
          <div className="flex">
            <div className="w-1/2 mr-3">
              <p>{EXTRA_QUESTIONS.children_for_age}</p>
            </div>
            <div className="w-1/2 ml-3">
              <SelectField
                defaultValue=""
                placeholder="Select number of children over 14"
                options={getChildOptions()}
                onChange={onChangeTeenSelect}
              />
            </div>
          </div>
        </Card.Body>
      )}
    </Card>
  )
}

export default Checkout as FunctionComponent<Props>
