import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
}

const Footer: FunctionComponent<Props> = ({ children, className }) => {
  const classes = cx('px-6 py-3 border-gray-200 border-t mt-auto', {
    [className as any]: !!className
  })

  return <div className={classes}>{children}</div>
}

export default Footer as FunctionComponent<Props>
