import React, { FunctionComponent } from 'react'
import Button from '../../components/Button'

interface Props {
  numSelected: number
  handleAssignSelectedButtonClicked: () => void
  handleUnassignSelectedButtonClicked: () => void
}

const TasksAssignControls: FunctionComponent<Props> = ({
  numSelected,
  handleAssignSelectedButtonClicked,
  handleUnassignSelectedButtonClicked
}) => {
  return (
    <div className="flex flex-end mt-8">
      <span className="mr-4 self-center">{numSelected} selected</span>
      <Button
        disabled={numSelected === 0}
        variant="primary"
        onClick={handleAssignSelectedButtonClicked}
        className="mr-4"
      >
        Assign selected
      </Button>
      <Button
        disabled={numSelected === 0}
        variant="default"
        onClick={handleUnassignSelectedButtonClicked}
      >
        Unassign selected
      </Button>
    </div>
  )
}

export default TasksAssignControls as FunctionComponent<Props>
