import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { Issue } from 'types/issues'
import { todayOrFormattedDate } from 'utils/format_dates'
import Button from 'components/Button'
import ButtonIcon from 'components/ButtonIcon'
import ChatIcon from 'components/icons/ChatIcon'
import { fetchIssues } from 'api/issues'

interface Props {
  handleEditButtonClick: (issueId: string) => void
  handleResolveButtonClick: (kaseId: string, issueId: string) => void
  issue: Issue
  openThreadPanel: (issue: Issue) => void
  showCreateIssue: boolean
  showEditIssue: boolean
}

const KaseIssue: FunctionComponent<Props> = ({
  handleEditButtonClick,
  handleResolveButtonClick,
  issue,
  openThreadPanel,
  showCreateIssue,
  showEditIssue
}) => {
  const [resolve, setResolve] = useState(false)
  const {
    description,
    creator_name,
    created_at,
    status,
    links
  } = issue.attributes
  const messages = useSelector((state: RootState) => state.kaseIssues.messages)
  const currentUserId = useSelector((state: RootState) => state.currentUser.id)
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)

  const handleClickResolveButton = () => {
    setResolve(true)
  }

  const handleClickCancelButton = () => {
    setResolve(false)
  }

  const allLinkedIssues: string[] = []
  links?.map((link) => {
    allLinkedIssues.push(link.label.replace('View ', ''))
  })

  // Compile the list of messages for this issue
  const messagesForCurrentIssue = []
  messages.allIds.forEach((messageId) => {
    if (messages.byId[messageId].attributes.issue_id === issue.id) {
      messagesForCurrentIssue.push(messages.byId[messageId])
    }
  })

  const messagesLength = messagesForCurrentIssue?.length
  const lastMessage = messagesForCurrentIssue[messagesLength - 1]
  const hasMessages = messagesForCurrentIssue?.length > 0
  const singleMessageFromAdmin =
    messagesForCurrentIssue?.length === 0 &&
    messagesForCurrentIssue[0]?.attributes?.creator_id !==
      currentUserId?.toString()
  const hasOneMessage = messagesForCurrentIssue?.length === 1

  const hasNewMessage =
    hasMessages &&
    lastMessage?.attributes?.creator_id !== currentUserId?.toString()

  const hasReplied =
    hasMessages &&
    !hasOneMessage &&
    !singleMessageFromAdmin &&
    lastMessage?.attributes?.creator_id === currentUserId?.toString()

  const openMessages = () => {
    fetchIssues(kaseOverview.kaseId)
    openThreadPanel(issue)
  }
  return (
    <>
      <div className="mx-4 mt-4">
        <div
          className={cx('text-sm text-gray-700', {
            'pt-4': showCreateIssue || showEditIssue
          })}
        >
          <div className="flex flex-row flex-wrap mb-2">
            <div className="mr-4">{creator_name}</div>
            {todayOrFormattedDate(created_at)}
          </div>
          {!!links?.length && (
            <div className="flex flex-wrap">
              {allLinkedIssues?.map((link, index) => {
                return (
                  <div
                    className="rounded-full bg-blue-100 px-2 mr-2 mt-2"
                    key={index}
                  >
                    {link}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <p className="my-4">{description}</p>
        <div className="flex justify-between mb-4">
          {resolve ? (
            <>
              <div className="flex-col">
                <p className="font-bold mb-4">
                  Resolve issue and remove from list?
                </p>
                <Button
                  variant="danger"
                  disabled={showCreateIssue || showEditIssue}
                  onClick={() => {
                    handleResolveButtonClick(issue.attributes.kase_id, issue.id)
                  }}
                >
                  Resolve Issue
                </Button>
              </div>
              <Button
                variant="plain"
                disabled={showCreateIssue || showEditIssue}
                onClick={handleClickCancelButton}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="plain"
                className={cx({ hidden: status === 'resolved' })}
                onClick={() => handleEditButtonClick(issue.id)}
              >
                Edit
              </Button>
              <Button
                variant="plain"
                className={cx({ hidden: status === 'resolved' })}
                onClick={handleClickResolveButton}
              >
                Resolve
              </Button>
            </>
          )}
        </div>
      </div>
      <Button onClick={openMessages} className="mx-4 mb-4">
        <ButtonIcon placement="before">
          <ChatIcon />
        </ButtonIcon>
        Messages
      </Button>

      {hasNewMessage && <span className="text-red-600 text-sm">New</span>}
      {hasReplied && <span className="text-green-600 text-sm">Replied</span>}
      <hr className="w-full mb-4" />
    </>
  )
}

export default KaseIssue
