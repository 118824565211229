interface EditMessageParams {
  userId: string
  messageCreatorId: string
}

const ADMIN_ACCESS_RULES = {
  static: [
    'issue:read',
    'issue:create',
    'issue:update',
    'message:create',
    'task:update',
    'task:read',
    'user:update',
    'user:read',
    'role:update',
    'role:create',
    'role:read',
    'kases:read'
  ],
  dynamic: {
    'message:update': ({ userId, messageCreatorId }: EditMessageParams) => {
      if (!userId || !messageCreatorId) return false
      // CurrentUser id is stored as number and message creator_id is string
      // eslint-disable-next-line eqeqeq
      return userId == messageCreatorId
    }
  }
}

const DEFAULT_ACCESS_RULES = {
  // read-only
  static: ['issue:read'],
  dynamic: {}
}

const rules: { [index: string]: any } = {
  agent: DEFAULT_ACCESS_RULES,
  comms: DEFAULT_ACCESS_RULES,
  cso: ADMIN_ACCESS_RULES,
  default: DEFAULT_ACCESS_RULES,
  dev: ADMIN_ACCESS_RULES,
  dra: DEFAULT_ACCESS_RULES,
  'former employee': DEFAULT_ACCESS_RULES,
  lawyer: DEFAULT_ACCESS_RULES,
  manager: DEFAULT_ACCESS_RULES,
  printer: DEFAULT_ACCESS_RULES,
  root: DEFAULT_ACCESS_RULES,
  steward: ADMIN_ACCESS_RULES,
  'temporary cso': DEFAULT_ACCESS_RULES,
  'temporary worker': DEFAULT_ACCESS_RULES
}

export default rules
