import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import cx from 'classnames'

export enum AlertVariants {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

interface Props {
  border?: boolean
  /**
   * The title displayed at top of the alert.
   */
  title: ReactNode
  variant: 'success' | 'warning' | 'error' | 'info'
  className?: string
  errorRef?: any
}

const Alert: FunctionComponent<Props> = ({
  border,
  variant = 'success',
  title,
  children,
  className,
  errorRef,
  ...otherProps
}) => {
  const classes = cx(className, 'rounded-md p-4 flex', {
    'bg-green-50 text-green-700': variant === 'success',
    'bg-yellow-50 text-yellow-700': variant === 'warning',
    'bg-red-50 text-red-700': variant === 'error',
    'bg-blue-50 text-blue-700': variant === 'info',
    'border-green-400 border': variant === 'success' && border,
    'border-yellow-400 border': variant === 'warning' && border,
    'border-red-400 border': variant === 'error' && border,
    'border-blue-400 border': variant === 'info' && border
  })

  const titleClasses = cx('font-medium text-sm leading-5', {
    'text-green-800': variant === 'success',
    'text-yellow-800': variant === 'warning',
    'text-red-800': variant === 'error'
  })

  const getIcon = () => {
    switch (variant) {
      case 'info':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 text-blue-400"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        )
      case 'warning':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 text-yellow-400"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        )
      case 'error':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 text-red-400"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )
      case 'success':
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 text-green-400"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        )
    }
  }

  useEffect(() => {
    if (!!errorRef) {
      errorRef?.current?.scrollIntoView()
    }
  })

  return (
    <div className={classes} {...otherProps} ref={errorRef}>
      <div className="flex-shrink-0 mr-3">{getIcon()}</div>
      <div>
        <div className={titleClasses}>{title}</div>
        {children && <div className="mt-2 text-sm leading-5">{children}</div>}
      </div>
    </div>
  )
}

export default Alert
