import React, { FunctionComponent } from 'react'
import { User } from 'types/users'
import UserRow from 'pages/users/UserRow'
import Table from 'components/tables/Table'
import LoadingSpinner from 'components/LoadingSpinner'

interface Props {
  users: User[]
  isLoading: boolean
}

const UsersTable: FunctionComponent<Props> = ({ users, isLoading }) => {
  if (isLoading) {
    return (
      <div className="mt-48">
        <LoadingSpinner />
      </div>
    )
  }
  return (
    <div className="w-full shadow sm:rounded-lg overflow-auto">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID</Table.Header>
            <Table.Header>Email</Table.Header>
            <Table.Header>Guest</Table.Header>
            <Table.Header>Kases</Table.Header>
            <Table.Header></Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {users.map((user) => (
            <UserRow key={user.id} user={user} />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default UsersTable
