import { OptionKeyValue } from './index'
import {
  OverviewInformationItem,
  OverviewInformationItemWithOptions
} from './kaseOverview'

export function isEditableSelect(
  item: OverviewInformationItem
): item is OverviewInformationItemWithOptions {
  return (
    !!item.editable &&
    item.editable_type === 'select' &&
    Array.isArray(item.editable_options)
  )
}

export function isOptionKeyValue(
  option: OptionKeyValue | string
): option is OptionKeyValue {
  return typeof option !== 'string' && !!option.value
}

export function isListOfOptionKeyValues(
  options: unknown[]
): options is OptionKeyValue[] {
  return Array.isArray(options) && options.every((val) => isOptionKeyValue(val))
}

export function isListOfOptionStrings(options: unknown[]): options is string[] {
  return (
    Array.isArray(options) && options.every((val) => typeof val === 'string')
  )
}
