import server from 'api/server'
import {
  setIssues,
  setIssue,
  updateMessageInStore,
  setMessage,
  deleteIssueFromStore
} from 'store/reducers/kaseIssues'
import store from 'store/root'
import normalize from 'json-api-normalizer'

import { Message } from 'types/message'
import { ICreateIssueParams } from 'types/issues'

export async function fetchIssues(kaseId: string) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/issues`)
    .then((response) => {
      const normalizeResponse = normalize(response.data, {
        camelizeKeys: false
      })
      store.dispatch(setIssues(normalizeResponse))
      return response
    })
    .catch((error) => error)
}

export async function createIssue(
  kaseId: string,
  createIssueParams: ICreateIssueParams
) {
  const data = {
    type: 'issues',
    attributes: {
      description: createIssueParams.description
    },
    relationships: {}
  }

  data.relationships = {
    ...(createIssueParams.employmentHistoriesIssuesData && {
      employment_histories_issues: {
        data: createIssueParams.employmentHistoriesIssuesData
      }
    }),
    ...(createIssueParams.addressHistoriesIssuesData && {
      address_histories_issues: {
        data: createIssueParams.addressHistoriesIssuesData
      }
    }),
    ...(createIssueParams.answersIssuesData && {
      answers_issues: {
        data: createIssueParams.answersIssuesData
      }
    }),
    ...(createIssueParams.documentsIssuesData && {
      documents_issues: {
        data: createIssueParams.documentsIssuesData
      }
    })
  }

  return server
    .post(`/api/v1/admin/kases/${kaseId}/issues`, {
      data
    })
    .then((response) => {
      store.dispatch(setIssue(response.data.data))
      return response
    })
    .catch((error) => error)
}

interface UpdateMessageArgs {
  kaseId: string
  messageId: string
  text: string
  issueId: string
}

export async function updateMessage({
  kaseId,
  messageId,
  issueId,
  text
}: UpdateMessageArgs) {
  return server
    .patch(
      `/api/v1/admin/kases/${kaseId}/issues/${issueId}/messages/${messageId}`,
      {
        data: {
          type: 'messages',
          attributes: {
            text
          }
        }
      }
    )
    .then((response) => {
      const updatedMessage = response.data.data as Message
      store.dispatch(updateMessageInStore(updatedMessage))
      return response
    })
    .catch((error) => error)
}

export async function saveMessage({
  kaseId,
  issueId,
  text
}: {
  kaseId: string
  issueId: string
  text: string
}) {
  return server
    .post(`/api/v1/admin/kases/${kaseId}/issues/${issueId}/messages`, {
      data: {
        type: 'messages',
        attributes: {
          text
        }
      }
    })
    .then((response) => {
      const newMessage = response.data.data
      store.dispatch(setMessage(newMessage))
      return newMessage
    })
    .catch((error) => error)
}

export async function resolveIssue(kaseId: string, issueId: string) {
  return server
    .put(`/api/v1/admin/kases/${kaseId}/issues/${issueId}/resolve`, {})
    .then((response) => {
      store.dispatch(setIssue(response.data.data))
      return response
    })
    .catch((error) => error)
}

export async function deleteIssue(kaseId: string, issueId: string) {
  return server
    .delete(`/api/v1/admin/kases/${kaseId}/issues/${issueId}`)
    .then(() => store.dispatch(deleteIssueFromStore(issueId)))
    .catch((error) => error)
}

export async function updateIssueDescription(
  kaseId: string,
  issueId: string,
  newDescription: string
) {
  return server
    .patch(`/api/v1/admin/kases/${kaseId}/issues/${issueId}`, {
      data: {
        type: 'string',
        attributes: {
          description: newDescription
        }
      }
    })
    .then((response) => {
      store.dispatch(setIssue(response.data.data))
      return response
    })
    .catch((error) => error)
}
