import React, { FunctionComponent, useEffect, useState } from 'react'
import cx from 'classnames'
import Label from 'components/forms/Label'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import Card from 'components/Card'
import { LawyerInteraction } from 'types/kaseOverview'
import {
  LawyerInteractionStatus,
  LawyerInteractionStatusMessage
} from 'constants/lawyerInteractions'
import { PopupButton, useCalendlyEventListener } from 'react-calendly'

interface Props {
  className?: string
  height?: number
}

const LawyerInteractionsSection: FunctionComponent<Props> = ({
  className,
  height
}) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const [lawyerConsultEvent, setLawyerConsultEvent] = useState<
    LawyerInteraction
  >()
  const [lawyerPrepEvent, setLawyerPrepEvent] = useState<LawyerInteraction>()
  const [schedulingInProgress, setSchedulingInProgress] = useState<boolean>(
    false
  )

  // This method is called multiple times by React, and state can change along the way.
  useEffect(() => {
    // For debugging
    // console.log(kaseOverview)

    // If the LawyerInteractions hash exists, use it to get the 2 call/interactions; else null them out.
    if (kaseOverview.lawyer_interactions) {
      setLawyerConsultEvent(
        kaseOverview.lawyer_interactions['lawyer_consult_event']
      )
      setLawyerPrepEvent(
        kaseOverview.lawyer_interactions['interview_prep_event']
      )
    } else {
      setLawyerConsultEvent(undefined)
      setLawyerPrepEvent(undefined)
    }
  }, [kaseOverview.lawyer_interactions])

  // Only show the component if there is a lawyer call that is worth showing
  const showThisComponent = () => {
    return showSectionCallConsult() || showSectionCallPrep()
  }

  // Only show the section if the data exists
  const showSectionCallConsult = () => {
    if (
      lawyerConsultEvent &&
      lawyerConsultEvent.status !== LawyerInteractionStatus.NA
    ) {
      return true
    } else {
      return false
    }
  }

  // Only show the section if the data exists
  const showSectionCallPrep = () => {
    if (
      lawyerPrepEvent &&
      lawyerPrepEvent.status !== LawyerInteractionStatus.NA
    ) {
      return true
    } else {
      return false
    }
  }

  // The status is an enum, but what we show is a human readable string
  const getStatusMessage = (
    lawyerInteraction: LawyerInteraction | undefined
  ) => {
    if (lawyerInteraction && lawyerInteraction?.status) {
      // Do a lookup in our ENUM table
      const lookupStatusMessage =
        LawyerInteractionStatusMessage[
          lawyerInteraction.status as LawyerInteractionStatus
        ]
      // In case we cant find the value in our ENUM table, resort to the actual ENUM string itself
      return lookupStatusMessage ? lookupStatusMessage : status
    } else {
      // In case the lawyerInteraction is undefined
      return ''
    }
  }

  // Only show the date when the call is SCHEDULED or COMPLETED
  const getDisplayDate = (lawyerInteraction: LawyerInteraction | undefined) => {
    if (
      lawyerInteraction &&
      lawyerInteraction?.status &&
      lawyerInteraction?.date_scheduled
    ) {
      // We have everything we need to possibly show a date, lets see if we should...
      if (
        lawyerInteraction.status === LawyerInteractionStatus.SCHEDULED ||
        lawyerInteraction.status === LawyerInteractionStatus.COMPLETED
      ) {
        const date_obj = new Date(lawyerInteraction.date_scheduled)
        return date_obj.toString()
      } else {
        // Looks like we should not show a date
        return ''
      }
    } else {
      // In case the lawyerInteraction is undefined, or there was no date, etc...
      return ''
    }
  }

  // The status is an enum, but what we show is a human readable string
  const getLawyerName = (lawyerInteraction: LawyerInteraction | undefined) => {
    if (lawyerInteraction && lawyerInteraction?.lawyer_name) {
      return lawyerInteraction.lawyer_name
    } else {
      // In case the lawyerInteraction is undefined
      return ''
    }
  }

  const getMeetingLocationJsx = (
    lawyerInteraction: LawyerInteraction | undefined
  ) => {
    if (lawyerInteraction && lawyerInteraction?.google_meet_url) {
      return (
        <span>
          <span>{lawyerInteraction.google_meet_url}</span>
          <span
            className="ml-4 text-blue-600 hover:underline"
            onClick={() => copyMeetingLocationToClipboard(lawyerInteraction)}
          >
            Copy
          </span>
        </span>
      )
    } else {
      // In case the lawyerInteraction is undefined
      return <span></span>
    }
  }

  // Copy the MeetingURL to the user's clipboard
  const copyMeetingLocationToClipboard = (
    lawyerInteraction: LawyerInteraction | undefined
  ) => {
    if (lawyerInteraction && lawyerInteraction?.google_meet_url) {
      navigator.clipboard.writeText(lawyerInteraction.google_meet_url)
    }
  }

  // Sometimes we want to Book, Cancel, or show nothing
  const getCalendlyPopupJsx = (
    lawyerInteraction: LawyerInteraction | undefined
  ) => {
    if (
      lawyerInteraction &&
      lawyerInteraction?.calendly_scheduling_url &&
      (lawyerInteraction?.status === LawyerInteractionStatus.NOT_SCHEDULED ||
        lawyerInteraction?.status === LawyerInteractionStatus.NO_SHOW ||
        lawyerInteraction?.status === LawyerInteractionStatus.CANCELLED)
    ) {
      return (
        <div className="App">
          <PopupButton
            className={'text-blue-600 hover:underline'}
            url={lawyerInteraction.calendly_scheduling_url as string}
            rootElement={document.getElementById('root') as HTMLElement}
            text="Book Now"
          />
        </div>
      )
    } else if (
      lawyerInteraction &&
      lawyerInteraction?.calendly_scheduling_url &&
      lawyerInteraction?.status === LawyerInteractionStatus.SCHEDULED
    ) {
      return (
        <div className="App">
          <PopupButton
            className={'text-blue-600 hover:underline'}
            url={lawyerInteraction.calendly_canceling_url as string}
            rootElement={document.getElementById('root') as HTMLElement}
            text="Cancel Now"
          />
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const getConsultCallJsx = () => {
    return (
      <div>
        <Label className="mt-6">Call - Lawyer Consult</Label>
        <Card className="p-4">
          <div className="m-1">
            <b>Status: {getStatusMessage(lawyerConsultEvent)}</b>
            <span className="float-right">
              {getCalendlyPopupJsx(lawyerConsultEvent)}
            </span>
          </div>
          <div className="m-1">Date: {getDisplayDate(lawyerConsultEvent)}</div>
          <div className="m-1">Lawyer: {getLawyerName(lawyerConsultEvent)}</div>
          <div className="m-1">
            Location: {getMeetingLocationJsx(lawyerConsultEvent)}
          </div>
        </Card>
      </div>
    )
  }

  const getPrepCallJsx = () => {
    return (
      <div>
        <Label className="mt-6">Call - Interview Prep</Label>
        <Card className="p-4">
          <div className="m-1">
            <b>Status: {getStatusMessage(lawyerPrepEvent)}</b>
            <span className="float-right">
              {getCalendlyPopupJsx(lawyerPrepEvent)}
            </span>
          </div>
          <div className="m-1">Date: {getDisplayDate(lawyerPrepEvent)}</div>
          <div className="m-1">Lawyer: {getLawyerName(lawyerPrepEvent)}</div>
          <div className="m-1">
            Location: {getMeetingLocationJsx(lawyerPrepEvent)}
          </div>
        </Card>
      </div>
    )
  }

  // When Calendly posts the meeting info, we will take note of that, and let the user know
  useCalendlyEventListener({
    onEventScheduled: () => {
      setSchedulingInProgress(true)
    }
  })

  const getSchedulingInProgressJsx = () => {
    return (
      <div className="mt-6">
        <Card className="p-4">
          <div className="m-1">
            <b>Status: Scheduling in Progress...</b>
          </div>
          <div className="m-1">
            Refresh the browser to see the latest details (scheduling usually
            takes a few seconds to complete).
          </div>
        </Card>
      </div>
    )
  }

  // Here is where the final JSX is built and rendered
  return (
    <div>
      {showThisComponent() && (
        <div
          id="lawyer-calls-section"
          style={{ maxHeight: height }}
          className={cx(className, 'px-6')}
        >
          <div>
            <h2 className="font-bold text-base">Lawyer Calls</h2>
            {schedulingInProgress && getSchedulingInProgressJsx()}
            {!schedulingInProgress &&
              showSectionCallConsult() &&
              getConsultCallJsx()}
            {!schedulingInProgress && showSectionCallPrep() && getPrepCallJsx()}
          </div>
        </div>
      )}
    </div>
  )
}

export default LawyerInteractionsSection
