export const formatPostRequestBody = (
  type: string,
  attributes: Record<string, any>,
  relationships?: Record<string, any>
) => {
  let requestBody: Record<string, any> = {
    data: { type, attributes: attributes }
  }

  if (relationships) {
    requestBody = {
      ...requestBody,
      data: {
        ...requestBody.data,
        relationships: relationships
      }
    }
  }

  return requestBody
}
