import React, {
  ChangeEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useState
} from 'react'
import { nanoid } from '@reduxjs/toolkit'
import cx from 'classnames'

export enum LabelPosition {
  LEFT = 'left',
  RIGHT = 'right'
}

type Props = InputHTMLAttributes<HTMLInputElement> & {
  id?: string
  children: string
  checked: boolean
  disabled: boolean
  labelPosition: LabelPosition
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Toggle: FunctionComponent<Props> = ({
  id,
  children,
  checked = false,
  disabled = false,
  labelPosition = LabelPosition.RIGHT,
  ...otherProps
}) => {
  // Create a unique ID in case the use doesn't provide one
  const [uniqueId] = useState(nanoid())
  const fieldId = id || uniqueId

  const classes = {
    background: cx('block bg-white w-10 h-6 rounded-full', {
      'bg-blue-600': checked,
      'bg-gray-300': !checked,
      'opacity-50': disabled
    }),
    dot: cx(
      'absolute left-1 top-1 bg-gray-100 w-4 h-4 rounded-full transform transition',
      {
        'translate-x-full': checked
      }
    ),
    label: cx('text-sm', {
      'ml-3': LabelPosition.LEFT,
      'mr-3': LabelPosition.RIGHT,
      'text-blue-600': checked,
      'opacity-50': disabled
    })
  }

  const labelComponent = <div className={classes.label}>{children}</div>

  return (
    <label htmlFor={fieldId} className="flex items-center cursor-pointer">
      {labelPosition === LabelPosition.LEFT && labelComponent}
      <div className="relative">
        <input
          type="checkbox"
          id={fieldId}
          className="sr-only"
          role="switch"
          disabled={disabled}
          {...otherProps}
        />
        <div className={classes.background} />
        <div className={classes.dot} />
      </div>
      {labelPosition === LabelPosition.RIGHT && labelComponent}
    </label>
  )
}

export default Toggle
