import React, {
  FunctionComponent,
  useState,
  ReactNode,
  ChangeEvent,
  InputHTMLAttributes
} from 'react'
import { nanoid } from '@reduxjs/toolkit'
import SelectInput from 'components/forms/SelectInput'
import Label from 'components/forms/Label'
import InlineError from 'components/forms/InlineError'
import cx from 'classnames'

export interface SelectOption {
  label: ReactNode
  value: string | number | undefined
  disabled?: boolean
  selected?: boolean
}

type Props = InputHTMLAttributes<HTMLSelectElement> & {
  /**
   * The ID of the input, used to map the label to the input element
   */
  id?: string
  /**
   * A required label for the field
   */
  label?: ReactNode
  /**
   * A boolean that determines if this will be inline (will also put label inline with select field)
   */
  inline?: boolean
  /**
   * A name added to the input element can be used for form parsing and
   * validation
   */

  includeNullValue?: boolean

  name?: string
  /**
   * If true, the input will not be editable, but the text will remain
   * selectable
   */
  readOnly?: boolean
  /**
   * A string that represents a custom validation error
   */
  error?: string
  /**
   * The type of the input element. Defaults to "text"
   */
  type?: string
  /**
   * If true, the input field will not be editable or selectable
   */
  disabled?: boolean
  /**
   * A required list of options to display in the dropdown
   */
  options: SelectOption[]
  /**
   * Placeholder text when no option is selected
   */
  placeholder?: string
  /**
   * Callback triggered when the text changes. Works the same way as a regular
   * input element.
   */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
}

const SelectField: FunctionComponent<Props> = ({
  id,
  label,
  inline = false,
  includeNullValue = false,
  error = null,
  options = [],
  placeholder = null,
  ...otherProps
}) => {
  // Create a unique ID in case the use doesn't provide one
  const [uniqueId] = useState(nanoid())

  const fieldId = id || uniqueId

  if (placeholder != null) {
    options.unshift({
      value: '',
      label: placeholder,
      disabled: true,
      selected: true
    })
  }

  if (includeNullValue !== false) {
    options.unshift({
      value: 'all',
      label: 'All'
    })
  }

  return (
    <div className={cx({ 'flex items-center': inline }, 'w-full')}>
      {label && (
        <Label
          className={cx({ 'flex-none mr-2': inline })}
          inline={inline}
          htmlFor={fieldId}
        >
          {label}
        </Label>
      )}
      {error && <InlineError>{error}</InlineError>}
      <SelectInput
        className={cx({ 'flex-1': inline })}
        {...otherProps}
        hasError={!!error}
        id={fieldId}
        inline={inline}
      >
        {options.map((option) => (
          <option
            value={option.value}
            key={option.value}
            disabled={option.disabled}
            selected={option.selected}
          >
            {option.label}
          </option>
        ))}
      </SelectInput>
    </div>
  )
}

export default SelectField
