import React, { FunctionComponent, useState, FormEvent, useEffect } from 'react'

import TextArea from 'components/forms/TextArea'
import Button from 'components/Button'
import { ThreadPanelState } from './ThreadPanel'

interface Props {
  panelIsOpen: boolean
  setThreadPanelState: (panelState: ThreadPanelState) => void
  submitReply: (reply: string) => void
  /**
   * If true, the panel will not accept user input.
   */
  disabled?: boolean
}

const ThreadPanelReply: FunctionComponent<Props> = ({
  panelIsOpen,
  setThreadPanelState,
  submitReply,
  disabled
}) => {
  const [reply, setReply] = useState('')

  useEffect(() => {
    if (!panelIsOpen) {
      setReply('')
    }
  }, [panelIsOpen])

  useEffect(() => {
    // Thread panel displays confirmation modal if user tries to close
    // while message is being added
    if (reply) {
      setThreadPanelState(ThreadPanelState.AddingMessage)
    } else {
      setThreadPanelState(ThreadPanelState.Idle)
    }
  }, [reply])

  const onMessageSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitReply(reply)
    setReply('')
  }

  return (
    <form
      onSubmit={onMessageSave}
      className="p-4 border-t border-gray-200 text-right"
    >
      <TextArea
        disabled={disabled}
        className="mb-2"
        name="reply"
        placeholder="Reply to this thread"
        value={reply}
        onChange={(event) => setReply(event.target.value)}
      />
      <Button type="submit" disabled={disabled || reply.length === 0}>
        Save reply
      </Button>
    </form>
  )
}

export default ThreadPanelReply
