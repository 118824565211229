import React from 'react'

/**
 * Takes a URL as a string and returns a link element as a React component.
 * @param text A valid URL
 */
export const formatLinkFromString = (text: string) => {
  // Check if the string a valid URL first
  let url
  try {
    url = new URL(text)
  } catch (e) {
    return text
  }

  if (url.protocol.startsWith('http')) {
    return (
      <a
        className="text-blue-700 leading-5 hover:underline"
        href={url.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    )
  }

  return text
}

/**
 * Takes a relative path and prefixes it with the appropriate origin, based on
 * the page's current URL.
 * @example formatLinkToOldAdmin('/document_review') // returns 'http://localhost:3000/document_review
 * @param path Path to the page
 */
export const formatLinkToOldAdmin = (path: string) => {
  const currentOrigin = window.location.origin

  switch (currentOrigin) {
    case 'http://localhost:8080':
      return 'http://localhost:3000' + path
    case 'https://admin-staging.boundless.com':
      return 'https://staging.boundless.com' + path
    case 'https://admin.boundless.com':
    default:
      return 'https://apply.boundless.com' + path
  }
}

/**
 * Generates a link element to MPDF creation tool for a kase.
 * @example getLinkToMpdf('123') // returns a link element with url to
 * 'http://localhost:3000/admin/kases/123/master_pdfs/new`
 * @param kaseId kase ID to navigate to
 */
export const getLinkToMpdf = (kaseId: string) => {
  const linkToMpdf = formatLinkToOldAdmin(
    `/admin/kases/${kaseId}/master_pdfs/new`
  )
  return (
    <a
      href={linkToMpdf}
      className="text-blue-700 hover:text-blue-800 ml-2"
      rel="noopener noreferrer"
    >
      Go to MPDF tool →
    </a>
  )
}
