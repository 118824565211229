export interface ErrorObject {
  /**
   * Quick description of the problem
   */
  title: string
  /**
   * A more elaborate description of this error
   */
  detail: string
  /**
   * An HTTP status code
   * @example "404"
   */
  status: string
}

/**
 * This method can be used to format errors from a .catch block:
 * .catch((error) => {
 *   const formattedErrors = formatApiErrors(error)
 * })
 */
export const formatApiErrors = (error: any) => {
  /**
   * Errors from our API have this shape:
   * {
   *   errors: [{
   *     title: "Not Authenticated",
   *     detail: "You are not authenticated",
   *     status: "401"
   *   }]
   * }
   *
   * The sign-in endpoint returns a 404 with the body below when the users isn't
   * connected to the VPN:
   * { "error": "not found" }
   */

  if (error.response?.data?.errors == null) {
    return [
      {
        title: error.message || error.title || error.detail || 'Error',
        detail: error.detail || 'Something went wrong for some reason',
        status: (
          error.code ||
          error.status ||
          error.response?.status ||
          '500'
        ).toString()
      }
    ] as ErrorObject[]
  }

  return error.response.data.errors as ErrorObject[]
}
