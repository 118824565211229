import React, { FunctionComponent, useEffect, useState } from 'react'
import Button from 'components/Button'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { formatAnswer, formatTitle } from '../FormatQuestionHelpers'
import {
  IAddressHistoriesIssues,
  IAnswersIssues,
  IDocumentIssues,
  IEmploymentHistoriesIssues
} from 'types/issues'
import { useParams } from 'react-router-dom'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'

interface Props {
  show: boolean
  setShow: (i: boolean) => void
  issueId: string
  handleSaveEditIssue: (
    kaseId: string,
    issueId: string,
    newDescription: string
  ) => void
}

const KaseEditIssuePanel: FunctionComponent<Props> = ({
  show,
  setShow,
  issueId,
  handleSaveEditIssue
}) => {
  const [editText, setEditText] = useState('')
  const [addressHistoriesIssues, setAddressHistoriesIssues] = useState<
    IAddressHistoriesIssues[] | undefined
  >(undefined)
  const [answersIssues, setAnswersIssues] = useState<
    IAnswersIssues[] | undefined
  >(undefined)
  const [documentIssues, setDocumentIssues] = useState<
    IDocumentIssues[] | undefined
  >(undefined)
  const [employmentHistoriesIssues, setEmploymentHistoriesIssues] = useState<
    IEmploymentHistoriesIssues[] | undefined
  >(undefined)
  const kaseIssues = useSelector((state: RootState) => state.kaseIssues)
  const { kaseId } = useParams<IKaseIndexRouteParams>()

  useEffect(() => {
    const { issues } = kaseIssues
    const issue = issues.byId[issueId]
    let addressHistoriesIssues,
      answersIssues,
      documentsIssues,
      employmentHistoriesIssues

    if (issue) {
      addressHistoriesIssues = issue.relationships.address_histories_issues.data.map(
        (address_history_issue) => {
          const id = address_history_issue.id
          return kaseIssues.address_histories_issues.byId[id]
        }
      )

      answersIssues = issue.relationships.answers_issues.data.map(
        (answer_issue) => {
          const id = answer_issue.id
          return kaseIssues.answers_issues.byId[id]
        }
      )

      documentsIssues = issue.relationships.documents_issues.data.map(
        (document_issue) => {
          const id = document_issue.id
          return kaseIssues.documents_issues.byId[id]
        }
      )

      employmentHistoriesIssues = issue.relationships.employment_histories_issues.data.map(
        (employment_history_issue) => {
          const id = employment_history_issue.id
          return kaseIssues.employment_histories_issues.byId[id]
        }
      )

      setAddressHistoriesIssues(addressHistoriesIssues)
      setAnswersIssues(answersIssues)
      setDocumentIssues(documentsIssues)
      setEmploymentHistoriesIssues(employmentHistoriesIssues)
      setEditText(issue.attributes.description)
    }
  }, [issueId])

  return (
    <div
      className={cx('bg-white', {
        hidden: !show
      })}
    >
      <div className="mx-4">
        <div className="flex justify-between mt-4 mb-6">
          <h1 className="font-bold">Edit Issue</h1>
          <Button
            variant="plain"
            onClick={() => {
              setShow(false)
            }}
          >
            Cancel
          </Button>
        </div>
        {addressHistoriesIssues?.map((addressHistoriesIssue, index) => {
          const { title } = addressHistoriesIssue.attributes
          return (
            <div key={index}>
              <h3 className="text-sm text-gray-700 mb-2">Address History</h3>
              <h4 className="mb-4">{formatAnswer(title)}</h4>
            </div>
          )
        })}
        {answersIssues?.map((answerIssue, index) => {
          const { field_name, value } = answerIssue.attributes
          return (
            <div key={index}>
              <h3 className="text-sm text-gray-700 mb-2">
                {formatTitle(field_name)}
              </h3>
              <h4 className="mb-4">{formatAnswer(value)}</h4>
            </div>
          )
        })}
        {documentIssues?.map((documentIssue, index) => {
          const { title } = documentIssue.attributes
          return (
            <div key={index}>
              <h4 className="mb-4">{formatAnswer(title)}</h4>
            </div>
          )
        })}
        {employmentHistoriesIssues?.map((employmentHistoriesIssue, index) => {
          const { title } = employmentHistoriesIssue.attributes
          return (
            <div key={index}>
              <h3 className="text-sm text-gray-700 mb-2">Employment History</h3>
              <h4 className="mb-4">{formatAnswer(title)}</h4>
            </div>
          )
        })}
        <h2 className="text-sm text-gray-700 mb-4">
          Issue Description and Resolution
        </h2>
        <textarea
          className="w-full h-56 mb-4 border border-solid border-gray-700"
          value={editText}
          onChange={(e) => setEditText(e.target.value)}
        />
        <div className="flex justify-center">
          <Button
            variant="primary"
            className="uppercase"
            onClick={() => {
              handleSaveEditIssue(kaseId, issueId, editText)
              setShow(false)
            }}
          >
            Save Issue
          </Button>
        </div>
      </div>
    </div>
  )
}

export default KaseEditIssuePanel
