import React, { FunctionComponent } from 'react'

import Button from 'components/Button'
import Card from 'components/Card'
import { PaymentOptions } from 'pages/payment/PrePayCart'

import LineItems from './LineItems'

import { formatCentsInDollars } from 'utils/format_money'
import { ServiceCheckoutDetails } from 'types/payment'

interface Props {
  onClickPaymentOption: (selection: PaymentOptions) => void
  selectedPaymentOption: PaymentOptions | null
  checkoutDetails: ServiceCheckoutDetails
}

const PaymentOptionSelect: FunctionComponent<Props> = ({
  onClickPaymentOption,
  selectedPaymentOption,
  checkoutDetails
}) => {
  const {
    side_by_side_line_items: {
      boundless_only: { pay_in_full, pay_over_time }
    },
    has_applied_discounts,
    split_it_max_months
  } = checkoutDetails
  return (
    <Card
      className="w-1/2 mr-3"
      disabled={selectedPaymentOption === PaymentOptions.Bundle}
      selected={selectedPaymentOption === PaymentOptions.BoundlessOnly}
    >
      <Card.Header>Boundless Service Only</Card.Header>
      <Card.Body>
        Pay just your Boundless service now, later you’ll pay your government
        application fees by check
      </Card.Body>
      <Card.Body>
        <h3 className="text-base font-bold mb-2">Pay in full</h3>
        <LineItems
          items={pay_in_full.line_items}
          discounts={pay_in_full.discounts}
          hasAppliedDiscounts={has_applied_discounts}
        />
        <div className="flex justify-between font-bold mt-2">
          <h3>Total</h3>
          <p className="text-right">
            {formatCentsInDollars(pay_in_full.total)}
          </p>
        </div>
      </Card.Body>
      <Card.Body>
        <h3 className="text-base font-bold mb-2">Pay monthly</h3>
        <LineItems
          items={pay_over_time.line_items}
          discounts={pay_over_time.discounts}
          hasAppliedDiscounts={has_applied_discounts}
        />
        <div className="flex justify-between font-bold mt-2">
          <h3>As little as</h3>
          <p className="text-right">
            {formatCentsInDollars(pay_over_time.monthly)}/month
            <br />
            <span className="text-sm font-normal">
              for {split_it_max_months} months, or{' '}
              {formatCentsInDollars(pay_over_time.total)} total
            </span>
          </p>
        </div>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button
          variant="primary"
          onClick={() => onClickPaymentOption(PaymentOptions.BoundlessOnly)}
          className="relative z-2"
        >
          Select Boundless Only
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default PaymentOptionSelect as FunctionComponent<Props>
