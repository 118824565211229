import React, { FunctionComponent, HTMLAttributes } from 'react'
import cx from 'classnames'
interface Props {
  innerRef?: any
}

const TableBody: FunctionComponent<
  HTMLAttributes<HTMLTableSectionElement> & Props
> = (props) => {
  const { children, className, innerRef, ...otherProps } = props
  return (
    <tbody
      ref={innerRef}
      {...otherProps}
      className={cx('bg-white', { [className as string]: !!className })}
    >
      {children}
    </tbody>
  )
}

export default TableBody
