import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperProps } from 'components/icons/IconWrapper'

const AnnotationIcon: FunctionComponent<IconWrapperProps> = (props) => (
  <IconWrapper {...props}>
    <path
      fillRule="evenodd"
      d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
      clipRule="evenodd"
    />
  </IconWrapper>
)

export default AnnotationIcon
