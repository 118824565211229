import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/root'

import KaseIssue from './KaseIssue'
import cx from 'classnames'
import { Issue } from 'types/issues'

interface Props {
  showCreateIssue: boolean
  showEditIssue: boolean
  handleEditButtonClick: (issueId: string) => void
  handleResolveButtonClick: (kaseId: string, issueId: string) => void
  showOpenIssues: boolean
  openThreadPanel: (issue: Issue) => void
}

const KaseIssuesList: FunctionComponent<Props> = ({
  showCreateIssue,
  showEditIssue,
  handleEditButtonClick,
  openThreadPanel,
  handleResolveButtonClick,
  showOpenIssues
}) => {
  const kaseIssues = useSelector((state: RootState) => state.kaseIssues)
  const issues = kaseIssues.issues
  // TODO: json-api-normalize re-orders the back end so we have to put it back together on our side
  // Should be a better way to do this, looking into the package
  const ids = issues.allIds.slice().reverse()

  return (
    <div className="relative">
      <div
        className={cx('absolute z-20 w-full h-full bg-gray-400 bg-opacity-25', {
          hidden: !showCreateIssue && !showEditIssue
        })}
      />

      {ids?.map((issueId, index) => {
        const issueStatus = issues.byId[issueId].attributes.status
        const isOpen = issueStatus === 'open'
        const isResolved = issueStatus === 'resolved'

        if (showOpenIssues && isOpen) {
          return (
            <KaseIssue
              handleEditButtonClick={handleEditButtonClick}
              handleResolveButtonClick={handleResolveButtonClick}
              issue={issues.byId[issueId]}
              key={index}
              openThreadPanel={openThreadPanel}
              showCreateIssue={showCreateIssue}
              showEditIssue={showEditIssue}
            />
          )
        }

        if (!showOpenIssues && isResolved) {
          return (
            <KaseIssue
              handleEditButtonClick={handleEditButtonClick}
              handleResolveButtonClick={handleResolveButtonClick}
              issue={issues.byId[issueId]}
              key={index}
              openThreadPanel={openThreadPanel}
              showCreateIssue={showCreateIssue}
              showEditIssue={showEditIssue}
            />
          )
        }

        return null
      })}
    </div>
  )
}

export default KaseIssuesList
