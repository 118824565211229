import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KaseReviewData, QuestionField } from 'types/kaseReviewData'

interface EditableAnswerPayload {
  reviewAnswersIndex: number
  reviewFieldsIndex: number
  editableAnswersIndex: number
  newAnswerText: string
}
const initialState: KaseReviewData = {
  answers: [],
  sections: [],
  details: {
    names: '',
    kase_product: '',
    kase_type: ''
  },
  id: '',
  review_type: ''
}

const kaseDataSlice = createSlice({
  name: 'kaseReviewData',
  initialState,
  reducers: {
    setKaseReviewData: (state, action: PayloadAction<KaseReviewData>) =>
      action.payload,
    // setEditableAnswer is currently unused. Was implemented thinking we'd use it but ended up not. Could
    // use it in the future though
    setEditableAnswer: (
      state,
      action: PayloadAction<EditableAnswerPayload>
    ) => {
      const {
        reviewAnswersIndex,
        reviewFieldsIndex,
        editableAnswersIndex,
        newAnswerText
      } = action.payload

      const editableAnswer = (state.answers[reviewAnswersIndex].fields[
        reviewFieldsIndex
      ] as QuestionField).editable_answers[editableAnswersIndex]

      editableAnswer.answer_text = newAnswerText
      editableAnswer.question.attributes.input_value = newAnswerText
    }
  }
})

export const { setKaseReviewData, setEditableAnswer } = kaseDataSlice.actions

export default kaseDataSlice.reducer
