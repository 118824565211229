import React, { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/root'
import { mostRecentMessageIsFromCustomer } from './utils'
import { IssueStatus } from 'constants/issues'
import { Issue } from 'types/issues'
import { getCurrentOpenTask } from 'store/selectors/kaseTasks'
import cx from 'classnames'
import CheckIcon from 'components/icons/CheckIcon'
import AnnotationIcon from 'components/icons/AnnotationIcon'
import FolderIcon from 'components/icons/FolderIcon'
import ClockIcon from 'components/icons/ClockIcon'

interface Props {
  issue?: Issue
}

const IssueHeader: FunctionComponent<Props> = ({ issue }) => {
  const customerId = useSelector(
    (state: RootState) => state.kaseData?.attributes?.user_id
  )
  const messages = useSelector((state: RootState) => state.kaseIssues.messages)
  const currentOpenTask = useSelector((state: RootState) =>
    getCurrentOpenTask(state)
  )

  const mostRecentReplyIsCustomer = issue
    ? mostRecentMessageIsFromCustomer({
        issue,
        messages: messages.byId,
        customerId
      })
    : null

  const getHeaderAttributes = () => {
    if (!issue) {
      return {
        title: 'Add a new issue',
        icon: <FolderIcon />,
        colorClasses: 'bg-gray-50 text-gray-500 border-gray-200'
      }
    } else if (issue?.attributes.status === IssueStatus.Resolved) {
      return {
        title: 'Resolved',
        icon: <CheckIcon />,
        colorClasses: 'bg-gray-50 text-gray-500 border-gray-200'
      }
    } else if (!!currentOpenTask?.attributes?.assignee_id) {
      return {
        title: 'Waiting on customer',
        icon: <ClockIcon />,
        colorClasses: 'bg-gray-50 text-gray-500 border-gray-200'
      }
    } else if (issue.relationships.messages.data.length === 0) {
      return {
        title: 'New issue',
        icon: <FolderIcon />,
        colorClasses: 'bg-gray-50 text-gray-500 border-gray-200'
      }
    } else if (mostRecentReplyIsCustomer) {
      return {
        title: 'New customer reply',
        icon: <AnnotationIcon />,
        colorClasses: 'bg-orange-50 text-orange-800 border-orange-200'
      }
    }
    return {
      title: 'Replied',
      icon: <CheckIcon />,
      colorClasses: 'bg-green-50 text-green-800 border-green-200'
    }
  }

  const { title, icon, colorClasses } = useMemo(getHeaderAttributes, [issue])

  const headerClasses = cx(
    'rounded-t-sm px-4 py-2 border text-sm font-medium',
    colorClasses
  )

  return (
    <header className={headerClasses}>
      <p className="flex items-center">
        {icon}
        {title}
      </p>
    </header>
  )
}

export default IssueHeader as FunctionComponent<Props>
