import React, { FunctionComponent } from 'react'
import Count, { CountVariants } from 'components/Count'

interface Props {
  isActive?: boolean
  children: string | number
}

const TabCountLabel: FunctionComponent<Props> = ({ children, isActive }) => (
  <Count variant={isActive ? CountVariants.PRIMARY : CountVariants.DEFAULT}>
    {children}
  </Count>
)

export default TabCountLabel
