import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuditLog } from 'types/auditLog'

const kaseAuditLogSlice = createSlice({
  name: 'kaseAuditLog',
  initialState: [] as AuditLog[],
  reducers: {
    setKaseAuditLog: (state, action: PayloadAction<AuditLog[]>) =>
      action.payload
  }
})

export const { setKaseAuditLog } = kaseAuditLogSlice.actions

export default kaseAuditLogSlice.reducer
