import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  createKase,
  updateKaseAccountEmail,
  updateEmailOptIn,
  sendKaseAnswer
} from 'api/kaseData'

import MainLayout from 'layouts/MainLayout'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import {
  Questionnaire,
  QuestionnairePage,
  PageElementQuestion
} from 'types/questionnaire'
import SinglePageQuestionnaire from './single-page-questionnaire'
import { KaseKind } from 'types/kase'
import QuestionElement from 'components/question-element'

/**
 * This page allows admins to create a new kase,
 * once kase type is selected it will show the eligibility questions.
 * once the questionnaire is complete they will receive a link to the kase overview page
 */
const NewApplication: FunctionComponent = () => {
  const [applicationType, setApplicationType] = useState('')
  const [kaseId, setKaseId] = useState('')
  const [questionnaireId, setQuestionnaireId] = useState('')
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null)
  const [questionPages, setQuestionPages] = useState<
    QuestionnairePage[] | null
  >(null)
  const [isError, setIsError] = useState(false)
  const [percentComplete, setPercentComplete] = useState(0)
  const [isEmailSaved, setIsEmailSaved] = useState(false)
  const [isIneligible, setIsIneligible] = useState(false)
  const [ineligibleText, setIneligibleText] = useState<string>()
  const { setGlobalError } = useGlobalError()
  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  useEffect(() => {
    let kaseKind: KaseKind | undefined
    switch (applicationType) {
      case 'k1':
        kaseKind = 'K1FianceVisa'
        break
      case 'mbgc':
        kaseKind = 'NewMarriageBasedGreenCard'
        break
      case 'b1b2':
        kaseKind = 'B1B2TravelVisa'
        break
      default:
        kaseKind = undefined
    }

    if (kaseKind) {
      createKase(kaseKind).then((response) => {
        const data = response.data
        if (data) {
          setKaseId(data.kase_id)
          setQuestionnaireId(data.id)
          setQuestionnaire(data)
        }
      })
    }
  }, [applicationType])

  useEffect(() => {
    const q = questionnaire?.attributes.questionnaire_sections[0].pages
    const findIneligible = q?.find(
      (el) => el?.attributes?.type === 'IneligiblePage'
    )
    if (findIneligible) {
      setIsIneligible(true)
      setIneligibleText(findIneligible.attributes.body)
      const questionPgs = q?.slice(0, q?.indexOf(findIneligible)) || null
      setQuestionPages(questionPgs)
    } else {
      isIneligible && setIsIneligible(false)
      setQuestionPages(q || null)
    }
  }, [questionnaire])

  const handleQuestionAnswer = async (
    value: string,
    key: string,
    answerId: string | null
  ) => {
    if (value) {
      await sendKaseAnswer(kaseId, key, value, answerId, questionnaireId)
        .then((response) => {
          const percent = response.data.data.attributes.percent_complete
          setPercentComplete(percent)
          setQuestionnaire(response.data.data)
          setEmailErrorMessage('')
        })
        .catch((error) => {
          setIsError(true)
          setGlobalError(error)
          setEmailErrorMessage(
            JSON.parse(error?.request?.response)?.errors[0]?.title
          )
        })
    }
  }

  const handleEmailAddressEntered = async (value: string) => {
    await updateKaseAccountEmail(kaseId, value)
      .then(() => {
        setIsEmailSaved(true)
      })
      .catch((error) => {
        setIsError(true)
        setGlobalError(error)
      })
  }

  const handleOptIn = async (value: string) => {
    const optIn = value === 'true' ? true : false
    await updateEmailOptIn(kaseId, optIn).catch((err) => {
      setIsError(true)
      setGlobalError(err)
    })
  }

  /**
   * MBGC has the account holder email built into the questionnaire but our other
   * products do not. So if the current kase kind isn't MBGC, then we need to make
   * sure we collect the account email before continuing to payment, regardless of
   * the percentComplete.
   * @returns {boolean}
   */
  const allowToContinue = () => {
    const kaseKindsWithBuiltInEmailEligibilityQuestion = ['mbgc']
    const emailNeeded =
      !kaseKindsWithBuiltInEmailEligibilityQuestion.includes(applicationType) &&
      !isEmailSaved

    if (emailNeeded || percentComplete < 100) {
      return false
    }

    return true
  }

  const showEmailCollection = applicationType === 'k1' || 'mbgc'
  const showEmailOptin = true // In case we need to make this conditional, e.g. set it to `isEmailSaved` state
  const emailCollectionQuestionData: PageElementQuestion = {
    id: 'account_email',
    attributes: {
      input_type: 'email',
      key: '',
      markdown:
        'Please provide the email address that will be associated with this application.',
      question_jit_elements: [],
      text: 'Email'
    }
  }
  const optInText = (() => {
    switch (applicationType) {
      case 'b1b2':
        return 'B1/B2 Travel Visa'
      case 'k1':
        return 'K-1 Fiance Visa'
      case 'mbgc':
        return 'marriage-based green card'
      default:
        ''
    }
  })()
  const optInToEmailsQuestion: PageElementQuestion = {
    id: 'email_opt_in',
    attributes: {
      input_type: 'checkbox',
      key: '',
      text: `Please send me helpful tips and important updates about the ${optInText} process`,
      question_jit_elements: [],
      markdown: ''
    }
  }

  return (
    <MainLayout>
      <div className="flex flex-col justify-center items-center">
        <h2 className="font-bold text-xl pl-6 mb-2 my-4">
          Create New Application
        </h2>
        <div>Please make sure to answer ALL questions!</div>
        <div>
          <label htmlFor="kase_type" className="font-bold">
            Application Type:
          </label>
          <select
            className="mx-2 px-2 border-solid border-gray-300 border-2"
            id="kase_type"
            onChange={(e) => setApplicationType(e.target.value)}
            defaultValue=""
          >
            <option value="" disabled>
              --Select--
            </option>
            <option value="mbgc">Marriage Based Green Card</option>
            <option value="k1">K1 (Fiancé) Visa</option>
            <option value="b1b2">B1/B2 Travel Visa</option>
          </select>
        </div>
        {kaseId && (
          <div className="flex flex-col justify-center items-center my-8 w-3/5">
            {isError && (
              <div className="text-red-500">
                Something went wrong, please refresh and try again.
              </div>
            )}
            <>
              {!isIneligible && <div>Percent Complete: {percentComplete}%</div>}
              {showEmailCollection && (
                <div className="bg-white rounded p-4 m-2 w-full">
                  <span className="font-bold">
                    Register Account Email Address
                  </span>
                  <p>
                    Please provide the email address that will be associated
                    with this application.
                  </p>
                  <div>
                    <QuestionElement
                      question={emailCollectionQuestionData}
                      handleQuestionAnswer={(value) =>
                        handleEmailAddressEntered(value)
                      }
                    />
                  </div>
                  {showEmailOptin && (
                    <div>
                      <QuestionElement
                        question={optInToEmailsQuestion}
                        handleQuestionAnswer={(value) => handleOptIn(value)}
                        defaultValue="true"
                      />
                    </div>
                  )}
                </div>
              )}
              {questionPages && (
                <SinglePageQuestionnaire
                  questionPages={questionPages}
                  handleQuestionAnswer={handleQuestionAnswer}
                  emailErrorMessage={emailErrorMessage}
                />
              )}
              {!isIneligible && <div>Percent Complete: {percentComplete}%</div>}
              {isIneligible && ineligibleText && (
                <div className="text-red-500 font-bold text-center">
                  <div dangerouslySetInnerHTML={{ __html: ineligibleText }} />
                </div>
              )}
              {allowToContinue() && (
                <div className="flex flex-row">
                  <div className="p-2">
                    <a
                      href={`/payment/${kaseId}`}
                      className="p-2 bg-white border border-gray-300 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow focus:border-blue-500 active:bg-gray-50 active:text-gray-900"
                    >
                      Continue to Payment
                    </a>
                  </div>
                  <div className="p-2">
                    <a
                      href={`/overview/${kaseId}`}
                      className="p-2 bg-white border border-gray-300 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow focus:border-blue-500 active:bg-gray-50 active:text-gray-900"
                    >
                      Overview (skip payment)
                    </a>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </MainLayout>
  )
}

export default NewApplication
