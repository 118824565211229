import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReviewType } from 'types/reviewType'

export interface ReviewTypesShape {
  byId: { [key: string]: ReviewType }
}

export const initialState: ReviewTypesShape = {
  byId: {}
}

const reviewTypes = createSlice({
  name: 'reviewType',
  initialState,
  reducers: {
    setReviewTypes: (
      state,
      action: PayloadAction<{ [key: string]: ReviewType }>
    ) => {
      state.byId = action.payload
    }
  }
})

export const { setReviewTypes } = reviewTypes.actions

export default reviewTypes.reducer
