import React, { FunctionComponent, useState } from 'react'
import cx from 'classnames'

import KaseQuestion from './KaseQuestion'
import { KaseReviewField } from 'types/kaseReviewData'

import _camelCase from 'lodash/camelCase'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import Toggle, { LabelPosition } from 'components/Toggle'

interface Props {
  handleReviewFieldCheck: (reviewField: KaseReviewField) => void
  isShowingAll: boolean
  isToggleDisabled: boolean
  kaseId: string
  onChangeViewToggle: (isShowingAll: boolean) => void
  selectedReviewFields: KaseReviewField[]
  updateKaseReviewData: () => void
  submitAnswer: (
    value: string | null,
    questionId: string,
    answerId: string | null
  ) => Promise<void>
}

const KaseQuestions: FunctionComponent<Props> = ({
  handleReviewFieldCheck,
  isShowingAll,
  isToggleDisabled,
  kaseId,
  onChangeViewToggle,
  selectedReviewFields,
  updateKaseReviewData,
  submitAnswer
}) => {
  const [
    currentEditAnswerQuestionId,
    setCurrentEditAnswerQuestionId
  ] = useState('')

  const kaseReviewData = useSelector((state: RootState) => state.kaseReviewData)

  const classes = cx({
    'opacity-50 pointer-events-none': isToggleDisabled
  })

  return (
    <ul>
      {kaseReviewData &&
        kaseReviewData.answers?.map((answer, i) => {
          const { section_title, fields } = answer
          return (
            <li key={i}>
              <div
                id={`${_camelCase(section_title)}${i}`}
                className="border-gray-400 border-1"
              >
                <div className="flex justify-between">
                  <h2 className="flex-shrink-0 w-auto pb-4 font-bold">
                    {section_title}
                  </h2>
                  <Toggle
                    checked={isShowingAll}
                    labelPosition={LabelPosition.LEFT}
                    disabled={isToggleDisabled}
                    onChange={() => onChangeViewToggle(!isShowingAll)}
                  >
                    {isShowingAll ? 'Hide extra fields' : 'Show all fields'}
                  </Toggle>
                </div>
                <ul className={classes}>
                  {fields.map((field, index) => {
                    const highlight = field.highlighted
                    const className = highlight ? 'highlight mb-2' : 'mb-2'

                    return (
                      <li className={className} key={`review-field-${index}`}>
                        <KaseQuestion
                          currentEditAnswerQuestionId={
                            currentEditAnswerQuestionId
                          }
                          handleReviewFieldCheck={handleReviewFieldCheck}
                          kaseId={kaseId}
                          reviewField={field}
                          selectedReviewFields={selectedReviewFields}
                          setCurrentEditAnswerQuestionId={
                            setCurrentEditAnswerQuestionId
                          }
                          submitAnswer={submitAnswer}
                          updateKaseReviewData={updateKaseReviewData}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </li>
          )
        })}
    </ul>
  )
}

export default KaseQuestions
