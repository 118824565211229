import React, { FunctionComponent } from 'react'

const Sidebar: FunctionComponent = () => {
  return (
    <aside className="h-full overflow-hidden bg-white flex flex-shrink-0 shadow-md">
      <div className="flex flex-col w-64">
        <div className="p-4 flex flex-grow flex-col">
          <p className="text-sm mb-2">
            Use this sidebar when you need to display contextual information on
            a page.
          </p>
          <p className="text-sm mb-2">
            We envision this being particularly important in the context of
            document review.
          </p>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
