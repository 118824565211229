import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import MainLayout from 'layouts/MainLayout'

import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import LoadingSpinner from 'components/LoadingSpinner'
import SubNavigation from 'components/SubNavigation'
import {
  fetchKaseStatesAndEvents,
  manuallyTransitionState,
  sendStateEvent
} from 'api/stateEvent'
import { updateKaseOverview } from 'api/overview'
import Button from 'components/Button'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { KaseStateEvent } from 'types/kaseStateEvent'
import { getRouteForKaseOverview } from 'constants/routes'
import { KaseStateValues } from 'constants/kaseStates'
import TextInput from 'components/forms/TextInput'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

const KaseStatesAndEvents: FunctionComponent = () => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const allTransitions = useSelector(
    (state: RootState) => state.kaseStateEvents
  )
  const [isLoading, setIsLoading] = useState(false)
  const [proposedTransition, setProposedTransition] = useState<KaseStateEvent>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [modifiedTrackingNumber, setModifiedTrackingNumber] = useState('')
  const history = useHistory()
  const { setGlobalError } = useGlobalError()

  const handleConfirmClick = (transition: KaseStateEvent) => {
    setProposedTransition(transition)
    setShowConfirmation(true)
  }

  const startStateTransition = () => {
    if (proposedTransition) {
      if (proposedTransition.relevant) {
        // We don't need to manually transition because the event we want is normally allowed
        sendStateEvent(kaseId, proposedTransition.event)
          .then(() => {
            setShowConfirmation(false)
            history.push(getRouteForKaseOverview(kaseId))
          })
          .catch((error) => {
            setGlobalError(error)
            setShowConfirmation(false)
          })
      } else {
        manuallyTransitionState(
          kaseId,
          proposedTransition.from,
          proposedTransition.event
        ).then(() => {
          setShowConfirmation(false)
          history.push(getRouteForKaseOverview(kaseId))
        })
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetchKaseStatesAndEvents(kaseId).then(() => setIsLoading(false))
  }, [])

  // Find any state transitions that a customer could make based on their
  // current state: these are the relevant state transitions
  const allRelevantStateTransitions = allTransitions.filter((transition) => {
    return transition.relevant
  })

  const existingTrackingNumber = kaseOverview?.information.find(
    (item) => item.key === 'Package Tracking Number'
  )?.value

  const needsTrackingNumber =
    proposedTransition?.to === 'customer_package_shipped' &&
    !existingTrackingNumber

  const saveKaseOverviewValue = (key: any, value: any) => {
    updateKaseOverview(kaseId, key, value)
  }

  const trackingNumberContent = () => {
    return (
      <>
        <p className="text-sm text-gray-600 leading-5 my-3">
          Please enter a <strong>tracking number</strong> to move the customer
          into this state.
        </p>
        <TextInput
          className="flex-2"
          onChange={(event) => setModifiedTrackingNumber(event.target.value)}
          placeholder="Add tracking number here"
        />
      </>
    )
  }

  const saveWithTrackingNumber = () => {
    saveKaseOverviewValue('package_tracking_number', modifiedTrackingNumber)
    handleConfirmClick(allRelevantStateTransitions[0])
    startStateTransition()
  }

  return (
    <>
      {/* Show when we need to provide a tracking number before transitioning */}
      <ConfirmationModal
        isOpen={showConfirmation && needsTrackingNumber}
        title="Transition Customer"
        dangerousOperation
        confirmDisabled={!modifiedTrackingNumber}
        description={`Are you sure you want to transition the customer’s state to ${proposedTransition?.to} from ${proposedTransition?.from}?`}
        confirmLabel="Yes, transition the customer"
        onConfirm={saveWithTrackingNumber}
        onRequestClose={() => setShowConfirmation(false)}
      >
        {trackingNumberContent()}
      </ConfirmationModal>

      {/* Do not show when we need to provide a tracking number before transitioning */}
      <ConfirmationModal
        isOpen={showConfirmation && !needsTrackingNumber}
        title="Transition Customer"
        dangerousOperation
        description={`Are you sure you want to transition the customer’s state to ${proposedTransition?.to} from ${proposedTransition?.from}?`}
        confirmLabel="Yes, transition the customer"
        onConfirm={startStateTransition}
        onRequestClose={() => setShowConfirmation(false)}
      />

      {isLoading ? (
        <div className="mt-48">
          <LoadingSpinner />
        </div>
      ) : (
        <MainLayout
          subNavigation={<SubNavigation context="states-and-events" />}
        >
          <div className="text-sm">
            <div className="max-w-5xl w-full mx-auto p-4">
              <h2 className="text-3xl font-bold mb-2">
                Manual State Transitions
              </h2>
              <div className="pt-4 pb-8">
                <h3 className="text-base mb-4">
                  Relevant Next States for Current Case:
                </h3>
                {allRelevantStateTransitions.map(
                  (transition) =>
                    // @todo: backend should return list of allowed events
                    // https://boundless-immigration.atlassian.net/browse/LTV-1181
                    transition.event !== 'customer_payment_success' && (
                      <Button
                        variant="primary"
                        className="mr-4"
                        key={`${transition.to}-button`}
                        onClick={() => handleConfirmClick(transition)}
                      >
                        Transition to:{' '}
                        {KaseStateValues[
                          transition.to as keyof typeof KaseStateValues
                        ] || transition.to}
                      </Button>
                    )
                )}
              </div>
              {allTransitions.map((transition, index) => {
                return (
                  <div
                    className="p-4 bg-white mb-6 border border-gray-200 sm:rounded-lg"
                    key={index}
                  >
                    {/* <div className="text-base mb-4">{transition.event}</div> */}
                    <div className="grid grid-cols-4">
                      <div className="col-span-2">
                        <div className="text-gray-400 font-bold text-xs">
                          EVENT
                        </div>
                        <div className="text-base font-bold">
                          {transition.event}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="text-gray-400 font-bold text-xs">
                          TO STATE
                        </div>
                        <div className="text-base font-bold">
                          {transition.to}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="text-gray-400 font-bold text-xs">
                          FROM STATE
                        </div>
                        <div className="text-base font-bold">
                          {transition.from}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 col-span-2">
                      {transition.description}
                    </div>
                    <div className="col-span-1 mt-4">
                      <Button
                        variant="danger"
                        onClick={() => handleConfirmClick(transition)}
                      >
                        Transition to {transition.to}
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </MainLayout>
      )}
    </>
  )
}

export default KaseStatesAndEvents
