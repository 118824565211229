const ROUTES = {
  ADMIN_USERS_ROOT: '/admin-users',
  DOCUMENT_DETAIL_VIEW: '/document-review/:kaseId/document/:docId',
  DOCUMENT_REVIEW_ROOT: '/document-review',
  DOCUMENT_REVIEW_TOOL: '/document-review/:kaseId',
  HOME: '/',
  ISSUES_ROOT: '/issues',
  ISSUES_TOOL: '/issues/:kaseId',
  KASE_ELIGIBILITY: '/eligibility/:kaseId',
  KASE_REVIEW_ROOT: '/kase-review',
  KASE_REVIEW_INFO: '/kase-review/:kaseId',
  KASE_AUDIT_ROOT: '/kase-audit-logs',
  KASE_AUDIT_INFO: '/kase-audit-logs/:kaseId',
  KASE_OVERVIEW_ROOT: '/overview',
  KASE_OVERVIEW: '/overview/:kaseId',
  KASE_ROOT: '/kases',
  KASE_STATES_AND_EVENTS_ROOT: '/transitions',
  KASE_STATES_AND_EVENTS: '/transitions/:kaseId',
  KITCHEN_SINK: '/kitchen-sink',
  MPDF_DOCUMENT_DETAIL: '/mpdf/:kaseId/document/:docId',
  MPDF_TOOL: '/mpdf/:kaseId',
  NEW_APPLICATION: '/new-application',
  PAYMENT_ROOT: '/payment',
  PAYMENT: '/payment/:kaseId',
  ROLE_EDIT: '/roles/:roleId',
  ROLE_NEW: '/role/new',
  ROLES_ROOT: '/roles',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  TASKS_ROOT: '/tasks',
  USERS_ROOT: '/users',
  USER_DETAIL: '/users/:userId'
}

// todo: make these a bit more dynamic (?)

/**
 * Returns the Document Review Tool route for the kaseId provided
 * @param kaseId
 */
export function getRouteForDocumentReviewTool(kaseId: number | string) {
  return `${ROUTES.DOCUMENT_REVIEW_ROOT}/${kaseId}`
}

/**
 * Returns the Form Review Tool route for the kaseId provided
 * @param kaseId
 */
export function getRouteForFormReviewTool(kaseId: number | string) {
  return `${ROUTES.KASE_REVIEW_ROOT}/${kaseId}`
}

/**
 * Returns the Issues Tool route for the kaseId provided
 * @param kaseId
 */
export function getRouteForIssuesTool(kaseId: number | string) {
  return `${ROUTES.ISSUES_ROOT}/${kaseId}`
}

/**
 * Returns the Kase Overview route for the kaseId provided
 * @param kaseId
 */
export function getRouteForKaseOverview(kaseId: number | string) {
  return `${ROUTES.KASE_OVERVIEW_ROOT}/${kaseId}`
}

/**
 * Returns the Kase Overview route for the kaseId provided
 * @param kaseId
 */
export function getRouteForStatesAndEvents(kaseId: number | string) {
  return `${ROUTES.KASE_STATES_AND_EVENTS_ROOT}/${kaseId}`
}

export function getRouteForPayment(kaseId: number | string) {
  return `${ROUTES.PAYMENT_ROOT}/${kaseId}`
}

export function getRouteForKaseAuditLogs(kaseId: number | string) {
  return `${ROUTES.KASE_AUDIT_ROOT}/${kaseId}`
}

export default ROUTES
