import React, { FunctionComponent, useEffect, useState } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { checkCurrentUserAuth } from 'api/authentication'
import { OFF_VPN_ERROR, SESSION_EXPIRED_ERROR } from 'constants/errors'
import ROUTES from 'constants/routes'
import { getAuthJWT } from 'api/jwt'

enum AuthState {
  Authenticated,
  Unauthenticated,
  CheckingAuth
}

const PrivateRoute: FunctionComponent<RouteProps> = ({
  children,
  ...otherProps
}: RouteProps) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.CheckingAuth)
  const [errorCode, setErrorCode] = useState<string>()

  // Check that the user is authenticated
  useEffect(() => {
    checkCurrentUserAuth()
      .then(() => {
        setAuthState(AuthState.Authenticated)
      })
      .catch((error) => {
        setAuthState(AuthState.Unauthenticated)

        const statusCode: string | number =
          error.status || error.response.status

        setErrorCode(statusCode.toString())
      })
  }, [])

  if (authState === AuthState.CheckingAuth) {
    return <div>Verifying your credentials, please wait...</div>
  }

  if (authState === AuthState.Authenticated) {
    return <Route {...otherProps}>{children}</Route>
  }

  let errorMessage = SESSION_EXPIRED_ERROR

  // If the user has no JWT, they probably never signed in. No need to display
  // an error message in this case
  if (getAuthJWT() == null) {
    errorMessage = ''
  }

  if (errorCode === '404') {
    errorMessage = OFF_VPN_ERROR
  }

  return (
    <Redirect
      to={{
        pathname: ROUTES.SIGN_IN,
        state: {
          error: errorMessage,
          targetPath: window.location.pathname
        }
      }}
    />
  )
}

export default PrivateRoute
