import React, { FunctionComponent, ButtonHTMLAttributes, Ref } from 'react'
import cx from 'classnames'

import ButtonIcon from 'components/ButtonIcon'

/**
 * By default, it should look like a neutral button
 *
 * If it needs added weight, use the primary variant
 * If it's in a table row and needs to be shorter, use the slim variant
 *
 * weight: primary (full bg), secondary (default) (border), tertiary (text)
 * slim?: boolean
 *
 * Inspiration:
 * - https://polaris.shopify.com/components/actions/button
 * - https://primer.style/css/components/buttons
 *
 */

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  // We could do individual booleans for a more concise API, but then we have to handle collisions
  // primary?: boolean
  // plain?: boolean
  // destructive?: boolean
  /**
   * Use this in a table or list to avoid increasing the height of the container.
   */
  slim?: boolean
  /**
   * Choose the right button for your context
   * @param default This is the default button. Use another style if the button requires a different visual weight.
   * @param primary Used to highlight the most important actions. Use sparingly! Avoid showing multiple primary buttons in the same section.
   * @param plain For less important or less commonly-used actions.
   * @param danger Use when the action will delete data or be difficult to recover from. Destructive buttons should trigger a confirmation prompt before the action is completed. Use sparingly! These buttons are stressful.
   */
  variant?: 'default' | 'plain' | 'primary' | 'danger' | 'neutral' | 'outline'
  /**
   * An optional way to pass a ref down to the <button> element
   */
  forwardRef?: Ref<HTMLButtonElement>
  /**
   * Sets if white space should wrap or not. Default is false
   */
  whiteSpaceWrap?: boolean
}

const Button: FunctionComponent<ButtonProps> & { Icon: typeof ButtonIcon } = ({
  variant = 'default',
  slim = false,
  type = 'button',
  forwardRef,
  whiteSpaceWrap = false,
  ...otherProps
}) => {
  const { disabled, className, children } = otherProps

  const classes = cx(
    'inline-flex items-center text-sm leading-5 font-medium rounded-sm focus:outline-none focus:shadow-outline transition ease-in-out duration-150',
    className,
    {
      // Default
      'bg-white border border-gray-300 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow focus:border-blue-500 active:bg-gray-50 active:text-gray-900':
        variant === 'default' && !disabled,
      'bg-gray-50 border border-gray-300 text-gray-400 focus:border-blue-500':
        variant === 'default' && disabled,
      // Plain variant
      'text-blue-600 hover:text-blue-500 active:text-blue-700':
        variant === 'plain' && !disabled,
      'text-blue-400': variant === 'plain' && disabled,
      // Neutral variant
      'bg-white text-gray-600 hover:text-gray-700 active:text-gray-900':
        variant === 'neutral' && !disabled,
      'text-gray-400': variant === 'neutral' && disabled,
      // Primary variant
      'bg-blue-600 hover:bg-blue-500 border border-transparent text-white active:bg-blue-700':
        variant === 'primary' && !disabled,
      'bg-blue-400 border border-transparent text-blue-50':
        variant === 'primary' && disabled,
      // Danger variant
      'text-red-600 border border-gray-300 hover:text-white hover:border-transparent hover:bg-red-700 hover:shadow-sm focus:border-blue-500 active:bg-red-800':
        variant === 'danger' && !disabled,
      'text-red-400 border border-gray-300 focus:border-blue-500':
        variant === 'danger' && disabled,
      // Outline variant
      'bg-transparent hover:bg-blue-600 text-blue-600 font-semibold hover:text-white py-2 px-4 border border-blue-600 hover:border-transparent':
        variant === 'outline' && !disabled,
      'bg-transparent text-blue-400 font-semibold py-2 px-4 border border-blue-400':
        variant === 'outline' && disabled,
      // Sizing
      'px-4 py-2': !slim && variant !== 'plain',
      'px-3 py-1': slim && variant !== 'plain',
      // Disabled stuff
      'cursor-not-allowed': disabled,
      // White space wrap
      'whitespace-no-wrap': !whiteSpaceWrap
    }
  )

  if (disabled) {
    otherProps['aria-disabled'] = 'true'
  }

  return (
    <button {...otherProps} ref={forwardRef} type={type} className={classes}>
      {children}
    </button>
  )
}

Button.Icon = ButtonIcon

export default Button
