import store from 'store/root'
import server from 'api/server'

import { setKaseData } from 'store/reducers/kaseData'
import { setKaseReviewData } from 'store/reducers/kaseReviewData'
import { setReviewTypes } from 'store/reducers/reviewType'
import { setKaseAuditLog } from 'store/reducers/kaseAuditLog'
import { KaseKind } from 'types/kase'

export async function fetchKaseData(kaseId: string, summary?: boolean) {
  return server
    .get(`/api/v1/kases/${kaseId}/data?filter[summary]=${summary}`)
    .then((response) => {
      store.dispatch(setKaseData(response.data.data))
      return response
    })
    .catch((error) => error)
}

export async function fetchKaseReviewData(
  kaseId: string,
  reviewType: string,
  sectionId: number,
  showAll = false
) {
  return server
    .get(
      `/api/v1/admin/kases/${kaseId}/review/${reviewType}?show_all=${showAll}&section=${sectionId}`
    )
    .then((response) => {
      store.dispatch(setKaseReviewData(response.data.data))
      return response
    })
    .catch((error) => error)
}

export async function fetchReviewTypes(kaseId: string) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/review_types`)
    .then((response) => {
      store.dispatch(setReviewTypes(response.data))
      return response
    })
    .catch((error) => error)
}

export async function fetchKaseAuditLogs(kaseId: string) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/audit_logs`)
    .then((response) => {
      store.dispatch(setKaseAuditLog(response.data.data))
      return response
    })
    .catch((error) => error)
}

export async function createKase(kaseKind: KaseKind) {
  return server
    .post(`/api/v1/admin/kases`, { kind: kaseKind })
    .then((response) => {
      return response.data
    })
    .catch((error) => error)
}

export async function sendKaseAnswer(
  kaseId: string,
  questionId: string,
  value: string,
  answerId: string | number | null,
  questionnaireId?: string
) {
  if (answerId) {
    return server
      .patch(`/api/v1/admin/answers/${answerId}`, {
        kase_id: kaseId,
        questionnaire_id: questionnaireId,
        text: value
      })
      .then((response) => {
        return response
      })
  } else {
    return server
      .post(`/api/v1/admin/answers`, {
        questionnaire_id: questionnaireId,
        kase_id: kaseId,
        question_id: questionId,
        text: value
      })
      .then((response) => {
        return response
      })
  }
}

export async function clearKaseCache(kaseId: string) {
  return server.get(`/api/v1/admin/kases/${kaseId}/clear_kase_cache`)
}

export async function updateKaseAccountEmail(kaseId: string, email: string) {
  return server
    .patch(`/api/v1/admin/kases/${kaseId}/update_account_holder_email`, {
      user: { email }
    })
    .then((response) => {
      const kaseData = {
        ...response.data.data.attributes
      }

      store.dispatch(setKaseData(kaseData))

      return response
    })
}

export async function updateEmailOptIn(kaseId: string, optIn: boolean) {
  return server
    .patch(`/api/v1/admin/kases/${kaseId}/update_account_holder_opt_in`, {
      emailOptIn: optIn
    })
    .then((response) => {
      const kaseData = {
        ...response.data.data.attributes
      }

      store.dispatch(setKaseData(kaseData))

      return response
    })
}

export async function confirmKaseWaiverEligibility(
  kaseId: string,
  email: string
) {
  return server
    .post(`/api/v1/admin/kases/${kaseId}/waivers`, { email })
    .then((response) => response)
}
