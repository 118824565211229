import React, { FunctionComponent, MouseEvent } from 'react'

interface Props {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

const CloseButton: FunctionComponent<Props> = ({ onClick }) => {
  return (
    <button
      aria-label="Close modal"
      className="text-gray-400 hover:text-gray-500 rounded-sm focus:outline-none focus:shadow-outline focus:text-gray-500 transition ease-in-out duration-150"
      type="button"
      onClick={onClick}
    >
      <svg
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  )
}

export default CloseButton
