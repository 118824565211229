import server from 'api/server'
import { setTasks, updateTask } from 'store/reducers/kaseTasks'
import store from 'store/root'
import normalize from 'json-api-normalizer'

import { TaskType, TaskResolvedFilter } from 'constants/tasks'

const resolvedTaskFilter = {
  [TaskResolvedFilter.OnlyResolved]: true,
  [TaskResolvedFilter.OnlyUnresolved]: false
}

export async function fetchTasks({
  kaseId,
  taskKind,
  resolvedFilter
}: {
  kaseId: string
  taskKind?: TaskType
  resolvedFilter?: TaskResolvedFilter
}) {
  let tasksRequest = `/api/v1/kases/${kaseId}/tasks/`
  const taskFilters = []

  if (resolvedFilter) {
    const filter = resolvedTaskFilter[resolvedFilter]

    taskFilters.push(`filter[resolved]=${filter}`)
  }

  if (taskKind) {
    taskFilters.push(`filter[kind]=${taskKind}`)
  }

  if (taskFilters.length > 0) {
    const requestString = taskFilters.join('&')

    tasksRequest += `?${requestString}`
  }

  return server.get(tasksRequest).then(({ data }) => {
    const normalizedData = normalize(data, { camelizeKeys: false }).tasks || {}
    store.dispatch(setTasks(normalizedData))
  })
}

export function tasksUrl(
  isAssigned?: boolean,
  isAssignedToMe?: boolean,
  taskType?: string,
  limit?: number,
  offset?: number
) {
  const taskFilters = []
  let url = '/api/v1/admin/tasks'

  // We need to check if this is a boolean because passing in false is a valid
  // argument. Just checking if it is truthy is not enough
  if (typeof isAssigned === 'boolean') {
    taskFilters.push(`filter[assigned]=${isAssigned}`)
  }

  if (isAssignedToMe) {
    taskFilters.push(`assigned_to_me=${isAssignedToMe}`)
  }

  if (taskType) {
    taskFilters.push(`filter[action]=${taskType}`)
  }

  if (limit) {
    taskFilters.push(`limit=${limit}`)
  }

  if (offset) {
    taskFilters.push(`offset=${offset}`)
  }

  if (taskFilters.length > 0) {
    const requestString = taskFilters.join('&')
    url += `?${requestString}`
  }
  return url
}

export async function fetchAllTasks({
  isAssigned,
  isAssignedToMe,
  taskType,
  limit,
  offset = 0
}: {
  isAssigned?: boolean
  isAssignedToMe?: boolean
  taskType?: string
  limit?: number
  offset?: number
}) {
  const url = tasksUrl(isAssigned, isAssignedToMe, taskType, limit, offset)

  return server.get(url).then(({ data }) => {
    const normalizedData = normalize(data, { camelizeKeys: false }).tasks || {}
    store.dispatch(setTasks(normalizedData))
    return {
      totalCount: data.total_count,
      myTasksCount: data.assigned_to_me_count
    }
  })
}

export function fetchTaskStateList() {
  return server.get(`/api/v1/admin/tasks/list_states`).then((response) => {
    return response.data
  })
}

export async function resolveTask({
  kaseId,
  taskId,
  resolutionSummary
}: {
  kaseId: string
  taskId: string
  resolutionSummary: string
}) {
  return server
    .patch(`/api/v1/kases/${kaseId}/tasks/${taskId}/resolve`, {
      data: {
        type: 'tasks',
        attributes: {
          metadata: {
            resolution_summary: resolutionSummary
          }
        }
      }
    })
    .then((response) => {
      store.dispatch(updateTask(response.data.data))
    })
}

export async function assignTask({
  kaseId,
  taskId,
  assigneeId
}: {
  kaseId: string
  taskId: string
  assigneeId?: string
}) {
  return server
    .patch(`/api/v1/kases/${kaseId}/tasks/${taskId}/assign`, {
      data: {
        type: 'tasks',
        attributes: {
          assignee_id: assigneeId
        }
      }
    })
    .then((response) => {
      store.dispatch(updateTask(response.data.data))
    })
}

export async function transitionTask({
  state,
  taskId,
  assigneeId,
  kaseStateEvent
}: {
  state: string
  taskId: string
  assigneeId?: string | null
  kaseStateEvent?: string
}) {
  return server
    .patch(`/api/v1/admin/tasks/${taskId}/transition_state`, {
      assignee_id: assigneeId,
      transition: state,
      ...(kaseStateEvent !== undefined && { kase_state_event: kaseStateEvent })
    })
    .then((response) => {
      store.dispatch(updateTask(response.data.data))
    })
}

/**
 * Sets the filtered tasks in local state and returns total task count and
 * count of assigned tasks to current user
 *
 * @remarks
 * Returns filtered tasks without updating the redux store of tasks
 * Basically does the same thing as 'fetchAllTasks' but use this if you don't want
 * the redux store of tasks to be updated
 *
 * @param isAssigned - Filters tasks by whether or not they are assigned
 * @param isAssignedToMe - Filters tasks by if it's assigned to the current user
 * @param limit - The total number of tasks to return
 * @param offset - The number of tasks to offset the initial count by
 *  (e.g. 'offset' of 50 will give you tasks after the first 50)
 * @param taskKind - Filters by the type of task (property is named 'action' in Task attributes)
 *
 * @returns An object with 'totalCount' and 'myTasksCount'
 *
 */
export const getFilteredTasks = async ({
  isAssigned,
  isAssignedToMe,
  taskType,
  limit,
  offset = 0
}: {
  isAssigned?: boolean
  isAssignedToMe?: boolean
  taskType?: string
  limit?: number
  offset?: number
}) => {
  const url = tasksUrl(isAssigned, isAssignedToMe, taskType, limit, offset)

  return server.get(url).then(({ data }) => {
    return data
  })
}
