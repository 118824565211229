import React, { FunctionComponent, FormEvent, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { signIn } from 'api/authentication'
import Button from 'components/Button'
import Label from 'components/forms/Label'
import TextInput from 'components/forms/TextInput'
import Alert from 'components/Alert'
import { useDispatch } from 'react-redux'
import { setCurrentUserData } from 'store/reducers/currentUser'
import { formatApiErrors } from 'utils/format_errors'

enum SignInState {
  Idle,
  SigningIn,
  SignedIn
}

const SignInScreen: FunctionComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation<{ error?: string; targetPath?: string }>()
  const [error, setError] = useState<string>(location.state?.error || '')
  const [signInState, setSignInState] = useState<SignInState>(SignInState.Idle)

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Parse the email and password
    const formData = new FormData(event.currentTarget)
    const email = formData.get('email') as string
    const password = formData.get('password') as string
    const isBoundlessStaff =
      email.endsWith('@boundless.com') || email.endsWith('@boundlesslegal.com')

    if (!isBoundlessStaff) {
      setError('Please enter a valid email')
      return
    }

    // Clear any errors
    setError('')

    // Sign the user in
    setSignInState(SignInState.SigningIn)
    signIn({
      email,
      password
    })
      .then((currentUser) => {
        currentUser.id = currentUser.id.toString()

        // Update the Redux store
        dispatch(setCurrentUserData(currentUser))

        // Update this page
        setSignInState(SignInState.SignedIn)
      })
      .catch((error) => {
        setSignInState(SignInState.Idle)

        const errors = formatApiErrors(error)

        // The /sign-in endpoint returns a different error format than the
        // regular API, so if we detect a 404, we can assume it's a VPN issue.
        if (errors.some((error) => error.status === '404')) {
          setError('Please check your VPN connection and try again')
        } else {
          setError(
            errors.map((error) => error.detail).join(', ') ||
              'Unable to sign in. Please check your credentials.'
          )
        }
      })
  }

  // If we're signed in, redirect to the home page
  if (signInState === SignInState.SignedIn) {
    return <Redirect to={location.state?.targetPath || '/'} />
  }

  return (
    <div className="min-h-screen bg-gray-100 sm:flex sm:flex-col sm:justify-center">
      <div className="h-screen sm:h-auto bg-white sm:rounded p-6 sm:mx-auto sm:w-full sm:max-w-md shadow border-t-4 border-indigo-500">
        <h1 className="mt-2 mb-8 text-center text-2xl leading-9 font-bold text-gray-900">
          Sign in to your Admin account
        </h1>
        {error && (
          <Alert
            data-test="error-message"
            className="mb-4"
            title={error}
            variant="error"
          />
        )}
        <form onSubmit={submitForm}>
          <div className="mb-4">
            <Label htmlFor="email">Email</Label>
            <TextInput required name="email" id="email" autoFocus />
          </div>
          <div className="mb-8">
            <Label htmlFor="password">Password</Label>
            <TextInput required name="password" type="password" id="password" />
          </div>
          <div className="flex justify-end items-center">
            <Button type="submit" variant="primary" data-test="sign-in-button">
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignInScreen
