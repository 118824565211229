import React from 'react'

export const formatTitle = (name: string) => {
  const capitalized = name.charAt(0).toUpperCase() + name.slice(1)
  return capitalized.split('_').join(' ')
}

export const formatLabel = (path: string) => {
  const fields = path.split('.')
  const firstFields = fields
    .map((item: any, index: number) =>
      index === fields.length - 1 ? null : formatTitle(item)
    )
    .join(' ')
  return firstFields
    .slice(0, -1)
    .concat(`: ${formatTitle(fields[fields.length - 1])}`)
}

export const addressBlock = (answer: any) => {
  const { city, country, postal_code, province, street } = answer
  return (
    <p>
      {street} {city} {province} {postal_code} {country.code}
    </p>
  )
}

export const passportBlock = (passport: any) => {
  const { country, expiration_date, number } = passport
  return (
    <p>
      {number} {expiration_date} {country.code}
    </p>
  )
}

export const formatAnswer = (answer: any) => {
  if (typeof answer === 'string' || typeof answer === 'number') {
    return answer
  } else if (typeof answer === 'boolean') {
    return answer === true ? 'yes' : 'no'
  } else if (Array.isArray(answer)) {
    return answer.join(' ')
  } else if (answer && typeof answer === 'object') {
    if (answer.type === 'Address') {
      return addressBlock(answer)
    } else if (answer.type === 'Phone') {
      return answer.number
    } else if (answer.type === 'Passport') {
      return passportBlock(answer)
    } else {
      return null
    }
  }
}
