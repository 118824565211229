import React, { FunctionComponent, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { getFilteredUsers } from 'api/users'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import MainLayout from 'layouts/MainLayout'
import { User } from 'types/users'
import UsersTable from './UsersTable'
import UserListFilters from './UserListFilters'

const UserList: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()

  const [usersList, setUsersList] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [includeAdmin, setIncludeAdmin] = useState('registered_customers')
  const [emailSearch, setEmailSearch] = useState('')
  // Limit is the number of users that show up on each page
  const limitFilter = 50
  const [currentPaginationCount, setCurrentPaginationCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const onFilterChange = (newIncludeAdmin: string, newEmailSearch: string) => {
    setIncludeAdmin(newIncludeAdmin)
    setEmailSearch(newEmailSearch)
  }

  useEffect(() => {
    loadUsers(0)
  }, [includeAdmin, emailSearch])

  const loadUsers = (selectedPage: number) => {
    setIsLoading(true)

    const offset = selectedPage * limitFilter

    getFilteredUsers({
      include_users: includeAdmin,
      email: emailSearch,
      limit: limitFilter,
      offset: offset
    })
      .then((data: { data: User[]; total_count: number }) => {
        setUsersList(data.data)
        setCurrentPaginationCount(data.total_count)
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => setGlobalError(error))
  }

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected)
    loadUsers(selectedItem.selected)
  }

  const pageCount = Math.ceil(currentPaginationCount / limitFilter)

  return (
    <MainLayout>
      <div className="p-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
            Users
          </h1>
        </header>

        <UserListFilters onFilterChange={onFilterChange} />
        <UsersTable users={usersList} isLoading={isLoading} />
        {pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            activeClassName={'font-bold text-blue-600'}
            pageClassName={'mx-2'}
            nextClassName={'ml-2'}
            previousClassName={'mr-2'}
            containerClassName={'flex flex-row mt-16 text-sm'}
          />
        )}
      </div>
    </MainLayout>
  )
}

export default UserList
