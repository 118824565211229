import React, { FunctionComponent, useEffect, useState } from 'react'
import cx from 'classnames'
import Button from 'components/Button'
import Label from 'components/forms/Label'
import TextArea from 'components/forms/TextArea'
import { useParams } from 'react-router-dom'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { postComment } from 'api/overview'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import KaseComments from './KaseComments'
import KaseAssignments from '../../kase_review/KaseAssignments'

interface Props {
  className?: string
  height?: number
}

const KaseCommentSection: FunctionComponent<Props> = ({
  className,
  height
}) => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const [comment, setComment] = useState('')
  const [commentSectionHeight, setCommentSectionHeight] = useState(0)
  const [overallHeight, setOverallHeight] = useState(height)

  const pinnedComments = kaseOverview.comments.filter(
    (comment) => comment.pinned
  )

  useEffect(() => {
    const addCommentSectionHeight = document.getElementById(
      'add-comment-section'
    )?.clientHeight
    if (height) {
      if (addCommentSectionHeight) {
        const commentSectionHeight = height - addCommentSectionHeight
        setCommentSectionHeight(commentSectionHeight)
        // 50 is the height of the text area
        setOverallHeight(height + 50)
      }
    }
  }, [height])

  const onAddCommentBtnClick = () => {
    postComment(kaseId, comment)
    setComment('')
  }

  return (
    <div style={{ maxHeight: overallHeight }} className={cx(className, 'px-6')}>
      <KaseAssignments className="mb-8" />
      <div id="add-comment-section">
        <h2 className="font-bold text-base">Internal Comments</h2>
        <Label className="mt-6 text-gray-700" htmlFor="comment-input">
          Comment text
        </Label>
        <TextArea
          className="h-32 mt-4"
          id="comment-input"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button className="mt-4" onClick={onAddCommentBtnClick} variant="plain">
          Add Comment
        </Button>
        <hr className="mt-4" />
      </div>
      <div
        style={{ maxHeight: commentSectionHeight }}
        className="overflow-scroll pr-6"
      >
        {pinnedComments.length > 0 && (
          <>
            <KaseComments
              comments={pinnedComments}
              className="mt-6"
              label={'Pinned'}
            />
            <hr className="my-6" />
          </>
        )}
        <KaseComments
          comments={kaseOverview.comments}
          className="mt-6"
          label={'All'}
        />
      </div>
    </div>
  )
}

export default KaseCommentSection
