import Button from 'components/Button'
import Popover, { PopoverPosition } from 'components/Popover'
import React, { FunctionComponent, ReactNode } from 'react'
import { Align } from 'react-tiny-popover'
import ActionMenuButton from './ActionMenuButton'

interface ActionProps {
  /**
   * The markup to display inside the button
   */
  label: ReactNode
  /**
   * The callback triggered when the button is clicked
   */
  onClick: () => void
  /**
   * If true, the button will be disabled
   */
  disabled?: boolean
  /**
   * An optional `data-test` ID that's handy for testing purposes
   */
  testId?: string
}

interface Props {
  /**
   * A list of actions to display in the ActionMenu. You can also provide a
   * `"divider"` string to separate items visually.
   */
  actions: (ActionProps | 'divider')[]
  /**
   * The preferred position of the popover, or an array of preferred positions
   * in order of priority.
   */
  position?: PopoverPosition | PopoverPosition[]
  /**
   * If start is specified, the popover content's top or left location is
   * aligned with its target's. With end specified, the content's bottom or
   * right location is aligned with its target's. If center is specified, the
   * popover content and target's centers are aligned. Defaults to center.
   */
  align?: Align
}

const ActionMenu: FunctionComponent<Props> = ({ actions, position, align }) => {
  return (
    <Popover
      align={align}
      position={position}
      content={
        <div className="inline-flex max-w-xs rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1" role="menu" aria-orientation="vertical">
              {actions.map((action, index) => {
                if (action === 'divider') {
                  return (
                    <hr
                      className="border-gray-100 my-1"
                      key={`divider-${index}`}
                    />
                  )
                }

                return (
                  <ActionMenuButton
                    key={`action-${index}`}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    data-test={action.testId}
                  >
                    {action.label}
                  </ActionMenuButton>
                )
              })}
            </div>
          </div>
        </div>
      }
    >
      {({ ref, togglePopover }) => (
        <Button forwardRef={ref} onClick={togglePopover} variant="plain">
          <Button.Icon>
            <svg viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
            </svg>
          </Button.Icon>
        </Button>
      )}
    </Popover>
  )
}

export default ActionMenu
