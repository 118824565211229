import server from 'api/server'

export async function fetchFormOptions(select_set: string) {
  return server
    .get(`/api/v1/admin/form_options/${select_set}`)
    .then((response) => response.data)
    .catch((error) => error)
}

export default fetchFormOptions
