import server from 'api/server'
import { setKaseStates } from 'store/reducers/kaseStates'
import store from 'store/root'

const KASES_URL = '/api/v1/admin/kases'
const KASE_STATES_URL = '/api/v1/admin/kase_states'

export const getFilteredKases = async ({
  kase_kind,
  kase_state,
  lawyer_id,
  steward_id,
  paid,
  phone,
  email,
  otherEmail,
  first_name,
  last_name,
  limit,
  offset = 0
}: {
  kase_kind: string
  kase_state: string
  lawyer_id: string
  steward_id: string
  paid: string
  phone: string
  email: string
  otherEmail: string
  first_name: string
  last_name: string
  limit?: number
  offset?: number
}) => {
  const params = {
    kase_kind,
    kase_state,
    lawyer_id,
    steward_id,
    paid,
    phone,
    email,
    otherEmail,
    first_name,
    last_name,
    limit,
    offset
  }
  return server.get(KASES_URL, { params }).then(({ data }) => {
    return data
  })
}

export const getKaseStates = async () => {
  return server.get(KASE_STATES_URL).then((response) => {
    store.dispatch(setKaseStates(response.data))
  })
}
