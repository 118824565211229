import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import KasePaymentLog from './KasePaymentLog'

interface Props {
  className?: string
  height?: number
}

const KasePaymentLogSection: FunctionComponent<Props> = ({
  className = '',
  height = 'auto'
}) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)

  return (
    <div className={cx(className, 'px-6')}>
      <div>
        <h2 className="font-bold text-base">Completed Payments</h2>
        <hr className="mt-4" />
      </div>
      <div style={{ height: height }} className="overflow-scroll pr-6">
        <div className="mt-6">
          {kaseOverview.payments.map((paymentLog, index) => {
            return (
              <KasePaymentLog
                key={`payment-${index}-${paymentLog.created_at}`}
                paymentLog={paymentLog}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default KasePaymentLogSection
