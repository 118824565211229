import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import {
  fetchKaseReviewData,
  fetchReviewTypes,
  sendKaseAnswer
} from 'api/kaseData'
import {
  createIssue,
  fetchIssues,
  resolveIssue,
  updateIssueDescription
} from 'api/issues'

import { ReviewType } from 'types/reviewType'
import { KaseReviewField } from 'types/kaseReviewData'
import { ICreateIssueParams } from 'types/issues'

import MainLayout from 'layouts/MainLayout'
import LoadingSpinner from 'components/LoadingSpinner'
import SubNavigation from 'components/SubNavigation'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import KaseQuestions from './KaseQuestions'
import KaseIssuesPanel from './KaseIssuesPanel'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import KaseSidebar from './KaseSidebar'

const KaseIndex: FunctionComponent = () => {
  const [selectedReviewFields, setSelectedReviewFields] = useState<
    KaseReviewField[]
  >([])
  const [showIssuePanel, setShowIssuePanel] = useState(false)
  const [isIssuePanelLoading, setIsIssuePanelLoading] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [reviewType, setReviewType] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isShowingAll, setIsShowingAll] = useState(false)
  const [sectionId, setSectionId] = useState(0)
  const [isToggleDisabled, setIsToggleDisabled] = useState(false)

  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()

  const history = useHistory()
  const queryString = useLocation().search
  const kaseReviewData = useSelector((state: RootState) => state.kaseReviewData)

  const handleReviewFieldCheck = (reviewField: KaseReviewField) => {
    const isChecked = selectedReviewFields.some(
      (field) => field === reviewField
    )
    if (!isChecked) {
      setSelectedReviewFields([...selectedReviewFields, reviewField])
    } else {
      setSelectedReviewFields([
        ...selectedReviewFields.filter((field) => field !== reviewField)
      ])
    }

    setShowIssuePanel(true)
    setShowCreateIssue(true)
  }

  const handleCreateIssueButtonClick = () => {
    setShowIssuePanel(true)
    setShowCreateIssue(true)
  }

  const handleClickSaveButton = (createIssueParams: ICreateIssueParams) => {
    setIsIssuePanelLoading(true)

    createIssue(kaseId, createIssueParams)
      .then(() => fetchIssues(kaseId))
      .catch((error) => setGlobalError(error))
      .finally(() => {
        setSelectedReviewFields([])
        setIsIssuePanelLoading(false)
      })
  }

  const handleResolveButtonClick = (kaseId: string, issueId: string) => {
    setIsIssuePanelLoading(true)

    resolveIssue(kaseId, issueId)
      .then(() => setIsIssuePanelLoading(false))
      .catch((error) => setGlobalError(error))
  }

  const handleViewIssuesButtonClick = () => {
    setShowIssuePanel(!showIssuePanel)
    setShowCreateIssue(false)
  }

  const handleSaveEditIssue = (
    kaseId: string,
    issueId: string,
    newDescription: string
  ) => {
    setIsIssuePanelLoading(true)

    updateIssueDescription(kaseId, issueId, newDescription)
      .then(() => setIsIssuePanelLoading(false))
      .catch((error) => setGlobalError(error))
  }

  const handleClickCancelCreateIssueButton = () => {
    setSelectedReviewFields([])
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  const updateKaseReviewData = () => {
    setIsToggleDisabled(true)
    return fetchKaseReviewData(
      kaseId,
      reviewType.toString(),
      sectionId,
      isShowingAll
    )
      .then(() => setIsToggleDisabled(false))
      .catch((error) => setGlobalError(error))
      .finally(() => setIsLoading(false))
  }

  const submitAnswer = async (
    value: string | null,
    questionId: string,
    answerId: string | null
  ) => {
    if (value !== null) {
      setIsToggleDisabled(true)

      return sendKaseAnswer(kaseId, questionId, value, answerId)
        .then(() =>
          fetchKaseReviewData(
            kaseId,
            reviewType.toString(),
            sectionId,
            isShowingAll
          )
        )
        .then(() => setIsToggleDisabled(false))
        .catch((error) => setGlobalError(error))
        .finally(() => setIsLoading(false))
    } else {
      return Promise.resolve()
    }
  }

  const sectionUpdate = (sectionId: number) => {
    setSectionId(sectionId)
  }

  const handleReviewTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault()
    const { value } = event.target
    setSectionId(0)
    setReviewType(parseInt(value))
    pushHistory(value)
  }

  const pushHistory = (id: string) => {
    const params = new URLSearchParams()
    params.append('reviewTypeId', id)
    history.push({ search: params.toString() })
  }

  useEffect(() => {
    const reviewTypeId = new URLSearchParams(queryString).get('reviewTypeId')

    fetchReviewTypes(kaseId)
      .then((response) => {
        const reviewTypes = response.data
        if (reviewTypes?.length) {
          if (reviewTypeId !== null) {
            setReviewType(parseInt(reviewTypeId))
          } else {
            const firstReviewType: ReviewType = reviewTypes[0]
            pushHistory(firstReviewType.id.toString())
            setReviewType(firstReviewType.id)
          }
          fetchIssues(kaseId).catch((error) => setGlobalError(error))
        } else {
          setGlobalError({
            title: 'No Form Review Types Returned',
            detail:
              'Consider adding review types in Ops Tools to use the Form Review Tool.'
          })
        }
      })
      .catch((error) => setGlobalError(error))
  }, [])

  useEffect(() => {
    if (reviewType <= 0) return
    updateKaseReviewData()
  }, [reviewType, isShowingAll, sectionId])

  const onClickOutsideIssuePanel = () => {
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  let sectionList

  if (kaseReviewData && kaseReviewData.sections) {
    sectionList = kaseReviewData.sections
  }

  if (isLoading) {
    return (
      <div className="mt-48">
        <LoadingSpinner />
      </div>
    )
  } else {
    return (
      <MainLayout
        subNavigation={
          <SubNavigation
            context="form-review-tool"
            showIssuePanel={showIssuePanel}
            reviewType={reviewType}
            isToggleDisabled={isToggleDisabled}
            onRequestShowOrHideIssuesPanel={handleViewIssuesButtonClick}
            onRequestCreateIssue={handleCreateIssueButtonClick}
            onRequestChangeReviewType={(event) => handleReviewTypeChange(event)}
          />
        }
      >
        <div className="px-8">
          <div
            className="border-r-2 fixed h-full pr-10 pt-4"
            // Using inline style since tailwind won't work at above 12 rem for some reason
            style={{ width: '18rem' }}
          >
            <KaseSidebar
              sections={sectionList}
              currentSection={sectionId}
              onSectionClick={sectionUpdate}
            />
          </div>
          <div className="pl-4 pt-4" style={{ marginLeft: '18rem' }}>
            <KaseQuestions
              handleReviewFieldCheck={handleReviewFieldCheck}
              isShowingAll={isShowingAll}
              isToggleDisabled={isToggleDisabled}
              kaseId={kaseId}
              onChangeViewToggle={setIsShowingAll}
              selectedReviewFields={selectedReviewFields}
              submitAnswer={submitAnswer}
              updateKaseReviewData={updateKaseReviewData}
            />
          </div>
        </div>
        <KaseIssuesPanel
          onClickOutsideIssuePanel={onClickOutsideIssuePanel}
          handleClickCancelButton={handleClickCancelCreateIssueButton}
          handleClickSaveButton={handleClickSaveButton}
          handleResolveButtonClick={handleResolveButtonClick}
          handleSaveEditIssue={handleSaveEditIssue}
          isLoading={isIssuePanelLoading}
          selectedReviewFields={selectedReviewFields}
          setShowCreateIssue={setShowCreateIssue}
          show={showIssuePanel}
          showCreateIssue={showCreateIssue}
        />
      </MainLayout>
    )
  }
}

export default KaseIndex
