import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDocumentSet } from 'types/documents'

export interface DocumentReviewShape {
  documentSets: IDocumentSet[]
}

const initialState: DocumentReviewShape = {
  documentSets: []
}

const documentReviewDataSlice = createSlice({
  name: 'documentReviewData',
  initialState,
  reducers: {
    setDocumentSets: (
      state: DocumentReviewShape,
      action: PayloadAction<IDocumentSet[]>
    ) => {
      const documentList = action.payload
      // display Other bucket first
      if (
        documentList[documentList.length - 1]?.name.toLowerCase() === 'other'
      ) {
        documentList.unshift(documentList.pop() as IDocumentSet)
      }
      state.documentSets = documentList
    }
  }
})

export const { setDocumentSets } = documentReviewDataSlice.actions

export default documentReviewDataSlice.reducer
