import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import useOptions from 'hooks/useCountryOptions'
import { PageElementQuestion } from 'types/questionnaire'

interface Props {
  excludeUS?: boolean
  onBlur?: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  question: PageElementQuestion
  useStateDeptCountries?: boolean
  value?: string
}

function CountryQuestionElement({
  excludeUS,
  onBlur,
  onChange,
  question,
  useStateDeptCountries,
  value
}: Props): JSX.Element {
  const { loading, options } = useOptions({ excludeUS, useStateDeptCountries })

  return (
    <div key={question?.id}>
      {question.attributes.text ?? 'Country'}: &nbsp;
      <select
        name={question?.id}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        <option value="">(Select a country)</option>
        {!loading &&
          Object.entries(options).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
      </select>
    </div>
  )
}

export default CountryQuestionElement
