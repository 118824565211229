/**
 * Taken and adapted from https://github.com/odysseyscience/react-s3-uploader
 */
import { getAuthJWT } from 'api/jwt'
import _defaults from 'lodash/defaults'

const XHR_DONE = 4
const SUCCESS_CODES = [200, 201]

interface KeyValuePair {
  [key: string]: string
}

export enum UploadProgress {
  Waiting = 'waiting',
  Uploading = 'uploading',
  Finalizing = 'finalizing',
  UploadComplete = 'complete'
}

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'
type FileOperation =
  | 'upload-to-doc-request'
  | 'replace-in-doc-view'
  | 'bulk-mpdf-upload'

interface S3UploadOptions {
  contentDisposition?: string
  s3path: string
  server?: string
  signingUrl?: string
  signingUrlHeaders?: KeyValuePair
  signingUrlMethod?: MethodType
  uploadRequestHeaders?: KeyValuePair
  getSignedUrl?: (
    file: File,
    callback: (
      file: File,
      signedUrl: SignResult,
      docRequestId?: number | string,
      docRequestPartId?: number,
      docReplacedId?: number
    ) => void
  ) => void
  onError?: (errorMessage: string, file: File, xhr?: XMLHttpRequest) => void
  onProgress?: (percent: number, status: UploadProgress, file: File) => void
  onUploadComplete?: (
    signResult: SignResult,
    rawFile: File,
    docRequestId: number | string,
    docRequestPartId: number
  ) => void
  onReplaceUploadComplete?: (
    signResult: SignResult,
    rawFile: File,
    docReplacedId: number
  ) => void
  onUploadMpdfFileComplete?: (signResult: SignResult, rawFile: File) => void

  replaceFile?: boolean
  docRequestId?: number | string
  docRequestPartId?: number
  docReplacedId?: number
}

export interface SignResult {
  filename: string
  contentType: string
  originalFilename: string
  signedUrl: string
  headers: { [key: string]: string }
}

class S3Upload {
  private httpRequest: XMLHttpRequest | null
  private options: S3UploadOptions
  private fileOperation: FileOperation

  constructor(
    files: File[],
    kaseId: number,
    options: S3UploadOptions,
    fileOperation: FileOperation
  ) {
    if (!kaseId) {
      throw new Error('Please provide a kase ID when uploading documents')
    }

    this.options = _defaults(options, {
      contentDisposition: 'auto',
      server: process.env.API_ORIGIN,
      signingUrl: `/api/v1/admin/kases/${kaseId}/documents/signed_url`,
      signingUrlMethod: 'POST'
    })

    this.handleFileSelect(files)

    this.fileOperation = fileOperation
    this.httpRequest = null

    if (!files) {
      return
    }
  }

  private onUploadComplete = (
    signResult: SignResult,
    file: File,
    docRequestId: number | string,
    docRequestPartId: number
  ) => {
    if (this.options.onUploadComplete) {
      this.options.onUploadComplete(
        signResult,
        file,
        docRequestId,
        docRequestPartId
      )
    }
  }

  private onReplaceUploadComplete = (
    signResult: SignResult,
    file: File,
    docReplacedId: number
  ) => {
    if (this.options.onReplaceUploadComplete) {
      this.options.onReplaceUploadComplete(signResult, file, docReplacedId)
    }
  }

  private onUploadMpdfFileComplete = (signResult: SignResult, file: File) => {
    if (this.options.onUploadMpdfFileComplete) {
      this.options.onUploadMpdfFileComplete(signResult, file)
    }
  }

  private onProgress = (
    percent: number,
    status: UploadProgress,
    file: File
  ) => {
    if (this.options.onProgress) {
      this.options.onProgress(percent, status, file)
    }
  }

  private onError = (
    errorMessage: string,
    file: File,
    xhr?: XMLHttpRequest
  ) => {
    if (this.options.onError) {
      this.options.onError(status, file, xhr)
    }
  }

  private scrubFileName = (fileName: string) => {
    return fileName.replace(/[^\w\d_\-.]+/gi, '')
  }

  private handleFileSelect = (files: File[]) => {
    const result = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      result.push(this.uploadFile(file))
    }
  }

  private createCORSRequest = (
    method: MethodType,
    url: string,
    uploadingToS3: boolean
  ) => {
    const xhr = new XMLHttpRequest()

    xhr.open(method, url, true)
    !uploadingToS3 && xhr.setRequestHeader('Authorization', getAuthJWT() || '')

    return xhr
  }

  private executeOnSignedUrl = (
    file: File,
    callback: (
      file: File,
      signedUrl: SignResult,
      docRequestId?: number | string,
      docRequestPartId?: number,
      docReplacedId?: number
    ) => void
  ) => {
    const fileName = this.scrubFileName(file.name)
    let queryString = `?objectName=${fileName}&contentType=${encodeURIComponent(
      file.type
    )}`

    if (this.options.s3path) {
      queryString += `&path=${encodeURIComponent(this.options.s3path)}`
    }

    const { signingUrlMethod, server, signingUrl } = this.options

    if (signingUrlMethod && server && signingUrl) {
      const xhr = this.createCORSRequest(
        signingUrlMethod,
        server + signingUrl + queryString,
        false
      )

      const signingUrlHeaders = Object.assign(
        {},
        this.options.signingUrlHeaders
      )

      Object.keys(signingUrlHeaders).forEach((key) => {
        const value = signingUrlHeaders[key]
        xhr.setRequestHeader(key, value)
      })

      if (xhr.overrideMimeType) {
        xhr.overrideMimeType('text/plain; charset=x-user-defined')
      }

      xhr.onreadystatechange = () => {
        if (
          xhr.readyState === XHR_DONE &&
          SUCCESS_CODES.indexOf(xhr.status) >= 0
        ) {
          let result
          try {
            result = JSON.parse(xhr.responseText)
          } catch (error) {
            this.onError('Invalid response from server', file, xhr)
            return false
          }
          return callback(
            file,
            result,
            this.options.docRequestId,
            this.options.docRequestPartId,
            this.options.docReplacedId
          )
        } else if (
          xhr.readyState === XHR_DONE &&
          SUCCESS_CODES.indexOf(xhr.status) < 0
        ) {
          return this.onError(
            `Could not contact request-signing server. Status: ${xhr.status}`,
            file,
            xhr
          )
        }
      }
      return xhr.send()
    } else {
      throw new Error(
        'DOC UPLOAD ERROR - signingUrlMethod, server, signingUrl must be provided to options in S3Upload'
      )
    }
  }

  private isUndefined = (variable: any) => {
    return typeof variable !== 'undefined'
  }

  uploadToS3 = (
    file: File,
    signResult: SignResult,
    docRequestId?: number | string,
    docRequestPartId?: number,
    docReplacedId?: number
  ) => {
    const xhr = this.createCORSRequest('PUT', signResult.signedUrl, true)

    if (!xhr) {
      this.onError('CORS not supported', file)
    } else {
      xhr.onload = () => {
        if (SUCCESS_CODES.indexOf(xhr.status) >= 0) {
          this.onProgress(100, UploadProgress.UploadComplete, file)

          if (this.fileOperation === 'upload-to-doc-request')
            if (
              typeof docRequestId !== 'undefined' &&
              typeof docRequestPartId !== 'undefined'
            ) {
              return this.onUploadComplete(
                signResult,
                file,
                docRequestId,
                docRequestPartId
              )
            } else {
              throw new Error(
                'UPLOAD DOCUMENT ERROR - file not written to database. Must supply docRequestId and docRequestPartId to S3Upload options'
              )
            }
          if (this.fileOperation === 'replace-in-doc-view')
            if (typeof docReplacedId !== 'undefined') {
              return this.onReplaceUploadComplete(
                signResult,
                file,
                docReplacedId
              )
            } else {
              throw new Error(
                'REPLACE DOCUMENT UPLOAD ERROR - file not written to database. Must supply docReplacedId S3Upload options'
              )
            }
          if (this.fileOperation === 'bulk-mpdf-upload')
            return this.onUploadMpdfFileComplete(signResult, file)
        } else {
          return this.onError('Upload error: ' + xhr.status, file, xhr)
        }
      }

      xhr.onerror = () => this.onError('XHR error', file, xhr)

      xhr.upload.onprogress = (e) => {
        let percentLoaded = 0
        if (e.lengthComputable) {
          percentLoaded = Math.round((e.loaded / e.total) * 100)
          return this.onProgress(
            percentLoaded,
            percentLoaded === 100
              ? UploadProgress.Finalizing
              : UploadProgress.Uploading,
            file
          )
        }
      }
    }

    xhr.setRequestHeader('Content-Type', file.type)

    const { contentDisposition } = this.options
    if (contentDisposition) {
      let disposition = contentDisposition
      if (contentDisposition === 'auto') {
        if (file.type.substr(0, 6) === 'image/') {
          disposition = 'inline'
        } else {
          disposition = 'attachment'
        }
      }

      const fileName = this.scrubFileName(file.name)
      xhr.setRequestHeader(
        'Content-Disposition',
        `${disposition}; filename="${fileName}"`
      )
    }

    if (signResult.headers) {
      const signResultHeaders = signResult.headers
      Object.keys(signResultHeaders).forEach((key) => {
        xhr.setRequestHeader(key, signResultHeaders[key])
      })
    }

    const uploadRequestHeaders = Object.assign(
      {},
      { 'x-amz-acl': 'private' },
      this.options.uploadRequestHeaders
    )
    Object.keys(uploadRequestHeaders).forEach((key) => {
      xhr.setRequestHeader(key, uploadRequestHeaders[key])
    })

    this.httpRequest = xhr

    return xhr.send(file)
  }

  uploadFile = async (file: File) => {
    if (this.options.getSignedUrl) {
      return this.options.getSignedUrl(file, this.uploadToS3)
    }

    return this.executeOnSignedUrl(file, this.uploadToS3)
  }

  abortUpload = () => {
    this.httpRequest && this.httpRequest.abort()
  }
}

export default S3Upload
