import { CurrentUser } from 'types/currentUser'

export const getAuthJWT = () => {
  return localStorage.getItem('token')
}

export const setAuthJWT = (token: string) => {
  localStorage.setItem('token', token)
}

/**
 * Clears the JWT in localStorage
 */
export const clearAuthJWT = () => {
  localStorage.removeItem('token')
}

export const saveCurrentUserToLocalStorage = (userData: CurrentUser) => {
  // Save the data in local storage
  localStorage.setItem('user', JSON.stringify(userData))
}

/**
 * Returns the current user's details from local storage, or null if it's empty.
 * Do NOT rely on this for any kind of authentication/authorization.
 */
export const getCurrentUserDataFromLocalStorage = () => {
  const data = localStorage.getItem('user')
  if (data == null) {
    return null
  }

  return JSON.parse(data) as CurrentUser
}

export const clearUserData = () => {
  localStorage.removeItem('user')
}
