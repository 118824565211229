import React, { FunctionComponent } from 'react'
import { User } from 'types/users'
import TableData from 'components/tables/TableData'
import TableRow from 'components/tables/TableRow'
import { Link } from 'react-router-dom'

interface Props {
  user: User
}
const UserRow: FunctionComponent<Props> = ({ user }) => {
  return (
    <TableRow data-test={`user-${user.id}`}>
      <TableData className="w-0">{user.id}</TableData>
      <TableData>{user.attributes.email}</TableData>
      <TableData>{user.attributes.guest.toString()}</TableData>
      <TableData>
        {user.attributes.kases.map((kase) => kase.attributes.kind).join(', ')}
      </TableData>
      <TableData>
        <Link
          to={`/users/${user.id}`}
          className="inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          View User
        </Link>
      </TableData>
    </TableRow>
  )
}

export default UserRow
