import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { fetchOverview } from 'api/overview'
import { fetchKaseData } from 'api/kaseData'
import { registerUser, sendMagicLink } from 'api/accountHolder'
import { RootState } from 'store/root'
import { useSelector } from 'react-redux'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import KaseCommentSection from './comments/KaseCommentSection'
import LawyerInteractionsSection from './lawyer/LawyerInteractionsSection'
import KaseInformation from './KaseInformation'
import KaseTools from './KaseTools'
import LoadingSpinner from 'components/LoadingSpinner'
import MainLayout from 'layouts/MainLayout'
import SubNavigation from 'components/SubNavigation'
import MPDFGenerationSection from './MPDFGenerationSection'
import MPDFView from './MPDFView'
import Alert from 'components/Alert'
import { getAccountHolderEmail } from 'store/selectors/kaseOverview'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { getRouteForKaseOverview } from 'constants/routes'
import KaseLogs from './logs/KaseLogs'
import KasePaymentLogSection from './payment_log/KasePaymentLogSection'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import SaveCompletedPayment from 'pages/payment/save_completed_payment'

const KaseOverview: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const kaseData = useSelector((state: RootState) => state.kaseData)
  const [infoHeight, setInfoHeight] = useState(0)
  const [toolsHeight, setToolsHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [overviewLoaded, setOverviewLoaded] = useState(false)
  const [isViewingDocument, setIsViewingDocument] = useState(false)
  const [currentMpdfDocUrl, setCurrentMpdfDocUrl] = useState('')
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false)
  const completed_eligibility = kaseData?.attributes?.completed_eligibility
  const registered = kaseData?.attributes?.registered
  const registrationMessage =
    'Account not registered and/or has not completed eligibility.'
  const accountHolderEmail = useSelector((state: RootState) =>
    getAccountHolderEmail(state)
  )
  const setInfoSectionHeight = (height: number) => {
    setInfoHeight(height)
  }
  const setToolsSectionHeight = (height: number) => {
    setToolsHeight(height)
  }
  const kaseState = kaseOverview?.state
  const params = new URLSearchParams(window.location.search.substring(1))
  const stripePaymentSuccess = params.get('payment_success') === 'true'
  const splititPaymentSuccess = params.get('splitit_payment_success') === 'true'
  const showPaymentSuccess = stripePaymentSuccess || splititPaymentSuccess

  useEffect(() => {
    if (kaseId !== kaseOverview.kaseId) {
      setIsLoading(true)

      fetchOverview(kaseId)
        .then(() => fetchKaseData(kaseId, true))
        .then(() => {
          setIsLoading(false)
          setOverviewLoaded(true)
        })
        .catch((error) => {
          setGlobalError(error)
          setIsLoading(false)
          setOverviewLoaded(false)
        })
    }
  }, [kaseId])

  const onViewMpdfBtnClicked = (mpdfUrl: string) => {
    setCurrentMpdfDocUrl(mpdfUrl)
    setIsViewingDocument(true)
  }

  const renderExtraKaseAlerts = () => {
    if (kaseOverview.primary_kase_id) {
      return (
        <Alert
          variant="info"
          title="This is an extra kase attached to a primary application"
        >
          <Link
            to={getRouteForKaseOverview(kaseOverview.primary_kase_id)}
            className="underline hover:no-underline font-normal truncate"
          >
            View Primary Kase
          </Link>
        </Alert>
      )
    } else if (kaseOverview.extra_kase_ids?.length > 0) {
      return (
        <Alert variant="info" title="This application has extra kases attached">
          {kaseOverview.extra_kase_ids.map((id) => (
            <Link
              key={id}
              to={getRouteForKaseOverview(id)}
              className="mr-4 underline hover:no-underline font-normal truncate"
            >
              View Extra Kase {id}
            </Link>
          ))}
        </Alert>
      )
    }
  }

  if (isViewingDocument) {
    return (
      <MainLayout subNavigation={<SubNavigation context="overview" />}>
        <MPDFView
          documentUrl={currentMpdfDocUrl}
          onBackBtnClicked={() => setIsViewingDocument(false)}
        />
      </MainLayout>
    )
  }

  const handlOnClickRegistration = (
    userId: string | number,
    email: string,
    kaseId: string
  ) => {
    registerUser(userId, email, kaseId)
      .then(() => {
        window.location.reload()
      })
      .catch((error: any) => {
        setGlobalError(error)
      })
  }

  const handleOnClickMagicLink = (email: string) => {
    sendMagicLink(email)
      .catch((error: any) => {
        setGlobalError(error)
      })
      .finally(() => {
        setShowMagicLinkModal(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <div className="mt-48">
          <LoadingSpinner />
        </div>
      ) : (
        <MainLayout subNavigation={<SubNavigation context="overview" />}>
          {showMagicLinkModal && (
            <ConfirmationModal
              title="Send Magic Login Link to Account Holder"
              description="Are you sure you want to send a magic login link to the account holder?"
              confirmLabel="Yes, send magic link"
              cancelLabel="Cancel"
              onConfirm={() => {
                handleOnClickMagicLink(accountHolderEmail ?? '')
              }}
              onRequestClose={() => {
                setShowMagicLinkModal(false)
              }}
              isOpen={showMagicLinkModal}
            />
          )}
          {(!completed_eligibility || !registered) && (
            <Alert variant="warning" title={registrationMessage} />
          )}
          {showPaymentSuccess && (
            <Alert variant="success" title="Payment completed successfully!" />
          )}
          {renderExtraKaseAlerts()}
          <div className="font-medium text-sm">
            <div className="grid grid-cols-2">
              <KaseInformation
                setHeight={setInfoSectionHeight}
                className="border-r border-gray-300 col-span-1 pt-8 ml-4"
              />
              <div>
                <LawyerInteractionsSection
                  height={infoHeight}
                  className="col-span-1 mb-8 pt-8"
                />
                <hr />
                <KaseCommentSection
                  height={infoHeight}
                  className="col-span-1 mb-8 pt-8"
                />
              </div>
              <hr className="col-span-2" />
              <KaseTools
                setHeight={setToolsSectionHeight}
                className="border-r border-gray-300 col-span-1 ml-4 pt-8"
                accountHolderEmail={accountHolderEmail}
                onClickRegistration={handlOnClickRegistration}
                setShowMagicLinkModal={setShowMagicLinkModal}
                completed_eligibility={completed_eligibility}
                registered={registered}
                kaseState={kaseState}
              />
              <KaseLogs
                height={toolsHeight}
                className="col-span-1 overflow-scroll pt-8"
              />
            </div>
            <hr />
            <MPDFGenerationSection
              mpdfList={kaseOverview.master_pdfs}
              onViewMpdfBtnClicked={onViewMpdfBtnClicked}
            />
            {!!kaseOverview.payments?.length && (
              <>
                <hr />
                <KasePaymentLogSection
                  height={toolsHeight}
                  className="col-span-2 pt-8 mb-12"
                />
              </>
            )}
          </div>
          {overviewLoaded && <SaveCompletedPayment />}
        </MainLayout>
      )}
    </>
  )
}

export default KaseOverview
