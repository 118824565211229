export enum KaseStates {
  PRE_PAYMENT = 'pre_payment',
  POST_PAYMENT = 'post_payment',

  OPS_FORM_CHECK = 'ops_form_check',
  CUSTOMER_FORM_CHECK = 'customer_form_check',
  OPS_FOLLOWUP_FORM_CHECK = 'ops_followup_form_check',

  CUSTOMER_DOCUMENT_UPLOAD = 'customer_document_upload',
  OPS_DOCUMENT_CHECK = 'ops_document_check',
  CUSTOMER_DOCUMENT_CHECK = 'customer_document_check',
  OPS_FOLLOWUP_DOCUMENT_CHECK = 'ops_followup_document_check',

  OPS_QA = 'ops_qa',
  CUSTOMER_QA = 'customer_qa',
  OPS_FOLLOWUP_QA = 'ops_followup_qa',
  CUSTOMER_FOLLOWUP_QA = 'customer_followup_qa',

  LAWYER_LEGAL_REVIEW = 'lawyer_legal_review',
  LAWYER_FOLLOWUP_LEGAL_REVIEW = 'lawyer_followup_legal_review',
  CUSTOMER_LEGAL_REVIEW = 'customer_legal_review',
  OPS_LEGAL_REVIEW = 'ops_legal_review',
  OPS_FOLLOWUP_LEGAL_REVIEW = 'ops_followup_legal_review',

  PRINTER_PACKAGING = 'printer_packaging',
  CUSTOMER_PACKAGE_SHIPPED = 'customer_package_shipped',
  CUSTOMER_COMPLETED = 'customer_completed',

  INELIGIBLE = 'ineligible',
  PENDING_REFUND = 'pending_refund',
  REFUNDED = 'refunded',

  CANCELLED = 'cancelled',
  INACTIVE = 'inactive',

  CUSTOMER_FORM_COMPLETION = 'customer_form_completion',
  OPS_SUBMIT_FORM = 'ops_submit_form',
  OPS_FEE_PAYMENT = 'ops_fee_payment',
  CUSTOMER_CHOOSE_PAYMENT_TYPE = 'customer_choose_payment_type',
  OPS_FOLLOWUP_FEE_PAYMENT_SETUP = 'ops_followup_fee_payment_setup',
  CUSTOMER_FEE_PAYMENT = 'customer_fee_payment',
  OPS_INTERVIEW_SCHEDULING = 'ops_interview_scheduling',
  CUSTOMER_INTERVIEW_PREP = 'customer_interview_prep',
  OPS_INTERVIEW_PREP = 'ops_interview_prep',
  READY_FOR_INTERVIEW = 'ready_for_interview',
  CUSTOMER_COMPLETE_APPROVED = 'customer_complete_approved',
  CUSTOMER_COMPLETE_DENIED = 'customer_complete_denied'
}

export const KaseStateValues = {
  [KaseStates.PRE_PAYMENT]: 'Pre Payment',
  [KaseStates.POST_PAYMENT]: 'Post Payment',

  [KaseStates.OPS_FORM_CHECK]: 'Ops Form Check',
  [KaseStates.CUSTOMER_FORM_CHECK]: 'Customer Form Check',
  [KaseStates.OPS_FOLLOWUP_FORM_CHECK]: 'Ops Follow-Up Form Check',
  [KaseStates.CUSTOMER_DOCUMENT_UPLOAD]: 'Document Upload',

  [KaseStates.OPS_DOCUMENT_CHECK]: 'Ops Document Check',
  [KaseStates.CUSTOMER_DOCUMENT_CHECK]: 'Customer Document Check',
  [KaseStates.OPS_FOLLOWUP_DOCUMENT_CHECK]: 'Ops Follow-Up Document Check',

  [KaseStates.OPS_QA]: 'Ops QA',
  [KaseStates.CUSTOMER_QA]: 'Customer QA',
  [KaseStates.OPS_FOLLOWUP_QA]: 'Ops Follow-Up QA',
  [KaseStates.CUSTOMER_FOLLOWUP_QA]: 'Customer Follow-Up QA',

  [KaseStates.LAWYER_LEGAL_REVIEW]: 'Lawyer Review',
  [KaseStates.LAWYER_FOLLOWUP_LEGAL_REVIEW]: 'Lawyer Follow-Up Review',
  [KaseStates.CUSTOMER_LEGAL_REVIEW]: 'Customer Legal Review',
  [KaseStates.OPS_LEGAL_REVIEW]: 'Ops Legal Review',
  [KaseStates.OPS_FOLLOWUP_LEGAL_REVIEW]: 'Ops Follow-Up Legal Review',

  [KaseStates.PRINTER_PACKAGING]: 'Print & Ship',
  [KaseStates.CUSTOMER_PACKAGE_SHIPPED]: 'Customer Package Shipped',
  [KaseStates.CUSTOMER_COMPLETED]: 'Customer Completed',

  [KaseStates.INELIGIBLE]: 'Ineligible',
  [KaseStates.PENDING_REFUND]: 'Pending Refund',
  [KaseStates.REFUNDED]: 'Refunded',

  [KaseStates.CANCELLED]: 'Cancelled',
  [KaseStates.INACTIVE]: 'Inactive',

  [KaseStates.CUSTOMER_FORM_COMPLETION]: 'Customer Form Completion',
  [KaseStates.OPS_SUBMIT_FORM]: 'Ops Submit Form',
  [KaseStates.OPS_FEE_PAYMENT]: 'Ops Fee Payment',
  [KaseStates.CUSTOMER_CHOOSE_PAYMENT_TYPE]: 'Customer Choose Payment Type',
  [KaseStates.OPS_FOLLOWUP_FEE_PAYMENT_SETUP]: 'Ops Followup Fee Payment Setup',
  [KaseStates.CUSTOMER_FEE_PAYMENT]: 'Customer Fee Payment',
  [KaseStates.OPS_INTERVIEW_SCHEDULING]: 'Ops Interview Scheduling',
  [KaseStates.CUSTOMER_INTERVIEW_PREP]: 'Customer Interview Prep',
  [KaseStates.OPS_INTERVIEW_PREP]: 'Ops Interview Prep',
  [KaseStates.READY_FOR_INTERVIEW]: 'Ready For Interview',
  [KaseStates.CUSTOMER_COMPLETE_APPROVED]: 'Customer Complete Approved',
  [KaseStates.CUSTOMER_COMPLETE_DENIED]: 'Customer Complete Denied'
}
