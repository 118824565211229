import React, { FunctionComponent } from 'react'
import { formatCentsInDollars } from 'utils/format_money'

interface LineItem {
  amount_before_discount?: number
  amount: number
  discount_description?: string
  name: string
}

interface Props {
  items: LineItem[]
  discounts: LineItem[]
  hasAppliedDiscounts: boolean | undefined
}

const LineItems: FunctionComponent<Props> = ({
  items,
  discounts,
  hasAppliedDiscounts
}) => {
  return (
    <>
      {items.map(
        (
          {
            amount_before_discount,
            amount,
            discount_description,
            name
          }: LineItem,
          idx
        ) => (
          <React.Fragment key={idx}>
            <div className="flex justify-between mb-2">
              <div className="text-sm">
                {name.replace('[service charge]()', 'service charge')}
              </div>
              <div className="text-sm">
                {hasAppliedDiscounts &&
                amount_before_discount &&
                amount_before_discount > 0
                  ? formatCentsInDollars(amount_before_discount)
                  : formatCentsInDollars(amount)}
              </div>
            </div>
            {!hasAppliedDiscounts &&
              amount_before_discount &&
              amount_before_discount > 0 && (
                <div className="flex justify-between -mt-2 mb-2">
                  <div className="text-xs text-green-500">
                    {discount_description}
                  </div>
                  <div className="text-xs line-through text-gray-400">
                    {formatCentsInDollars(amount_before_discount)}
                  </div>
                </div>
              )}
          </React.Fragment>
        )
      )}
      {hasAppliedDiscounts &&
        discounts.map(({ amount, name }: LineItem, idx) => (
          <React.Fragment key={idx}>
            <div className="flex justify-between mb-2 text-green-500">
              <div className="text-sm">{name}</div>
              <div className="text-sm">
                {'-' + formatCentsInDollars(amount)}
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  )
}

export default LineItems as FunctionComponent<Props>
