import React, { FunctionComponent, HTMLAttributes } from 'react'

interface Props {
  innerRef?: any
}

const TableRow: FunctionComponent<
  HTMLAttributes<HTMLTableRowElement> & Props
> = ({ children, innerRef, ...otherProps }) => {
  const conditionalAttrs = {
    ...(innerRef && { ref: innerRef })
  }
  return (
    <tr {...conditionalAttrs} {...otherProps}>
      {children}
    </tr>
  )
}

export default TableRow
