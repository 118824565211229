import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  useState
} from 'react'
import { nanoid } from '@reduxjs/toolkit'

import Tab from 'components/tabs/Tab'
import SelectField from 'components/forms/SelectField'

interface Props {
  /**
   * Callback triggered when a tab is clicked. It passes the index of the
   * selected tab. It's up to the parent to implement the selection logic.
   */
  onTabChange: (tabIndex: number) => void
  /**
   * The index of the selected tab.
   */
  selected?: number
  /**
   * A list of items to populate the tabs.
   */
  tabs: ReactNode[]
  /**
   * A label that will be displayed on mobile.
   * This is required because, on mobile, the TabList component becomes a
   * drodpown.
   */
  mobileLabel: ReactNode
}

const TabList: FunctionComponent<Props> = ({
  onTabChange,
  selected,
  tabs,
  mobileLabel
}) => {
  // Set a unique ID for the label and select
  const [fieldId] = useState(nanoid())

  const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.currentTarget.value, 10)
    onTabChange(value)
  }

  return (
    <>
      <div className="hidden sm:block border-b border-gray-200">
        <nav className="flex -mb-px space-x-8">
          {tabs.map((tab, index) => (
            <Tab
              isActive={selected === index}
              onClick={() => onTabChange(index)}
              key={`tab-${index}`}
            >
              {tab}
            </Tab>
          ))}
        </nav>
      </div>
      <div className="block sm:hidden">
        <SelectField
          label={mobileLabel}
          id={fieldId}
          value={selected}
          onChange={handleDropdownChange}
          options={tabs.map((tab, index) => ({ value: index, label: tab }))}
        />
      </div>
    </>
  )
}

export default TabList
