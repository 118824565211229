import React, { FunctionComponent } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import cx from 'classnames'
import MPDFDraggable from './MPDFDraggable'
import { IMasterPDFPage } from 'types/masterPdf'

interface Props {
  fileList: IMasterPDFPage[]
  draggedFileKey: string
  droppableId: string
  height?: string
  multiSelectTo: (key: string) => void
  onCopyFileBtnClicked: (
    file: IMasterPDFPage,
    index: number,
    droppableId: string
  ) => void
  onDeleteFileBtnClicked: (index: number, droppableId: string) => void
  toggleSelectionInGroup: (id: string) => void
  onViewDocumentBtnClicked: (document: IMasterPDFPage) => void
  selectedFileKeys: string[]
}

const MPDFDroppable: FunctionComponent<Props> = ({
  fileList,
  draggedFileKey,
  droppableId,
  height = '100%',
  multiSelectTo,
  onCopyFileBtnClicked,
  onDeleteFileBtnClicked,
  toggleSelectionInGroup,
  onViewDocumentBtnClicked,
  selectedFileKeys
}) => {
  const copyFileBtnClicked = (file: IMasterPDFPage, index: number) => {
    onCopyFileBtnClicked(file, index, droppableId)
  }

  const deleteFileBtnClicked = (index: number) => {
    onDeleteFileBtnClicked(index, droppableId)
  }

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={cx('overflow-scroll', {
            'bg-blue-100': snapshot.isDraggingOver
          })}
          style={{ height }}
        >
          {fileList.map((file, index) => {
            return (
              <MPDFDraggable
                key={file.key}
                draggedFileKey={draggedFileKey}
                file={file}
                index={index}
                isSelected={selectedFileKeys.includes(file.key)}
                keyName={file.key}
                multiSelectTo={multiSelectTo}
                numSelectedFiles={selectedFileKeys.length}
                onCopyFileBtnClicked={copyFileBtnClicked}
                onDeleteFileBtnClicked={deleteFileBtnClicked}
                toggleSelectionInGroup={toggleSelectionInGroup}
                onViewDocumentBtnClicked={onViewDocumentBtnClicked}
              />
            )
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default MPDFDroppable
