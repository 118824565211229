import React, { FunctionComponent, HTMLAttributes, useState } from 'react'
import cx from 'classnames'
import { OptionKeyValue } from '../../types'
import {
  isListOfOptionKeyValues,
  isListOfOptionStrings
} from '../../types/guards'

interface Props {
  onSaveHandler: (keyValue: string, value?: string) => void
  value?: string
  border?: boolean // TODO: Update storybook docs
  keyValue: string
  options: OptionKeyValue[] | string[]
  selected?: string
}

const TableEditableSelectCell: FunctionComponent<
  HTMLAttributes<HTMLTableDataCellElement> & Props
> = ({
  keyValue,
  value,
  className,
  options,
  selected,
  onSaveHandler,
  border = true,
  ...otherProps
}) => {
  const [modifiedValue, setModifiedValue] = useState(value)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  if (isEditing) {
    return (
      <td
        className={cx(
          className,
          { 'border-t border-gray-200': border },
          'text-sm leading-5 px-6 py-4 bg-white flex w-full relative'
        )}
        {...otherProps}
      >
        <select onChange={(event) => setModifiedValue(event.target.value)}>
          {isListOfOptionKeyValues(options) &&
            options.map((opt) => (
              <option
                value={opt.value}
                key={opt.value}
                selected={selected === opt.value}
              >
                {opt.label ?? opt.key}
              </option>
            ))}

          {isListOfOptionStrings(options) &&
            options.map((opt) => (
              <option value={opt} key={opt} selected={selected === opt}>
                {opt}
              </option>
            ))}
        </select>
        <button
          className="absolute right-0 pr-3"
          onClick={() => {
            onSaveHandler(keyValue, modifiedValue)
            setIsEditing(false)
          }}
        >
          Save
        </button>
      </td>
    )
  }

  return (
    <td
      className={cx(
        className,
        { 'border-t border-gray-200': border },
        'text-sm leading-5 px-6 py-4 flex justify-between w-full'
      )}
      {...otherProps}
    >
      {value ? value : <span></span>}
      <button className="pr-3" onClick={() => setIsEditing(true)}>
        Edit
      </button>
    </td>
  )
}

export default TableEditableSelectCell
