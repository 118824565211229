import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  isActive?: boolean
  onClick?: () => void
}

const Tab: FunctionComponent<Props> = ({ children, isActive, onClick }) => {
  const classes = cx(
    'whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5',
    {
      'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300': !isActive,
      'border-blue-500 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-default': isActive
    }
  )

  return (
    <button type="button" onClick={onClick} className={classes}>
      {children}
    </button>
  )
}

export default Tab
