import React, { FunctionComponent } from 'react'
import KaseComment from './KaseComment'
import { Comment } from 'types/kaseOverview'

interface Props {
  comments: Comment[]
  label?: string
  className?: string
}

const KaseComments: FunctionComponent<Props> = ({
  comments,
  label,
  className
}) => {
  return (
    <div className={className}>
      {label && <h2 className="font-bold text-base">{label}</h2>}
      {comments.map((comment, index) => {
        return (
          <KaseComment
            key={`comment-${index}-${comment.created_at}`}
            comment={comment}
          />
        )
      })}
    </div>
  )
}

export default KaseComments
