import React, { FunctionComponent } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import DocumentState from './Columns/DocumentState'
import DocumentSize from './Columns/DocumentSize'
import Table from 'components/tables/Table'

import { simpleFormatDateTime } from 'utils/format_dates'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { IDocument } from 'types/documents'

interface Props {
  document: IDocument
}

const DismissedDocumentRow: FunctionComponent<Props> = ({ document }) => {
  const history = useHistory()
  const { kaseId } = useParams<IKaseIndexRouteParams>()

  return (
    <Table.Row
      className="text-gray-400 cursor-pointer hover:bg-gray-100"
      id={`document-${document.id}`}
      key={document.id}
      onClick={() =>
        history.push(`/document-review/${kaseId}/document/${document.id}`)
      }
    >
      <Table.Cell>{document.file_name}</Table.Cell>
      <Table.Cell>
        <DocumentSize size={parseInt(document.file_size)} />
      </Table.Cell>
      <Table.Cell>{simpleFormatDateTime(document.created_at)}</Table.Cell>
      <Table.Cell className="o-layout--right">
        {document.deleted_at ? (
          <span className="text-gray-400">Deleted by customer</span>
        ) : (
          <DocumentState status={document.state} document={document} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default DismissedDocumentRow as FunctionComponent<Props>
