import React, { ButtonHTMLAttributes, FunctionComponent } from 'react'
import cx from 'classnames'

const ActionMenuButton: FunctionComponent<ButtonHTMLAttributes<
  HTMLButtonElement
>> = ({ children, ...otherProps }) => {
  const { disabled, className } = otherProps

  const classes = cx(
    className,
    'block w-full text-left px-4 py-2 text-sm leading-5 transition duration-100',
    {
      'text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:shadow-outline focus:bg-gray-100 focus:text-gray-900': !disabled,
      'text-gray-400 bg-gray-50 cursor-not-allowed': disabled
    }
  )

  return (
    <button {...otherProps} className={classes} role="menuitem" type="button">
      {children}
    </button>
  )
}

export default ActionMenuButton
