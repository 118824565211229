import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { Link, useParams } from 'react-router-dom'
import { createUpload, updateMasterPDFWithNote } from 'api/masterPdf'
import { fetchOverview } from 'api/overview'
import { MasterPDF } from 'types/kaseOverview'
import { RootState } from 'store/root'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import S3Upload, { SignResult } from 'utils/S3Upload'

import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import BlankModal from 'components/modals/BlankModal'
import Button from 'components/Button'
import Table from 'components/tables/Table'
import TextArea from 'components/forms/TextArea'
import MPDFGenerationSectionTableRow from './MPDFGenerationSectionTableRow'

// Dropzone style structure from https://react-dropzone.js.org/#section-styling-dropzone
const baseStyle = {
  backgroundColor: 'white',
  borderColor: '#D2D6DC',
  borderRadius: 1,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '#1A56DB',
  cursor: 'pointer',
  marginRight: '1rem',
  outline: 'none',
  padding: '10px',
  textAlign: 'center' as const,
  transition: 'border .24s ease-in-out',
  width: '500px'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

interface Props {
  mpdfList: MasterPDF[]
  onViewMpdfBtnClicked: (mpdfUrl: string) => void
}

const MPDFGenerationSection: FunctionComponent<Props> = ({
  mpdfList,
  onViewMpdfBtnClicked
}) => {
  const [isLogModalOpen, setIsLogModalOpen] = useState(false)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)
  const [isMpdfTableLoading, setIsMpdfTableLoading] = useState(false)
  const [currentLog, setCurrentLog] = useState('')
  const [noteField, setNoteField] = useState('')
  const [currentMPDF, setCurrentMPDF] = useState<MasterPDF>()

  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const currentUser = useSelector((state: RootState) => state.currentUser)

  const onDrop = useCallback((acceptedFiles) => {
    setIsMpdfTableLoading(true)

    new S3Upload(
      acceptedFiles,
      +kaseId,
      {
        onUploadMpdfFileComplete: onUploadMpdfFileComplete,
        s3path: `MPDFs/documents/${kaseId}`
      },
      'bulk-mpdf-upload'
    )
  }, [])

  const onUploadMpdfFileComplete = (signResult: SignResult, rawFile: File) => {
    const uploadedFileName = signResult.filename
    createUpload(parseInt(kaseId), {
      created_by: currentUser.fullName,
      file_size: rawFile.size,
      file_s3_direct_path: uploadedFileName
    })
      .then(() => fetchOverview(kaseId).then((response: any) => response))
      .catch((error) => setGlobalError(error))
      .finally(() => setIsMpdfTableLoading(false))
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive && activeStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle)
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const onViewLogBtnClicked = (log: string) => {
    setCurrentLog(log)
    setIsLogModalOpen(true)
  }

  const onClickNoteModal = (mpdf: MasterPDF) => {
    setIsNoteModalOpen(true)
    setNoteField(mpdf.admin_note)
    setCurrentMPDF(mpdf)
  }

  const onClickSaveNote = () => {
    updateMasterPDFWithNote({
      admin_note: noteField,
      id: currentMPDF?.id,
      kase_id: currentMPDF?.kase_id
    })
  }

  return (
    <>
      {/* Log modal */}
      <BlankModal
        isOpen={isLogModalOpen}
        modalWidth={'80%'}
        onRequestClose={() => setIsLogModalOpen(false)}
      >
        <div className="p-6">
          <h3 className="text-lg text-gray-800 leading-6 font-medium mb-2">
            View debug logs
          </h3>
          <pre className="text-sm text-gray-600 leading-5 mb-2">
            {currentLog}
          </pre>
          <div className="mt-6 space-x-3">
            <Button onClick={() => setIsLogModalOpen(false)}>Cancel</Button>
          </div>
        </div>
      </BlankModal>
      {/* note modal */}
      <BlankModal
        isOpen={isNoteModalOpen}
        onRequestClose={() => setIsNoteModalOpen(false)}
      >
        <div className="p-6">
          <h3 className="text-lg text-gray-800 leading-6 font-medium mb-4">
            Edit Notes
          </h3>
          <TextArea
            className="h-48 mb-6"
            onChange={(e) => setNoteField(e.target.value)}
            placeholder="Add notes..."
            value={noteField ? noteField : ''}
          />
          <Button
            onClick={onClickSaveNote}
            disabled={currentMPDF?.admin_note === noteField}
            variant="primary"
          >
            Save
          </Button>
          <Button onClick={() => setIsNoteModalOpen(false)} className="ml-6">
            Cancel
          </Button>
        </div>
      </BlankModal>
      <div className="mx-4 mb-8">
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-base my-8">MPDF Generation</h2>
          <div className="flex">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Click or drag file to upload</p>
            </div>
            <Link
              to={`/mpdf/${kaseId}`}
              className="inline-flex items-center text-blue-600 hover:text-blue-800"
            >
              New Master PDF
            </Link>
          </div>
        </div>
        <div className="border border-gray-300">
          <Table isLoading={isMpdfTableLoading} shadow>
            <Table.Head>
              <Table.Row>
                <Table.Header>Created at/by</Table.Header>
                <Table.Header>Note</Table.Header>
                <Table.Header>Source</Table.Header>
                <Table.Header>Size</Table.Header>
                <Table.Header>Status</Table.Header>
                <Table.Header>Actions</Table.Header>
                <Table.Header></Table.Header>
                <Table.Header></Table.Header>
                <Table.Header></Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {mpdfList.map((mpdf) => {
                return (
                  <MPDFGenerationSectionTableRow
                    key={mpdf.id}
                    kaseId={kaseId}
                    mpdf={mpdf}
                    onClickNoteModal={onClickNoteModal}
                    onViewLogBtnClicked={onViewLogBtnClicked}
                    onViewMpdfBtnClicked={onViewMpdfBtnClicked}
                  />
                )
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>
  )
}

export default MPDFGenerationSection
