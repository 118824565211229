import axios from 'axios'
import server from './server'
import {
  setAuthJWT,
  saveCurrentUserToLocalStorage,
  clearUserData,
  clearAuthJWT
} from './jwt'
import {
  setCurrentUserData,
  clearCurrentUserData
} from 'store/reducers/currentUser'
import store from 'store/root'
import { CurrentUser } from 'types/currentUser'

export interface UserData {
  email: string
  password: string
}

/**
 * Note that this endpoint doesn't need to pass a JWT with the request, so we
 * use Axios directly.
 */
export async function signIn(user: UserData) {
  return axios
    .post(
      `${process.env.API_ORIGIN}/api/v1/admin/sign_in.json`,
      { user },
      {
        headers: { Accept: 'application/json' }
      }
    )
    .then(({ headers, data }) => {
      // Store the JWT in localStorage
      setAuthJWT(headers['authorization'])

      // Parse the current user's data
      const currentUser: CurrentUser = {
        id: `${data.id}`,
        fullName: data.full_name,
        email: data.email,
        roles: data.active_roles?.map((role: any) => role.name)
      }

      // Save the user data in localstorage
      saveCurrentUserToLocalStorage(currentUser)

      return Promise.resolve(currentUser)
    })
}

export async function signOut() {
  return server
    .get(`${process.env.API_ORIGIN}/api/v1/admin/sign_out`)
    .then(() => {
      clearAuthJWT()
      clearUserData()

      // Clear the Redux store
      store.dispatch(clearCurrentUserData())
    })
}

/**
 * Checks if current user is admin, authenticated and on VPN.
 * Used by PrivateRoute wrapper to only render admin routes
 * when the user is behind a login-wall and VPN-wall.
 *
 * @returns a promise wrapping the response from a call to the
 * /api/admin/me endpoint in apply
 */
export async function checkCurrentUserAuth() {
  return server
    .get(`${process.env.API_ORIGIN}/api/v1/admin/me`, {
      // TODO remove this header override once we make the /me endpoint accept
      // the correct application/vnd.api+json Accept header
      headers: { Accept: 'application/json' }
    })
    .then(({ data }) => {
      const currentUser: CurrentUser = {
        id: `${data.id}`,
        fullName: data.full_name,
        email: data.email,
        roles: data.active_roles?.map((role: any) => role.name)
      }

      // Save the user data in localstorage
      saveCurrentUserToLocalStorage(currentUser)

      // Update the Redux store
      store.dispatch(setCurrentUserData(currentUser))

      return Promise.resolve()
    })
}
