import React, { FunctionComponent, useState, useEffect } from 'react'
import { RootState } from 'store/root'
import { useSelector } from 'react-redux'
import { getFilteredTasks, transitionTask } from 'api/tasks'
import { fetchAllAdmins } from 'api/adminUsers'
import { Task } from 'types/task'
import { AdminUser } from 'types/users'
import ReactPaginate from 'react-paginate'
import TasksFilters from './TasksFilters'
import TasksTable from './TasksTable'
import TasksTabs from './TasksTabs'
import MainLayout from 'layouts/MainLayout'
import TasksAssignControls from './TasksAssignControls'
import TasksAssignModal from './TasksAssignModal'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { fetchRoles } from 'api/roles'

const TasksDashboard: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()

  const admins = useSelector((state: RootState) => state.adminUsers)
  const tasks = useSelector((state: RootState) => state.kaseTasks)
  const roles = useSelector((state: RootState) => state.adminRoles.roles)
  const [adminsList, setAdminsList] = useState<AdminUser[]>([])
  // Filtered tasks will contain tasks that have been filtered but also
  // tasks on the current page
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([])
  // --- FILTERS ---
  // We keep track of the filters in local state so that we can sync the filtered
  // tasks whenever the redux store of tasks changes
  const [isAssignedFilter, setIsAssignedFilter] = useState<boolean | undefined>(
    undefined
  )
  const [isAssignedToMeFilter, setIsAssignedToMeFilter] = useState(false)
  const [taskTypeFilter, setTaskTypeFilter] = useState('')
  // Limit is the number of tasks that show up on each page
  const [limitFilter] = useState(50)
  const [offsetFilter, setOffsetFilter] = useState(0)
  // --- END FILTERS ---
  const [isLoading, setIsLoading] = useState(false)
  const [myTaskCount, setMyTaskCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([])
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [currentPaginationCount, setCurrentPaginationCount] = useState(0)
  const [tabIndex, setTabIndex] = useState(0)

  // Fetch all admins and roles on page load for assignment modal and dropdowns
  useEffect(() => {
    if (admins.allIds.length === 0) {
      fetchRoles()
      fetchAllAgents()
    }
  }, [])

  useEffect(() => {
    setAdminsList(admins.allIds.map((id) => admins.byId[id]))
  }, [admins])

  // Whenever the redux store of tasks is updated, we sync the local state of filtered
  // tasks using the current filters
  useEffect(() => {
    getFilteredTasks({
      isAssigned: isAssignedFilter,
      isAssignedToMe: isAssignedToMeFilter,
      taskType: taskTypeFilter,
      limit: limitFilter,
      offset: offsetFilter
    })
      .then((data) => {
        setFilteredTasks(data.data)
        setMyTaskCount(data.assigned_to_me_count)
        setCurrentPaginationCount(
          tabIndex === 0 ? data.total_count : data.assigned_to_me_count
        )
      })
      .catch((error) => setGlobalError(error))
  }, [tasks])

  /**
   * Sets the 'filteredTasks' state by whether or not the tasks are assigned and sets the
   * 'assignedFilter' state
   *
   * @param isAssigned - A boolean which determines if the tasks should be filtered by
   * whether or not they are assigned. A value of 'undefined' will return tasks regardless
   * of whether or not they are assigned
   *
   */
  const onFilterAssignmentStatusChange = (isAssigned: boolean | undefined) => {
    getFilteredTasks({
      isAssigned: isAssigned,
      isAssignedToMe: isAssignedToMeFilter,
      taskType: taskTypeFilter,
      limit: limitFilter,
      offset: offsetFilter
    })
      .then((data) => {
        setIsAssignedFilter(isAssigned)
        setFilteredTasks(data.data)
        setMyTaskCount(data.assigned_to_me_count)
      })
      .catch((error) => setGlobalError(error))
  }

  /**
   * Sets the 'filteredTasks' state by the tasks type and sets the
   * 'taskFilter' state
   *
   * @param taskType - A string value of the type of task to filter by. An empty string value will return
   * tasks of all types
   *
   */
  const onFilterTaskTypeChange = (taskType: string) => {
    getFilteredTasks({
      isAssigned: isAssignedFilter,
      isAssignedToMe: isAssignedToMeFilter,
      taskType: taskType,
      limit: limitFilter,
      offset: offsetFilter
    })
      .then((data) => {
        setTaskTypeFilter(taskType)
        setFilteredTasks(data.data)
        setMyTaskCount(data.assigned_to_me_count)
      })
      .catch((error) => setGlobalError(error))
  }

  const decrementMyTaskCount = () => {
    setMyTaskCount(myTaskCount - 1)
  }

  const fetchAllAgents = () => {
    fetchAllAdmins()
  }

  const loadTasks = (selectedPage: number) => {
    setIsLoading(true)

    const isAssignedToMe = tabIndex === 1
    const offset = selectedPage * limitFilter

    getFilteredTasks({
      isAssigned: isAssignedFilter,
      isAssignedToMe: isAssignedToMe,
      taskType: taskTypeFilter,
      limit: limitFilter,
      offset: offset
    })
      .then((data) => {
        setIsAssignedToMeFilter(isAssignedToMe)
        setOffsetFilter(offset)
        setFilteredTasks(data.data)
        setMyTaskCount(data.assigned_to_me_count)
        setCurrentPaginationCount(
          tabIndex === 0 ? data.total_count : data.assigned_to_me_count
        )
        fetchAllAgents()
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => setGlobalError(error))
  }

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected)
    loadTasks(selectedItem.selected)
  }

  const handleTaskCheck = (id: string) => {
    const isSelected = selectedTasks.some((task) => task.id === id)
    if (!isSelected) {
      const tasksToSet = [...selectedTasks]
      const foundTask = filteredTasks.find((task) => task.id === id)
      if (foundTask) tasksToSet.push(foundTask)
      setSelectedTasks(tasksToSet)
    } else {
      setSelectedTasks([...selectedTasks.filter((task) => task.id !== id)])
    }
  }

  const handleAssignSelectedButtonClicked = () => {
    setShowAssignModal(true)
  }

  const handleTaskAssignModalAssignButtonClick = (adminId: string) => {
    setShowAssignModal(false)
    setIsLoading(true)
    // TODO: the api will eventually allow us to post an array of tasks
    Promise.all(
      selectedTasks.map((selectedTask) =>
        transitionTask({
          taskId: selectedTask.id,
          assigneeId: adminId,
          state: 'assign'
        })
      )
    )
      // Fetch admins to update their relationship table to assigned tasks
      .then(() => fetchAllAgents())
      .finally(() => {
        setSelectedTasks([])
        setIsLoading(false)
      })
      .catch((error) => setGlobalError(error))
  }

  const handleUnassignSelectedButtonClicked = async () => {
    setIsLoading(true)
    Promise.all(
      selectedTasks.map((task) =>
        transitionTask({ taskId: task.id, assigneeId: null, state: 'unassign' })
      )
    )
      // Fetch admins to update their relationship table to assigned tasks
      .then(() => fetchAllAgents())
      .finally(() => {
        setSelectedTasks([])
        setIsLoading(false)
      })
      .catch((error) => setGlobalError(error))
  }

  const onRequestTasksAssignModalClose = () => {
    setShowAssignModal(false)
  }

  /**
   * Sets the task filtering based on what tab is clicked on
   *
   * @param newTabIndex - The tab index that the user has just clicked on
   *
   */
  const handleTabChange = (newTabIndex: number) => {
    const isAssignedToMe = newTabIndex === 1

    if (newTabIndex === tabIndex) {
      return
    }

    setIsLoading(true)
    getFilteredTasks({
      isAssigned: isAssignedFilter,
      isAssignedToMe: isAssignedToMe,
      taskType: taskTypeFilter,
      limit: limitFilter,
      offset: offsetFilter
    })
      .then((data) => {
        setIsAssignedToMeFilter(isAssignedToMe)
        setFilteredTasks(data.data)
        setMyTaskCount(data.assigned_to_me_count)
        setCurrentPaginationCount(
          newTabIndex === 0 ? data.total_count : data.assigned_to_me_count
        )
        setSelectedTasks([])
        setIsLoading(false)
      })
      .finally(() => {
        setTabIndex(newTabIndex)
        setCurrentPage(0)
      })
      .catch((error) => setGlobalError(error))
  }

  const pageCount = Math.ceil(currentPaginationCount / limitFilter)

  return (
    <MainLayout>
      <div className="p-4">
        <TasksTabs
          myTaskCount={myTaskCount}
          tabIndex={tabIndex}
          handleTabChange={handleTabChange}
        />
        <div className="flex justify-between items-center mb-10">
          <TasksFilters
            onFilterAssignmentStatusChange={onFilterAssignmentStatusChange}
            onFilterTaskTypeChange={onFilterTaskTypeChange}
            tabIndex={tabIndex}
          />
          {tabIndex === 0 ? ( // Only render this in the 'Task Assignment' tab
            <TasksAssignControls
              numSelected={selectedTasks.length}
              handleAssignSelectedButtonClicked={
                handleAssignSelectedButtonClicked
              }
              handleUnassignSelectedButtonClicked={
                handleUnassignSelectedButtonClicked
              }
            />
          ) : null}
        </div>
        <TasksTable
          isLoading={isLoading}
          filteredTasks={filteredTasks}
          adminUsers={adminsList}
          handleTaskCheck={handleTaskCheck}
          decrementMyTaskCount={decrementMyTaskCount}
          tabIndex={tabIndex}
          selectedTasks={selectedTasks}
          roles={roles}
        />
        {pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            activeClassName={'font-bold text-blue-600'}
            pageClassName={'mx-2'}
            nextClassName={'ml-2'}
            previousClassName={'mr-2'}
            containerClassName={'flex flex-row mt-16 text-sm'}
          />
        )}
      </div>
      <TasksAssignModal
        show={showAssignModal}
        onRequestClose={onRequestTasksAssignModalClose}
        adminUsers={adminsList}
        selectedTasks={selectedTasks}
        handleTaskAssignModalAssignButtonClick={
          handleTaskAssignModalAssignButtonClick
        }
      />
    </MainLayout>
  )
}

export default TasksDashboard as FunctionComponent
