import React, { FunctionComponent } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'

import DocumentState from './Columns/DocumentState'
import DocumentSize from './Columns/DocumentSize'
import CheckboxField from 'components/forms/CheckboxField'
import Table from 'components/tables/Table'
import RefreshIcon from 'components/icons/RefreshIcon'

import { simpleFormatDateTime } from 'utils/format_dates'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { IDocument } from 'types/documents'

interface Props {
  checkboxChecked: boolean
  document: IDocument
  onDocumentCheckboxClicked: (docRequest: IDocument) => void
  provided?: any
  snapshot?: any
}

const DocumentRow: FunctionComponent<Props> = ({
  checkboxChecked,
  document,
  onDocumentCheckboxClicked,
  provided,
  snapshot
}) => {
  const history = useHistory()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const { created_at, file_name, file_size, state } = document

  const preventOpenDoc = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const label = (
    <div className="flex flex-col">
      {file_name}
      {document.replaced_at !== null && (
        <div className="flex font-normal items-center mt-1 text-xs text-gray-500">
          <RefreshIcon className="inline" />
          Replaced
        </div>
      )}
    </div>
  )

  const isDragging = snapshot && snapshot.isDragging
  const conditionalAttrs = {
    ...(isDragging && { border: false })
  }

  return (
    <Table.Row
      {...(provided && {
        ...provided.draggableProps,
        ...provided.dragHandleProps,
        innerRef: provided.innerRef
      })}
      onClick={() =>
        history.push(`/document-review/${kaseId}/document/${document.id}`)
      }
      className={cx('cursor-pointer hover:bg-gray-100', {
        'table bg-indigo-100': isDragging
      })}
    >
      <Table.Cell style={{ width: '40%' }} {...conditionalAttrs}>
        <div className="flex items-start">
          <div className="flex items-center h-5" onClick={preventOpenDoc}>
            <CheckboxField
              checked={checkboxChecked}
              label=""
              onChange={() => onDocumentCheckboxClicked(document)}
            />
          </div>
          <div>{label}</div>
        </div>
      </Table.Cell>
      <Table.Cell style={{ width: '15%' }} {...conditionalAttrs}>
        <DocumentSize size={parseInt(file_size)} />
      </Table.Cell>
      <Table.Cell style={{ width: '20%' }} {...conditionalAttrs}>
        {simpleFormatDateTime(created_at)}
      </Table.Cell>
      <Table.Cell style={{ width: '25%' }} {...conditionalAttrs}>
        <DocumentState status={state} document={document} />
      </Table.Cell>
    </Table.Row>
  )
}

export default DocumentRow as FunctionComponent<Props>
