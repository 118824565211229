import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  noBorder?: boolean
}

const Body: FunctionComponent<Props> = ({ noBorder, children, className }) => {
  const classes = cx('p-6 text-sm leading-5', {
    [className as any]: !!className,
    'border-t border-gray-200': !noBorder
  })

  return <div className={classes}>{children}</div>
}

export default Body as FunctionComponent<Props>
