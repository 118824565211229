import React, { FunctionComponent, InputHTMLAttributes, useState } from 'react'
import cx from 'classnames'
import Label from './Label'
import { nanoid } from '@reduxjs/toolkit'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * If true, the field will be displayed with a red border
   */
  hasError?: boolean
  label?: string
}

const TextInput: FunctionComponent<Props> = ({
  type = 'text',
  hasError = false,
  label,
  ...otherProps
}) => {
  const { id, disabled, readOnly, className } = otherProps
  const [uniqueId] = useState(nanoid())

  const fieldId = id || uniqueId

  const classes = cx(
    className,
    'w-full border px-3 py-2 rounded focus:outline-none focus:shadow-outline focus:border-blue-500',
    {
      'border-gray-300 hover:border-gray-400':
        !hasError && !readOnly && !disabled,
      'border-red-400': hasError,
      'bg-gray-100': readOnly || disabled,
      'cursor-not-allowed': disabled
    }
  )
  return (
    <>
      {label && (
        <Label inline={false} htmlFor={fieldId}>
          {label}
        </Label>
      )}
      <input {...otherProps} id={fieldId} type={type} className={classes} />
    </>
  )
}

export default TextInput
