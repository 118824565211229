import React, { FunctionComponent, ReactNode } from 'react'
import TopNavigation from 'components/TopNavigation'
import GlobalErrorBanner from 'components/errors/GlobalErrorBanner'
interface Props {
  footer?: ReactNode
  subNavigation?: ReactNode
}

const MainLayout: FunctionComponent<Props> = ({
  children,
  footer,
  subNavigation
}) => {
  return (
    <div className="h-screen flex flex-col">
      <TopNavigation />
      {subNavigation}
      <div className="h-screen flex bg-gray-100 overflow-hidden">
        <main className="w-full overflow-y-auto">
          <GlobalErrorBanner />
          {/* https://github.com/w3c/csswg-drafts/issues/129 */}
          {children}
        </main>
      </div>
      {footer}
    </div>
  )
}

export default MainLayout
