import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/root'
import { getOpenIssues } from 'store/selectors/kaseIssues'

import AssignTaskToCustomer from './AssignTaskToCustomer'
import ResolveWithoutIssues from './ResolveWithoutIssues'
import ResolveQATask from './ResolveQATask'
import Can from 'components/Can'

interface Props {
  currentOpenTaskId?: string
  kaseId: string
  taskAssignedToCustomer: boolean
}

const UpdateTask: FunctionComponent<Props> = ({
  currentOpenTaskId,
  kaseId
}) => {
  const openIssues = useSelector((state: RootState) => getOpenIssues(state))
  const issues = useSelector((state: RootState) => state.kaseIssues.issues)

  const completeTaskActionComponent = () => {
    return (
      <>
        {/* If there are no issues, display ability to resolve qa without opening issues */}
        {issues.allIds.length === 0 && <ResolveWithoutIssues kaseId={kaseId} />}
        {/* If there are open issues, display button to assign to customer OR if there are no issues
        display disabled button  */}
        {openIssues.length > 0 && (
          <AssignTaskToCustomer
            openIssues={openIssues}
            kaseId={kaseId}
            currentOpenTaskId={currentOpenTaskId}
          />
        )}
        {/* If there are no open issues, but issues have been opened, display button to resolve task */}
        {openIssues.length === 0 && issues.allIds.length > 0 && (
          <ResolveQATask kaseId={kaseId} />
        )}
      </>
    )
  }

  return <Can perform="task:update" yes={completeTaskActionComponent()} />
}

export default UpdateTask
