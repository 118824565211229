import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Comment, KaseOverview, MasterPDF } from 'types/kaseOverview'
interface CommentReturnShape {
  attributes: {
    content: string
    created_at: string
    created_by: string
    pinned: boolean
    id: number
  }
}

const initialState: KaseOverview = {
  admin_metadata: [],
  comments: [],
  contact_info: {},
  extra_kase_ids: [],
  form_names: [],
  information: [],
  is_waiver_eligible: false,
  lawyer_interactions: {},
  logs: [],
  masquerade_token: '',
  master_pdfs: [],
  paid: false,
  payments: [],
  state: '',
  tier: undefined
}

const kaseOverviewSlice = createSlice({
  name: 'kaseOverview',
  initialState,
  reducers: {
    setKaseOverview: (
      state: KaseOverview,
      action: PayloadAction<KaseOverview>
    ) => action.payload,
    setComment: (
      state: KaseOverview,
      action: PayloadAction<CommentReturnShape>
    ) => {
      const newComment: Comment = {
        content: action.payload.attributes.content,
        created_at: action.payload.attributes.created_at,
        id: action.payload.attributes.id,
        pinned: action.payload.attributes.pinned,
        user: action.payload.attributes.created_by
      }

      state.comments = [newComment, ...state.comments]
    },
    updateCommentsAfterDelete: (
      state: KaseOverview,
      action: PayloadAction<CommentReturnShape>
    ) => {
      const deletedComment = action.payload.attributes
      const newComments = state.comments.filter(
        (comment) => comment.id !== deletedComment.id
      )

      state.comments = newComments
    },
    updateCommentsAfterPin: (
      state: KaseOverview,
      action: PayloadAction<CommentReturnShape>
    ) => {
      const pinnedComment = action.payload.attributes
      const newComments = state.comments.map((comment) => {
        if (comment.id === pinnedComment.id) {
          comment.pinned = pinnedComment.pinned
        }
        return comment
      })

      state.comments = newComments
    },
    updateMasterPDF: (
      state: KaseOverview,
      action: PayloadAction<MasterPDF>
    ) => {
      const indexToUpdate = state.master_pdfs.findIndex(
        (pdf) => pdf.id === action.payload.id
      )

      state.master_pdfs[indexToUpdate] = action.payload
    }
  }
})

export const {
  setComment,
  setKaseOverview,
  updateCommentsAfterDelete,
  updateCommentsAfterPin,
  updateMasterPDF
} = kaseOverviewSlice.actions

export default kaseOverviewSlice.reducer
