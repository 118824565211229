import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperProps } from 'components/icons/IconWrapper'

const ChatIcon: FunctionComponent<IconWrapperProps> = (props) => (
  <IconWrapper {...props}>
    <path
      d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </IconWrapper>
)

export default ChatIcon
