import React from 'react'

import Card from 'components/Card'
import { ServiceCheckoutDetails, TieredCheckoutDetails } from 'types/payment'
import Button from 'components/Button'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

interface Props {
  checkoutDetails: TieredCheckoutDetails
  selectedTier: ServiceCheckoutDetails | null
  onClickServiceTierOption: (
    selection: ServiceCheckoutDetails,
    name: 'essential' | 'premium'
  ) => void
  onClickUpdateServiceTier: () => void
}

const ServiceTierSelect: React.FC<Props> = ({
  checkoutDetails,
  selectedTier,
  onClickServiceTierOption,
  onClickUpdateServiceTier
}) => {
  const featureItem = (text: string) => {
    return <li className="flex mb-2">{text}</li>
  }

  const essentialFeatures = (
    <>
      {featureItem('All USCIS forms')}
      {featureItem('Personalized document list')}
      {featureItem('Live support, 7 days a week')}
      {featureItem('Multiple quality reviews')}
      {featureItem('Printing and assembly')}
    </>
  )

  const premiumFeaturesFull = (
    <>
      {essentialFeatures}
      {featureItem('One video consultation with an attorney')}
      {featureItem('Attorney review of your full application')}
      {featureItem('Interview prep with an attorney')}
    </>
  )
  const { information } = useSelector((state: RootState) => state.kaseOverview)
  const kaseType = information.find((info) => info.key === 'Kase Kind')?.value

  const getKaseTypeString = (kaseType: string | undefined) => {
    switch (kaseType) {
      case 'K1':
        return 'K1 Fiance Visa'
      case 'B1B2':
        return 'B1/B2 Travel Visa'
      default:
        return 'Green Card'
    }
  }

  if (selectedTier) {
    return (
      <Card selected>
        <Card.Header>Select a Boundless Service</Card.Header>
        <Card.Body>
          <div className="flex justify-between font-bold mt-2">
            <h3 className="text-base">{selectedTier.readable_service_name}</h3>
            <Button
              variant="primary"
              onClick={onClickUpdateServiceTier}
              className="relative z-2"
            >
              Update Service Selection
            </Button>
          </div>
        </Card.Body>
      </Card>
    )
  }

  return (
    <div className="mb-4">
      <Card>
        <Card.Header>Select a Boundless Service</Card.Header>
        <Card.Body>
          <p className="font-bold">
            Choose the service that’s right for your{' '}
            {getKaseTypeString(kaseType)} Application
          </p>
        </Card.Body>
        <Card.Body>
          <div className="flex justify-around mt-2">
            <div>
              <Button
                variant="primary"
                onClick={() =>
                  onClickServiceTierOption(
                    checkoutDetails.essential,
                    'essential'
                  )
                }
                className="relative z-2 uppercase"
              >
                Essential Service
              </Button>
              <p className="text-gray-500 text-xs">
                Starting at ${checkoutDetails.essential.total_boundless_fees}
              </p>
              <h3 className="font-bold mt-4">Features include:</h3>
              <ul className="c-type c-type--body-sans-sm o-list-bare mt-2">
                {essentialFeatures}
              </ul>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() =>
                  onClickServiceTierOption(checkoutDetails.premium, 'premium')
                }
                className="relative z-2 uppercase"
              >
                Premium Service
              </Button>
              <p className="text-gray-500 text-xs">
                Starting at ${checkoutDetails.premium.total_boundless_fees}
              </p>
              <h3 className="font-bold mt-4">Features include:</h3>
              <ul className="c-type c-type--body-sans-sm mt-2">
                {premiumFeaturesFull}
              </ul>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ServiceTierSelect as React.FC<Props>
