import React, { FunctionComponent, InputHTMLAttributes } from 'react'

import cx from 'classnames'

type Props = InputHTMLAttributes<HTMLTextAreaElement> & {
  /**
   * If true, the field will be displayed with a red border
   */
  hasError?: boolean
}

const TextArea: FunctionComponent<Props> = ({ hasError, ...otherProps }) => {
  const { disabled, readOnly, className } = otherProps

  const classes = cx(
    className,
    'w-full border px-3 py-2 rounded focus:outline-none focus:shadow-outline focus:border-blue-500',
    {
      'border-gray-300 hover:border-gray-400':
        !hasError && !readOnly && !disabled,
      'border-red-400': hasError,
      'bg-gray-100': readOnly || disabled,
      'cursor-not-allowed': disabled
    }
  )

  return <textarea {...otherProps} className={classes} />
}

export default TextArea
