import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import cx from 'classnames'

import SelectField from 'components/forms/SelectField'
import TextArea from 'components/forms/TextArea'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import BlankModal from 'components/modals/BlankModal'
import Button from 'components/Button'

import { docHasOpenIssues } from '../Tool/utils'
import { fileSizeToReadable } from 'utils/format_file_size'
import { DocumentStateValues } from 'constants/documents'
import { IPartContent } from 'types/documents'

interface Props {
  partContent: IPartContent
  documentFileNameChanged: (docId: number | string, state: string) => void
  metaAnswerChanged: (
    documentMetaAnswerId: number | string,
    text: string
  ) => void
  onDeleteDocumentBtnClicked: (docId: number) => void
  onDocumentReplaced: (file: File, replacedDocId: number) => void
  onStateSelectChanged: (docId: number | string, state: string) => void
}

const DocumentViewSidebar: FunctionComponent<Props> = ({
  partContent,
  documentFileNameChanged,
  metaAnswerChanged,
  onDeleteDocumentBtnClicked,
  onDocumentReplaced,
  onStateSelectChanged
}) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const document = partContent.document
  const [fileName, setFileName] = useState('')
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(
    false
  )
  const [isForbiddenModalOpen, setIsForbiddenModalOpen] = useState(false)
  const [isEditingFileName, setIsEditingFileName] = useState(false)
  const [metaAnswer, setMetaAnswer] = useState('')
  const [metaAnswerEditId, setMetaAnswerEditId] = useState(-1)

  const kaseIssues = useSelector((state: RootState) => state.kaseIssues)

  const sidebarHeaderClassnames = 'text-base font-bold mt-6'
  const editDocumentDataBtnClassnames = 'whitespace-normal break-all text-left'

  useEffect(() => {
    setFileName(document.file_name)
  }, [partContent])

  const stateSelectChanged = (state: string) => {
    onStateSelectChanged(document.id, state)
  }

  const onFileNameChangeBlur = () => {
    saveDocumentFileName()
  }

  const onFileNameChangeEnterKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      saveDocumentFileName()
    }
  }

  const saveDocumentFileName = () => {
    setIsEditingFileName(false)
    documentFileNameChanged(document.id, fileName)
  }

  const showDeleteModal = () => {
    if (docHasOpenIssues(document, kaseIssues)) {
      setIsForbiddenModalOpen(true)
    } else {
      setIsDeleteDocumentModalOpen(true)
    }
  }

  const onDeleteDocumentModalConfirmBtnClicked = () => {
    onDeleteDocumentBtnClicked(document.id)

    setIsDeleteDocumentModalOpen(false)
  }

  const onUploadFileBtnClicked = () => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }

  const uploadFileChanged = () => {
    if (inputFile.current && inputFile.current.files && document) {
      onDocumentReplaced(inputFile.current.files[0], document.id)
    }
  }

  const selectFieldStateOptions = Object.entries(DocumentStateValues).map(
    (stateValue) => {
      return { label: stateValue[1], value: stateValue[0] }
    }
  )

  const onEditMetaAnswerBtnClicked = (
    metaAnswerId: number,
    metaAnswer: string
  ) => {
    setMetaAnswer(metaAnswer)
    setMetaAnswerEditId(metaAnswerId)
  }

  const onMetaAnswerChangeBlur = () => {
    saveMetaAnswer()
  }

  const onMetaAnswerChangeEnterKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      saveMetaAnswer()
    }
  }

  const saveMetaAnswer = () => {
    metaAnswerChanged(metaAnswerEditId, metaAnswer)
    setMetaAnswerEditId(-1)
  }

  return (
    <div className="bg-gray-100 ml-8 p-4 min-h-full">
      <ConfirmationModal
        isOpen={isDeleteDocumentModalOpen}
        title="Delete document"
        description="Are you sure you want to delete this document? This document will be gone forever."
        dangerousOperation={true}
        onConfirm={() => onDeleteDocumentModalConfirmBtnClicked()}
        onRequestClose={() => setIsDeleteDocumentModalOpen(false)}
        confirmLabel="Yes, delete this document"
      />
      <BlankModal
        isOpen={isForbiddenModalOpen}
        onRequestClose={() => setIsForbiddenModalOpen(false)}
      >
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <p className="text-sm text-gray-600 leading-5 pr-5">
            There are <strong>open issues</strong> on this document. These
            issues must be deleted or resolved before the document can be
            deleted.
          </p>
        </div>
      </BlankModal>
      <h1 className="text-xs font-normal text-gray-700">General Info</h1>
      <hr />
      <h2 className={sidebarHeaderClassnames}>Rename</h2>
      {isEditingFileName ? (
        <TextArea
          onBlur={onFileNameChangeBlur}
          onKeyDown={(e) => onFileNameChangeEnterKeyPress(e)}
          onChange={(e) => setFileName(e.target.value)}
          value={fileName}
        />
      ) : (
        <Button
          className={editDocumentDataBtnClassnames}
          variant="plain"
          whiteSpaceWrap
          onClick={() => setIsEditingFileName(true)}
        >
          {fileName}
        </Button>
      )}
      <h2 className={sidebarHeaderClassnames}>Size</h2>
      <span className="text-sm font-medium">
        {fileSizeToReadable(parseInt(document ? document.file_size : '0'))}
      </span>
      <h2 className={sidebarHeaderClassnames}>Editing</h2>
      {/* Commenting out for now, will have the feature implemented in the future */}
      {/* <Button variant="plain">Download Document</Button> */}
      {/* <br /> */}
      <input
        accept="application/pdf, image/png, image/jpeg"
        className="hidden"
        id="replace-document-input"
        ref={inputFile}
        type="file"
        onChange={uploadFileChanged}
      />
      <label htmlFor="replace-document-input">
        <Button onClick={onUploadFileBtnClicked} variant="plain">
          Upload
        </Button>
      </label>
      <span className="text-gray-400 ml-2 mr-2">|</span>
      <Button variant="plain" onClick={() => showDeleteModal()}>
        Delete
      </Button>
      <br />
      <span className="text-xs font-normal text-gray-700">
        Note: re-uploading will dismiss this file
      </span>
      <h2 className={sidebarHeaderClassnames}>Status</h2>
      {document.deleted_at ? (
        <h2>
          Deleted by Customer
          <br />
          State: {DocumentStateValues[document.state]}
        </h2>
      ) : (
        <SelectField
          options={selectFieldStateOptions}
          value={document.state}
          onChange={(event) => stateSelectChanged(event.target.value)}
        />
      )}
      <h1 className="text-xs font-normal text-gray-700 mt-6">Metadata</h1>
      <hr
        className={cx({ 'mb-32': partContent.meta_questions.length === 0 })}
      />
      {partContent.meta_questions.map((metaQuestion) => {
        const {
          document_meta_answer_id,
          input_value,
          key,
          markdown
        } = metaQuestion.attributes
        return (
          <div key={key}>
            <h2 className={sidebarHeaderClassnames}>{markdown}</h2>
            {metaAnswerEditId === document_meta_answer_id ? (
              <TextArea
                onBlur={onMetaAnswerChangeBlur}
                onKeyDown={(e) => onMetaAnswerChangeEnterKeyPress(e)}
                onChange={(e) => setMetaAnswer(e.target.value)}
                value={metaAnswer}
              />
            ) : (
              <Button
                className={editDocumentDataBtnClassnames}
                variant="plain"
                whiteSpaceWrap
                onClick={() =>
                  onEditMetaAnswerBtnClicked(
                    document_meta_answer_id,
                    input_value
                  )
                }
              >
                {input_value}
              </Button>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default DocumentViewSidebar as FunctionComponent<Props>
