import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'
interface Props {
  icon: ReactNode
  title: ReactNode | string
  className: string
}

const TaskStatusIndicator: FunctionComponent<Props> = ({
  icon,
  title,
  className
}) => {
  const headerClasses = cx(
    'rounded-t-sm px-4 py-2 text-sm font-medium',
    className
  )

  return (
    <header className={headerClasses}>
      <p className="flex items-center">
        {icon}
        {title}
      </p>
    </header>
  )
}

export default TaskStatusIndicator
