import React, { FunctionComponent } from 'react'
import {
  capitalize,
  formatIfDate,
  getTimeFromDateString
} from 'utils/format_utils'
import { formatKaseEvent } from './utils'
import { Log } from 'types/kaseOverview'

interface Props {
  log: Log
  className?: string
}

const KaseLog: FunctionComponent<Props> = ({ className, log }) => {
  return (
    <div className={className}>
      <div className="border border-gray-300 mt-4 p-4">
        <strong>Id:</strong> {log.id}
        <br />
        <strong>Kase Event:</strong> {formatKaseEvent(log.type)}
        {Object.entries(log.metadata).map(([key, value], index) => {
          return (
            <div key={`kase-log-metadata-${log.id}-${index}`}>
              <strong>{capitalize(key)}:</strong> {formatIfDate(value)}
            </div>
          )
        })}
        <strong>Timestamp:</strong> {formatIfDate(log.created_at)}{' '}
        {getTimeFromDateString(log.created_at)}
      </div>
    </div>
  )
}

export default KaseLog
