export enum DocumentStates {
  APPROVED = 'approved',
  DISMISSED = 'dismissed',
  NEEDS_REVIEW = 'needs_review',
  REJECTED = 'rejected'
}

export const DocumentStateValues = {
  [DocumentStates.APPROVED]: 'Accepted',
  [DocumentStates.DISMISSED]: 'Dismissed',
  [DocumentStates.NEEDS_REVIEW]: 'Needs Review',
  [DocumentStates.REJECTED]: 'Rejected'
}
