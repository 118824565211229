import Button from 'components/Button'
import LoadingSpinner from 'components/LoadingSpinner'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import QuestionElement from 'components/question-element'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { EditableAnswer } from 'types/kaseReviewData'
import cx from 'classnames'

interface Props {
  editableAnswer: EditableAnswer
  isEditing: boolean
  kaseId: string
  setCurrentEditAnswerQuestionId: (answerQuestionId: string) => void
  submitAnswer: (
    value: string | null,
    questionId: string,
    answerId: string | null
  ) => Promise<void>
  updateKaseReviewData: () => void
}

/**
 * This component is used to edit question answers in the Form Review Tool
 */
export const KaseEditableAnswer: FunctionComponent<Props> = ({
  editableAnswer,
  isEditing,
  setCurrentEditAnswerQuestionId,
  submitAnswer
}) => {
  const {
    answer_text,
    question,
    question_text,
    required,
    visible
  } = editableAnswer
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  // Set newAnswerValue to existing answer as default to check for difference in canSaveAnswer
  const [newAnswerValue, setNewAnswerValue] = useState(answer_text)

  const questionId = question.id
  const answerId = question.attributes?.answer_id || null
  const canSaveAnswer = newAnswerValue !== answer_text

  useEffect(() => {
    setCurrentEditAnswerQuestionId('')
  }, [editableAnswer])

  const getFormattedAnswerText = (
    answerText: string | null,
    isQuestionVisible: boolean,
    questionText: string | null
  ) => {
    const renderedAnswerText =
      question.attributes.input_type === 'country_us_only'
        ? 'US'
        : answerText || <em>unanswered</em>

    return (
      isQuestionVisible &&
      (questionText ? (
        <>
          {questionText}: {renderedAnswerText}
        </>
      ) : (
        renderedAnswerText
      ))
    )
  }

  const handleSubmitAnswer = (
    value: string | null,
    questionId: string,
    answerId: string | null
  ) => {
    setIsConfirmationModalOpen(false)
    setCurrentEditAnswerQuestionId('')
    setIsLoading(true)

    submitAnswer(value, questionId, answerId).finally(() => setIsLoading(false))
  }

  const cancel = () => {
    setIsConfirmationModalOpen(false)
    setCurrentEditAnswerQuestionId('')
    setNewAnswerValue(answer_text)
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <ConfirmationModal
        cancelLabel="Cancel"
        confirmDisabled={!canSaveAnswer}
        confirmLabel="Yes, save these changes"
        dangerousOperation
        description="Are you sure you want to make the following changes? (This change will be reflected in the customer's account)"
        isOpen={isConfirmationModalOpen}
        modalWidth="30rem"
        onConfirm={() =>
          handleSubmitAnswer(newAnswerValue, questionId, answerId)
        }
        onRequestClose={cancel}
        title="Confirm changes"
      >
        {answer_text || 'unanswered'} → {newAnswerValue}
      </ConfirmationModal>
      {isEditing ? (
        <div>
          <QuestionElement
            handleQuestionAnswer={setNewAnswerValue}
            question={question}
            useHandleQuestionAnswerOnChange
          />
          <Button
            className="mr-4"
            disabled={!canSaveAnswer}
            variant="primary"
            onClick={() => setIsConfirmationModalOpen(true)}
          >
            Save
          </Button>
          <Button variant="plain" onClick={cancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <p
          className={cx('hover: cursor-pointer', {
            'font-bold text-red-600': !answer_text && required
          })}
          onClick={() => setCurrentEditAnswerQuestionId(questionId)}
        >
          {getFormattedAnswerText(answer_text, visible, question_text)}
        </p>
      )}
    </div>
  )
}
