import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import DocumentRow from './DocumentRow'
import DismissedDocumentRow from './DismissedDocumentRow'
import { updateDocumentOrder } from 'api/documentReview'
import Button from 'components/Button'
import Table from 'components/tables/Table'
import CheckboxField from 'components/forms/CheckboxField'

import { NameCheckboxIds } from '.'
import {
  IDocument,
  IDocumentRequest,
  IDocumentRequestPart,
  IPartContent
} from 'types/documents'
import { DocumentStates } from 'constants/documents'

interface Props {
  documentRequestId: IDocumentRequest['id']
  documentRequestPart: IDocumentRequestPart
  onDocumentCheckboxClicked: (docRequest: IDocument) => void
  onNameCheckboxClicked: (documentRequestPartId: number) => void
  onUploadFileChanged: (
    file: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => void
  selectedDocuments: IDocument[]
  selectedNameCheckboxIds: NameCheckboxIds[]
}

const DocumentRequestPart: FunctionComponent<Props> = ({
  documentRequestId,
  documentRequestPart,
  onDocumentCheckboxClicked,
  onNameCheckboxClicked,
  onUploadFileChanged,
  selectedDocuments,
  selectedNameCheckboxIds
}) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const kaseId = useSelector((state: RootState) => state.kaseData.id)
  const [showDismissedDocuments, setShowDismissedDocuments] = useState(false)
  const [docList, setDocList] = useState<IPartContent[]>([])
  useEffect(() => {
    const newList = documentRequestPart.contents.filter(
      (content) =>
        content.document.state !== DocumentStates.DISMISSED &&
        !content.document.deleted_at
    )
    setDocList(newList)
  }, [documentRequestPart])

  if (!kaseId || !documentRequestPart) return null

  const onUploadFileBtnClicked = () => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }

  const uploadFileChanged = (docRequestPartId: number) => {
    if (inputFile.current && inputFile.current.files) {
      onUploadFileChanged(
        inputFile.current.files[0],
        documentRequestId,
        docRequestPartId
      )
    }
  }

  const dismissedDocs = documentRequestPart.contents.filter(
    (content) =>
      content.document.state === DocumentStates.DISMISSED ||
      content.document.deleted_at
  )

  const moveDoc = (
    arrayList: IPartContent[],
    fromIndex: number,
    toIndex: number
  ) => {
    const draggedDoc = arrayList[fromIndex]
    const newDocList = Array.from(arrayList)

    newDocList.splice(fromIndex, 1)
    newDocList.splice(toIndex, 0, draggedDoc)

    setDocList(newDocList)

    Promise.all(
      newDocList.map((content, index) => {
        return updateDocumentOrder(kaseId, content.document.id, index)
      })
    )
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    // If they drag it nowhere do nothing
    if (!destination) return

    // If they dragged it back to the same place do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    moveDoc(docList, source.index, destination.index)
  }

  return (
    <Table rounded={false} classNames="table-fixed">
      <Table.Head>
        {documentRequestPart.header && (
          <Table.Row>
            <Table.Header
              borderBottom={false}
              borderTop
              className="pb-0"
              colSpan={4}
            >
              <div className="text-black normal-case text-sm">
                {documentRequestPart.header}
              </div>
            </Table.Header>
          </Table.Row>
        )}

        <Table.Row>
          <Table.Header style={{ width: '40%' }}>
            <CheckboxField
              checked={selectedNameCheckboxIds.some(
                (currentNameCheckbox) =>
                  currentNameCheckbox.docRequestId === documentRequestId &&
                  currentNameCheckbox.docRequestPartId ===
                    documentRequestPart.id
              )}
              label="Name"
              labelClassName="text-gray-500 text-xs"
              onChange={() => onNameCheckboxClicked(documentRequestPart.id)}
            />
          </Table.Header>
          <Table.Header style={{ width: '15%' }}>Size</Table.Header>
          <Table.Header style={{ width: '20%' }}>Date Uploaded</Table.Header>
          <Table.Header style={{ width: '25%' }}>
            <div className="flex items-center justify-between">
              Status
              {documentRequestPart.can_upload && (
                <>
                  <input
                    accept="application/pdf, image/png, image/jpeg"
                    className="hidden"
                    id={`upload-document-input-${documentRequestId}-${documentRequestPart.id}`}
                    ref={inputFile}
                    type="file"
                    onChange={() => uploadFileChanged(documentRequestPart.id)}
                  />
                  <label
                    htmlFor={`upload-document-input-${documentRequestId}-${documentRequestPart.id}`}
                  >
                    <Button
                      disabled={!documentRequestPart.can_upload}
                      onClick={onUploadFileBtnClicked}
                    >
                      Upload File
                    </Button>
                  </label>
                </>
              )}
            </div>
          </Table.Header>
        </Table.Row>
      </Table.Head>
      {/* id of 0 will be a standard bucket, no parts */}
      {documentRequestPart.id === 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`docReq${documentRequestId.toString()}`}>
            {(provided) => (
              <Table.Body
                {...provided.droppableProps}
                innerRef={provided.innerRef}
              >
                {docList.map((content, index) => (
                  <Draggable
                    key={content.document.id}
                    draggableId={`doc-${content.document.id.toString()}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <DocumentRow
                        key={content.document.id}
                        checkboxChecked={selectedDocuments.some(
                          (doc) => doc === content.document
                        )}
                        document={content.document}
                        onDocumentCheckboxClicked={onDocumentCheckboxClicked}
                        provided={provided}
                        snapshot={snapshot}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Table.Body>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Table.Body>
          {docList.map((content) => {
            return (
              <DocumentRow
                key={content.document.id}
                checkboxChecked={selectedDocuments.some(
                  (doc) => doc === content.document
                )}
                document={content.document}
                onDocumentCheckboxClicked={onDocumentCheckboxClicked}
              />
            )
          })}
        </Table.Body>
      )}

      {dismissedDocs.length > 0 && (
        <Table.Body>
          <Table.Row
            className="cursor-pointer"
            onClick={() => setShowDismissedDocuments(!showDismissedDocuments)}
          >
            <Table.Header colSpan={4} borderTop={true}>
              {showDismissedDocuments
                ? '- Hide Dismissed Documents'
                : '+ Show Dismissed Documents'}
            </Table.Header>
          </Table.Row>

          {showDismissedDocuments && (
            <>
              {dismissedDocs.map((content) => (
                <DismissedDocumentRow
                  document={content.document}
                  key={content.document.id}
                />
              ))}
            </>
          )}
        </Table.Body>
      )}
    </Table>
  )
}

export default DocumentRequestPart as FunctionComponent<Props>
