import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrentUser } from 'types/currentUser'
import { getCurrentUserDataFromLocalStorage } from 'api/jwt'

// Build the initial state using the value in localStorage
const initialState: Partial<CurrentUser> =
  getCurrentUserDataFromLocalStorage() || {}

// Slice creation
// https://redux-starter-kit.js.org/api/createSlice
const currentUserSlice = createSlice({
  name: 'currentUser', // This prefixes all the action types
  initialState,
  reducers: {
    // Define the actions here
    // https://redux-starter-kit.js.org/api/createAction
    setCurrentUserData: (state, action: PayloadAction<CurrentUser>) =>
      action.payload,
    clearCurrentUserData: () => ({})
  }
})

// Export the actions by name
export const {
  setCurrentUserData,
  clearCurrentUserData
} = currentUserSlice.actions

// Export the reducer as the default
export default currentUserSlice.reducer
