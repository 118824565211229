import Hyperlink, { HyperlinkItem } from 'components/Hyperlink'
import { FEATURES } from 'constants/featureFlags'
import {
  getRouteForDocumentReviewTool,
  getRouteForFormReviewTool,
  getRouteForIssuesTool,
  getRouteForKaseAuditLogs,
  getRouteForPayment
} from 'constants/routes'
import { useFeature } from 'hooks/useFeatureFlags'
import React from 'react'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { RootState } from 'store/root'

type Props = {
  kaseId: string
  accountHolderId: string | number
  masqueradeToken: string
  accountHolderEmail?: string
  baseURL?: string
  completedEligibility?: boolean
  kaseState?: string
  registered?: boolean
  handleOnClickRegistration?: (
    userId: string | number,
    email: string,
    kaseId: string
  ) => void
  setShowMagicLinkModal: (show: boolean) => void
}

function ReviewAndSupportLinks({
  baseURL,
  accountHolderId,
  accountHolderEmail,
  completedEligibility,
  handleOnClickRegistration,
  setShowMagicLinkModal,
  kaseId,
  masqueradeToken,
  registered
}: Props) {
  const { paid } = useSelector((state: RootState) => state.kaseOverview)
  const isPostPayCartEnabled = useFeature(FEATURES.POST_PAY_CART)
  const showPaymentToolLink = !paid || (paid && isPostPayCartEnabled)

  const completedEligibilityWithoutRegistration =
    completedEligibility && !registered

  const masqueradeURL = [
    baseURL ?? process.env.API_ORIGIN,
    'users',
    'masquerade',
    [
      `?kase_id=${kaseId}`,
      `id=${accountHolderId}`,
      `masquerade=${masqueradeToken}`,
      'masqueraded_resource_class=User'
    ].join('&')
  ].join('/')

  type LinkItem = {
    url?: string
    label: string
  }

  const reviewAndSupportToolsLinks: (HyperlinkItem & LinkItem)[] = [
    {
      label: 'Form Review Tool',
      url: getRouteForFormReviewTool(kaseId)
    },
    {
      label: 'Document Review Tool',
      url: getRouteForDocumentReviewTool(kaseId)
    },
    {
      label: 'Issues Tool',
      url: getRouteForIssuesTool(kaseId)
    },
    {
      label: 'Questionnaire Edit View',
      url: masqueradeURL,
      blank: true
    },
    {
      label: 'Audit Logs',
      url: getRouteForKaseAuditLogs(kaseId)
    },
    {
      label: 'Send Magic Login Link to Account Holder',
      onClick: () => setShowMagicLinkModal(true)
    },
    ...(completedEligibilityWithoutRegistration && !!accountHolderEmail
      ? [
          {
            label: 'Register Account Holder',
            onClick: () =>
              handleOnClickRegistration?.(
                accountHolderId,
                accountHolderEmail,
                kaseId
              )
          }
        ]
      : []),
    ...(showPaymentToolLink
      ? [
          {
            label: 'Payment Tool',
            url: getRouteForPayment(kaseId)
          }
        ]
      : [])
  ].filter((x) => !!x)

  return (
    <>
      <h3 className="font-bold text-base">Review & Support Tools</h3>
      <ul className="pb-3">
        {reviewAndSupportToolsLinks.map((link, index) => {
          // if an external link or onClick handler, use hyperlink component
          // otherwise use react router's Link component for SPA routing
          return (
            <li key={`${index}-${link.label}`} className="my-3">
              {link.blank || !link.url ? (
                <Hyperlink
                  href={link.url}
                  onClick={link?.onClick}
                  className="cursor-pointer"
                  {...(link.blank && { target: '_blank' })}
                >
                  {link.label}
                </Hyperlink>
              ) : (
                <Link
                  to={link.url}
                  className="text-blue-600 hover:text-blue-500"
                >
                  {link.label}
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ReviewAndSupportLinks
