import React, { FunctionComponent, useEffect, useState } from 'react'
import MainLayout from 'layouts/MainLayout'
import AdminsTable from './AdminsTable'
import { fetchAllAdmins } from 'api/adminUsers'
import { RootState } from 'store/root'
import { useSelector } from 'react-redux'
import Button from 'components/Button'
import { AdminUser } from 'types/users'

const AdminList: FunctionComponent = () => {
  const admins = useSelector((state: RootState) => state.adminUsers)
  const [adminsList, setAdminsList] = useState<AdminUser[]>([])

  useEffect(() => {
    fetchAllAdmins()
  }, [])

  useEffect(() => {
    setAdminsList(admins.allIds.map((id) => admins.byId[id]))
  }, [admins])

  const addAdminRow = () => {
    const adminUserDefaults = {
      id: '',
      type: 'adminUsers',
      attributes: {
        full_name: '',
        email: ''
      }
    } as AdminUser

    setAdminsList([adminUserDefaults, ...adminsList])
  }

  const onCancelCreate = () => {
    const adminList = adminsList.filter((admin) => admin.id)
    setAdminsList(adminList)
  }

  return (
    <MainLayout>
      <div className="p-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
            Admin users
          </h1>

          <Button
            variant="primary"
            className="ml-3 mb-5"
            onClick={addAdminRow}
            data-test="create-admin-button"
          >
            Create Admin
          </Button>
        </header>

        <AdminsTable admins={adminsList} onCancelCreate={onCancelCreate} />
      </div>
    </MainLayout>
  )
}

export default AdminList
