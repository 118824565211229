import { NewApplicationJit } from 'pages/new_application/shared/new-application-jit'
import React from 'react'
import { InputOptions } from 'types/shared'

export interface RadioInputOptionsProps {
  name?: string
  options: InputOptions[]
  selection?: string
}

function RadioInputOptions({
  name,
  options,
  selection
}: RadioInputOptionsProps) {
  return (
    <>
      {!!options &&
        options.length > 0 &&
        options.map((option) => {
          return (
            <div className="my-2" key={option.key}>
              <label>
                <input
                  type="radio"
                  value={option.key}
                  name={name}
                  defaultChecked={selection === option.key}
                />
                <span className="mx-2">{option.value}</span>
              </label>
              {option.jitElements?.map((jitElement, index) => (
                <NewApplicationJit
                  jit={jitElement}
                  key={`${option.key}-jit-${index}`}
                />
              ))}
            </div>
          )
        })}
    </>
  )
}

export default RadioInputOptions
