import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { getCurrentOpenTask } from 'store/selectors/kaseTasks'

export const useTaskAssignedToCustomer = () => {
  const [taskAssignedToCustomer, setTaskAssignedToCustomer] = useState(false)
  const currentOpenTask = useSelector((state: RootState) =>
    getCurrentOpenTask(state)
  )

  useEffect(() => {
    return setTaskAssignedToCustomer(!!currentOpenTask?.attributes?.assignee_id)
  }, [currentOpenTask])

  return taskAssignedToCustomer
}
