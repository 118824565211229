// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Inter.var.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * This is a variable font! It works in all recent browsers.\n * It supports font weights between 100 and 900\n */\n@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: normal;\n  font-named-instance: 'Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n", "",{"version":3,"sources":["webpack://src/assets/fonts/inter.css"],"names":[],"mappings":"AAAA;;;EAGE;AACF;EACE,wBAAwB;EACxB,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,8BAA8B;EAC9B,4DAA2C;AAC7C","sourcesContent":["/**\n * This is a variable font! It works in all recent browsers.\n * It supports font weights between 100 and 900\n */\n@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: normal;\n  font-named-instance: 'Regular';\n  src: url('Inter.var.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
