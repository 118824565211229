export const EMBASSY_CITIES = {
  mbgc: {
    '': 'Select a city',
    Abidjan: "Abidjan, Côte d'Ivoire",
    'Abu Dhabi': 'Abu Dhabi, United Arab Emirates',
    Accra: 'Accra, Ghana',
    'Addis Ababa': 'Addis Ababa, Ethiopia',
    Algiers: 'Algiers, Algeria',
    Almaty: 'Almaty, Kazakhstan',
    Amman: 'Amman, Jordan',
    Amsterdam: 'Amsterdam, Netherlands',
    Ankara: 'Ankara, Turkey',
    Antananarivo: 'Antananarivo, Madagascar',
    Ashgabat: 'Ashgabat, Turkmenistan',
    Asmara: 'Asmara, Eritrea',
    Asuncion: 'Asunción, Paraguay',
    Athens: 'Athens, Greece',
    Auckland: 'Auckland, New Zealand',
    Baghdad: 'Baghdad, Iraq',
    Bangkok: 'Bangkok, Thailand',
    Bangui: 'Bangui, Central African Republic',
    Beirut: 'Beirut, Lebanon',
    Belgrade: 'Belgrade, Serbia',
    Belmopan: 'Belmopan, Belize',
    Bern: 'Bern, Switzerland',
    Bishkek: 'Bishkek, Kyrgyz Republic',
    Bogota: 'Bogotá, Colombia',
    Bratislava: 'Bratislava, Slovakia',
    Brazzaville: 'Brazzaville, Republic of the Congo',
    Bridgetown: 'Bridgetown, Barbados',
    Brussels: 'Brussels, Belgium',
    Bucharest: 'Bucharest, Romania',
    Budapest: 'Budapest, Hungary',
    'Buenos Aires': 'Buenos Aires, Argentina',
    Bujumbura: 'Bujumbura, Burundi',
    Cairo: 'Cairo, Egypt',
    Caracas: 'Caracas, Venezuela',
    Casablanca: 'Casablanca, Morocco',
    Chisinau: 'Chisinau, Moldova',
    'Ciudad Juarez': 'Ciudad Juárez, Mexico',
    Colombo: 'Colombo, Sri Lanka',
    Copenhagen: 'Copenhagen, Denmark',
    Cotonou: 'Cotonou, Benin',
    Dakar: 'Dakar, Senegal',
    Damascus: 'Damascus, Syria',
    'Dar es Salaam': 'Dar es Salaam, Tanzania',
    Dhaka: 'Dhaka, Bangladesh',
    Djibouti: 'Djibouti, Republic of Djibouti',
    Doha: 'Doha, Qatar',
    Dublin: 'Dublin, Ireland',
    Frankfurt: 'Frankfurt, Germany',
    Freetown: 'Freetown, Sierra Leone',
    Georgetown: 'Georgetown, Guyana',
    Guangzhou: 'Guangzhou, China',
    'Guatemala City': 'Guatemala City, Guatemala',
    Guayaquil: 'Guayaquil, Ecuador',
    Hamilton: 'Hamilton, Bermuda',
    Havana: 'Havana, Cuba via Guyana',
    Helsinki: 'Helsinki, Finland',
    'Ho Chi Minh City': 'Ho Chi Minh City, Vietnam',
    'Hong Kong': 'Hong Kong and Macau',
    Harare: 'Harare, Zimbabwe',
    Islamabad: 'Islamabad, Pakistan',
    Jakarta: 'Jakarta, Indonesia',
    Jerusalem: 'Jerusalem, Israel',
    Johannesburg: 'Johannesburg, South Africa',
    Kabul: 'Kabul, Afghanistan',
    Kampala: 'Kampala, Uganda',
    Kathmandu: 'Kathmandu, Nepal',
    Khartoum: 'Khartoum, Sudan',
    Kigali: 'Kigali, Rwanda',
    Kingston: 'Kingston, Jamaica',
    Kinshasa: 'Kinshasa, Democratic Republic of the Congo',
    'Kuala Lumpur': 'Kuala Lumpur, Malaysia',
    'Kuwait City': 'Kuwait City, Kuwait',
    Kyiv: 'Kyiv, Ukraine',
    Lagos: 'Lagos, Nigeria',
    'La Paz': 'La Paz, Bolivia',
    Libreville: 'Libreville, Gabon',
    Lilongwe: 'Lilongwe, Malawi',
    Lima: 'Lima, Perú',
    Lisbon: 'Lisbon, Portugal via Paris, France',
    Ljubljana: 'Ljubljana, Slovenia',
    Lome: 'Lome, Togo',
    London: 'London, United Kingdom',
    Luanda: 'Luanda, Angola',
    Lusaka: 'Lusaka, Zambia',
    Madras: 'U.S. Embassy Madras',
    Madrid: 'Madrid, Spain',
    Managua: 'Managua, Nicaragua',
    Manama: 'Manama, Bahrain',
    Manila: 'Manila, Philippines',
    Monrovia: 'Monrovia, Liberia',
    Montevideo: 'Montevideo, Uruguay',
    Montreal: 'Montreal, Canada',
    Moscow: 'Moscow, Russia',
    Mumbai: 'Mumbai, India',
    Muscat: 'Muscat, Oman',
    Naha: 'Naha, Japan',
    Nairobi: 'Nairobi, Kenya',
    Naples: 'Naples, Italy',
    Nassau: 'Nassau, The Bahamas',
    'New Delhi': 'New Delhi, India',
    Niamey: 'Niamey, Niger',
    Nicosia: 'Nicosia, Cyprus',
    Oslo: 'Oslo, Norway',
    Ouagadougou: 'Ouagadougou, Burkina Faso',
    'Panama City': 'Panama City, Panama',
    Paramaribo: 'Paramaribo, Suriname',
    Paris: 'Paris, France',
    'Phnom Penh': 'Phnom Penh, Cambodia',
    Prague: 'Prague, Czech Republic',
    Praia: 'Praia, Cabo Verde',
    Pristina: 'Pristina, Kosovo',
    'Ponta Delgada': 'Portugal',
    'Port-au-Prince': 'Port-au-Prince, Haiti',
    'Port Moresby': 'Port Moresby, Papua New Guinea',
    'Port of Spain': 'Port of Spain, Trinidad and Tobago',
    Rangoon: 'Rangoon, Burma',
    Reykjavik: 'Reykjavik, Iceland',
    Riga: 'Riga, Latvia',
    'Rio de Janeiro': 'Rio de Janeiro, Brazil',
    Riyadh: 'Riyadh, Saudi Arabia',
    Sanaa: 'Sanaa, Yemen',
    'San Jose': 'San Jose, Costa Rica',
    'San Salvador': 'San Salvador, El Salvador',
    Santiago: 'Santiago, Chile',
    'Santo Domingo': 'Santo Domingo, Dominican Republic',
    Sarajevo: 'Sarajevo, Bosnia and Herzegovina',
    Seoul: 'Seoul, South Korea',
    Singapore: 'Singapore',
    Skopje: 'Skopje, Macedonia',
    Sofia: 'Sofia, Bulgaria',
    Stockholm: 'Stockholm, Sweden',
    Suva: 'Suva, Fiji',
    Sydney: 'Sydney, Australia',
    Taipei: 'Taipei, Taiwan',
    Tallinn: 'Tallin, Estonia',
    Tashkent: 'Tashkent, Uzbekistan',
    Tbilisi: 'Tbilisi, Georgia',
    Tegucigalpa: 'Tegucigalpa, Honduras',
    'Tel Aviv': 'Tel Aviv, Israel',
    Tirana: 'Tirana, Albania',
    Tokyo: 'Tokyo, Japan',
    Tunis: 'Tunis, Tunisia',
    Ulaanbaatar: 'Ulaanbaatar, Mongolia',
    Vancouver: 'Vancouver, Canada',
    Vienna: 'Vienna, Austria',
    Vientiane: 'Vientiane, Laos',
    Vilnius: 'Vilnius, Lithuania',
    Warsaw: 'Warsaw, Poland',
    Yaounde: 'Yaoundé, Cameroon',
    Yerevan: 'Yerevan, Armenia',
    Zagreb: 'Zagreb, Croatia'
  },
  k1: {
    '': 'Select a city',
    Abidjan: "Abidjan, Côte d'Ivoire",
    'Abu Dhabi': 'Abu Dhabi, United Arab Emirates',
    Accra: 'Accra, Ghana',
    'Addis Ababa': 'Addis Ababa, Ethiopia',
    Algiers: 'Algiers, Algeria',
    Almaty: 'Almaty, Kazakhstan',
    Amman: 'Amman, Jordan',
    Amsterdam: 'Amsterdam, Netherlands',
    Ankara: 'Ankara, Turkey',
    Antananarivo: 'Antananarivo, Madagascar',
    Ashgabat: 'Ashgabat, Turkmenistan',
    Asmara: 'Asmara, Eritrea',
    Asuncion: 'Asunción, Paraguay',
    Athens: 'Athens, Greece',
    Auckland: 'Auckland, New Zealand',
    Baghdad: 'Baghdad, Iraq',
    Bangkok: 'Bangkok, Thailand',
    Bangui: 'Bangui, Central African Republic',
    Beirut: 'Beirut, Lebanon',
    Belgrade: 'Belgrade, Serbia',
    Belmopan: 'Belmopan, Belize',
    Bern: 'Bern, Switzerland',
    Bogota: 'Bogotá, Colombia',
    Bratislava: 'Bratislava, Slovakia',
    Brazzaville: 'Brazzaville, Republic of the Congo',
    Bridgetown: 'Bridgetown, Barbados',
    Brussels: 'Brussels, Belgium',
    Bucharest: 'Bucharest, Romania',
    Budapest: 'Budapest, Hungary',
    'Buenos Aires': 'Buenos Aires, Argentina',
    Bujumbura: 'Bujumbura, Burundi',
    Cairo: 'Cairo, Egypt',
    Caracas: 'Caracas, Venezuela',
    Casablanca: 'Casablanca, Morocco',
    Chisinau: 'Chisinau, Moldova',
    'Ciudad Juarez': 'Ciudad Juarez, Mexico',
    Colombo: 'Colombo, Sri Lanka',
    Copenhagen: 'Copenhagen, Denmark',
    Cotonou: 'Cotonou, Benin',
    Dakar: 'Dakar, Senegal',
    Damascus: 'Damascus, Syria',
    'Dar es Salaam': 'Dar es Salaam, Tanzania',
    Dhaka: 'Dhaka, Bangladesh',
    Djibouti: 'Djibouti, Republic of Djibouti',
    Doha: 'Doha, Qatar',
    Dublin: 'Dublin, Ireland',
    Frankfurt: 'Frankfurt, Germany',
    Freetown: 'Freetown, Sierra Leone',
    Georgetown: 'Georgetown, Guyana',
    Guangzhou: 'Guangzhou, China',
    'Guatemala City': 'Guatemala City, Guatemala',
    Guayaquil: 'Guayaquil, Ecuador',
    Hamilton: 'Hamilton, Bermuda',
    Havana: 'Havana, Cuba via Guyana',
    Helsinki: 'Helsinki, Finland',
    'Ho Chi Minh City': 'Ho Chi Minh City, Vietnam',
    'Hong Kong': 'Hong Kong and Macau',
    Harare: 'Harare, Zimbabwe',
    Islamabad: 'Islamabad, Pakistan',
    Jakarta: 'Jakarta, Indonesia',
    Jerusalem: 'Jerusalem, Israel',
    Johannesburg: 'Johannesburg, South Africa',
    Kabul: 'Kabul, Afghanistan',
    Kathmandu: 'Kathmandu, Nepal',
    Khartoum: 'Khartoum, Sudan',
    Kigali: 'Kigali, Rwanda',
    Kingston: 'Kingston, Jamaica',
    Kinshasa: 'Kinshasa, Democratic Republic of the Congo',
    'Kuala Lumpur': 'Kuala Lumpur, Malaysia',
    'Kuwait City': 'Kuwait City, Kuwait',
    Kyiv: 'Kyiv, Ukraine',
    Lagos: 'Lagos, Nigeria',
    'La Paz': 'La Paz, Bolivia',
    Libreville: 'Libreville, Gabon',
    Lilongwe: 'Lilongwe, Malawi',
    Lima: 'Lima, Perú',
    Lisbon: 'Lisbon, Portugal via Paris, France',
    Ljubljana: 'Ljubljana, Slovenia',
    Lome: 'Lome, Togo',
    London: 'London, United Kingdom',
    Luanda: 'Luanda, Angola',
    Lusaka: 'Lusaka, Zambia',
    Madras: 'U.S. Embassy Madras',
    Madrid: 'Madrid, Spain',
    Managua: 'Managua, Nicaragua',
    Manama: 'Manama, Bahrain',
    Manila: 'Manila, Philippines',
    'Mexico City': 'Mexico City, Mexico',
    Monrovia: 'Monrovia, Liberia',
    Montevideo: 'Montevideo, Uruguay',
    Montreal: 'Montreal, Canada',
    Moscow: 'Moscow, Russia',
    Mumbai: 'Mumbai, India',
    Muscat: 'Muscat, Oman',
    Naha: 'Naha, Japan',
    Nairobi: 'Nairobi, Kenya',
    Naples: 'Naples, Italy',
    Nassau: 'Nassau, The Bahamas',
    'New Delhi': 'New Delhi, India',
    Niamey: 'Niamey, Niger',
    Nicosia: 'Nicosia, Cyprus',
    Oslo: 'Oslo, Norway',
    Ouagadougou: 'Ouagadougou, Burkina Faso',
    'Panama City': 'Panama City, Panama',
    Paramaribo: 'Paramaribo, Suriname',
    Paris: 'Paris, France',
    'Phnom Penh': 'Phnom Penh, Cambodia',
    Prague: 'Prague, Czech Republic',
    Praia: 'Praia, Cabo Verde',
    'Ponta Delgada': 'Portugal',
    'Port-au-Prince': 'Port-au-Prince, Haiti',
    'Port Moresby': 'Port Moresby, Papua New Guinea',
    'Port of Spain': 'Port of Spain, Trinidad and Tobago',
    Rangoon: 'Rangoon, Burma',
    Reykjavik: 'Reykjavik, Iceland',
    Riga: 'Riga, Latvia',
    'Rio de Janeiro': 'Rio de Janeiro, Brazil',
    Riyadh: 'Riyadh, Saudi Arabia',
    Sanaa: 'Sanaa, Yemen',
    'San Jose': 'San Jose, Costa Rica',
    'San Salvador': 'San Salvador, El Salvador',
    Santiago: 'Santiago, Chile',
    'Santo Domingo': 'Santo Domingo, Dominican Republic',
    Sarajevo: 'Sarajevo, Bosnia and Herzegovina',
    Seoul: 'Seoul, South Korea',
    Singapore: 'Singapore',
    Skopje: 'Skopje, Macedonia',
    Sofia: 'Sofia, Bulgaria',
    Stockholm: 'Stockholm, Sweden',
    Suva: 'Suva, Fiji',
    Sydney: 'Sydney, Australia',
    Taipei: 'Taipei, Taiwan',
    Tallinn: 'Tallin, Estonia',
    Tashkent: 'Tashkent, Uzbekistan',
    Tbilisi: 'Tbilisi, Georgia',
    Tegucigalpa: 'Tegucigalpa, Honduras',
    'Tel Aviv': 'Tel Aviv, Israel',
    Tirana: 'Tirana, Albania',
    Tokyo: 'Tokyo, Japan',
    Tunis: 'Tunis, Tunisia',
    Ulaanbaatar: 'Ulaanbaatar, Mongolia',
    Vancouver: 'Vancouver, Canada',
    Vienna: 'Vienna, Austria',
    Vientiane: 'Vientiane, Laos',
    Vilnius: 'Vilnius, Lithuania',
    Warsaw: 'Warsaw, Poland',
    Yaounde: 'Yaoundé, Cameroon',
    Yerevan: 'Yerevan, Armenia',
    Zagreb: 'Zagreb, Croatia'
  }
}
