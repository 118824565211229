import fetchStateDeptCountries from 'api/countries'
import fetchFormOptions from 'api/formOptions'
import React, { useState, useEffect } from 'react'

interface HookProps {
  excludeUS?: boolean
  useStateDeptCountries?: boolean
}

function useCountryOptions(
  { excludeUS = false, useStateDeptCountries = false }: HookProps = {
    excludeUS: false,
    useStateDeptCountries: false
  }
) {
  const [options, setOptions] = useState<Record<string, string>>({})
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const load = React.useCallback(async () => {
    setHasError(false)
    setLoading(true)
    try {
      const data = useStateDeptCountries
        ? await fetchStateDeptCountries()
        : await fetchFormOptions(excludeUS ? 'non_us_countries' : 'countries')
      if (!data?.isAxiosError) setOptions(data)
      else throw data
    } catch (err) {
      setHasError(true)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    load()
  }, [])

  return { hasError, load, loading, options }
}

export default useCountryOptions
