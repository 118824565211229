import React, { FunctionComponent, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import {
  deleteDocumentRequest,
  fetchDocumentSetsByKaseId
} from 'api/documentReview'

import DocumentRequestPart from './DocumentRequestPart'
import Button from 'components/Button'
import CheckboxField from 'components/forms/CheckboxField'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import BlankModal from 'components/modals/BlankModal'

import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { docRequestHasOpenIssues } from './utils'
import { NameCheckboxIds } from '.'
import { IDocument, IDocumentRequest, IPartContent } from 'types/documents'

interface Props {
  checkboxChecked: boolean
  currentView?: string
  documentRequest: IDocumentRequest
  onDocumentCheckboxClicked: (docRequest: IDocument) => void
  onDocRequestCheckboxClicked: (docRequest: IDocumentRequest) => void
  onNameCheckboxClicked: (
    documentRequest: IDocumentRequest,
    documentRequestPartId: number
  ) => void
  onUploadFileChanged: (
    file: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => void
  selectedDocuments: IDocument[]
  selectedNameCheckboxIds: NameCheckboxIds[]
}

const DocumentRequest: FunctionComponent<Props> = ({
  checkboxChecked,
  currentView,
  documentRequest,
  onDocumentCheckboxClicked,
  onDocRequestCheckboxClicked,
  onNameCheckboxClicked,
  onUploadFileChanged,
  selectedDocuments,
  selectedNameCheckboxIds
}) => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const ref = useRef<HTMLDivElement>(null)
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false)
  const [showForbiddenModal, setShowForbiddenModal] = useState(false)
  const kaseIssues = useSelector((state: RootState) => state.kaseIssues)

  const { name, title } = documentRequest
  const isCurrentView = currentView === name
  let partContents: IPartContent[] = []

  documentRequest.parts.forEach((part) => {
    partContents = partContents.concat(part.contents)
  })

  if (isCurrentView && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  const showDismissModal = () => {
    if (docRequestHasOpenIssues(documentRequest, kaseIssues)) {
      setShowForbiddenModal(true)
    } else {
      setShowConfirmActionModal(true)
    }
  }

  const dismissDocumentRequest = () => {
    deleteDocumentRequest(kaseId, documentRequest.id).then(() => {
      fetchDocumentSetsByKaseId(kaseId)
    })
  }

  const uploadFileChanged = (
    file: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => {
    onUploadFileChanged(file, docRequestId, docRequestPartId)
  }

  return (
    <div
      className="border-solid border border-gray-200 mb-10 bg-white"
      ref={ref}
    >
      <ConfirmationModal
        isOpen={showConfirmActionModal}
        title="Dismiss Document Request"
        description={
          <>
            When a document request is dismissed, it will no longer be shown to
            the customer nor will it be required of them.
          </>
        }
        confirmLabel="Dismiss Document Request"
        cancelLabel="Cancel"
        onConfirm={() => {
          setShowConfirmActionModal(false)
          dismissDocumentRequest()
        }}
        onRequestClose={() => setShowConfirmActionModal(false)}
      />
      <BlankModal
        isOpen={showForbiddenModal}
        onRequestClose={() => setShowForbiddenModal(false)}
      >
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <p className="text-sm text-gray-600 leading-5">
            There are <strong>open issues</strong> on this document request or
            on the documents it contains. These issues must be deleted or
            resolved before the request can be dismissed.
          </p>
        </div>
      </BlankModal>
      <div className="px-6 py-2 border-b bg-gray-200 border-gray-200 flex items-center min-w-min justify-between">
        <div className="flex items-center">
          <CheckboxField
            checked={checkboxChecked}
            label={title}
            onChange={() => onDocRequestCheckboxClicked(documentRequest)}
          />
        </div>
        <div className="space-x-8">
          <Button variant="plain" onClick={showDismissModal}>
            Dismiss
          </Button>
        </div>
      </div>

      {documentRequest.parts.map((part) => {
        return (
          <DocumentRequestPart
            key={`${documentRequest.id}-${part.id}`}
            documentRequestId={documentRequest.id}
            documentRequestPart={part}
            onDocumentCheckboxClicked={onDocumentCheckboxClicked}
            onNameCheckboxClicked={(documentRequestPartId) =>
              onNameCheckboxClicked(documentRequest, documentRequestPartId)
            }
            onUploadFileChanged={uploadFileChanged}
            selectedDocuments={selectedDocuments}
            selectedNameCheckboxIds={selectedNameCheckboxIds}
          />
        )
      })}
    </div>
  )
}

export default DocumentRequest as FunctionComponent<Props>
