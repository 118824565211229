import axios, { AxiosRequestConfig } from 'axios'
import { getAuthJWT } from './jwt'

if (process.env.API_ORIGIN == null) {
  // eslint-disable-next-line no-console
  console.error(
    'The API_ORIGIN must be set. Please check your environment variables.'
  )
}

function getDefaultConfigHeaders() {
  // Make users sign in if there's no JWT in localStorage
  const token = getAuthJWT()

  return {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    Authorization: token
  }
}

function addConfigToRequest(config: AxiosRequestConfig) {
  config.headers = Object.assign(
    {},
    getDefaultConfigHeaders(),
    config.headers || {}
  )

  return config
}

// We create an instance of Axios in order to customize it
const customAxios = axios.create()

// Add the JWT to each request
customAxios.interceptors.request.use(addConfigToRequest)

// Set the base URL
customAxios.defaults.baseURL = process.env.API_ORIGIN

export default customAxios
