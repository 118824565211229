import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from 'constants/routes'

const isDevEnvironment = process.env.NODE_ENV !== 'production'

/**
 * A set of error messages based on the environment (dev vs prod) and the error
 * code provided.
 */
export const API_ERROR_DETAILS: { [key: string]: ReactNode } = {
  '401': (
    <>
      You must{' '}
      <Link className="underline font-medium" to={ROUTES.SIGN_OUT}>
        sign in
      </Link>{' '}
      to view this page.
    </>
  ),
  '440': (
    <>
      Your session has expired. Please{' '}
      <Link className="underline font-medium" to={ROUTES.SIGN_OUT}>
        sign in again
      </Link>
      .
    </>
  ),
  '404': isDevEnvironment
    ? "The endpoint you're trying to call is returning a 404. Check the URL and make sure the server is running."
    : "Please check that you're connected to the VPN, and then refresh the page."
}

/**
 * Used when the user is not connected to the VPN.
 * In a dev environment, it provides some debugging pointers.
 */
export const OFF_VPN_ERROR = isDevEnvironment
  ? 'The checkCurrentUserAuth API is throwing a 404. Please check the URL and make sure the server is running.'
  : 'Please check your VPN connection and try again.'

export const SESSION_EXPIRED_ERROR =
  'Your session expired. Please sign in again.'
