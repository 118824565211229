import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FeatureFlagsShape {
  [key: string]: boolean
}

export interface GlobalShape {
  featureFlags: {
    featureFlags: FeatureFlagsShape
    isLoaded: boolean
  }
}

const initialState: GlobalShape = {
  featureFlags: {
    featureFlags: {},
    isLoaded: false
  }
}

const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setFeatureFlags: (
      state: GlobalShape,
      action: PayloadAction<FeatureFlagsShape>
    ) => {
      state.featureFlags = {
        featureFlags: action.payload,
        isLoaded: true
      }
    }
  }
})

export const { setFeatureFlags } = GlobalSlice.actions

export default GlobalSlice.reducer
