import React, { FunctionComponent, ReactNode, useRef } from 'react'

import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Alert from 'components/Alert'
import { API_ERROR_DETAILS } from 'constants/errors'

/**
 * This component displays any error present in the GlobalErrorContext.
 * It provides contextual and environment-appropriate action items when
 * possible.
 */
const GlobalErrorBanner: FunctionComponent = () => {
  const { errors } = useGlobalError()
  const errorRef = useRef(null)

  if (errors.length === 0) {
    return null
  }

  // We only display the first error because it's good enough for now
  const errorToDisplay = errors[0]

  // Add some information to the error, when possible
  let alertText: ReactNode = errorToDisplay.detail

  if (!alertText && API_ERROR_DETAILS[errorToDisplay.status] != null) {
    alertText = API_ERROR_DETAILS[errorToDisplay.status]
  }

  return (
    <div className="p-4">
      <Alert
        className="border border-red-200"
        title={errorToDisplay.title || 'Something went wrong'}
        variant="error"
        errorRef={errorRef}
      >
        <p>{alertText}</p>
      </Alert>
    </div>
  )
}

export default GlobalErrorBanner
