import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KaseFilters } from 'types/kaseFilters'

// const KIND_FILTER = 'KIND_FILTER'

const initialState: KaseFilters = {
  kaseKind: 'all',
  kaseState: 'all',
  lawyerId: 'all',
  stewardId: 'all',
  paid: 'all',
  phone: null,
  email: '',
  otherEmail: '',
  firstName: '',
  lastName: ''
}

const kaseFilterSlice = createSlice({
  name: 'kaseFilters',
  initialState,
  reducers: {
    setKaseFilters: (state, action: PayloadAction<KaseFilters>) =>
      action.payload,
    resetFilters: () => initialState
  }
})

export const { setKaseFilters, resetFilters } = kaseFilterSlice.actions

export default kaseFilterSlice.reducer
