import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useScript } from 'hooks/useScript'
import { initiateSplitit, expireSplitIt, verifySplitit } from 'api/payment'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Card from 'components/Card'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import LoadingSpinner from 'components/LoadingSpinner'
import { formatCentsInDollars } from 'utils/format_money'
import { getAccountHolderEmail } from 'store/selectors/kaseOverview'
import { RootState } from 'store/root'
import { InitiatePaymentPayload, ServiceCheckoutDetails } from 'types/payment'

interface Props {
  checkoutDetails: ServiceCheckoutDetails
  initiatePaymentPayload: InitiatePaymentPayload
  monthly: number
}

const SPLIT_IT_ID = 'splitit-payment-container'

const SplitItCheckout: FunctionComponent<Props> = ({
  checkoutDetails,
  initiatePaymentPayload,
  monthly
}) => {
  useScript('https://checkout.sandbox.splitit.com/Popup/v1/pf-embedded.js')

  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const accountHolderEmail = useSelector((state: RootState) =>
    getAccountHolderEmail(state)
  )
  const {
    split_it_max_months,
    split_it_min_months,
    split_it_allowed_months
  } = checkoutDetails

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.getElementById(SPLIT_IT_ID)?.firstChild?.remove()
    setIsLoading(true)
    initiateSplitit(kaseId, getCheckoutConfig())
      .then(({ data }) =>
        setupIframe(
          data.InstallmentPlan.InstallmentPlanNumber,
          data.CheckoutUrl
        )
      )
      .catch((error) => {
        setGlobalError(error)
      })
  }, [monthly])

  const getCheckoutConfig = () => {
    return {
      ...initiatePaymentPayload,
      options: {
        PlanData: {
          RefOrderNumber: kaseId
        },
        ConsumerData: {
          Email: accountHolderEmail
        },
        PaymentWizardData: {
          // Get the allowed months from the backend, otherwise default to what was set before
          RequestedNumberOfInstallments: split_it_allowed_months || '2,3,4,5,6'
        }
      }
    }
  }

  const setupIframe = (installmentPlanNumber: string, checkoutUrl: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Splitit.Popup.setup({
      style: {
        poweredByStyle: {
          display: 'none'
        }
      },
      checkoutUrl: checkoutUrl,
      container: '#splitit-payment-container',
      onError: function (error: any) {
        setGlobalError(error)
        expireSplitIt(kaseId)
      },
      onSuccess: function () {
        const currentOrigin = window.location.origin
        verifySplitit(kaseId, installmentPlanNumber)
          .then((res) => {
            const isPaid = res.data.IsPaid
            if (isPaid) {
              window.location.href = `${currentOrigin}/overview/${kaseId}?payment_type=splitit&plan_number=${installmentPlanNumber}&splitit_success=true`
            } else {
              setGlobalError({
                message:
                  'Payment Plan verification failed, please refresh to retry.'
              })
            }
          })
          .catch((error) => setGlobalError(error))
      },
      onCancel: function () {
        expireSplitIt(kaseId)
      }
    })
    setIsLoading(false)
  }

  return (
    <Card className="w-1/2 ml-3">
      <Card.Header>Checkout with SplitIt</Card.Header>
      <Card.Body>
        <p className="mb-3">Pay as little as</p>
        <p>
          <span className="font-bold text-lg">
            {formatCentsInDollars(monthly)}/month
          </span>{' '}
          {`over ${split_it_min_months} to ${split_it_max_months} months with SplitIt`}
        </p>
      </Card.Body>
      <Card.Body>
        {isLoading && <LoadingSpinner />}
        <div id={SPLIT_IT_ID} />
      </Card.Body>
    </Card>
  )
}

export default SplitItCheckout
