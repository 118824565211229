/**
 * This is a fully-controlled modal wrapper. The API was written to be content-
 * agnostic, which means we can render whatever we want inside the modal
 * without overriding this code.
 *
 * Features:
 * - when the modal isn't visible, it's not in the DOM
 * - when the modal is shown, it's appended to the document.body
 * - the modal uses the correct aria attributes
 * - focus is "trapped" within the modal when it's open
 * - handling the Escape key and clicking on the backdrop to close the modal
 *   MUST be handled by the consumer
 * - prevents the body from scrolling, without the sidebar jitter effect
 *
 * Implementation details:
 * - there needs to be at least one focusable element within the modal
 */

import React, { CSSProperties, createRef, MouseEvent, Component } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { createPortal } from 'react-dom'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { keyIsEscape } from 'utils/keyboard'
import TailwindCSSTransition from 'components/transition/TailwindCSSTransition'
import FocusTrap from 'focus-trap-react'

export enum CloseReason {
  /**
   * The Escape key was pressed
   */
  EscapeKey,
  /**
   * The backdrop (the area outside the modal) was clicked
   */
  BackdropClicked,
  /**
   * The Close button was pressed
   */
  CloseButton,
  /**
   * A Cancel button (or similar wording) was pressed
   */
  Cancel
}

interface Props {
  isOpen?: boolean
  style?: CSSProperties
  /**
   * A callback that's triggered when the backdrop is clicked
   */
  handleBackdropClick?: () => void
  /**
   * This callback is required for accessibility reasons. It's bad practice not
   * to allow users to close the modal using the Escape key or clicking on the
   * backdrop. The consumer of this component can choose not to close the modal
   * when this callback is triggered, but I hope that by making it required, we
   * will encourage good practices.
   */
  onRequestClose: (reason: CloseReason) => void
}

export default class ModalBase extends Component<Props> {
  private uniqueKey: string
  private portalElement?: HTMLDivElement
  private modalContainerRef = createRef<HTMLElement>()
  private focusTrapRef = createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.uniqueKey = nanoid()
  }

  componentDidMount() {
    this.portalElement = document.createElement('div')
    this.portalElement.dataset['modalId'] = this.uniqueKey
    document.body.appendChild(this.portalElement)

    // Force a render on the next frame
    setTimeout(() => {
      this.forceUpdate()

      // If the modal is open when mounted, componentDidUpdate will be skipped.
      // Thus, we set up the side effects here.
      if (this.props.isOpen) {
        this.addModalSideEffects()
      }
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.addModalSideEffects()
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.removeModalSideEffects()
    }
  }

  componentWillUnmount() {
    if (this.portalElement != null) {
      document.body.removeChild(this.portalElement)
      this.portalElement = undefined
    }

    this.removeModalSideEffects()
  }

  /**
   * Call this method when showing the modal to activate the side effects:
   * - lock the html and body elements
   * - add a keyboard listener for the Escape key
   */
  private addModalSideEffects = () => {
    if (this.portalElement != null) {
      disableBodyScroll(this.portalElement, { reserveScrollBarGap: true })
      document.addEventListener('keydown', this.handleKeyPress)
    }
  }

  private removeModalSideEffects = () => {
    clearAllBodyScrollLocks()
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  private handleKeyPress = (event: KeyboardEvent) => {
    if (keyIsEscape(event)) {
      this.props.onRequestClose(CloseReason.EscapeKey)
    }
  }

  private onClickOutside = (event: MouseEvent) => {
    if (
      this.modalContainerRef.current &&
      this.modalContainerRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    this.props.onRequestClose(CloseReason.BackdropClicked)
  }

  render() {
    const { children, isOpen, style } = this.props

    // The portalElement is added to the DOM after mount, so the first render
    // should be skipped
    if (!this.portalElement) {
      return null
    }

    return createPortal(
      <>
        <TailwindCSSTransition
          in={isOpen}
          timeout={400}
          enter="transition duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity mt-16">
            <div className="absolute inset-0 bg-gray-600 opacity-50"></div>
          </div>
        </TailwindCSSTransition>
        <TailwindCSSTransition
          in={isOpen}
          timeout={400}
          enter="transition duration-200 transform"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition duration-200 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <div
            className="flex inset-0 overflow-y-auto fixed w-screen z-30 mt-16"
            onClick={this.onClickOutside}
            ref={this.focusTrapRef}
          >
            <FocusTrap
            // focusTrapOptions={{ fallbackFocus: this.getFocusTrapFallback }}
            >
              <aside
                ref={this.modalContainerRef}
                className="m-auto w-full max-w-full relative"
                aria-modal="true"
                role="dialog"
                style={style}
                tabIndex={-1}
              >
                {children}
              </aside>
            </FocusTrap>
          </div>
        </TailwindCSSTransition>
      </>,
      this.portalElement
    )
  }
}
