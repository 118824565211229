import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { getDirectUrl, downloadLink } from 'api/masterPdf'
import { fileSizeToReadable } from 'utils/format_file_size'
import { formatIfDate, getTimeFromDateString } from 'utils/format_utils'
import { MasterPDF } from 'types/kaseOverview'

import Button from 'components/Button'
import Table from 'components/tables/Table'

interface Props {
  kaseId: string
  mpdf: MasterPDF
  onClickNoteModal: (mpdf: MasterPDF) => void
  onViewLogBtnClicked: (log: string) => void
  onViewMpdfBtnClicked: (mpdfUrl: string) => void
}

const MPDFGenerationSectionTableRow: FunctionComponent<Props> = ({
  kaseId,
  mpdf,
  onClickNoteModal,
  onViewLogBtnClicked,
  onViewMpdfBtnClicked
}) => {
  const getMpdfViewUrl = async (kaseId: number, mdpfId: number) => {
    const result = await getDirectUrl(kaseId, mdpfId)
    return result.data.url
  }

  return (
    <Table.Row>
      <Table.Cell>
        <div>
          {`${formatIfDate(mpdf.created_at)} at ${getTimeFromDateString(
            mpdf.created_at
          )}`}
        </div>
        <div>by {mpdf.created_by}</div>
      </Table.Cell>
      <Table.Cell>
        {mpdf.admin_note && <p>{mpdf.admin_note}</p>}
        <Button variant="plain" onClick={() => onClickNoteModal(mpdf)}>
          {mpdf.admin_note?.length > 0 ? 'Edit' : 'Add'}
        </Button>
      </Table.Cell>
      <Table.Cell>{mpdf.source}</Table.Cell>
      <Table.Cell>
        {mpdf.file_size ? fileSizeToReadable(mpdf.file_size) : '0'}
      </Table.Cell>
      <Table.Cell>{mpdf.status}</Table.Cell>
      {/* TODO: Need an endpoint to download the file. Anchor tag will just open the file in the browser */}
      <Table.Cell>
        <a
          download
          href={downloadLink(mpdf.kase_id, mpdf.id)}
          rel="noreferrer"
          target="_blank"
        >
          <Button
            disabled={mpdf.status === 'failed' || mpdf.status === 'pending'}
            variant="plain"
          >
            Download
          </Button>
        </a>
      </Table.Cell>
      <Table.Cell>
        <Link
          to={`/mpdf/` + kaseId + `?new_from_mpdf=` + mpdf.id}
          className="inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          New From This
        </Link>
      </Table.Cell>
      {/* TODO: Implement Log button (What does it do?) */}
      <Table.Cell>
        <Button
          onClick={() => onViewLogBtnClicked(mpdf.debug_log)}
          variant="plain"
        >
          Log
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Button
          disabled={mpdf.status === 'failed' || mpdf.status === 'pending'}
          onClick={async () => {
            onViewMpdfBtnClicked(await getMpdfViewUrl(mpdf.kase_id, mpdf.id))
          }}
        >
          View
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}
export default MPDFGenerationSectionTableRow
