import React, { FunctionComponent, ReactNode, Ref, useState } from 'react'
import ReactTinyPopover, { Align } from 'react-tiny-popover'

export type PopoverPosition = 'top' | 'left' | 'bottom' | 'right'

interface Props {
  /**
   * The preferred position of the popover, or an array of preferred positions
   * in order of priority.
   */
  position?: PopoverPosition | PopoverPosition[]
  /**
   * The content to display inside the popover
   */
  content: ReactNode
  /**
   * If start is specified, the popover content's top or left location is
   * aligned with its target's. With end specified, the content's bottom or
   * right location is aligned with its target's. If center is specified, the
   * popover content and target's centers are aligned. Defaults to center.
   */
  align?: Align
  /**
   * The content that will trigger the popover as a render function.
   */
  children: (renderProps: { ref: Ref<any>; togglePopover: () => void }) => void
}

const Popover: FunctionComponent<Props> = ({
  content,
  align = 'start',
  children,
  position = 'bottom'
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const togglePopover = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ReactTinyPopover
      isOpen={isOpen}
      align={align}
      padding={0}
      transitionDuration={0.1}
      onClickOutside={() => setIsOpen(false)}
      containerStyle={{ overflow: 'visible' }} // Don't cut off the box-shadow
      position={position}
      content={<>{content}</>}
    >
      {/* A wrapper or a forwarded ref is necessary for custom components,
      see https://github.com/alexkatz/react-tiny-popover */}
      {(ref) => children({ ref, togglePopover })}
    </ReactTinyPopover>
  )
}

export default Popover
