import React, {
  FunctionComponent,
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect
} from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorObject, formatApiErrors } from 'utils/format_errors'

interface ErrorContextShape {
  setGlobalError: (error: any) => void
  errors: ErrorObject[]
}

const defaultContextValue: ErrorContextShape = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setGlobalError: () => {},
  errors: []
}

const GlobalErrorContext = createContext(defaultContextValue)

/**
 * This component is a context provide for global errors. It should wrap other
 * components.
 */
const GlobalErrorWrapper: FunctionComponent = ({ children }) => {
  const history = useHistory()
  const [errors, setErrors] = useState<ErrorObject[]>([])

  // Listen to route changes and clear the message
  useEffect(() => {
    return history.listen(() => setErrors([]))
  }, [])

  const setGlobalErrorMessage = (error: any) => {
    // eslint-disable-next-line no-console
    console.log(error)

    // Parse an error object into structured data
    setErrors(formatApiErrors(error))
  }

  // Memoize the value of the context provider
  const contextValue = useMemo(
    () => ({ setGlobalError: setGlobalErrorMessage, errors }),
    [setGlobalErrorMessage, errors]
  )

  return (
    <GlobalErrorContext.Provider value={contextValue}>
      {children}
    </GlobalErrorContext.Provider>
  )
}

/**
 * This hook returns the value of the GlobalErrorContext.
 */
export const useGlobalError = () => useContext(GlobalErrorContext)

export default GlobalErrorWrapper
