import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import {
  createIssue,
  deleteDocument,
  replaceDocument,
  updateDocumentState,
  updateDocumentFileName,
  updateDocumentMetaAnswer,
  fetchDocumentSetsByKaseId,
  fetchDocumentById,
  fetchFileUrl
} from 'api/documentReview'
import { fetchIssues, resolveIssue, updateIssueDescription } from 'api/issues'

import MainLayout from 'layouts/MainLayout'
import KaseIssuesPanel from 'pages/kase_review/KaseIssuesPanel'
import DocumentViewNavigation from './DocumentViewNavigation'
import DocumentViewRender from './DocumentViewRender'
import DocumentViewSidebar from './DocumentViewSidebar'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import SubNavigation from 'components/SubNavigation'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from 'components/Button'

import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import {
  IDocument,
  IDocumentRequest,
  IPartContent,
  IDocumentRequestPart
} from 'types/documents'
import { ICreateIssueParams } from 'types/issues'
import Hyperlink from 'components/Hyperlink'
import S3Upload, { SignResult } from 'utils/S3Upload'

const isImage = (file_type: string | null) => {
  if (!file_type) {
    return false
  }

  return !!file_type.match(/^image/)
}

export const ReadOnlyDocumentView: FunctionComponent<{
  backButtonLabel: string
  document: IDocument
  documentUrl: string
  onBackButtonClicked: () => void
}> = ({ backButtonLabel, document, documentUrl, onBackButtonClicked }) => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  return (
    <MainLayout subNavigation={<SubNavigation context={'mpdf'} />}>
      <div className="bg-gray-200 h-full px-8 py-8">
        <h1 className="text-sm font-medium">{document.file_name}</h1>
        <Button onClick={onBackButtonClicked} variant="plain">
          {backButtonLabel}
        </Button>
        <Hyperlink
          href={`/document-review/${kaseId}/document/${document.id}`}
          className="float-right cursor-pointer inline-flex items-center text-sm leading-5 font-medium"
          target="_blank"
        >
          Edit Document In New Tab
        </Hyperlink>
        <div className="mt-6 mx-auto" style={{ height: '90%' }}>
          {isImage(document.file_type) ? (
            <img src={documentUrl} className="mx-auto" />
          ) : (
            <iframe src={documentUrl} width="100%" height="100%" />
          )}
        </div>
      </div>
    </MainLayout>
  )
}

const DocumentView: FunctionComponent = () => {
  const history = useHistory()
  const { kaseId, docId } = useParams<IKaseIndexRouteParams>()
  const [currentDoc, setCurrentDoc] = useState<IDocument>()
  const [currentDocIndex, setCurrentDocIndex] = useState(0)
  const [currentDocListType, setCurrentDocListType] = useState('non-dismissed')
  const [currentDocList, setCurrentDocList] = useState<IDocument[]>([])
  const [currentDocRequest, setCurrentDocRequest] = useState<IDocumentRequest>()
  const [currentPart, setCurrentPart] = useState<IDocumentRequestPart>()
  const [currentPartContent, setCurrentPartContent] = useState<IPartContent>()
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([])
  const [showIssuePanel, setShowIssuePanel] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [isIssuePanelLoading, setIsIssuePanelLoading] = useState(false)
  const [documentUrl, setDocumentUrl] = useState('')
  const documentSets = useSelector(
    (state: RootState) => state.documentReviewData.documentSets
  )

  const { setGlobalError } = useGlobalError()

  useEffect(() => {
    fetchDocumentSetsByKaseId(kaseId)
    fetchIssues(kaseId)
  }, [])

  useEffect(() => {
    fetchDocumentById(kaseId, docId)
      .then((resp: any) => setCurrentDoc(resp.data.data.attributes))
      .catch((error) => {
        setCurrentDoc(undefined)
        setGlobalError(error)
      })

    fetchFileUrl(kaseId, docId)
      .then((resp: any) => setDocumentUrl(resp.data.url))
      .catch((error) => {
        setDocumentUrl('')
        setGlobalError(error)
      })
  }, [docId])

  // Once we have a current document, find the current doc request
  useEffect(() => {
    if (currentDoc) {
      let docListType
      if (currentDoc.deleted || currentDoc.state === 'dismissed') {
        docListType = 'dismissed'
      } else {
        docListType = 'non-dismissed'
      }

      setCurrentDocListType(docListType)

      if (documentSets && documentSets.length > 0) {
        for (const set of documentSets) {
          const docRequest = set.document_requests.find(
            (request) => request.id === currentDoc.document_request_id
          )
          if (docRequest) {
            setCurrentDocRequest(docRequest)
            break
          }
        }
      }

      setCurrentDocIndex(
        currentDocList.findIndex((document) => document.id === currentDoc.id)
      )

      // This is not a great solution, but the issue creation endpoint
      // expects an array of documents. If an agent creates an issue from
      // the doc detail screen, the only "selected document" is the current
      // doc in view. So I've made this temporary array of a single document.
      let tempArr: IDocument[] = []
      tempArr = tempArr.concat(currentDoc)
      setSelectedDocuments(tempArr)
    }
  }, [documentSets, currentDocList, currentDoc])

  // Once we have a current doc request, find the current part content and
  // the current list of documents (dismissed or non dismissed of the request)
  useEffect(() => {
    if (currentDoc && currentDocRequest && currentDocRequest.parts.length > 0) {
      for (const part of currentDocRequest.parts) {
        const partContent = part.contents.find(
          (content) => content.document.id === currentDoc.id
        )
        if (partContent) {
          setCurrentPartContent(partContent)
          break
        }
      }

      // Map through all the documents in the current doc request parts, creating
      // an array of non active (all dismissed and deleted) and active (all other)
      // documents. Then, set one of those arrays to the current doc list depending
      // on the current doc list type.
      let activeDocs: IDocument[] = []
      let nonActiveDocs: IDocument[] = []
      currentDocRequest.parts.map((part) =>
        part.contents.map(
          (content) =>
            ((content.document.state === 'dismissed' ||
              content.document.deleted_at) &&
              (nonActiveDocs = nonActiveDocs.concat(content.document))) ||
            (content.document.state !== 'dismissed' &&
              !content.document.deleted_at &&
              (activeDocs = activeDocs.concat(content.document)))
        )
      )

      if (currentDocListType === 'dismissed') {
        setCurrentDocList(nonActiveDocs)
      } else {
        setCurrentDocList(activeDocs)
      }
    }
  }, [currentDocIndex, currentDocRequest])

  // And finally, once we have the current part content, set the current part (for
  // use in doc requests that have many parts)
  useEffect(() => {
    if (
      currentDocRequest &&
      currentPartContent &&
      currentDocList &&
      currentDoc
    ) {
      setCurrentPart(
        currentDocRequest.parts.find(
          (part) => part.id === currentPartContent.part_id
        )
      )
    }
  }, [currentPartContent, currentDocList])

  const documentFileNameChanged = (
    docId: number | string,
    fileName: string
  ) => {
    updateDocumentFileName(kaseId, docId, fileName).then(() =>
      fetchDocumentSetsByKaseId(kaseId)
    )
  }

  const onStateSelectChanged = (docId: number | string, state: string) => {
    // Create an issue if document is rejected
    if (state === 'rejected') {
      handleCreateIssueButtonClick()
    }

    // If the agent dismisses a document OR they update FROM dismissed
    // TO one of the "active" states and they are at the end of
    // the current doc list, take them to the list view. If they are
    // NOT at the end of the list, advance them to the next doc.
    if (
      state === 'dismissed' ||
      (currentDoc?.state === 'dismissed' &&
        (state === 'needs_review' ||
          state === 'rejected' ||
          state === 'approved'))
    ) {
      if (currentDocIndex + 1 === currentDocList.length) {
        history.push(`/document-review/${kaseId}`)
      } else {
        const nextDocId = currentDocList[currentDocIndex + 1].id
        history.push(`${nextDocId}`)
      }
    }

    updateDocumentState(kaseId, docId, state)
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .catch((error) => setGlobalError(error))
  }

  const metaAnswerChanged = (
    documentMetaAnswerId: number | string,
    text: string
  ) => {
    updateDocumentMetaAnswer(kaseId, documentMetaAnswerId, text).then(() =>
      fetchDocumentSetsByKaseId(kaseId)
    )
  }

  const documentReplaced = (file: File, replacedDocId: number) => {
    onDocumentReplaced(file, replacedDocId)
  }

  const onBackBtnClicked = () => {
    if (history.location.pathname.includes('mpdf')) {
      history.push(`/mpdf/${kaseId}`)
    } else {
      history.push(`/document-review/${kaseId}`)
    }
  }

  const onDeleteDocumentBtnClicked = (docId: number) => {
    deleteDocument(kaseId, docId)
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .then(() => onBackBtnClicked())
      .catch((error) => setGlobalError(error))
  }

  const onDocumentReplaced = (file: File, replacedDocId: number) => {
    // NEEWW
    new S3Upload(
      [file],
      +kaseId,
      {
        onReplaceUploadComplete: onReplaceUploadComplete,
        docReplacedId: replacedDocId,
        s3path: `documents/${kaseId}`
      },
      'replace-in-doc-view'
    )
  }

  const onReplaceUploadComplete = (
    signResult: SignResult,
    rawFile: File,
    replacedDocId: number
  ) => {
    const s3_path = signResult.filename
    return replaceDocument(kaseId, replacedDocId, rawFile, s3_path)
      .then((document: IDocument) => {
        if (document?.id) {
          history.push(`/document-review/${kaseId}/document/${document.id}`)
          return document
        } else {
          throw new Error(
            'Error replacing document. Please check that you are able to upload documents to this document request part'
          )
        }
      })
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .catch((error: any) => setGlobalError(error))
  }

  const onPrevBtnClicked = () => {
    if (currentDocIndex > 0) {
      const prevDocId = currentDocList[currentDocIndex - 1].id
      history.push(`${prevDocId}`)
    }
  }

  const onNextBtnClicked = () => {
    if (currentDocIndex < currentDocList.length) {
      const nextDocId = currentDocList[currentDocIndex + 1].id
      history.push(`${nextDocId}`)
    }
  }

  const handleClickCancelCreateIssueButton = () => {
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  const onClickOutsideIssuePanel = () => {
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  const handleShowOrHideIssuesClick = () => {
    setShowIssuePanel(!showIssuePanel)
    setShowCreateIssue(false)
  }

  const handleCreateIssueButtonClick = () => {
    setShowIssuePanel(true)
    setShowCreateIssue(true)
  }

  const handleClickSaveButton = (createIssueParams: ICreateIssueParams) => {
    setIsIssuePanelLoading(true)

    if (currentDoc) {
      createIssue(kaseId, createIssueParams, selectedDocuments)
        .then(() => fetchIssues(kaseId))
        .then(() => updateDocumentState(kaseId, currentDoc.id, 'rejected'))
        .then(() => fetchDocumentSetsByKaseId(kaseId))
        .catch((error) => setGlobalError(error))
        .then(() => setIsIssuePanelLoading(false))
    }
  }

  const handleResolveButtonClick = (kaseId: string, issueId: string) => {
    setIsIssuePanelLoading(true)
    resolveIssue(kaseId, issueId)
      .then(() => fetchIssues(kaseId))
      .catch((error) => setGlobalError(error))
      .then(() => setIsIssuePanelLoading(false))
  }

  const handleSaveEditIssue = (
    kaseId: string,
    issueId: string,
    newDescription: string
  ) => {
    setIsIssuePanelLoading(true)
    updateIssueDescription(kaseId, issueId, newDescription)
      .then(() => fetchIssues(kaseId))
      .catch((error) => setGlobalError(error))
      .then(() => setIsIssuePanelLoading(false))
  }

  if (documentUrl && currentPartContent && currentDocRequest) {
    // Basic Document View routed from the MPDF tool
    if (history.location.pathname.includes('mpdf')) {
      return (
        <ReadOnlyDocumentView
          backButtonLabel="Back to MPDF Tool"
          document={currentPartContent.document}
          documentUrl={documentUrl}
          onBackButtonClicked={onBackBtnClicked}
        />
      )
    } else {
      return (
        <MainLayout
          subNavigation={
            <SubNavigation
              context={'document-review-tool'}
              showIssuePanel={showIssuePanel}
              onRequestShowOrHideIssuesPanel={handleShowOrHideIssuesClick}
              onRequestCreateIssue={handleCreateIssueButtonClick}
              showBulkUpdateStatusBtns={false}
            />
          }
        >
          <div className="bg-gray-200 py-10 px-8">
            <DocumentViewNavigation
              docRequestTitle={currentDocRequest.title}
              docRequestPartTitle={currentPart?.header}
              docFileName={currentPartContent?.document.file_name}
              docIndex={currentDocIndex}
              numDocuments={currentDocList.length}
              onBackBtnClicked={onBackBtnClicked}
              onNextBtnClicked={onNextBtnClicked}
              onPrevBtnClicked={onPrevBtnClicked}
            />
            <div className="grid grid-flow-col grid-cols-4 mt-6 pb-8">
              <DocumentViewRender
                doc={currentPartContent.document}
                documentUrl={documentUrl}
                isImage={isImage(currentPartContent?.document?.file_type)}
                onDocumentReplaced={documentReplaced}
              />
              <DocumentViewSidebar
                partContent={currentPartContent}
                documentFileNameChanged={documentFileNameChanged}
                metaAnswerChanged={metaAnswerChanged}
                onDeleteDocumentBtnClicked={(docId) =>
                  onDeleteDocumentBtnClicked(docId)
                }
                onDocumentReplaced={documentReplaced}
                onStateSelectChanged={onStateSelectChanged}
              />
            </div>
          </div>

          <KaseIssuesPanel
            onClickOutsideIssuePanel={onClickOutsideIssuePanel}
            show={showIssuePanel}
            showCreateIssue={showCreateIssue}
            selectedDocuments={selectedDocuments}
            setShowCreateIssue={setShowCreateIssue}
            handleClickSaveButton={handleClickSaveButton}
            handleResolveButtonClick={handleResolveButtonClick}
            handleSaveEditIssue={handleSaveEditIssue}
            handleClickCancelButton={handleClickCancelCreateIssueButton}
            isLoading={isIssuePanelLoading}
          />
        </MainLayout>
      )
    }
  } else {
    return <LoadingSpinner classNames="mt-12" />
  }
}

export default DocumentView as FunctionComponent
