import React from 'react'
import { PageElementQuestion } from 'types/questionnaire'

export interface CheckboxGroupProps {
  name?: string
  question: PageElementQuestion
  onChange: (value: string) => void
  selection?: string
}

function CheckboxGroup({
  name,
  question,
  onChange,
  selection
}: CheckboxGroupProps) {
  const { input_options } = question.attributes

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const values: string[] = selection ? selection.split(',') : []

    if (e.target.checked) {
      values.push(newValue)
    } else {
      values.splice(values.indexOf(newValue), 1)
    }

    onChange(values.join(','))
  }

  return (
    <div key={question?.id}>
      {!!input_options &&
        input_options.length > 0 &&
        input_options.map((option) => {
          return (
            <div className="my-2" key={option.key}>
              <label>
                <input
                  type="checkbox"
                  value={option.key}
                  name={name}
                  checked={selection?.includes(option.key)}
                  onChange={(e) => handleChecked(e)}
                />
                <span className="mx-2">{option.value}</span>
              </label>
            </div>
          )
        })}
    </div>
  )
}

export default CheckboxGroup
