import React, { FunctionComponent } from 'react'
import cx from 'classnames'

export enum CountVariants {
  DEFAULT = 'default',
  PRIMARY = 'primary'
}

interface Props {
  children: string | number
  variant?: 'default' | 'primary'
}

const TabCountLabel: FunctionComponent<Props> = ({
  children,
  variant = CountVariants.DEFAULT
}) => {
  const classes = cx('ml-2 text-xs font-medium p-1 rounded leading-3', {
    'bg-blue-100 text-blue-600': variant === CountVariants.PRIMARY,
    'bg-gray-200': variant === CountVariants.DEFAULT
  })
  return <span className={classes}>{children}</span>
}

export default TabCountLabel
