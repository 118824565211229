import React, { FunctionComponent } from 'react'
import { CSSTransition as ReactCSSTransition } from 'react-transition-group'

/**
 * This component wraps the CSSTransition component from react-transition-group
 * The underlying problem is that we don't control the specificity of Tailwind
 * classes. With regular CSS transitions, we would have something like:
 *
 * .fade-exit { opacity: 1; } // Start fading out at 100% opacity
 * .fade-exit-active { opacity: 0; } // Fade to 0 opacity
 *
 * In Tailwind, this would mean using the opacity-0 and opacity-100 classes.
 * However, if opacity-0 is defined before opacity-100, the latter will override
 * the opacity value, which means the element will not fade out.
 *
 * To get around the problem, we manually set the classes necessary during each
 * step of the transitions:
 *
 * leaveFrom="opacity-100"
 * leaveTo="opacity-0"
 *
 * Inspired by this gist:
 * https://gist.github.com/adamwathan/3b9f3ad1a285a2d1b482769aeb862467
 *
 * react-transition-group docs:
 * https://reactcommunity.org/react-transition-group/css-transition
 */

interface Props {
  timeout: number
  in?: boolean
  enter?: string
  enterFrom?: string
  enterTo?: string
  leave?: string
  leaveFrom?: string
  leaveTo?: string
}

const TailwindCSSTransition: FunctionComponent<Props> = ({
  enter = '',
  enterFrom = '',
  enterTo = '',
  leave = '',
  leaveFrom = '',
  leaveTo = '',
  children,
  ...otherProps
}) => {
  const enterClasses = enter.split(' ').filter((s) => s.length)
  const enterFromClasses = enterFrom.split(' ').filter((s) => s.length)
  const enterToClasses = enterTo.split(' ').filter((s) => s.length)
  const leaveClasses = leave.split(' ').filter((s) => s.length)
  const leaveFromClasses = leaveFrom.split(' ').filter((s) => s.length)
  const leaveToClasses = leaveTo.split(' ').filter((s) => s.length)

  function addClasses(node: HTMLElement, classes: string[]) {
    classes.length && node.classList.add(...classes)
  }

  function removeClasses(node: HTMLElement, classes: string[]) {
    classes.length && node.classList.remove(...classes)
  }

  return (
    <ReactCSSTransition
      {...otherProps}
      appear
      timeout={1000}
      unmountOnExit
      onEnter={(node: HTMLElement) => {
        addClasses(node, [...enterClasses, ...enterFromClasses])
      }}
      onEntering={(node: HTMLElement) => {
        removeClasses(node, enterFromClasses)
        addClasses(node, enterToClasses)
      }}
      onEntered={(node: HTMLElement) => {
        removeClasses(node, [...enterToClasses, ...enterClasses])
      }}
      onExit={(node) => {
        addClasses(node, [...leaveClasses, ...leaveFromClasses])
      }}
      onExiting={(node) => {
        removeClasses(node, leaveFromClasses)
        addClasses(node, leaveToClasses)
      }}
      onExited={(node) => {
        removeClasses(node, [...leaveToClasses, ...leaveClasses])
      }}
    >
      {children}
    </ReactCSSTransition>
  )
}

export default TailwindCSSTransition
