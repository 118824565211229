import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IAddressHistoriesIssues,
  IAnswersIssues,
  IDocumentIssues,
  IEmploymentHistoriesIssues
} from 'types/issues'
import { Issue } from 'types/issues'
import { Message } from 'types/message'

interface IssuesReturnShape {
  // TODO: Backend calls this answers/documents_issue (singular) whereas frontend has it plural
  // Should probably make it uniform across both to avoid confusion
  address_histories_issue?: { [key: string]: IAddressHistoriesIssues }
  answers_issue?: { [key: string]: IAnswersIssues }
  documents_issue?: { [key: string]: IDocumentIssues }
  employment_histories_issue?: { [key: string]: IEmploymentHistoriesIssues }
  issues: { [key: string]: Issue }
  messages: { [key: string]: Message }
}

export interface KaseIssuesShape {
  answers_issues: {
    byId: { [key: string]: IAnswersIssues }
    allIds: string[]
  }
  address_histories_issues: {
    byId: { [key: string]: IAddressHistoriesIssues }
    allIds: string[]
  }
  documents_issues: {
    byId: { [key: string]: IDocumentIssues }
    allIds: string[]
  }
  employment_histories_issues: {
    byId: { [key: string]: IEmploymentHistoriesIssues }
    allIds: string[]
  }
  issues: {
    byId: { [key: string]: Issue }
    allIds: string[]
  }
  messages: {
    byId: { [key: string]: Message }
    allIds: string[]
  }
}

export const initialState: KaseIssuesShape = {
  issues: {
    byId: {},
    allIds: []
  },
  address_histories_issues: {
    byId: {},
    allIds: []
  },
  answers_issues: {
    byId: {},
    allIds: []
  },
  documents_issues: {
    byId: {},
    allIds: []
  },
  employment_histories_issues: {
    byId: {},
    allIds: []
  },
  messages: {
    byId: {},
    allIds: []
  }
}

const kaseIssuesSlice = createSlice({
  name: 'kaseIssues', // This prefixes all the action types
  initialState,
  reducers: {
    // Define the actions here
    // https://redux-starter-kit.js.org/api/createAction
    setIssues: (
      state: KaseIssuesShape,
      action: PayloadAction<IssuesReturnShape>
    ) => {
      const issues = action.payload.issues
      const messages = action.payload.messages
      const address_histories_issues = action.payload.address_histories_issue
      const answers_issues = action.payload.answers_issue
      const documents_issues = action.payload.documents_issue
      const employment_histories_issues =
        action.payload.employment_histories_issue

      state.issues.byId = issues || {}
      state.issues.allIds = issues ? Object.keys(issues) : []

      state.messages.byId = messages || {}
      state.messages.allIds = messages ? Object.keys(messages) : []

      state.address_histories_issues.byId = address_histories_issues || {}
      state.address_histories_issues.allIds = address_histories_issues
        ? Object.keys(address_histories_issues)
        : []

      state.answers_issues.byId = answers_issues || {}
      state.answers_issues.allIds = answers_issues
        ? Object.keys(answers_issues)
        : []

      state.documents_issues.byId = documents_issues || {}
      state.documents_issues.allIds = documents_issues
        ? Object.keys(documents_issues)
        : []

      state.employment_histories_issues.byId = employment_histories_issues || {}
      state.employment_histories_issues.allIds = employment_histories_issues
        ? Object.keys(employment_histories_issues)
        : []
    },
    setIssue: (state: KaseIssuesShape, action: PayloadAction<Issue>) => {
      const issue = action.payload
      state.issues.byId[issue.id] = issue
      state.issues.allIds = Object.keys(state.issues.byId)
    },
    setMessage: (state: KaseIssuesShape, action: PayloadAction<Message>) => {
      // Update the messages object
      const updatedMessage = action.payload
      state.messages.byId[updatedMessage.id] = updatedMessage
      state.messages.allIds = Object.keys(state.messages.byId)

      // Update the matching issue
      const correspondingIssue =
        state.issues.byId[updatedMessage.attributes.issue_id]
      const messagesData =
        correspondingIssue.relationships?.messages?.data || []

      messagesData.push({
        type: 'messages',
        id: updatedMessage.id
      })

      correspondingIssue.relationships['messages'] = { data: messagesData }
    },
    updateMessageInStore: (
      state: KaseIssuesShape,
      action: PayloadAction<Message>
    ) => {
      const updatedMessage = action.payload

      state.messages.byId[updatedMessage.id] = updatedMessage
    },
    deleteIssueFromStore: (
      state: KaseIssuesShape,
      action: PayloadAction<string>
    ) => {
      const deletedIssueId = action.payload

      // Delete issue from issues object
      delete state.issues.byId[deletedIssueId]
      state.issues.allIds = Object.keys(state.issues.byId)
    }
  }
})

// Export the actions by name
export const {
  setIssues,
  setIssue,
  deleteIssueFromStore,
  updateMessageInStore,
  setMessage
} = kaseIssuesSlice.actions

// Export the reducer as the default
export default kaseIssuesSlice.reducer
