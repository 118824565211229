import React, { FunctionComponent, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Button from 'components/Button'
import IssueCard from 'components/IssueCard'
import ButtonIcon from 'components/ButtonIcon'
import Label from 'components/forms/Label'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import IssueHeader from './IssueHeader'

import { RootState } from 'store/root'
import { Issue } from 'types/issues'
import { resolveIssue, deleteIssue } from 'api/issues'
import { IssueStatus } from 'constants/issues'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Can from 'components/Can'
import ChatIcon from 'components/icons/ChatIcon'

interface Props {
  kaseId: string
  openIssueThread: (issue: Issue) => () => void
  statusFilter?: IssueStatus
  taskAssignedToCustomerOrResolved: boolean
  taskResolved: boolean
}

const IssueList: FunctionComponent<Props> = ({
  openIssueThread,
  kaseId,
  statusFilter,
  taskAssignedToCustomerOrResolved,
  taskResolved
}) => {
  const [issueToDelete, setIssueToDelete] = useState<string | null>()
  const issues = useSelector((state: RootState) => state.kaseIssues.issues)

  const { setGlobalError } = useGlobalError()

  const filteredIssueIds = useMemo(() => {
    return issues.allIds.filter(
      (issueId) => issues.byId[issueId].attributes.status === statusFilter
    )
  }, [issues, statusFilter])

  const resolve = (issueId: string) => {
    resolveIssue(kaseId, issueId)
  }

  const deleteSelectedIssue = () => {
    if (issueToDelete) {
      deleteIssue(kaseId, issueToDelete)
        .then(() => {
          setIssueToDelete(null)
        })
        .catch((error) => {
          setIssueToDelete(null)
          setGlobalError(error)
        })
    }
  }

  if (!filteredIssueIds.length && statusFilter === IssueStatus.Resolved) {
    return (
      <div className="rounded p-6 text-center border-2 my-6 border-dashed bg-gray-50 border-gray-200">
        <p className="text-gray-700 text-sm">
          There are no {statusFilter} issues for this application.
        </p>
      </div>
    )
  }

  return (
    <>
      <ConfirmationModal
        isOpen={!!issueToDelete}
        title="Delete issue"
        dangerousOperation
        description="Are you sure you want to delete this issue? This action is not reversible."
        confirmLabel="Yes, delete issue"
        onConfirm={deleteSelectedIssue}
        onRequestClose={() => setIssueToDelete(null)}
      />
      {filteredIssueIds.map((issueId) => (
        <IssueCard
          className="shadow"
          key={issueId}
          header={<IssueHeader issue={issues.byId[issueId]} />}
          body={
            <p className="whitespace-pre-wrap">
              <Label>Description</Label>
              {issues.byId[issueId].attributes.description}
            </p>
          }
          footer={
            <div className="flex justify-between">
              <Button onClick={openIssueThread(issues.byId[issueId])}>
                <ButtonIcon placement="before">
                  <ChatIcon />
                </ButtonIcon>
                Open thread
              </Button>
              {/* If currently viewing resolved issues of in read only
              do not show buttons that update issues */}
              {statusFilter !== IssueStatus.Resolved && (
                <Can
                  perform="issue:update"
                  yes={
                    <div>
                      <Button
                        className="mx-3"
                        onClick={() => resolve(issueId)}
                        disabled={taskResolved}
                      >
                        Resolve
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => setIssueToDelete(issueId)}
                        disabled={taskAssignedToCustomerOrResolved}
                      >
                        Delete
                      </Button>
                    </div>
                  }
                />
              )}
            </div>
          }
        />
      ))}
    </>
  )
}

export default IssueList
