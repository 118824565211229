import React, {
  FunctionComponent,
  useState,
  ReactNode,
  ChangeEvent,
  InputHTMLAttributes
} from 'react'
import { nanoid } from '@reduxjs/toolkit'
import TextArea from 'components/forms/TextArea'
import Label from 'components/forms/Label'
import InlineError from 'components/forms/InlineError'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * The ID of the input, used to map the label to the input element
   */
  id?: string
  /**
   * An optional label for the field
   */
  label?: ReactNode
  /**
   * A name added to the input element can be used for form parsing and
   * validation
   */
  name?: string
  /**
   * A placeholder value for the input
   */
  placeholder?: string
  /**
   * If true, the input will not be editable, but the text will remain
   * selectable
   */
  readOnly?: boolean
  /**
   * A string that represents a custom validation error
   */
  error?: string
  /**
   * If true, the input field will not be editable or selectable
   */
  disabled?: boolean
  /**
   * Callback triggered when the text changes. Works the same way as a regular
   * input element.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const TextAreaField: FunctionComponent<Props> = ({
  id,
  label,
  error = null,
  ...otherProps
}) => {
  // Create a unique ID in case the use doesn't provide one
  const [uniqueId] = useState(nanoid())

  const fieldId = id || uniqueId

  return (
    <div className="w-full">
      <Label htmlFor={fieldId}>{label}</Label>
      {error && <InlineError>{error}</InlineError>}
      <TextArea {...otherProps} hasError={!!error} id={fieldId} />
    </div>
  )
}

export default TextAreaField
