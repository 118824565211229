import ROUTES from 'constants/routes'
import { PermissionType } from 'types/permissions'

export type NavRoute = typeof ROUTES[keyof typeof ROUTES]

export type NavLink = {
  label: string
  route: NavRoute
  permission: PermissionType
}

export const NAV_LINKS: NavLink[] = [
  {
    label: 'Kases',
    route: ROUTES.KASE_ROOT,
    permission: 'kases:read'
  },
  {
    label: 'Users',
    route: ROUTES.USERS_ROOT,
    permission: 'user:read'
  },
  {
    label: 'Admins',
    route: ROUTES.ADMIN_USERS_ROOT,
    permission: 'user:read'
  },
  {
    label: 'Roles',
    route: ROUTES.ROLES_ROOT,
    permission: 'role:read'
  },
  {
    label: 'Tasks',
    route: ROUTES.TASKS_ROOT,
    permission: 'task:read'
  }
]
