import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import cx from 'classnames'

import { AdminUser } from 'types/users'
import { transitionTask } from 'api/tasks'
import SelectField from 'components/forms/SelectField'

interface Props {
  assigneeName?: string
  assigneeId?: string
  taskId: string
  adminUsers: AdminUser[]
  tabIndex: number
}

const TaskAssignee: FunctionComponent<Props> = ({
  assigneeName,
  assigneeId,
  taskId,
  adminUsers,
  tabIndex
}) => {
  const [showAssigneeSelector, setShowAssigneeSelector] = useState(false)

  const syncAssigneeId = (selected: string) => {
    transitionTask({ taskId, assigneeId: selected, state: 'assign' })
  }

  const setTaskAssignee = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault
    const selected = event.target.value
    syncAssigneeId(selected)
  }

  const handleOnMouseOver = () => {
    // Only enabled in the 'Task Assignment' tab
    if (tabIndex === 0) {
      setShowAssigneeSelector(true)
    }
  }

  const handleOnMouseOut = () => {
    // Only enabled in the 'Task Assignment' tab
    if (tabIndex === 0) {
      setShowAssigneeSelector(false)
    }
  }

  const options = adminUsers.map((user) => ({
    value: user.id,
    label: user.attributes.full_name
  }))

  options.unshift({ value: '', label: 'Select a user' })

  return (
    <div
      className="assignee-wrapper"
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
    >
      <div
        className={cx('border-white border px-3 py-2', {
          hidden: showAssigneeSelector
        })}
      >
        {assigneeName || <span>&nbsp;</span>}
      </div>
      <div className={showAssigneeSelector ? '' : 'hidden'}>
        <SelectField
          id={`assignee-${taskId}`}
          value={assigneeId ? assigneeId : ''}
          onChange={setTaskAssignee}
          options={options}
          disabled={
            tabIndex === 1 /* Only enabled in the 'Task Assignment tab'*/
          }
        />
      </div>
    </div>
  )
}

export default TaskAssignee
