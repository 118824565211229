import React, { FunctionComponent, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import _find from 'lodash/find'
import _includes from 'lodash/includes'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import KaseAssignmentDropdown from './assignment_dropdown'
import { AdminUser } from 'types/users'
import { fetchOverview, updateKaseTag } from 'api/overview'
import Button from 'components/Button'
import { fetchRoles } from 'api/roles'
import { fetchAllAdmins, reassignKase } from 'api/adminUsers'

interface Props {
  className?: string
}

const KaseAssignments: FunctionComponent<Props> = ({ className }) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const admins = useSelector((state: RootState) => state.adminUsers)
  const { setGlobalError } = useGlobalError()
  const [applicationGuides, setApplicationGuides] = useState<AdminUser[]>()
  const [lawyers, setLawyers] = useState<AdminUser[]>()
  const roles = useSelector((state: RootState) => state.adminRoles.roles)
  let showAGAssignment = false
  let showLawyerAssignment = false

  useEffect(() => {
    const adminsList = admins.allIds.map((id) => admins.byId[id])
    setApplicationGuides(filterUsersByRole(adminsList, 'Steward'))
    setLawyers(filterUsersByRole(adminsList, 'Lawyer'))
  }, [admins])

  const getRoleIdByName = (roleName: string) => {
    let roleId = '0'
    for (const [key, value] of Object.entries(roles.byId)) {
      if (value.attributes.name === roleName) {
        roleId = key
      }
    }
    return roleId
  }

  const filterUsersByRole = (users: AdminUser[], roleName: string) => {
    return users
      .sort((firstUser, secondUser) => {
        if (firstUser.attributes.full_name < secondUser.attributes.full_name) {
          return -1
        }
        if (firstUser.attributes.full_name > secondUser.attributes.full_name) {
          return 1
        }
        return 0
      })
      .filter((user) => {
        const userRoleIds = user.relationships.roles.data.map((role) => role.id)
        return userRoleIds.includes(getRoleIdByName(roleName))
      })
  }

  const handleReassignTeamMember = (member: {
    lawyer_id?: number
    application_guide_id?: number
  }) => {
    reassignKase(kaseOverview.kaseId, member)
      .then(() => fetchOverview(kaseOverview.kaseId))
      .catch((error) => setGlobalError(error))
  }

  const handleKaseChange = () => {
    updateKaseTag(String(kaseOverview.kaseId), oppositeKaseTag)
  }

  useEffect(() => {
    fetchRoles()
    fetchAllAdmins()
  }, [showAGAssignment, showLawyerAssignment])

  let ag_selected_value
  let lawyer_selected_value
  let showTagChange
  if (kaseOverview.information) {
    const ag_result = _find(kaseOverview.information, [
      'key',
      'Application Guide Name'
    ])
    const lawyer_result = _find(kaseOverview.information, [
      'key',
      'Lawyer Name'
    ])
    const tag_result = _find(kaseOverview.information, ['key', 'Tags'])
    if (ag_result) {
      // The CS team needs to be able to reassign application guides to kases
      // regardless of the new_editable flag's logic, which is tied to states
      // showAGAssignment = Boolean(ag_result.new_editable) // old
      showAGAssignment = Boolean(true)
      ag_selected_value = parseInt(ag_result.selected_value)
    }
    if (lawyer_result) {
      // The CS team needs to be able to reassign lawyers to kases
      // regardless of the new_editable flag's logic, which is tied to states
      // showLawyerAssignment = Boolean(lawyer_result.new_editable) // old
      showLawyerAssignment = Boolean(true)
      lawyer_selected_value = lawyer_result.selected_value
    }
    if (tag_result) {
      showTagChange = tag_result.new_editable
    }
  }
  const kaseTags = _find(kaseOverview.information, ['key', 'Tags'])
  let oppositeKaseTag = ''
  if (kaseTags) {
    oppositeKaseTag = _includes(kaseTags.value, 'AOS') ? 'CP' : 'AOS'
  }

  return (
    <div className={className}>
      {showTagChange && (
        <Button onClick={handleKaseChange}>
          Move kase to {oppositeKaseTag}
        </Button>
      )}
      {showAGAssignment && (
        <KaseAssignmentDropdown
          memberType="Application Guide"
          availableMembers={applicationGuides}
          initiallySelectedMemberId={ag_selected_value}
          updateSelectedMember={(memberId) =>
            handleReassignTeamMember({
              application_guide_id: memberId
            })
          }
        />
      )}
      {showLawyerAssignment && (
        <KaseAssignmentDropdown
          className="mt-8"
          memberType="Lawyer"
          availableMembers={lawyers}
          initiallySelectedMemberId={lawyer_selected_value}
          updateSelectedMember={(memberId) =>
            handleReassignTeamMember({
              lawyer_id: memberId
            })
          }
        />
      )}
    </div>
  )
}

export default KaseAssignments
