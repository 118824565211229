import React, {
  ChangeEvent,
  FunctionComponent,
  InputHTMLAttributes,
  ReactNode,
  useState
} from 'react'
import { nanoid } from '@reduxjs/toolkit'
import cx from 'classnames'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * The ID of the input, used to map the label to the input element
   */
  id?: string
  /**
   * A required label for the field
   */
  label: ReactNode
  /**
   * An optional class name exclusive to the label
   */
  labelClassName?: string
  /**
   * An optional description displayed below the checkbox
   */
  description?: ReactNode
  /**
   * If true, the input will not be editable, but the text will remain
   * selectable
   */
  readOnly?: boolean
  /**
   * If true, the input field will not be editable or selectable
   */
  disabled?: boolean
  /**
   * Callback triggered when the text changes. Works the same way as a regular
   * input element.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const CheckboxField: FunctionComponent<Props> = ({
  description,
  id,
  label,
  labelClassName,
  ...otherProps
}) => {
  // Create a unique ID in case the use doesn't provide one
  const [uniqueId] = useState(nanoid())

  const fieldId = id || uniqueId

  const { disabled, className } = otherProps

  const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer'

  const containerClasses = cx(className, 'flex items-start', {
    'opacity-50': disabled
  })

  const labelClasses = cx(
    'text-black mb-2 cursor-pointer',
    cursorClass,
    labelClassName
  )

  return (
    <div className={containerClasses}>
      <div className="flex items-center h-5">
        <input
          {...otherProps}
          className={cx('checkbox', cursorClass)}
          type="checkbox"
          id={fieldId}
        />
      </div>
      <div className="pl-2 text-sm leading-5">
        <label htmlFor={fieldId} className={labelClasses}>
          {label}
        </label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  )
}

export default CheckboxField
