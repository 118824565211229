export function capitalize(input: string) {
  const capitalized = input.charAt(0).toUpperCase() + input.slice(1)
  return capitalized.split('_').join(' ')
}

export function formatIfDate(input: any) {
  if (typeof input === 'string') {
    if (isNaN(Number(input))) {
      const isDate = new Date(input).getTime() > 0

      if (isDate) {
        return new Date(input).toLocaleDateString()
      }
    }
  }

  return input
}

export function getTimeFromDateString(dateStr: string) {
  return new Date(dateStr).toLocaleTimeString()
}

export function getNonNullString(input: string | undefined) {
  // this equates undefined to be the same as null, since I am using == (not ===)
  return input == null ? '' : input
}
