import normalize from 'json-api-normalizer'

import store from 'store/root'
import server from 'api/server'
import {
  setRoles,
  setRole,
  setPermissionSettings,
  setPermissions
} from 'store/reducers/roles'
import { RolePermissions } from 'types/role'
import { formatPostRequestBody } from 'utils/format_request'

export async function fetchRoles() {
  server
    .get(`/api/v1/admin/roles`)
    .then((response) => {
      const normalizedResponse = normalize(response.data, {
        camelizeKeys: false
      })
      store.dispatch(setRoles(normalizedResponse))

      return response
    })
    .catch((error) => error)
}

export async function editRole(
  roleId: string,
  attributes: Record<string, string>,
  permissions: RolePermissions
) {
  const requestBody = formatPostRequestBody('roles', attributes, permissions)

  server
    .patch(`/api/v1/admin/roles/${roleId}`, requestBody)
    .then((response) => {
      store.dispatch(setRole(response.data.data))
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function fetchPermissionSettings() {
  server
    .get(`/api/v1/admin/settings/permissions`)
    .then((response) => {
      store.dispatch(setPermissionSettings(response.data))
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function fetchPermissions() {
  server
    .get(`/api/v1/admin/permissions`)
    .then((response) => {
      const normalizedResponse = normalize(response.data, {
        camelizeKeys: false
      })
      store.dispatch(setPermissions(normalizedResponse))
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export async function addRole(
  attributes: Record<string, string>,
  permissions: RolePermissions
) {
  const requestBody = formatPostRequestBody('roles', attributes, permissions)

  server
    .post('/api/v1/admin/roles', requestBody)
    .then((response) => {
      store.dispatch(setRole(response.data.data))
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}
