import React, { FunctionComponent, useState } from 'react'
import ReactPaginate from 'react-paginate'
import MainLayout from 'layouts/MainLayout'
import { Kase } from 'types/kases'
import { getFilteredKases } from 'api/kases'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import KasesTable from './KasesTable'
import KasesListFilters from './KasesListFilters'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import Button from 'components/Button'

const KasesList: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const [kaseList, setKaseList] = useState<Kase[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const kaseFilters = useSelector((state: RootState) => state.kaseFilters)
  const limitFilter = 25
  const [currentPaginationCount, setCurrentPaginationCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const loadKases = (selectedPage: number) => {
    setIsLoading(true)

    const offset = selectedPage * limitFilter

    getFilteredKases({
      kase_kind: kaseFilters.kaseKind,
      kase_state: kaseFilters.kaseState,
      lawyer_id: kaseFilters.lawyerId,
      steward_id: kaseFilters.stewardId,
      paid: kaseFilters.paid,
      phone: kaseFilters.phone,
      email: kaseFilters.email,
      otherEmail: kaseFilters.otherEmail,
      first_name: kaseFilters.firstName,
      last_name: kaseFilters.lastName,
      limit: limitFilter,
      offset: offset
    })
      .then((data: { data: Kase[]; total_count: number }) => {
        setKaseList(data.data)
        setCurrentPaginationCount(data.total_count)
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        setGlobalError(error)
      })
  }

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected)
    loadKases(selectedItem.selected)
  }

  const pageCount = Math.ceil(currentPaginationCount / limitFilter)
  return (
    <MainLayout>
      <div className="p-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
            Kase Search
          </h1>
        </header>
        <div className="space-y-6">
          <KasesListFilters />
          <div>
            <Button
              className="w-full text-center block"
              variant="primary"
              onClick={() => loadKases(currentPage)}
            >
              Search
            </Button>
          </div>
          <div>
            <KasesTable kases={kaseList} isLoading={isLoading} />
          </div>
        </div>
        {pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            activeClassName={'font-bold text-blue-600'}
            pageClassName={'mx-2'}
            nextClassName={'ml-2'}
            previousClassName={'mr-2'}
            containerClassName={'flex flex-row mt-16 text-sm'}
          />
        )}
        <div className="space-x-6">
          <a
            href={`${process.env.API_ORIGIN}/active_admin/applications`}
            target="_blank"
            rel="noreferrer"
          >
            (Link to Legacy Applications)
          </a>
        </div>
      </div>
    </MainLayout>
  )
}

export default KasesList
