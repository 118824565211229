import React, { CSSProperties, FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  style?: CSSProperties
}

const TableData: FunctionComponent<Props> = ({ className, ...otherProps }) => {
  const { children } = otherProps
  const classNames = cx(
    'px-6 py-2 whitespace-no-wrap border-t border-gray-200',
    className
  )

  return (
    <td className={classNames} {...otherProps}>
      <div className="text-sm leading-5 text-gray-900 overflow-hidden truncate">
        {children}
      </div>
    </td>
  )
}

export default TableData
