import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperProps } from 'components/icons/IconWrapper'

const CheckIcon: FunctionComponent<IconWrapperProps> = (props) => (
  <IconWrapper {...props}>
    <path
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </IconWrapper>
)

export default CheckIcon
