import React, { FunctionComponent } from 'react'
import { fileSizeToReadable } from 'utils/format_file_size'

interface Props {
  size: number
}

const DocumentState: FunctionComponent<Props> = ({ size }) => {
  return <>{fileSizeToReadable(size)}</>
}

export default DocumentState as FunctionComponent<Props>
