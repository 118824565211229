import React, { FunctionComponent } from 'react'
import Sidebar from 'components/Sidebar'
import TopNavigation from 'components/TopNavigation'
import GlobalErrorBanner from 'components/errors/GlobalErrorBanner'

const KitchenSinkLayout: FunctionComponent = ({ children }) => {
  return (
    <div className="h-screen flex flex-col">
      <TopNavigation />
      <div className="h-screen flex bg-gray-100 overflow-hidden">
        <Sidebar />
        <main className="w-full overflow-y-auto">
          <GlobalErrorBanner />
          {/* https://github.com/w3c/csswg-drafts/issues/129 */}
          <div className="p-4 pb-12">{children}</div>
        </main>
      </div>
    </div>
  )
}

export default KitchenSinkLayout
