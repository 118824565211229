import React, { FunctionComponent } from 'react'
import TabCountLabel from 'components/tabs/TabCountLabel'
import TabList from 'components/tabs/TabList'

interface Props {
  myTaskCount: number
  tabIndex: number
  handleTabChange: (newTabIndex: number) => void
}

const TasksTabs: FunctionComponent<Props> = ({
  myTaskCount,
  tabIndex,
  handleTabChange
}) => {
  return (
    <div className="mb-8">
      <TabList
        mobileLabel="Task view"
        onTabChange={handleTabChange}
        selected={tabIndex}
        tabs={[
          'Task Assignment',
          <>
            Assigned to Me
            <TabCountLabel isActive={tabIndex === 1}>
              {myTaskCount}
            </TabCountLabel>
          </>
        ]}
      />
    </div>
  )
}

export default TasksTabs
