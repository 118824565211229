import React, { FunctionComponent, useEffect, useState } from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'
import Table from 'components/tables/Table'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { RootState } from 'store/root'
import { capitalize, formatIfDate } from '../../utils/format_utils'
import { updateKaseOverview } from 'api/overview'
import { OverviewInformationItem } from 'types/kaseOverview'
import { isEditableSelect } from 'types/guards'
interface Props {
  className?: string
  setHeight?: (height: number) => void
}

const KaseInformation: FunctionComponent<Props> = ({
  className,
  setHeight
}) => {
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const [isHeightSet, setIsHeightSet] = useState(false)
  const [sortedKaseFields, setSortedKaseFields] = useState<
    OverviewInformationItem[]
  >([])
  const [accountHolder, setAccountHolder] = useState('')
  const { setGlobalError } = useGlobalError()

  useEffect(() => {
    if (!_isEmpty(kaseOverview.information)) {
      const sorted = _orderBy(kaseOverview.information, ['order'], ['asc'])
      setSortedKaseFields(sorted)
      setAccountHolder(
        kaseOverview.information.find((info) => info.key === 'Account Owner')
          ?.value
      )
    }
  }, [kaseOverview])

  useEffect(() => {
    if (!isHeightSet && setHeight) {
      const height = document.getElementById('kase-information-section')
        ?.clientHeight
      if (height && height > 500) {
        setHeight(height)
        setIsHeightSet(true)
      } else {
        setHeight(501)
      }
    }
  }, [kaseOverview])

  const formatLabel = (label: string, label_override?: string) => {
    // this logic will handle label_override==<undefined | null> also, in case the method is called without the argument
    if (label_override) {
      return label_override
    } else if (label === 'task_sla') {
      return 'Task SLA'
    } else {
      return capitalize(label)
    }
  }

  type AnswerObject = { [key: string]: string }

  const formatValue = (
    value: string | number | string[] | null | AnswerObject,
    editable_type?: string
  ) => {
    if (Array.isArray(value)) {
      // If value is an array, we assume they are tags so format it in the style of "pillboxes"
      return value.map((item, index) => {
        return (
          <span
            key={`${index}-${item}`}
            className="bg-gray-200 font-normal mr-2 py-2 px-4 rounded-full"
          >
            {item}
          </span>
        )
      })
    } else if (editable_type === 'datetime' && typeof value === 'string') {
      return new Date(value).toLocaleString()
    } else if (
      typeof value === 'number' ||
      typeof value === 'string' ||
      value === null
    ) {
      return formatIfDate(value)
    } else {
      // If value is an answer object, format it as a list
      const answerObject = value as AnswerObject

      return Object.entries(answerObject).map(([key, value], index) => {
        return (
          <div key={`${index}-${key}`} className="flex flex-col">
            <span className="font-bold">{formatLabel(key)}</span>
            <span>{formatIfDate(value)}</span>
          </div>
        )
      })
    }
  }

  const saveValue = (key: any, value: any) => {
    updateKaseOverview(kaseOverview.kaseId, key, value).catch((error) => {
      setGlobalError(error)
    })
  }

  const saveMetadataValue = (key: any, value: any) => {
    updateKaseOverview(kaseOverview.kaseId, 'kase_metadata', {
      metadata: {
        [key]: value
      }
    }).catch((error) => {
      setGlobalError(error)
    })
  }

  return (
    <div className={cx(className, 'pr-6')} id="kase-information-section">
      <h2 className="font-bold pl-6 mb-2 text-base">Case Information</h2>
      <Table shadow={false}>
        <Table.Body className="bg-gray-100">
          {sortedKaseFields.map((field, index) => {
            if (!field.order || field.order < 0) {
              return null
            }
            if (field.editable) {
              if (isEditableSelect(field)) {
                return (
                  <Table.Row key={`${field.key.replace(' ', '_')}-${index}`}>
                    <Table.Cell className="w-1/2" border={false}>
                      {field.key}
                    </Table.Cell>
                    <Table.EditableSelectCell
                      className="w-1/2"
                      border={false}
                      options={field.editable_options}
                      selected={field.selected_value}
                      onSaveHandler={saveValue}
                      keyValue={field.save_key}
                      value={field.value || ''}
                    >
                      {formatValue(field.value)}
                    </Table.EditableSelectCell>
                  </Table.Row>
                )
              } else {
                return (
                  <Table.Row key={`${field.key.replace(' ', '_')}-${index}`}>
                    <Table.Cell className="w-1/2" border={false}>
                      {field.key}
                    </Table.Cell>
                    <Table.EditableCell
                      className="w-1/2"
                      border={false}
                      onSaveHandler={saveValue}
                      keyValue={field.save_key}
                      value={field.value || ''}
                      input_type={field.editable_type}
                    >
                      {formatValue(field.value)}
                    </Table.EditableCell>
                  </Table.Row>
                )
              }
            }
            return (
              <Table.Row key={`${field.key.replace(' ', '_')}-${index}`}>
                <Table.Cell className="w-1/2" border={false}>
                  {field.key}
                </Table.Cell>
                <Table.Cell className="w-1/2" border={false}>
                  {formatValue(field.value, field.editable_type)}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <hr />
      <Table shadow={false}>
        <Table.Body className="bg-gray-100">
          {Object.entries(kaseOverview.contact_info).map(([key, value]) => {
            const keyLabel = formatLabel(key)
            return (
              <Table.Row key={`${key}-${value}`}>
                <Table.Cell className="w-1/2" border={false}>
                  {keyLabel}
                </Table.Cell>
                <Table.Cell className="w-1/2" border={false}>
                  <span
                    className={cx({
                      'bg-gray-900 text-gray-200 py-1 px-2 rounded font-bold': keyLabel
                        .toLowerCase()
                        .includes(accountHolder)
                    })}
                  >
                    {value}
                  </span>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <hr />
      <Table shadow={false}>
        <Table.Body className="bg-gray-100">
          {kaseOverview.admin_metadata?.map((field) => {
            const keyLabel = formatLabel(field.key, field.display_label)
            return (
              <Table.Row key={`${field.key}-${field.value}`}>
                <Table.Cell className="w-1/2" border={false}>
                  {keyLabel}
                </Table.Cell>
                <Table.EditableCell
                  className="w-1/2"
                  border={false}
                  onSaveHandler={saveMetadataValue}
                  keyValue={field.key}
                  value={field.value || ''}
                  input_type={field.editable_type}
                >
                  {formatValue(field.value)}
                </Table.EditableCell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default KaseInformation
