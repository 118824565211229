import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect
} from 'react'
import { DocumentReviewListFilters } from 'api/documentReview'
import { AdminUser } from 'pages/admins/AdminList'
import Button from 'components/Button'
import { fetchAllStewards } from 'api/adminUsers'
import Label from 'components/forms/Label'

/**
 * These values are mapped to lists of Workflow Statuses on the backend.
 */
const WORKFLOW_STATUSES = [
  { value: 'all', label: 'Any' },
  {
    value: 'aos_upto_doc_gathering',
    label: 'AOS - Document Gathering'
  },
  {
    value: 'aos_post_doc_gathering',
    label: 'AOS - Post Document Gathering'
  },
  {
    value: 'cp1_upto_doc_gathering',
    label: 'CP1 - Document Gathering'
  },
  {
    value: 'cp1_post_doc_gathering',
    label: 'CP1 - Post Document Gathering'
  },
  {
    value: 'cp2_upto_doc_gathering',
    label: 'CP2 - Document Gathering'
  },
  {
    value: 'cp2_post_doc_gathering',
    label: 'CP2 - Post Document Gathering'
  },
  {
    value: 'natz_upto_doc_gathering',
    label: 'NATZ - Document Gathering'
  },
  {
    value: 'natz_post_doc_gathering',
    label: 'NATZ - Post Document Gathering'
  },
  {
    value: 'all_upto_doc_gathering',
    label: 'ALL - Document Gathering'
  },
  {
    value: 'all_post_doc_gathering',
    label: 'ALL - Post Document Gathering'
  }
]

interface Props {
  updateFilters: (filters: DocumentReviewListFilters) => void
}

const DocumentReviewFilters: FunctionComponent<Props> = (props) => {
  const [stewardFilter, setStewardFilter] = useState<number>(-1)
  const [stewards, setStewards] = useState<AdminUser[]>([])

  // When the filters have changed, we enable the "Apply" button
  const [filtersHaveChanged, setFiltersHaveChanged] = useState(false)

  const [workflowStatusFilter, setWorkflowStatusFilter] = useState<string>(
    'all'
  )

  // Fetch the list of stewards
  useEffect(() => {
    fetchAllStewards().then((response) => {
      setStewards([
        {
          id: -1,
          name: 'Any'
        },
        ...response.data.stewards
      ])
    })
  }, [])

  const updateStewardFilter = (event: ChangeEvent<HTMLSelectElement>) => {
    const stewardId = parseInt(event.target.value, 10)
    setStewardFilter(stewardId)
    setFiltersHaveChanged(true)
  }

  const updateWorkflowStatusFilter = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const workflowStatus = event.target.value
    setWorkflowStatusFilter(workflowStatus)
    setFiltersHaveChanged(true)
  }

  const applyFilters = () => {
    const filters: DocumentReviewListFilters = {}
    if (stewardFilter !== -1) {
      filters.stewardId = stewardFilter
    }

    if (workflowStatusFilter !== 'all') {
      filters.workflowStatus = workflowStatusFilter
    }

    props.updateFilters(filters)
    setFiltersHaveChanged(false)
  }

  return (
    <div className="p-2 bg-white flex flex-wrap items-end mb-6 shadow sm:rounded-lg">
      <div className="p-4">
        <Label htmlFor="stage-filter">Filter by stage</Label>
        <div className="inline-block relative w-64">
          <select
            value={workflowStatusFilter}
            onChange={updateWorkflowStatusFilter}
            id="stage-filter"
            className="block appearance-none w-full text-gray-700 bg-white border border-gray-300 px-3 py-2 pr-8 rounded-sm leading-tight focus:outline-none focus:shadow-outline"
          >
            {WORKFLOW_STATUSES.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="p-4">
        <Label htmlFor="steward-filter">Filter by steward</Label>
        <div className="inline-block relative w-64">
          <select
            value={stewardFilter}
            onChange={updateStewardFilter}
            id="steward-filter"
            className="block appearance-none w-full text-gray-700 bg-white border border-gray-300 px-3 py-2 pr-8 rounded-sm leading-tight focus:outline-none focus:shadow-outline"
          >
            {stewards.map((steward) => (
              <option key={steward.id} value={steward.id}>
                {steward.name}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="p-4">
        <Button disabled={!filtersHaveChanged} onClick={applyFilters}>
          Apply Filters
        </Button>
      </div>
    </div>
  )
}

export default DocumentReviewFilters
