import React, { FunctionComponent, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'

import RoleForm from './RoleForm'
import ROUTES from 'constants/routes'
import { editRole, fetchPermissions, fetchRoles } from 'api/roles'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { RolePermissions } from 'types/role'

const EditRoleForm: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const { roleId } = useParams()
  const history = useHistory()

  const role = useSelector(
    (state: RootState) => state.adminRoles.roles.byId[roleId]
  )

  useEffect(() => {
    Promise.all([fetchRoles(), fetchPermissions()])
  }, [])

  const handleSubmit = (
    attributes: { name: string; description: string },
    permissions: RolePermissions
  ) => {
    editRole(roleId, attributes, permissions)
      .then(() => {
        history.push(`${ROUTES.ROLES_ROOT}`)
      })
      .catch((error) => {
        setGlobalError(error)
        // TODO: delete when api endpoints are working
        history.push(`${ROUTES.ROLES_ROOT}`)
      })
  }

  if (!role) {
    return <div>Loading...</div>
  }

  return (
    <RoleForm
      currentDesc={role?.attributes?.description}
      currentName={role?.attributes?.name}
      currentPermissions={role.relationships.permissions.data}
      handleSubmit={handleSubmit}
    />
  )
}

export default EditRoleForm
