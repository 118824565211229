import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { capitalize } from 'utils/language_helpers'
import { todayOrFormattedDate } from 'utils/format_dates'
import { WorkflowStatus } from 'constants/workflow'
import { getRouteForDocumentReviewTool } from 'constants/routes'
import { KaseDocumentReview } from 'types/documentReview'
import Table from 'components/tables/Table'
import ActionMenu from 'components/action_menu/ActionMenu'

const getFormattedNames = (review: KaseDocumentReview) => {
  return review.names
    .map((name) => name.first_name) // Get the list of first names
    .filter((name) => !!name) // Remove the empty ones
    .join(' & ') // Join them together
}

const getNumDocumentsToReview = (review: KaseDocumentReview) => {
  const toReview = review.pending_documents || 0

  if (toReview === 0) {
    return 'No documents to review'
  }

  if (toReview === 1) {
    return '1 document to review'
  }

  return `${toReview} documents to review`
}

/**
 * Reads workflow category from status into a human-readable version.
 * If the category is falsy, returns "N/A".
 *
 * @example getReadableWorkflowStatus("aos_doc_gathering") // "AOS"
 */
function getWorkflowCategoryFromWorkflowStatus(status?: WorkflowStatus) {
  if (!status) {
    return 'N/A'
  }

  const categoryMatch = status.match(/(natz_|aos_|cp_)/)
  if (!categoryMatch || categoryMatch.length === 0) {
    return 'N/A'
  }

  const readableCategory = categoryMatch[0].replace('_', '')
  return readableCategory.toUpperCase()
}

/**
 * Formats a workflow_status in a human-readable version. If the status is
 * falsy, returns "N/A".
 *
 * @example
 * getReadableWorkflowStatus("aos_doc_gathering") // "Doc gathering"
 */
function getReadableWorkflowStatus(status?: WorkflowStatus) {
  if (!status) {
    return 'N/A'
  }

  const readableStatus = status
    .replace(/(natz_|aos_|cp_)/, '') // Remove the prefix
    .replace(/_/g, ' ') // Replace underscores with spaces
  return capitalize(readableStatus)
}

function formatSla(sla: number) {
  if (sla === null) return null

  let day = 'day'
  if (Math.abs(sla) !== 1) {
    day += 's'
  }

  return `${sla} ${day}`
}

interface Props {
  isLoading?: boolean
  documentReviews: KaseDocumentReview[]
}

const DocumentReviewFilters: FunctionComponent<Props> = ({
  documentReviews,
  isLoading
}) => {
  const assignReviewToSelf = () => {
    // TODO
  }

  const unsassignReview = () => {
    // TODO
  }

  const currentUserId = 0 // TODO

  return (
    <div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header key="name">Name</Table.Header>
            <Table.Header key="pending">Pending</Table.Header>
            <Table.Header key="workflow_stage">Workflow stage</Table.Header>
            <Table.Header key="type">Type</Table.Header>
            <Table.Header key="last_upload">Last upload</Table.Header>
            <Table.Header key="sla">SLA</Table.Header>
            <Table.Header key="assignee">Assignee</Table.Header>
            <th
              key="actions"
              className="p-3 border-b border-gray-200 bg-gray-50 w-0"
            />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {documentReviews.map((review) => (
            <Table.Row key={review.kase_id}>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap w-0">
                <div className="font-medium text-blue-800">
                  <Link
                    className="hover:underline"
                    title="Go to doc review tool"
                    to={getRouteForDocumentReviewTool(review.kase_id)}
                  >
                    {getFormattedNames(review)}
                  </Link>
                  {review.prioritized && (
                    <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                      High Priority
                    </span>
                  )}
                  {review.has_trello_label && (
                    <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      MRE/Boundless Lite
                    </span>
                  )}
                </div>
                <div className="text-gray-500">
                  {getNumDocumentsToReview(review)}
                </div>
              </Table.Cell>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                <span className="text-gray-900">
                  {review.needs_customer_action_doc_requests}
                </span>
              </Table.Cell>
              <Table.Cell
                className="px-6 py-4 whitespace-no-wrap"
                title={review.workflow_status}
              >
                <span className="text-gray-800">
                  {getReadableWorkflowStatus(review.workflow_status)}
                </span>
              </Table.Cell>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                <span className="text-gray-800">
                  {getWorkflowCategoryFromWorkflowStatus(
                    review.workflow_status
                  )}
                </span>
              </Table.Cell>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                <span className="text-gray-800">
                  {todayOrFormattedDate(review.last_uploaded_at) || 'n/a'}
                </span>
              </Table.Cell>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                <span
                  className={cx('text-gray-800', {
                    'text-gray-900': review.sla >= 0,
                    'text-red-700': review.sla < 0
                  })}
                >
                  {formatSla(review.sla)}
                </span>
              </Table.Cell>
              <Table.Cell className="px-6 py-4 whitespace-no-wrap font-medium">
                {review.reviewer_name || 'Unassigned'}
              </Table.Cell>
              <Table.Cell className="p-4 relative">
                <ActionMenu
                  align="end"
                  actions={[
                    {
                      label: 'Assign to me',
                      onClick: assignReviewToSelf,
                      disabled: review.reviewer_id != null
                    },
                    {
                      label: 'Unassign',
                      onClick: unsassignReview,
                      disabled: review.reviewer_id !== currentUserId
                    }
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {!isLoading && documentReviews.length === 0 && (
        <div className="p-6 bg-white text-center">
          <p className="text-sm text-gray-600">
            No document reviews to see here. Try adjusting your filters.
          </p>
        </div>
      )}
    </div>
  )
}

export default DocumentReviewFilters
