import server from 'api/server'
import { formatPostRequestBody } from 'utils/format_request'

const USERS_URL = '/api/v1/admin/users'

export const getFilteredUsers = async ({
  include_users = 'false',
  email = '',
  limit,
  offset = 0
}: {
  include_users: string
  email: string
  limit?: number
  offset?: number
}) => {
  const params = { include_users, email, limit, offset }
  return server.get(USERS_URL, { params }).then(({ data }) => {
    return data
  })
}

export async function fetchUserById(userId: string | number) {
  return server.get(`${USERS_URL}/${userId}`)
}

export async function updateUser(
  userId: string,
  userAttributes: Record<string, any>
) {
  const data = formatPostRequestBody('users', userAttributes)

  return server.patch(`${USERS_URL}/${userId}`, data)
}
