import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import CardBody from './Body'
import CardFooter from './Footer'
import CardHeader from './Header'

interface Props {
  className?: string
  disabled?: boolean
  selected?: boolean
}

type CardComponentShape = FunctionComponent<Props> & {
  Body: typeof CardBody
  Footer: typeof CardFooter
  Header: typeof CardHeader
}

const DisabledMask = () => (
  <div className="absolute z-1 top-0 left-0 bottom-0 right-0 bg-gray-100 opacity-75" />
)

const Card: CardComponentShape = ({
  children,
  className,
  disabled = false,
  selected = false
}) => {
  const classes = cx(
    'sm:rounded-lg bg-white overflow-hidden relative z-0 flex flex-col',
    {
      shadow: !selected,
      'shadow-selected border-2 border-blue-600': selected,
      [className as string]: !!className
    }
  )

  return (
    <div className={classes}>
      {disabled && <DisabledMask />}
      {children}
    </div>
  )
}

Card.Body = CardBody
Card.Footer = CardFooter
Card.Header = CardHeader

export default Card as CardComponentShape
