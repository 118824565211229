import React, { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { fetchIssues, saveMessage, updateMessage } from 'api/issues'
import { fetchKaseData } from 'api/kaseData'
import { fetchTasks } from 'api/tasks'
import { Issue } from 'types/issues'
import { IssueStatus } from 'constants/issues'
import { TaskType } from 'constants/tasks'
import { formattedDate } from 'utils/format_dates'

import MainLayout from 'layouts/MainLayout'
import NewIssue from './NewIssue'
import IssueList from './IssueList'
import Alert from 'components/Alert'
import UpdateTask from './update_task_options/UpdateTask'
import StatusTabs from './StatusTabs'
import { Message } from 'types/message'
import { RootState } from 'store/root'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import CreateNewIssueSlate from './CreateNewIssueSlate'
import { getCurrentOpenTask } from 'store/selectors/kaseTasks'
import { fetchAllAdmins } from 'api/adminUsers'
import ThreadPanel from 'components/ThreadPanel'
import TaskInfo from './TaskInfo'
import { useTaskAssignedToCustomer } from 'hooks/useTaskAssignedToCustomer'

const Issues: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams()

  const messages = useSelector((state: RootState) => state.kaseIssues.messages)
  const adminsMap = useSelector((state: RootState) => state.adminUsers.byId)

  const currentOpenTask = useSelector((state: RootState) =>
    getCurrentOpenTask(state)
  )
  const kaseData = useSelector((state: RootState) => state.kaseData)
  const tasks = useSelector((state: RootState) => state.kaseTasks)

  const [issuesLoaded, setIssuesLoaded] = useState(false)
  const [showNewIssueForm, setShowNewIssueForm] = useState(false)
  const [currentIssue, setCurrentIssue] = useState<Issue | null>(null)
  const [currentActiveView, setCurrentActiveView] = useState(IssueStatus.Open)
  const [currentThread, setCurrentThread] = useState<Message[]>([])

  const taskAssignedToCustomer = useTaskAssignedToCustomer()

  // Fetch the list of issues and update the store
  useEffect(() => {
    Promise.all([
      fetchIssues(kaseId).catch((error) => setGlobalError(error)),
      fetchKaseData(kaseId, true).catch((error) => setGlobalError(error)),
      // The admins are used by the ThreadPanelContainer
      fetchAllAdmins().catch((error) => setGlobalError(error)),
      fetchTasks({
        kaseId,
        taskKind: TaskType.QA
      }).catch((error) => setGlobalError(error))
    ]).then(() => {
      setIssuesLoaded(true)
    })
  }, [])

  // When the list of messages is updated in the store, we create the list of
  // messages to display in the thread.
  useEffect(() => {
    if (currentIssue != null) {
      // Make the description the same shape as a message to be the first in the
      // thread panel
      const descriptionAsMessage: Message = {
        id: 'description-as-message',
        attributes: {
          issue_id: currentIssue.id,
          creator_id: currentIssue.attributes.creator_id,
          created_at: currentIssue.attributes.created_at,
          text: currentIssue.attributes.description
        }
      }

      // Compile the list of messages for this issue
      const messagesForCurrentIssue = [descriptionAsMessage]
      messages.allIds.forEach((messageId) => {
        if (messages.byId[messageId].attributes.issue_id === currentIssue.id) {
          messagesForCurrentIssue.push(messages.byId[messageId])
        }
      })

      // Set the list of messages
      setCurrentThread(messagesForCurrentIssue)
    }
  }, [currentIssue, messages])

  const taskAssignedToCustomerOrResolved = useMemo(() => {
    // Issues display should be (mostly) read only if the task is currently assigned
    // to the customer or it has been resolved
    // Issue resolution should be allowed unless the task is resolved
    // For now, if assignee id is null it means it is assigned to boundless
    return !currentOpenTask || taskAssignedToCustomer
  }, [currentOpenTask, taskAssignedToCustomer])

  const taskResolved = useMemo(() => !currentOpenTask, [currentOpenTask])

  const openIssueThread = (issue: Issue) => () => {
    setCurrentIssue(issue)
  }

  const saveNewMessage = (reply: string) => {
    if (!currentIssue) {
      return
    }

    saveMessage({ kaseId, issueId: currentIssue.id, text: reply }).then(
      (message) => {
        // Update the list of messages
        setCurrentThread([...currentThread, message])

        // Scroll to the latest message, if necessary
        setTimeout(() => {
          const addedReply = document.getElementById(`message-${message.id}`)
          addedReply?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      }
    )
  }

  const updateActiveView = (statusClicked: IssueStatus) => {
    setCurrentActiveView(statusClicked)
  }

  const submitMessageEdits = async (data: {
    id: string
    text: string
    issueId: string
  }) => {
    return updateMessage({
      kaseId,
      messageId: data.id,
      text: data.text,
      issueId: data.issueId
    }).catch((error) => setGlobalError(error))
  }

  const taskStatusAlert = () => {
    if (!currentOpenTask) {
      const resolvedTask = Object.values(tasks.byId)[0]
      const formattedResolutionDate = formattedDate(
        resolvedTask.attributes.metadata.resolved_at
      )

      return (
        <Alert
          border
          title={`QA phase was completed on ${formattedResolutionDate}`}
          variant="success"
          className="mb-4"
        />
      )
    }

    return (
      <Alert
        border
        title="This task is currently assigned to the customer"
        variant="info"
        className="mb-4"
      />
    )
  }

  if (!issuesLoaded) return <div>Loading issues...</div>

  const threadPanelIsOpen = currentIssue != null

  return (
    <MainLayout
      footer={
        <footer className="bg-white py-3 px-4 flex justify-between items-end shadow-t relative">
          <UpdateTask
            currentOpenTaskId={currentOpenTask?.id}
            kaseId={kaseId}
            taskAssignedToCustomer={taskAssignedToCustomer}
          />
        </footer>
      }
    >
      <div className="p-4 flex flex-col flex-grow">
        <header className="max-w-5xl w-full mx-auto pb-4">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            QA Returns
          </h1>
          <div className="mb-8 text-sm font-light text-gray-500">
            Application #{kaseData.id}
          </div>
          {tasks.allIds.length > 0 &&
            taskAssignedToCustomerOrResolved &&
            taskStatusAlert()}
          <TaskInfo />
        </header>
        <div className="max-w-5xl w-full mx-auto">
          <StatusTabs setCurrentActiveView={updateActiveView} />
          <IssueList
            openIssueThread={openIssueThread}
            kaseId={kaseId}
            statusFilter={currentActiveView}
            taskAssignedToCustomerOrResolved={taskAssignedToCustomerOrResolved}
            taskResolved={taskResolved}
          />
          <CreateNewIssueSlate
            createIssue={() => setShowNewIssueForm(true)}
            disabled={
              currentActiveView === IssueStatus.Resolved ||
              taskAssignedToCustomerOrResolved
            }
            issueFilter={currentActiveView}
            hidden={showNewIssueForm}
          />
          {showNewIssueForm && (
            <NewIssue
              closeForm={() => setShowNewIssueForm(false)}
              kaseId={kaseId}
            />
          )}
          <ThreadPanel
            admins={adminsMap}
            customerInfo={kaseData}
            isOpen={threadPanelIsOpen}
            onClose={() => setCurrentIssue(null)}
            messages={currentThread}
            // Only allow replies if task and issue are open
            allowReplies={
              !taskAssignedToCustomerOrResolved &&
              currentIssue?.attributes.status !== IssueStatus.Resolved
            }
            onSaveReply={saveNewMessage}
            submitMessageEdits={submitMessageEdits}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default Issues
