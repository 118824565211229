import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import cx from 'classnames'

import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { User } from 'types/users'
import { fetchUserById, updateUser } from 'api/users'
import Table from 'components/tables/Table'
import TableData from 'components/tables/TableData'
import Button from 'components/Button'
import MainLayout from 'layouts/MainLayout'
import LoadingSpinner from 'components/LoadingSpinner'
import { formatIfDate } from 'utils/format_utils'

const UserDetail: FunctionComponent = () => {
  const { setGlobalError } = useGlobalError()
  const { userId } = useParams<{ userId: string }>()
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    fetchUserById(userId)
      .then((resp: any) => {
        setUser(resp.data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setUser(undefined)
        setGlobalError(error)
      })
  }, [userId])

  const saveValue = (key: any, value: any) => {
    setIsLoading(true)
    updateUser(userId, { [key]: value })
      .then((resp: any) => {
        setUser(resp.data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setGlobalError(error)
        setIsLoading(false)
      })
  }

  return (
    <MainLayout>
      <div className="p-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
            User Information
          </h1>
          <Link
            to={`/users`}
            className="inline-flex items-center text-blue-600 hover:text-blue-800"
          >
            Users List
          </Link>
        </header>
        {isLoading || !user ? (
          <div className="mt-48">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Table shadow={false}>
              <Table.Body className="bg-gray-100">
                <Table.Row key="email">
                  <Table.Cell className="w-1/4" border={false}>
                    Email
                  </Table.Cell>
                  <Table.EditableCell
                    className="w-1/2"
                    border={false}
                    onSaveHandler={saveValue}
                    keyValue="email"
                    value={user.attributes.email}
                  >
                    {user.attributes.email}
                  </Table.EditableCell>
                </Table.Row>
                <Table.Row key="created_at">
                  <Table.Cell className="w-1/4" border={false}>
                    Created
                  </Table.Cell>
                  <Table.Cell className="w-1/2" border={false}>
                    {formatIfDate(user.attributes.created_at)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row key="guest">
                  <Table.Cell className="w-1/4" border={false}>
                    Guest
                  </Table.Cell>
                  <Table.Cell className="w-1/2" border={false}>
                    {user.attributes.guest.toString()}
                  </Table.Cell>
                </Table.Row>
                <Table.Row key="guest-id">
                  <Table.Cell className="w-1/4" border={false}>
                    Guest ID
                  </Table.Cell>
                  <Table.Cell className="w-1/2" border={false}>
                    {user.attributes.guest_id}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <hr />
            <h2 className="font-bold pl-6 mb-2 mt-4 text-base">Kases</h2>
            <Table shadow={false}>
              <Table.Body className="bg-gray-100">
                <Table.Row>
                  <Table.Header>ID</Table.Header>
                  <Table.Header>Kind</Table.Header>
                  <Table.Header>Created</Table.Header>
                  <Table.Header>Paid</Table.Header>
                  <Table.Header>State</Table.Header>
                  <Table.Header>Current</Table.Header>
                  <Table.Header></Table.Header>
                  <Table.Header></Table.Header>
                </Table.Row>
                {user.attributes.kases.map((kase) => {
                  return (
                    <Table.Row
                      key={`kase-${kase.id}`}
                      className={cx({
                        'bg-green-100': kase.attributes.is_current
                      })}
                    >
                      <TableData>{kase.id}</TableData>
                      <TableData>{kase.attributes.kind}</TableData>
                      <TableData>
                        {formatIfDate(kase.attributes.created_at)}
                      </TableData>
                      <TableData>{kase.attributes.paid?.toString()}</TableData>
                      <TableData>{kase.attributes.state}</TableData>
                      <TableData>
                        {kase.attributes.is_current.toString()}
                      </TableData>
                      <TableData>
                        {kase.attributes.version_2 ? (
                          <Link
                            to={`/overview/${kase.id}`}
                            className="inline-flex items-center text-blue-600 hover:text-blue-800"
                          >
                            View Kase
                          </Link>
                        ) : (
                          <a
                            className="inline-flex items-center text-blue-600 hover:text-blue-800"
                            href={`${process.env.API_ORIGIN}/active_admin/applications/${kase.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Kase (Old Admin)
                          </a>
                        )}
                      </TableData>
                      <TableData>
                        {!kase.attributes.is_current && (
                          <Button
                            variant="plain"
                            onClick={() =>
                              saveValue('current_kase_id', kase.id)
                            }
                            data-test="edit-user-button"
                          >
                            Set As Current
                          </Button>
                        )}
                      </TableData>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </>
        )}
      </div>
    </MainLayout>
  )
}

export default UserDetail
