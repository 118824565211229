import Table from 'components/tables/Table'
import React, { FunctionComponent, useState } from 'react'
import { Breakdown, PostPayCartLineItem } from 'types/payment'
import { formatCentsInDollars } from 'utils/format_money'
import Button from 'components/Button'

export const PostPayCartLineItemTableRow: FunctionComponent<{
  lineItem: PostPayCartLineItem
  breakdown?: Breakdown[]
}> = ({ lineItem, breakdown }) => {
  // This is the only line item we need to show the breakdown for
  // We can add more or refactor this to be more dynamic when needed
  const isUSCISFees = lineItem.display_name === 'Government Fees'
  const uscisFeesOnly = breakdown?.filter(
    (breakdownItem) => breakdownItem.product_type === 'uscis'
  )
  const BreakdownButton = () => {
    return (
      <Button
        variant="plain"
        className="text-blue-500 ml-2"
        onClick={() => setShowBreakdown(!showBreakdown)}
      >
        {showBreakdown ? 'Hide' : 'Show'} breakdown
      </Button>
    )
  }

  const [showBreakdown, setShowBreakdown] = useState(false)

  return (
    <>
      <Table.Row>
        <Table.Cell
          className={`pl-10 ${showBreakdown && 'font-bold'}`}
          colSpan={2}
        >
          {lineItem.display_name} {isUSCISFees && <BreakdownButton />}
        </Table.Cell>
        <Table.Cell className={showBreakdown ? 'font-bold' : ''}>
          {showBreakdown && 'Total: '}
          {formatCentsInDollars(lineItem.amount_in_cents)}
        </Table.Cell>
      </Table.Row>
      {isUSCISFees &&
        showBreakdown &&
        uscisFeesOnly?.map((breakdownItem, index) => (
          <Table.Row key={index}>
            <Table.Cell
              className="pl-20 pr-10 text-xs"
              border={false}
              colSpan={2}
            >
              {breakdownItem.display_name}
            </Table.Cell>
            <Table.Cell border={false}>
              {formatCentsInDollars(breakdownItem.amount_in_cents)}
            </Table.Cell>
          </Table.Row>
        ))}
    </>
  )
}
