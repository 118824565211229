import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import { CurrentUser } from 'types/currentUser'
import { NAV_LINKS } from 'constants/nav'
import { pathMatchesUrl } from 'utils/match_path'
import PermissionLinkWrapper from 'components/PermissionLinkWrapper'

interface MobileNavLinksProps {
  to: string
  exact?: boolean
}

const MobileNavLinks: FunctionComponent<MobileNavLinksProps> = ({
  children,
  to,
  exact = false
}) => {
  // Figure out whether the link is active
  const location = useLocation()
  let isActive = pathMatchesUrl(to, location.pathname)
  if (exact) {
    isActive = location.pathname === to
  }

  const classes = cx('block p-2 rounded text-white', {
    'bg-gray-800': isActive,
    'hover:bg-gray-900 focus:bg-gray-900': !isActive
  })

  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  )
}

interface Props {
  /**
   * The MobileNav won't render unless this is true
   */
  isVisible?: boolean
  /**
   * Callback for the "Sign out" button
   */
  onSignOut: () => void
  currentUser: Partial<CurrentUser>
}

const MobileNav: FunctionComponent<Props> = ({
  isVisible,
  onSignOut,
  currentUser
}) => {
  return (
    <div
      className={cx('p-2 items-center h-full flex-grow md:hidden', {
        hidden: !isVisible
      })}
    >
      {NAV_LINKS.map((item) => (
        <PermissionLinkWrapper key={item.route} permission={item.permission}>
          <MobileNavLinks to={item.route}>{item.label}</MobileNavLinks>
        </PermissionLinkWrapper>
      ))}
      <a
        className="block p-2 rounded text-white hover:bg-gray-900 focus:bg-gray-900"
        href={process.env.API_ORIGIN + '/active_admin/applications'}
        rel="noreferrer"
        target="_blank"
      >
        Applications (Old Admin)
      </a>
      <hr className="border-gray-600 m-2" />
      <div className="px-2">
        {currentUser.fullName && (
          <p className="text-sm text-gray-100">{currentUser.fullName}</p>
        )}
        {currentUser.email && (
          <p className="text-sm text-gray-200 mb-2">{currentUser.email}</p>
        )}
      </div>
      <button
        className="block w-full text-left p-2 text-white rounded hover:bg-gray-900 focus:bg-gray-900"
        type="button"
        onClick={onSignOut}
      >
        Sign out
      </button>
    </div>
  )
}

export default MobileNav
