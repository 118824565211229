import ThreadPanelMessage, {
  ThreadPanelMessageProps
} from 'components/ThreadPanelMessage'
import { connect } from 'react-redux'
import { KaseIssuesShape } from 'store/reducers/kaseIssues'
import { RootState } from 'store/root'
import { CurrentUser } from 'types/currentUser'
import { Message } from 'types/message'

/**
 * Returns the most recent message sent by the user provided
 */
function getLatestMessageFromUser(messages: Message[], userId: string) {
  let latestTimeStamp = new Date(messages[0].attributes.created_at)
  let latestMessage: Message | null = null

  messages.forEach((message) => {
    const { creator_id, created_at } = message.attributes
    if (creator_id === userId && new Date(created_at) > latestTimeStamp) {
      latestTimeStamp = new Date(created_at)
      latestMessage = message
    }
  })

  return latestMessage as Message | null
}

/**
 * Returns false if the message was sent BEFORE another message sent by the
 * customer.
 */
function messageIsEditable(
  message: Message,
  messages: KaseIssuesShape['messages'],
  customerId: string
) {
  // The Redux store keeps track of all messages for the kase, not just the ones
  // that match this issue. That's why we filter out the messages for other
  // issues.
  const issueId = message.attributes.issue_id
  const messagesIdsForThisIssue = Object.values(messages.byId).filter(
    (message) => message.attributes.issue_id === issueId
  )

  // "last" chronologically, not "last in the array", just in case the array is
  // ever out of order
  const lastMessageFromCustomer = getLatestMessageFromUser(
    messagesIdsForThisIssue,
    customerId
  )

  // If the customer never sent a message, we can edit any message
  if (lastMessageFromCustomer == null) {
    return true
  }

  // If the last message from the customer was sent AFTER this message, we
  // can't edit it
  return (
    new Date(message.attributes.created_at) >=
    new Date(lastMessageFromCustomer.attributes.created_at)
  )
}

function messageIsFromCurrentUser(message: Message, currentUser: CurrentUser) {
  return message.attributes.creator_id === currentUser.id
}

function mapStateToProps(state: RootState, ownProps: ThreadPanelMessageProps) {
  return {
    ...ownProps,
    isEditable:
      ownProps.isEditable &&
      messageIsFromCurrentUser(
        ownProps.message,
        state.currentUser as CurrentUser
      ) &&
      messageIsEditable(
        ownProps.message,
        state.kaseIssues.messages,
        state.kaseData.attributes?.user_id || ''
      )
  }
}

export default connect(mapStateToProps)(ThreadPanelMessage)
