import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { AdminUser } from 'types/users'
import { Task } from 'types/task'
import { Role } from 'types/role'
import Button from 'components/Button'
import LoadingSpinner from 'components/LoadingSpinner'
import Table from 'components/tables/Table'
import TaskAssignee from './TaskAssignee'
import { transitionTask } from 'api/tasks'
import CheckboxField from 'components/forms/CheckboxField'
import { Link } from 'react-router-dom'
import { getRouteForKaseOverview } from 'constants/routes'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import { KaseTiers, KaseTierValues } from 'constants/kaseTiers'
import { getNonNullString } from 'utils/format_utils'

interface Props {
  isLoading?: boolean
  adminUsers: AdminUser[]
  filteredTasks: Task[]
  handleTaskCheck: (id: string) => void
  decrementMyTaskCount: () => void
  tabIndex: number
  selectedTasks: Task[]
  roles: {
    byId: { [key: string]: Role }
    allIds: string[]
  }
}

const TasksTable: FunctionComponent<Props> = ({
  isLoading,
  filteredTasks,
  adminUsers,
  handleTaskCheck,
  decrementMyTaskCount,
  tabIndex,
  selectedTasks,
  roles
}) => {
  const { setGlobalError } = useGlobalError()
  const actionButton = (task: Task) => {
    const { resolve_actions, state } = task.attributes
    if (state === 'started') {
      return resolve_actions.map((action, index) => {
        return (
          <Button
            key={index}
            title={action.kase_state_event && action.kase_state_event}
            variant="primary"
            onClick={() =>
              transitionTask({
                taskId: task.id,
                state: 'resolve',
                ...(action.kase_state_event !== null && {
                  kaseStateEvent: action.kase_state_event
                })
              })
                .then(() => decrementMyTaskCount())
                .catch((error) => {
                  setGlobalError(error.response.data)
                })
            }
            className={cx({ 'ml-2': index > 0 })}
          >
            {action.button_label}
          </Button>
        )
      })
    } else if (state === 'assigned') {
      return (
        <Button
          variant="default"
          onClick={() => transitionTask({ taskId: task.id, state: 'start' })}
        >
          Start Task
        </Button>
      )
      return null
    }
  }

  const getRoleIdByName = (roleName: string) => {
    let roleId = '0'
    for (const [key, value] of Object.entries(roles.byId)) {
      if (value.attributes.name === roleName) {
        roleId = key
      }
    }
    return roleId
  }

  const filterUsersByRole = (users: AdminUser[], roleName: string) => {
    return users
      .sort((firstUser, secondUser) => {
        if (firstUser.attributes.full_name < secondUser.attributes.full_name) {
          return -1
        }
        if (firstUser.attributes.full_name > secondUser.attributes.full_name) {
          return 1
        }
        return 0
      })
      .filter((user) => {
        const userRoleIds = user.relationships.roles.data.map((role) => role.id)
        return userRoleIds.includes(getRoleIdByName(roleName))
      })
  }

  /**
   * Filters which admins to show as assignee options depending on the task type
   */
  const filterAdminUsersByTaskType = (
    users: AdminUser[],
    taskType: string
  ): AdminUser[] => {
    if (taskType.indexOf('Ops') === 0 && taskType.indexOf('QA') !== -1) {
      return filterUsersByRole(users, 'Steward')
    }

    if (taskType.indexOf('Lawyer') === 0) {
      return filterUsersByRole(users, 'Lawyer')
    }

    if (taskType.indexOf('Printer') === 0) {
      return filterUsersByRole(users, 'Printer')
    }

    return filterUsersByRole(users, 'Agent')
  }

  return (
    <>
      <Table classNames="table-fixed">
        <Table.Head>
          <Table.Row>
            <Table.Header className="w-1" key="checkbox" />
            <Table.Header className="w-1/4" key="customer">
              Customer
            </Table.Header>
            <Table.Header key="kase_id">Kase ID</Table.Header>
            <Table.Header key="kase_kind">Kase Kind</Table.Header>
            <Table.Header key="kase_tier">Kase Tier</Table.Header>
            <Table.Header key="type">Type</Table.Header>
            <Table.Header key="tags">Tags</Table.Header>
            <Table.Header key="created_at_in_words">Created At</Table.Header>
            <Table.Header className="w-1/4" key="assigned_to">
              Assigned to
            </Table.Header>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Table.Row>
              <Table.Cell colSpan={8}>
                <LoadingSpinner />
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {filteredTasks.map((task) => {
                return (
                  <Table.Row className="task-row" key={task.id}>
                    <Table.Cell>
                      {tabIndex === 0 && (
                        <CheckboxField
                          description={null}
                          id={`checkbox-${task.id}`}
                          // TODO: should label in CheckboxField be optional?
                          label=""
                          checked={selectedTasks.some(
                            (selectedTask) => selectedTask.id === task.id
                          )}
                          onChange={() => handleTaskCheck(task.id)}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      <Link
                        className="text-blue-600"
                        to={getRouteForKaseOverview(task.attributes.kase_id)}
                      >
                        {task.attributes.customer_names}
                      </Link>
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      <Link
                        className="text-blue-600"
                        to={getRouteForKaseOverview(task.attributes.kase_id)}
                      >
                        {task.attributes.kase_id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      {task.attributes.kase_kind}
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      {
                        KaseTierValues[
                          getNonNullString(
                            task.attributes.kase_tier
                          ) as KaseTiers
                        ]
                      }
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      {task.attributes.kind}
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 space-x-2">
                      {task.attributes.tags.map((tag, index) => (
                        <span
                          className="px-4 py-1 bg-gray-200 rounded-full"
                          key={index}
                        >
                          {tag}
                        </span>
                      ))}
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      {task.attributes.created_at_in_words} ago
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap">
                      <TaskAssignee
                        assigneeName={task.attributes.assignee_name}
                        assigneeId={task.attributes.assignee_id}
                        taskId={task.id}
                        adminUsers={filterAdminUsersByTaskType(
                          adminUsers,
                          task.attributes.kind
                        )}
                        tabIndex={tabIndex}
                      />
                    </Table.Cell>
                    <Table.Cell className="px-6 py-4 whitespace-no-wrap text-right">
                      {tabIndex === 1 && actionButton(task)}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </>
          )}
        </Table.Body>
      </Table>

      {!isLoading && filteredTasks.length === 0 && (
        <div className="p-6 bg-white text-center">
          <p className="text-sm text-gray-600">
            There are currently no tasks. You are all caught up!
          </p>
        </div>
      )}
    </>
  )
}

export default TasksTable
