import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { Link, useParams } from 'react-router-dom'

import { RootState } from 'store/root'
import { useSelector } from 'react-redux'
import { AuditLog } from 'types/auditLog'
import SelectField, { SelectOption } from 'components/forms/SelectField'
import KaseAuditLog from './KaseAuditLog'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { fetchKaseAuditLogs } from 'api/kaseData'
import MainLayout from 'layouts/MainLayout'
import { getRouteForKaseOverview } from 'constants/routes'

const KaseAuditLogs: FunctionComponent = () => {
  const kaseAuditLogs = useSelector((state: RootState) => state.kaseAuditLog)
  const [filterOption, setFilterOption] = useState('All')
  const [filterOptions, setFilterOptions] = useState<SelectOption[]>([])
  const [logs, setAuditLogs] = useState<AuditLog[]>([])
  const { kaseId } = useParams<IKaseIndexRouteParams>()

  useEffect(() => {
    fetchKaseAuditLogs(kaseId)
  }, [kaseId])

  useEffect(() => {
    const filterOptions: SelectOption[] = [
      { value: filterOption, label: filterOption }
    ]

    kaseAuditLogs.forEach((log) => {
      const option = {
        value: log.attributes.item_type,
        label: log.attributes.item_type
      }
      if (
        !filterOptions.some(
          (filterOption) => filterOption.value === option.value
        )
      ) {
        filterOptions.push(option)
      }
    })

    setFilterOptions(filterOptions)
    setAuditLogs(kaseAuditLogs)
  }, [kaseAuditLogs])

  const onFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const filter = event.target.value
    let logs = []
    setFilterOption(filter)

    if (filter === 'All') {
      logs = kaseAuditLogs
    } else {
      logs = kaseAuditLogs.filter((log) => log.attributes.item_type === filter)
    }

    setAuditLogs(logs)
  }

  return (
    <MainLayout>
      <div className="flex items-baseline">
        <h2 className="font-bold text-base">
          Audit Log for{' '}
          <Link
            data-testid="overview-link"
            to={getRouteForKaseOverview(kaseId)}
            className="underline hover:no-underline font-normal truncate"
          >
            Kase {kaseId}
          </Link>
        </h2>
        <div className="ml-8 w-64">
          <SelectField
            id="case-audit-log-type-selector"
            label="Show"
            inline={true}
            options={filterOptions}
            onChange={onFilterChange}
            value={filterOption}
          />
        </div>
      </div>
      {logs.map((log, index) => (
        <KaseAuditLog key={`kase-audit-logs-${index}`} log={log} />
      ))}
    </MainLayout>
  )
}

export default KaseAuditLogs
