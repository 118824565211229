import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Task } from 'types/task'

export interface KaseTasksShape {
  byId: { [key: string]: Task }
  allIds: string[]
}

const initialState: KaseTasksShape = {
  byId: {},
  allIds: []
}

const kaseTasksSlice = createSlice({
  name: 'kaseTasks',
  initialState,
  /*
   * ⚠️ When you edit reducers, don't forget to update the allIds array!
   */
  reducers: {
    setTasks: (state, action: PayloadAction<{ [key: string]: Task }>) => {
      state.byId = action.payload
      state.allIds = Object.keys(state.byId)
    },
    updateTask: (state, action: PayloadAction<Task>) => {
      const updatedTask = action.payload
      state.byId[updatedTask.id] = updatedTask
    }
  }
})

export const { setTasks, updateTask } = kaseTasksSlice.actions

export default kaseTasksSlice.reducer
