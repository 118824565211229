import React, { FunctionComponent, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import fetchFeatureFlags from 'api/featureFlags'
import store, { RootState } from 'store/root'
import { setFeatureFlags } from 'store/reducers/global'

import AdminList from 'pages/admins/AdminList'
import Can from 'components/Can'
import DocumentReviewDashboard from 'pages/document_review/Dashboard'
import DocumentReviewTool from 'pages/document_review/Tool'
import DocumentView from 'pages/document_review/DocumentView'
import EditRoleForm from 'pages/roles/EditRoleForm'
import GlobalErrorWrapper from 'components/errors/GlobalErrorWrapper'
import Home from 'pages/Home'
// @TODO Issues screen is only used for 1.0 - remove these pages
// entirely when we can deprecate flows needed for 1.0
import Issues from 'pages/issues'
import IssuesRoot from 'pages/issues/IssuesRoot'
import KaseIndex from 'pages/kase_review/KaseIndex'
import KaseOverview from 'pages/overview'
import KaseAuditLogs from 'pages/kase_audit_logs'
import KaseEligibility from 'pages/eligibility'
import KaseStatesAndEvents from 'pages/states_and_events'
import KitchenSink from 'pages/KitchenSink'
import MainLayout from 'layouts/MainLayout'
import MPDFTool from 'pages/mpdf'
import NewApplication from 'pages/new_application'
import NewRoleForm from 'pages/roles/NewRoleForm'
import NoAccessError from './auth/NoAccessError'
import PageNotFound from 'pages/PageNotFound'
import PaymentTool from 'pages/payment'
import PrivateRoute from 'components/auth/PrivateRoute'
import RolesList from 'pages/roles/RolesList'
import UserDetail from 'pages/users/UserDetail'
import UserList from 'pages/users/UserList'
import routes from 'constants/routes'
import SignIn from 'pages/SignInScreen'
import SignOut from 'pages/SignOutScreen'
import TasksDashboard from 'pages/tasks/TasksDashboard'

import '../styles/index.css'
import KasesList from 'pages/kases/KasesList'

/**
 * This component sets up most of the routes in the app.
 * Check out React Router's documentation:
 * https://reactrouter.com/web/example/basic
 */
const App: FunctionComponent = () => {
  const { isLoaded: isFeaturesLoaded } = useSelector(
    (state: RootState) => state.global.featureFlags
  )
  useEffect(() => {
    fetchFeatureFlags().then((featureFlags) => {
      store.dispatch(setFeatureFlags(featureFlags))
    })
  }, [])

  return (
    <>
      {isFeaturesLoaded && (
        <BrowserRouter>
          <GlobalErrorWrapper>
            <Switch>
              <Route path={routes.SIGN_IN}>
                <SignIn />
              </Route>
              <Route path={routes.SIGN_OUT}>
                <SignOut />
              </Route>
              <PrivateRoute exact path={routes.HOME}>
                <Home />
              </PrivateRoute>
              <PrivateRoute path={routes.DOCUMENT_DETAIL_VIEW}>
                <DocumentView />
              </PrivateRoute>
              <PrivateRoute path={routes.DOCUMENT_REVIEW_TOOL}>
                <DocumentReviewTool />
              </PrivateRoute>
              <PrivateRoute path={routes.DOCUMENT_REVIEW_ROOT}>
                <DocumentReviewDashboard />
              </PrivateRoute>
              <PrivateRoute path={routes.ADMIN_USERS_ROOT}>
                <AdminList />
              </PrivateRoute>
              <PrivateRoute exact path={routes.ROLES_ROOT}>
                <RolesList />
              </PrivateRoute>
              <PrivateRoute exact path={routes.ROLE_NEW}>
                <MainLayout>
                  <Can
                    perform="role:update"
                    no={<NoAccessError className="mx-8" />}
                    yes={<NewRoleForm />}
                  />
                </MainLayout>
              </PrivateRoute>
              <PrivateRoute exact path={routes.ROLE_EDIT}>
                <MainLayout>
                  <Can
                    perform="role:update"
                    no={<NoAccessError className="mx-8" />}
                    yes={<EditRoleForm />}
                  />
                </MainLayout>
              </PrivateRoute>
              <PrivateRoute path={routes.ISSUES_TOOL}>
                <Issues />
              </PrivateRoute>
              <PrivateRoute path={routes.ISSUES_ROOT}>
                <IssuesRoot />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_REVIEW_INFO}>
                <KaseIndex />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_ROOT}>
                <KasesList />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_OVERVIEW}>
                <KaseOverview />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_AUDIT_INFO}>
                <KaseAuditLogs />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_ELIGIBILITY}>
                <KaseEligibility />
              </PrivateRoute>
              <PrivateRoute path={routes.KASE_STATES_AND_EVENTS}>
                <KaseStatesAndEvents />
              </PrivateRoute>
              <PrivateRoute path={routes.MPDF_DOCUMENT_DETAIL}>
                <DocumentView />
              </PrivateRoute>
              <PrivateRoute path={routes.MPDF_TOOL}>
                <MPDFTool />
              </PrivateRoute>
              <PrivateRoute path={routes.PAYMENT}>
                <PaymentTool />
              </PrivateRoute>
              <PrivateRoute path={routes.NEW_APPLICATION}>
                <NewApplication />
              </PrivateRoute>
              <PrivateRoute path={routes.TASKS_ROOT}>
                <TasksDashboard />
              </PrivateRoute>
              <PrivateRoute path={routes.USER_DETAIL}>
                <UserDetail />
              </PrivateRoute>
              <PrivateRoute path={routes.USERS_ROOT}>
                <UserList />
              </PrivateRoute>
              <Route path="/kitchen-sink">
                <KitchenSink />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </GlobalErrorWrapper>
        </BrowserRouter>
      )}
    </>
  )
}

export default App as FunctionComponent
