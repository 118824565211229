import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import SelectField from 'components/forms/SelectField'
import TextField from 'components/forms/TextField'
import { AdminUser } from 'types/users'
import { getKaseStates } from 'api/kases'
import store, { RootState } from 'store/root'
import { useSelector } from 'react-redux'
import { setKaseFilters, resetFilters } from 'store/reducers/kaseFiltersReducer'
import Button from 'components/Button'
import Label from 'components/forms/Label'
import { KaseState } from 'types/kaseState'
import { fetchAllAdmins } from 'api/adminUsers'
import { fetchRoles } from 'api/roles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

interface UserSelectOption {
  label: string
  value: any
}

const KASE_OPTIONS = [
  {
    label: 'All Kases',
    value: 'all'
  },
  {
    label: 'MBGC',
    value: 'NewMarriageBasedGreenCard'
  },
  {
    label: 'K1',
    value: 'K1FianceVisa'
  },
  {
    label: 'B1/B2',
    value: 'B1B2TravelVisa'
  }
]

const KASE_PAID_OPTIONS = [
  {
    label: 'All Paid',
    value: 'all'
  },
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
]

const KasesListFilters: FunctionComponent = () => {
  const [applicationGuides, setApplicationGuides] = useState<AdminUser[]>()
  const [lawyers, setLawyers] = useState<AdminUser[]>()
  const [kaseStateList, setKaseStateList] = useState<KaseState[]>()
  const admins = useSelector((state: RootState) => state.adminUsers)
  const roles = useSelector((state: RootState) => state.adminRoles.roles)
  const kaseStates = useSelector((state: RootState) => state.kaseStates)
  const kaseFilters = useSelector((state: RootState) => state.kaseFilters)

  useEffect(() => {
    getKaseStates()
    fetchAllAdmins()
    fetchRoles()
  }, [])

  useEffect(() => {
    const adminsList = admins.allIds.map((id) => admins.byId[id])
    setApplicationGuides(filterUsersByRole(adminsList, 'Steward'))
    setLawyers(filterUsersByRole(adminsList, 'Lawyer'))
  }, [admins, roles])

  useEffect(() => {
    setKaseStateList(kaseStates)
  }, [kaseStates])

  const getRoleIdByName = (roleName: string) => {
    let roleId = '0'
    for (const [key, value] of Object.entries(roles.byId)) {
      if (value.attributes.name === roleName) {
        roleId = key
      }
    }
    return roleId
  }

  const filterUsersByRole = (users: AdminUser[], roleName: string) => {
    return users
      .sort((firstUser, secondUser) => {
        if (firstUser.attributes.full_name < secondUser.attributes.full_name) {
          return -1
        }
        if (firstUser.attributes.full_name > secondUser.attributes.full_name) {
          return 1
        }
        return 0
      })
      .filter((user) => {
        const userRoleIds = user.relationships.roles.data.map((role) => role.id)
        return userRoleIds.includes(getRoleIdByName(roleName))
      })
  }

  const onFilterSelectTypeChange = (
    event: ChangeEvent<HTMLSelectElement>,
    filterType: string
  ) => {
    event.preventDefault()
    const value = event.target.value
    const newFilters = { ...kaseFilters, [filterType]: value }
    store.dispatch(setKaseFilters(newFilters))
  }

  const onFilterInputTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    filterType: string
  ) => {
    event.preventDefault()
    const value = event.target.value
    let newFilters = kaseFilters
    switch (filterType) {
      case 'email':
        newFilters = { ...kaseFilters, [filterType]: value, otherEmail: '' }
        break
      case 'otherEmail':
        newFilters = { ...kaseFilters, [filterType]: value, email: '' }
        break
      default:
        newFilters = { ...kaseFilters, [filterType]: value }
        break
    }

    store.dispatch(setKaseFilters(newFilters))
  }

  const onPhoneChange = (phone: string) => {
    store.dispatch(setKaseFilters({ ...kaseFilters, phone }))
  }

  const reset = () => {
    store.dispatch(resetFilters())
  }

  const stewardOptions: UserSelectOption[] = applicationGuides
    ? applicationGuides.map((member: AdminUser) => {
        return { value: member.id, label: member.attributes.full_name }
      })
    : [
        {
          value: '-1',
          label: 'Loading...'
        }
      ]

  const lawyerOptions: UserSelectOption[] = lawyers
    ? lawyers.map((member: AdminUser) => {
        return { value: member.id, label: member.attributes.full_name }
      })
    : [
        {
          value: '-1',
          label: 'Loading...'
        }
      ]

  const showAll: UserSelectOption = {
    label: 'All',
    value: 'all'
  }

  const kaseStateOptions: UserSelectOption[] = kaseStateList
    ? kaseStateList.map((member: KaseState) => {
        return member
      })
    : [
        {
          value: '-1',
          label: 'Loading...'
        }
      ]
  kaseStateOptions.unshift(showAll)

  return (
    <>
      <div className="flex flex-wrap mb-4">
        <div className="w-80 mr-4">
          <SelectField
            label="Kase Kind"
            onChange={(event) => onFilterSelectTypeChange(event, 'kaseKind')}
            options={KASE_OPTIONS}
            value={kaseFilters.kaseKind}
          />
        </div>
        <div className="w-80 mr-4">
          <SelectField
            label="Paid Status"
            onChange={(event) => onFilterSelectTypeChange(event, 'paid')}
            options={KASE_PAID_OPTIONS}
            value={kaseFilters.paid}
          />
        </div>
        <div className="w-80 mr-4">
          <SelectField
            label="Kase State"
            onChange={(event) => onFilterSelectTypeChange(event, 'kaseState')}
            options={kaseStateOptions}
            value={kaseFilters.kaseState}
          />
        </div>
        <div className="w-80 mr-4">
          <SelectField
            label="Application Guide"
            includeNullValue={true}
            onChange={(event) => onFilterSelectTypeChange(event, 'stewardId')}
            options={stewardOptions}
            value={kaseFilters.stewardId}
          />
        </div>
        <div className="w-80 mr-4">
          <SelectField
            label="Laywer"
            includeNullValue={true}
            onChange={(event) => onFilterSelectTypeChange(event, 'lawyerId')}
            options={lawyerOptions}
            value={kaseFilters.lawyerId}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-4">
        <hr />
        <p>or</p>
        <hr />
      </div>
      <div className="flex flex-wrap mb-4">
        <div className="w-80 mr-4">
          <TextField
            label="First Name"
            onChange={(event) => onFilterInputTypeChange(event, 'firstName')}
            value={kaseFilters.firstName}
          />
        </div>
        <div className="w-80 mr-4">
          <TextField
            label="Last Name"
            onChange={(event) => onFilterInputTypeChange(event, 'lastName')}
            value={kaseFilters.lastName}
          />
        </div>
        <div className="w-80 mr-4">
          <Label>Phone</Label>
          <PhoneInput
            value={kaseFilters.phone}
            country="us"
            autoFormat={true}
            defaultMask="................."
            enableAreaCodes={['us', 'ca']}
            onChange={(_value, _country, _e, formatValue) =>
              onPhoneChange(formatValue)
            }
          />
        </div>
        <div className="w-80 mr-4">
          <TextField
            label="Account Email"
            onChange={(event) => onFilterInputTypeChange(event, 'email')}
            value={kaseFilters.email}
          />
        </div>
        <div className="w-80 mr-4">
          <TextField
            label="Other Entered Email"
            onChange={(event) => onFilterInputTypeChange(event, 'otherEmail')}
            value={kaseFilters.otherEmail}
          />
        </div>
        <div className="w-80 mr-4">
          <Label>&nbsp;</Label>
          <Button color="secondary" onClick={() => reset()}>
            Reset Filters
          </Button>
        </div>
      </div>
    </>
  )
}

export default KasesListFilters
