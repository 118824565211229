import React, { FunctionComponent, HTMLAttributes } from 'react'
import cx from 'classnames'

interface Props {
  borderBottom?: boolean
  borderLeft?: boolean
  borderRight?: boolean
  borderTop?: boolean
  className?: string
  colSpan?: number
}

const TableHeader: FunctionComponent<
  HTMLAttributes<HTMLTableCellElement> & Props
> = ({
  children,
  className,
  borderBottom = true,
  borderLeft = false,
  borderRight = false,
  borderTop = false,
  ...otherProps
}) => {
  const classes = cx(
    'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-gray-200',
    {
      'border-b': borderBottom,
      'border-l': borderLeft,
      'border-r': borderRight,
      'border-t': borderTop,
      [className as any]: !!className
    }
  )

  return (
    <th {...otherProps} className={classes}>
      {children}
    </th>
  )
}

export default TableHeader
