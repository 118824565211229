// These should match what is in the file "app.boundless.co/app/models/concerns/kase_tier.rb"
// These are the values that will come from the backend API
// The empty string can also be used if a null/undefined value is received
export enum KaseTiers {
  NONE = '',
  NA = 'not_applicable',
  ESSENTIAL = 'essential',
  LEGACY = 'legacy_premium',
  PREMIUM = 'premium'
}

// These are the values you will see on the UI
export const KaseTierValues = {
  [KaseTiers.NONE]: 'None',
  [KaseTiers.NA]: 'Not applicable',
  [KaseTiers.ESSENTIAL]: 'Essential',
  [KaseTiers.LEGACY]: 'Legacy',
  [KaseTiers.PREMIUM]: 'Premium'
}

// https://tailwindcss.com/docs/text-color
// https://flowbite.com/docs/customize/colors/
// https://flowbite.com/docs/components/badge/
export const KaseTierCssColorClasses = {
  [KaseTiers.NONE]: 'bg-gray-400',
  [KaseTiers.NA]: 'bg-gray-400',
  [KaseTiers.ESSENTIAL]: 'bg-purple-500',
  [KaseTiers.LEGACY]: 'bg-yellow-400',
  [KaseTiers.PREMIUM]: 'bg-green-400'
}
