import React, { FunctionComponent } from 'react'
import cx from 'classnames'

export type IconWrapperProps = React.SVGAttributes<SVGSVGElement> & {
  className?: string
  alt?: string
}

const IconWrapper: FunctionComponent<IconWrapperProps> = ({
  className,
  children,
  alt,
  ...otherProps
}) => {
  const classNameArray = (!!className && className.split(' ')) || []
  // if the className prop is not passed a new `w-X` class
  // we use a default size `w-5`
  const useDefaultSize =
    classNameArray.length === 0 ||
    classNameArray.some((c) => c.charAt(0) !== 'w')

  const classes = cx({
    'w-5': useDefaultSize,
    [className as string]: !!className
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={classes}
      role="img"
      {...otherProps}
    >
      {alt && <title>{alt}</title>}
      {children}
    </svg>
  )
}

export default IconWrapper
