import React, { FunctionComponent, useState } from 'react'

import { createIssue } from 'api/issues'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import IssueCard from 'components/IssueCard'
import Button from 'components/Button'
import Label from 'components/forms/Label'
import TextArea from 'components/forms/TextArea'
import IssueHeader from 'pages/issues/IssueHeader'

interface ExplicitProps {
  closeForm: () => void
  kaseId: string
}

const NewIssue: FunctionComponent<ExplicitProps> = ({ closeForm, kaseId }) => {
  const { setGlobalError } = useGlobalError()
  const [issueDescription, setIssueDescription] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssueDescription(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    createIssue(kaseId, { description: issueDescription })
      .catch((error) => setGlobalError(error))
      .finally(() => {
        closeForm()
      })
  }

  const cancelSubmit = () => {
    setIssueDescription('')

    closeForm()
  }

  return (
    <IssueCard
      header={<IssueHeader />}
      body={
        <form onSubmit={handleSubmit}>
          <Label htmlFor="newIssueDescription">Description</Label>
          <TextArea
            id="newIssueDescription"
            autoFocus
            placeholder="Enter a description of the issue"
            onChange={handleChange}
            value={issueDescription}
          />

          <div className="mt-4">
            <Button
              variant="primary"
              type="submit"
              disabled={!issueDescription}
              className="mr-3"
            >
              Save
            </Button>
            <Button variant="plain" onClick={cancelSubmit}>
              Cancel
            </Button>
          </div>
        </form>
      }
    />
  )
}

export default NewIssue as FunctionComponent<ExplicitProps>
