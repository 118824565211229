import React, { FunctionComponent, useEffect, useState } from 'react'

import { fetchAllAdmins } from 'api/adminUsers'

import BadgeCheckIcon from 'components/icons/BadgeCheckIcon'
import Button from 'components/Button'
import CheckboxField from 'components/forms/CheckboxField'
import TextArea from 'components/forms/TextArea'

interface Props {
  addWatermark: boolean
  isFormsOnly: boolean
  isSuccessfullyCreated: boolean
  onCreateMasterPdfBtnClick: (adminNote: string) => void
  onIsAddWatermarkChecked: () => void
  onIsFormsOnlyChecked: () => void
}

const MPDFSidebarSection: FunctionComponent<Props> = ({
  addWatermark,
  isFormsOnly,
  isSuccessfullyCreated,
  onCreateMasterPdfBtnClick,
  onIsAddWatermarkChecked,
  onIsFormsOnlyChecked
}) => {
  const [notes, setNotes] = useState('')

  useEffect(() => {
    fetchAllAdmins()
  }, [])

  return (
    <div className="bg-white border-l border-gray-300 col-span-3 ml-3 p-3 h-full">
      <h2 className="font-bold">Options</h2>
      <CheckboxField
        id="add-watermark-checkbox"
        checked={addWatermark}
        className="mt-2 ml-1"
        label="Add watermark"
        onChange={onIsAddWatermarkChecked}
      />
      <CheckboxField
        id="forms-only-checkbox"
        checked={isFormsOnly}
        className="mt-1 ml-1"
        label="Forms only, no supporting documents"
        onChange={onIsFormsOnlyChecked}
      />
      <h2 className="font-bold mt-12">Notes</h2>
      <TextArea
        className="mt-2 h-48"
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Add notes..."
        value={notes}
      />
      <div
        className="flex flex-col justify-center items-center"
        style={{ marginTop: '11rem' }}
      >
        <Button
          onClick={() => onCreateMasterPdfBtnClick(notes)}
          variant="primary"
        >
          Create Master PDF
        </Button>
        {isSuccessfullyCreated && (
          <span className="mt-1">
            <BadgeCheckIcon alt="resolved" className="text-green-500 inline" />
            <span className="text-sm leading-5">
              Master Pdf successfully created
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export default MPDFSidebarSection
