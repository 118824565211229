import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TieredCheckoutDetails, TierlessCheckoutDetails } from 'types/payment'

const initialState = {} as TieredCheckoutDetails | TierlessCheckoutDetails

const checkoutDetailsSlice = createSlice({
  name: 'checkoutDetails',
  initialState,
  reducers: {
    setCheckoutDetails: (
      state,
      action: PayloadAction<TieredCheckoutDetails | TierlessCheckoutDetails>
    ) => action.payload
  }
})

export const { setCheckoutDetails } = checkoutDetailsSlice.actions

export default checkoutDetailsSlice.reducer
