import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'store/root'

import {
  addDocument,
  createIssue,
  fetchDocumentSetsByKaseId,
  toggleOtherDocumentsByKaseId,
  updateDocumentState
} from 'api/documentReview'
import { fetchIssues, resolveIssue, updateIssueDescription } from 'api/issues'

import KaseIssuesPanel from 'pages/kase_review/KaseIssuesPanel'
import DocumentList from './DocumentList'
import DocumentSet from './DocumentSet'
import SidebarLayout from 'layouts/SidebarLayout'
import Button from 'components/Button'
import SubNavigation from 'components/SubNavigation'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

import S3Upload, { SignResult } from 'utils/S3Upload'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { ICreateIssueParams } from 'types/issues'
import { IDocument, IDocumentRequest } from 'types/documents'

export interface NameCheckboxIds {
  docRequestId: number
  docRequestPartId: number
}

const DocumentReviewTool: FunctionComponent = () => {
  const [currentView, setCurrentView] = useState('')
  const [showIssuePanel, setShowIssuePanel] = useState(false)
  const [isIssuePanelLoading, setIsIssuePanelLoading] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [selectedDocRequests, setSelectedDocRequests] = useState<
    IDocumentRequest[]
  >([])
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([])

  // Keeps track of which checkboxes next to the "Name" column are selected
  // Have to keep track of doc request id and part content id because doc requests
  // that only have one part all have a part id of 0
  const [selectedNameCheckboxIds, setSelectedNameCheckboxIds] = useState<
    NameCheckboxIds[]
  >([])

  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const documentSets = useSelector(
    (store: RootState) => store.documentReviewData.documentSets
  )

  const { setGlobalError } = useGlobalError()

  useEffect(() => {
    fetchDocumentSetsByKaseId(kaseId)
    fetchIssues(kaseId)
  }, [])

  const onToggleOtherButtonClicked = () => {
    toggleOtherDocumentsByKaseId(kaseId).then(() =>
      fetchDocumentSetsByKaseId(kaseId)
    )
  }

  const onUploadFileChanged = (
    file: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => {
    new S3Upload(
      [file],
      +kaseId,
      {
        onUploadComplete: onUploadFinish,
        docRequestId: docRequestId,
        docRequestPartId: docRequestPartId,
        s3path: `documents/${kaseId}`
      },
      'upload-to-doc-request'
    )
  }

  const onUploadFinish = (
    signResult: SignResult,
    rawFile: File,
    docRequestId: string | number,
    docRequestPartId: number
  ) => {
    const s3_path = signResult.filename
    return addDocument(kaseId, docRequestId, rawFile, s3_path, docRequestPartId)
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .catch((error: any) => setGlobalError(error))
  }

  const handleClickSaveButton = (createIssueParams: ICreateIssueParams) => {
    setIsIssuePanelLoading(true)
    createIssue(
      kaseId,
      createIssueParams,
      selectedDocuments,
      selectedDocRequests
    )
      .then(() => fetchIssues(kaseId))
      // Reject all documents that issues were made about
      .then(() =>
        Promise.all(
          selectedDocuments.map((doc) =>
            updateDocumentState(kaseId, doc.id, 'rejected')
          )
        )
      )
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .catch((error) => setGlobalError(error))
      .finally(() => resetDocumentSelection())
  }

  const handleResolveButtonClick = (kaseId: string, issueId: string) => {
    setIsIssuePanelLoading(true)
    resolveIssue(kaseId, issueId)
      .then(() => fetchIssues(kaseId))
      .catch((error) => setGlobalError(error))
      .finally(() => resetDocumentSelection())
  }

  const handleSaveEditIssue = (
    kaseId: string,
    issueId: string,
    newDescription: string
  ) => {
    setIsIssuePanelLoading(true)
    updateIssueDescription(kaseId, issueId, newDescription)
      .then(() => fetchIssues(kaseId))
      .catch((error) => setGlobalError(error))
      .finally(() => resetDocumentSelection())
  }

  const handleClickCancelCreateIssueButton = () => {
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  const handleCreateIssueButtonClick = () => {
    setShowIssuePanel(true)
    setShowCreateIssue(true)
  }

  const resetDocumentSelection = () => {
    setSelectedDocuments([])
    setSelectedDocRequests([])
    setSelectedNameCheckboxIds([])
    setIsIssuePanelLoading(false)
  }

  const handleShowOrHideIssuesClick = () => {
    setShowIssuePanel(!showIssuePanel)
    setShowCreateIssue(false)
  }

  const onDocRequestCheckboxClicked = (docRequest: IDocumentRequest) => {
    const isChecked = selectedDocRequests.some(
      (currDocRequest) => currDocRequest === docRequest
    )

    if (!isChecked) {
      setSelectedDocRequests([...selectedDocRequests, docRequest])
    } else {
      setSelectedDocRequests([
        ...selectedDocRequests.filter(
          (currDocRequest) => currDocRequest !== docRequest
        )
      ])
    }

    setShowCreateIssue(true)
  }

  const onDocumentCheckboxClicked = (document: IDocument) => {
    const isChecked = selectedDocuments.some((currDoc) => currDoc === document)

    if (!isChecked) {
      setSelectedDocuments([...selectedDocuments, document])
    } else {
      setSelectedDocuments([
        ...selectedDocuments.filter((currDoc) => currDoc !== document)
      ])
    }

    setShowCreateIssue(true)
  }

  const onNameCheckboxClicked = (
    documentRequest: IDocumentRequest,
    documentRequestPartId: number
  ) => {
    const nameCheckboxId = {
      docRequestId: documentRequest.id,
      docRequestPartId: documentRequestPartId
    }

    const isChecked = selectedNameCheckboxIds.some(
      (currentNameCheckbox) =>
        currentNameCheckbox.docRequestId === nameCheckboxId.docRequestId &&
        currentNameCheckbox.docRequestPartId === nameCheckboxId.docRequestPartId
    )

    // Get documents from doc request part
    let documents = documentRequest.parts
      .find((part) => part.id === documentRequestPartId)
      ?.contents.map((content) => content.document)

    // If not currently checked, check it then add all documents from part to selected documents
    if (!isChecked) {
      setSelectedNameCheckboxIds([...selectedNameCheckboxIds, nameCheckboxId])

      if (documents) {
        // Filter out documents that are already selected
        documents = documents?.filter(
          (document) => !selectedDocuments.includes(document)
        )

        setSelectedDocuments([...selectedDocuments, ...documents])
      }
    }
    // If currently checked, uncheck it remove all documents from part to selected documents
    else {
      const newSelectedDocuments = selectedDocuments.filter((document) => {
        return !documents?.includes(document)
      })

      setSelectedDocuments(newSelectedDocuments)
      setSelectedNameCheckboxIds([
        ...selectedNameCheckboxIds.filter(
          (currentNameCheckbox) =>
            currentNameCheckbox.docRequestId !== nameCheckboxId.docRequestId ||
            currentNameCheckbox.docRequestPartId !==
              nameCheckboxId.docRequestPartId
        )
      ])
    }
  }

  const onClickOutsideIssuePanel = () => {
    setShowCreateIssue(false)
    setShowIssuePanel(false)
  }

  const kaseIssuesPanel = (
    <KaseIssuesPanel
      onClickOutsideIssuePanel={onClickOutsideIssuePanel}
      show={showIssuePanel}
      showCreateIssue={showCreateIssue}
      selectedDocuments={selectedDocuments}
      selectedDocRequests={selectedDocRequests}
      setShowCreateIssue={setShowCreateIssue}
      handleClickSaveButton={handleClickSaveButton}
      handleResolveButtonClick={handleResolveButtonClick}
      handleSaveEditIssue={handleSaveEditIssue}
      handleClickCancelButton={handleClickCancelCreateIssueButton}
      isLoading={isIssuePanelLoading}
    />
  )

  const onBulkUpdateStatusBtnClicked = (status: string) => {
    Promise.all(
      selectedDocuments.map((document) =>
        updateDocumentState(kaseId, document.id, status)
      )
    )
      .then(() => fetchDocumentSetsByKaseId(kaseId))
      .then(() => resetDocumentSelection())
      .catch((error) => setGlobalError(error))
  }

  return (
    <>
      <SidebarLayout
        subNavigation={
          <SubNavigation
            context="document-review-tool"
            showIssuePanel={showIssuePanel}
            onBulkUpdateStatusBtnClicked={onBulkUpdateStatusBtnClicked}
            onRequestShowOrHideIssuesPanel={handleShowOrHideIssuesClick}
            onRequestCreateIssue={handleCreateIssueButtonClick}
            isBulkUpdateStatusBtnsDisabled={selectedDocuments.length === 0}
            showBulkUpdateStatusBtns={true}
          />
        }
        sidebar={
          <DocumentList
            documentList={documentSets}
            setCurrentView={setCurrentView}
          />
        }
      >
        <>
          {documentSets[0] && documentSets[0].name.toLowerCase() !== 'other' && (
            <div className="flex flex-row justify-between">
              <h2 className="text-base font-bold mb-5 text-gray-400">
                Other Documents
              </h2>
              <Button variant="plain" onClick={onToggleOtherButtonClicked}>
                Show Request to Customer
              </Button>
            </div>
          )}
          {documentSets.map((document_set) => (
            <DocumentSet
              currentView={currentView}
              document_requests={document_set?.document_requests}
              id={document_set.id}
              key={document_set.id}
              name={document_set.name}
              onDocumentCheckboxClicked={onDocumentCheckboxClicked}
              onDocRequestCheckboxClicked={onDocRequestCheckboxClicked}
              onNameCheckboxClicked={onNameCheckboxClicked}
              onUploadFileChanged={onUploadFileChanged}
              selectedDocuments={selectedDocuments}
              selectedDocumentRequests={selectedDocRequests}
              selectedNameCheckboxIds={selectedNameCheckboxIds}
              title={document_set.title}
              onToggleOtherButtonClicked={onToggleOtherButtonClicked}
            />
          ))}
        </>
        {kaseIssuesPanel}
      </SidebarLayout>
    </>
  )
}

export default DocumentReviewTool as FunctionComponent
