import { KaseDocumentReview } from 'types/documentReview'
import store from 'store/root'
import { setDocumentSets } from 'store/reducers/documentReviewData'
import server from './server'

import { IDocument, IDocumentRequest } from 'types/documents'
import { ICreateIssueParams } from 'types/issues'

// B2.0 Doc Review
export async function fetchDocumentSetsByKaseId(kaseId: string | number) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/document_sets`)
    .then((response) => {
      store.dispatch(setDocumentSets(response.data.data.document_sets))
      return response.data.data.document_sets
    })
}

export async function createIssue(
  kaseId: string | number,
  createIssueParams: ICreateIssueParams,
  selectedDocuments?: IDocument[],
  selectedDocRequests?: IDocumentRequest[]
) {
  const documentIds = selectedDocuments
    ? selectedDocuments.map((doc) => {
        return { document_id: doc.id }
      })
    : []
  const documentRequestIds = selectedDocRequests
    ? selectedDocRequests.map((docRequest) => {
        return { document_request_id: docRequest.id }
      })
    : []

  const data = {
    data: {
      type: 'issues',
      attributes: {
        description: createIssueParams.description
      },
      relationships: {
        documents_issues: {
          data: [...documentIds, ...documentRequestIds]
        }
      }
    }
  }

  return server
    .post(`/api/v1/admin/kases/${kaseId}/issues`, data)
    .then((response) => response.data)
}

export async function toggleOtherDocumentsByKaseId(kaseId: string | number) {
  return server.patch(
    `/api/v1/admin/kases/${kaseId}/document_sets/toggle_other`
  )
}

export interface DocumentReviewListFilters {
  /**
   * This isn't really a WorkflowStatus.
   *
   * @see app/services/workflow_status_filter.rb:status_filter_method
   */
  workflowStatus?: string
  stewardId?: number | string
}

export async function fetchDocumentReviews(
  filters?: DocumentReviewListFilters
) {
  let url = '/api/admin/kase_document_reviews'

  const queryParams: string[] = []
  if (filters?.workflowStatus) {
    queryParams.push(`workflow_status=${filters.workflowStatus}`)
  }
  if (filters?.stewardId) {
    queryParams.push(`steward_id=${filters.stewardId}`)
  }

  if (queryParams.length > 0) {
    url = `${url}?${queryParams.join('&')}`
  }

  return server
    .get<{ kase_document_reviews: KaseDocumentReview[] }>(url)
    .then((response) => response.data.kase_document_reviews)
}

export async function fetchDocumentRequestsForKase(kaseId: string | number) {
  return server.get(`api/kases/${kaseId}/document_requests`)
}

export async function fetchDocumentById(
  kaseId: string | number,
  docId: string | number
) {
  return server
    .get(`/api/v1/admin/kases/${kaseId}/documents/${docId}`)
    .then((response) => response)
    .catch((error) => error)
}

export async function updateDocumentState(
  kaseId: string | number,
  docId: string | number,
  state: string
) {
  const data = {
    document: {
      state: state
    }
  }

  return server
    .patch(`api/v1/admin/kases/${kaseId}/documents/${docId}`, data)
    .then((response) => response)
    .catch((error) => error)
}

export async function updateDocumentFileName(
  kaseId: string | number,
  docId: string | number,
  file_name: string
) {
  const data = {
    document: {
      file_name
    }
  }

  return server.patch(`api/v1/admin/kases/${kaseId}/documents/${docId}`, data)
}

export async function updateDocumentOrder(
  kaseId: string | number,
  docId: string | number,
  order: number
) {
  const data = {
    document: {
      order: order
    }
  }

  return server
    .patch(`api/v1/admin/kases/${kaseId}/documents/${docId}`, data)
    .then((response) => response)
    .catch((error) => error)
}

export async function updateDocumentMetaAnswer(
  kaseId: string | number,
  documentMetaAnswerId: string | number,
  text: string
) {
  const data = {
    text
  }

  return server.patch(
    `/api/v1/admin/kases/${kaseId}/document_meta_answers/${documentMetaAnswerId}`,
    data
  )
}

export async function fetchFileUrl(
  kaseId: string | number,
  docId: string | number
) {
  return server.get(
    `/api/v1/admin/kases/${kaseId}/documents/${docId}/direct_url`
  )
}

export async function deleteDocument(
  kaseId: string | number,
  docId: string | number
) {
  return server
    .delete(`api/v1/admin/kases/${kaseId}/documents/${docId}`)
    .then((response) => response.data.data)
}

export async function deleteDocumentRequest(
  kaseId: string | number,
  docRequestId: string | number
) {
  return server.delete(
    `api/v1/admin/kases/${kaseId}/document_requests/${docRequestId}`
  )
}

export async function addDocument(
  kaseId: string | number,
  docRequestId: string | number,
  file: File,
  s3_path: string,
  document_request_part_id: number
) {
  const data = {
    document_request: {
      documents_attributes: [
        {
          file_name: file.name,
          file_size: file.size,
          file_type: file.type,
          s3_path,
          document_request_part_id
        }
      ]
    }
  }

  return server.patch(
    `api/v1/admin/kases/${kaseId}/document_requests/${docRequestId}`,
    data
  )
}

export async function replaceDocument(
  kaseId: string | number,
  docId: string | number,
  file: File,
  s3_path: string
) {
  const data = {
    document: {
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
      s3_path
    }
  }

  return server
    .post(`/api/v1/admin/kases/${kaseId}/documents/${docId}/replace`, data)
    .then((response) => {
      return response.data.data.attributes
    })
}
