import React, { FunctionComponent, LabelHTMLAttributes } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  inline?: boolean
}

const Label: FunctionComponent<
  LabelHTMLAttributes<HTMLLabelElement> & Props
> = ({ children, className, inline, ...otherProps }) => {
  return (
    <label
      className={cx(className, 'text-gray-500 text-sm', {
        'block mb-2': !inline
      })}
      {...otherProps}
    >
      {children}
    </label>
  )
}

export default Label
