import server from 'api/server'
import store from 'store/root'
import { fetchOverview } from './overview'
import { setCheckoutDetails } from 'store/reducers/checkoutDetails'
import { InitiatePaymentPayload, InitiateSplitItPayload } from 'types/payment'
import { setPostPayCartOptions } from 'store/reducers/postPayCartOptions'

const PAYMENTS_URL = (kaseId: string): string =>
  `/api/v1/admin/kases/${kaseId}/payments`

export async function initiateStripe(
  kaseId: string,
  payload: InitiatePaymentPayload
) {
  return server
    .post(`${PAYMENTS_URL(kaseId)}/initiate_stripe`, payload)
    .then((response) => response)
}

export async function expireStripe(kaseId: string, sessionId: string) {
  return server
    .post(`${PAYMENTS_URL(kaseId)}/expire_stripe`, {
      session_id: sessionId
    })
    .then((response) => response)
}

export async function fetchCheckoutDetails(kaseId: string) {
  return server
    .get(`${PAYMENTS_URL(kaseId)}/checkout_details`)
    .then(({ data }) => {
      store.dispatch(setCheckoutDetails(data))
      return data
    })
}

export async function fetchPostPayCartOptions(kaseId: string) {
  return server
    .get(`${PAYMENTS_URL(kaseId)}/postpay_cart_options`)
    .then(({ data }) => {
      store.dispatch(setPostPayCartOptions(data))
      return data
    })
}

export async function initiateSplitit(
  kaseId: string,
  payload: InitiatePaymentPayload & InitiateSplitItPayload
) {
  return server
    .post(`${PAYMENTS_URL(kaseId)}/initiate_split_it`, payload)
    .then((response) => response)
}

export async function verifySplitit(kaseId: string, planId: string) {
  return server.get(`${PAYMENTS_URL(kaseId)}/verify_split_it/${planId}`)
}

export async function expireSplitIt(kaseId: string) {
  return server.post(`${PAYMENTS_URL(kaseId)}/expire_split_it`)
}

export async function completePayment(
  kaseId: string,
  charge_id: string,
  type: 'stripe' | 'splitit'
) {
  return server
    .post(`${PAYMENTS_URL(kaseId)}/complete`, {
      charge_id,
      type
    })
    .then((response) => {
      // completing a payment transitions the customer
      // to post_payment on the backend, refetching
      // kaseOverview reflects that update in the subnav
      fetchOverview(kaseId)
      return response
    })
}
