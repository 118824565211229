import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminUser } from 'types/users'

export interface AdminUsersShape {
  byId: { [key: string]: AdminUser }
  allIds: string[]
}

const initialState: AdminUsersShape = {
  byId: {},
  allIds: []
}

const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    setAdminUsers: (
      state,
      action: PayloadAction<{
        [key: string]: AdminUser
      }>
    ) => {
      state.byId = action.payload
      state.allIds = Object.keys(state.byId)
    },
    setAdminUser: (state, action: PayloadAction<AdminUser>) => {
      const admin = action.payload
      state.byId[admin.id] = admin
      state.allIds = Object.keys(state.byId)
    }
  }
})

export const { setAdminUsers, setAdminUser } = adminUsersSlice.actions

// Export the reducer as the default
export default adminUsersSlice.reducer
