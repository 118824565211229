import React, { FunctionComponent } from 'react'
import Can from 'components/Can'
import { PermissionType } from 'types/permissions'

interface Props {
  // TODO: figure out typescript error where permission can be undefined
  permission?: PermissionType
}

const PermissionLinkWrapper: FunctionComponent<Props> = ({
  permission,
  children
}) => {
  const wrapper = permission ? (
    <Can perform={permission} yes={children} />
  ) : (
    children
  )

  return <>{wrapper}</>
}

export default PermissionLinkWrapper
