import React, { FunctionComponent } from 'react'
import Button from 'components/Button'
import { Comment } from 'types/kaseOverview'
import {
  formatIfDate,
  getTimeFromDateString
} from '../../../utils/format_utils'
import { deleteComment, pinComment } from 'api/overview'
import { useParams } from 'react-router-dom'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { RootState } from 'store/root'
import { useSelector } from 'react-redux'

interface Props {
  comment: Comment
}

const KaseComment: FunctionComponent<Props> = ({ comment }) => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const currentUser = useSelector((state: RootState) => state.currentUser)

  const onDeleteBtnClick = () => {
    deleteComment(kaseId, comment.id)
  }

  const onPinBtnClick = () => {
    pinComment(kaseId, comment.id, !comment.pinned)
  }

  return (
    <div className="mt-6 mb-4">
      <div className="flex justify-between">
        <div className="text-gray-700">
          {comment.user}{' '}
          <span className="ml-4">
            {formatIfDate(comment.created_at)} at{' '}
            {getTimeFromDateString(comment.created_at)}
          </span>
        </div>
        <Button onClick={onPinBtnClick} variant="plain">
          {comment.pinned ? 'Unpin' : 'Pin'}
        </Button>
      </div>
      <div className="border border-gray-300 mt-2 p-4">{comment.content}</div>
      {comment.user === currentUser.fullName && (
        <Button className="mt-2" onClick={onDeleteBtnClick} variant="plain">
          Delete
        </Button>
      )}
    </div>
  )
}

export default KaseComment
