import React, { useState, FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { confirmKaseWaiverEligibility } from 'api/kaseData'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import Alert, { AlertVariants } from 'components/Alert'
import Button from 'components/Button'
import MainLayout from 'layouts/MainLayout'
import TextField from 'components/forms/TextField'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { validateEmail } from 'utils/validate_email'

const KaseEligibility = () => {
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const [emailAddress, setEmailAddress] = useState('')
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const submitKaseWaiver = (event: FormEvent) => {
    event.preventDefault()
    confirmKaseWaiverEligibility(kaseId, emailAddress)
      .then(() => setShowSuccessAlert(true))
      .catch((error) => setGlobalError(error))
      .finally(() => setEmailAddress(''))
  }

  return (
    <MainLayout>
      <div className="flex flex-col p-8 ">
        <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-4">
          Confirm Waiver Eligibility
        </h1>
        {showSuccessAlert && (
          <Alert
            variant={AlertVariants.Success}
            title={`Waiver created for Application ID: ${kaseId}`}
            className="mb-4"
          />
        )}
        <div className="w-1/3 flex items-end">
          <TextField
            label="Email Address"
            onChange={(event) => setEmailAddress(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                submitKaseWaiver(event)
              }
            }}
            value={emailAddress}
          />

          <Button
            className="ml-4"
            variant="primary"
            onClick={(event) => submitKaseWaiver(event)}
            disabled={!validateEmail(emailAddress)}
          >
            Submit
          </Button>
        </div>
      </div>
    </MainLayout>
  )
}

export default KaseEligibility
