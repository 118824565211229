import Button from 'components/Button'
import React, { FunctionComponent, ReactNode } from 'react'
import CloseButton from './CloseButton'
import ModalBase, { CloseReason } from './ModalBase'

interface Props {
  /**
   * If true, the modal will be displayed.
   */
  isOpen?: boolean
  /**
   * If true, the confirm button will be disabled.
   */
  confirmDisabled?: boolean
  /**
   * If present, this will be rendered within the modal.
   */
  children?: any
  /**
   * The maximum width of the modal
   * @default 30rem
   */
  modalWidth?: string | number
  /**
   * The title of the modal
   * @example User deletion
   */
  title: string
  /**
   * Confirming action in modal will result in dangerous action e.g. record deletion
   * @default false
   */
  dangerousOperation?: boolean
  /**
   * The description inside the modal
   * @example Are you sure you want to delete this user?
   */
  description: ReactNode
  /**
   * The label of the confirmation button. Please make it as descriptive as
   * possible.
   * @example "Yes, delete issue"
   * @example "Yes, cancel account"
   */
  confirmLabel: string
  /**
   * The label of the Cancel button. Please make it as descriptive and
   * reassuring as possible.
   * @default "Cancel"
   */
  cancelLabel?: string
  /**
   * Required callback that's triggered when the confirmation button is pressed
   */
  onConfirm: () => void
  /**
   * Required callback that's triggered when the Cancel button is pressed or the
   * user is attempting to close the modal
   */
  onRequestClose: (reason: CloseReason) => void
}

const ConfirmationModal: FunctionComponent<Props> = ({
  modalWidth = '30rem',
  title,
  description,
  children,
  confirmDisabled = false,
  dangerousOperation,
  onConfirm,
  onRequestClose,
  confirmLabel,
  cancelLabel = 'Cancel',
  ...otherProps
}) => {
  const warningIcon = dangerousOperation ? (
    // Scary red triangle with exclamation
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 mb-1 inline-flex text-red-500"
    >
      <path
        fillRule="evenodd"
        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    // Purple circle with exclamation
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 mb-1 inline-flex text-blue-600"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  )

  return (
    <ModalBase
      {...otherProps}
      onRequestClose={onRequestClose}
      style={{ width: modalWidth }}
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <CloseButton onClick={() => onRequestClose(CloseReason.CloseButton)} />
      </div>
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h3 className="text-lg text-gray-800 leading-6 font-medium mb-2">
          {warningIcon} {title}
        </h3>
        <p className="text-sm text-gray-600 leading-5">{description}</p>
        {children}
        <div className="mt-6 space-x-3">
          <Button
            onClick={onConfirm}
            variant={dangerousOperation ? 'danger' : 'primary'}
            disabled={confirmDisabled}
          >
            {confirmLabel}
          </Button>
          <Button onClick={() => onRequestClose(CloseReason.Cancel)}>
            {cancelLabel}
          </Button>
        </div>
      </div>
    </ModalBase>
  )
}

export default ConfirmationModal
