import normalize from 'json-api-normalizer'
import server from 'api/server'
import store from 'store/root'
import { setAdminUsers, setAdminUser } from 'store/reducers/adminUsers'
import { formatPostRequestBody } from 'utils/format_request'

// TODO optimally we could send a filter to the admins endpoint
export async function fetchAllStewards() {
  return server.get('/api/admin/stewards')
}

export const fetchAllAdmins = async (role = '') => {
  const params = role ? { role } : {}
  return server.get(`/api/v1/admin/admins`, { params }).then(({ data }) => {
    const normalizedUsers = normalize(data, {
      camelizeKeys: false
    }).admin_user

    store.dispatch(setAdminUsers(normalizedUsers))
  })
}

export async function saveAdmin(
  adminAttributes: Record<string, any>,
  adminRelationships?: Record<string, any>
) {
  const data = formatPostRequestBody(
    'admin_users',
    adminAttributes,
    adminRelationships
  )
  return server.post(`/api/v1/admin/admins`, data).then((response) => {
    store.dispatch(setAdminUser(response.data.data))
  })
}

export async function updateAdmin(
  adminId: string,
  adminAttributes: Record<string, any>,
  adminRelationships?: Record<string, any>
) {
  const data = formatPostRequestBody(
    'admin_users',
    adminAttributes,
    adminRelationships
  )

  return server
    .patch(`/api/v1/admin/admins/${adminId}`, data)
    .then((response) => {
      store.dispatch(setAdminUser(response.data.data))
    })
}

export async function reassignKase(
  kaseId: string | undefined,
  teamMemberids: {
    lawyer_id?: number
    application_guide_id?: number
  }
) {
  const data = {
    kase_id: kaseId,
    data: teamMemberids
  }

  return server.patch(`/api/v1/admin/kases/${kaseId}/assign_team`, data)
}
