import React, { FunctionComponent, useEffect, useState } from 'react'
import MainLayout from 'layouts/MainLayout'
import { useSelector } from 'react-redux'
import { RootState } from 'store/root'
import { Role } from 'types/role'
import { fetchRoles } from 'api/roles'
import PermissionLinkWrapper from 'components/PermissionLinkWrapper'
import ROUTES from 'constants/routes'
import { Link } from 'react-router-dom'

import Table from 'components/tables/Table'

const RolesList: FunctionComponent = () => {
  const roles = useSelector((state: RootState) => state.adminRoles.roles)
  const [rolesList, setRolesList] = useState<Role[]>([])

  useEffect(() => {
    fetchRoles()
  }, [])

  useEffect(() => {
    setRolesList(roles.allIds.map((id) => roles.byId[id]))
  }, [roles])

  return (
    <MainLayout>
      <div className="flex justify-center">
        <div className="w-11/12">
          <header className="flex items-center justify-between px-3">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-5">
              Roles
            </h1>
            <PermissionLinkWrapper permission="role:create">
              <Link
                to={`${ROUTES.ROLE_NEW}`}
                className="text-blue-600 hover:text-blue-500 active:text-blue-700"
              >
                Add New Role
              </Link>
            </PermissionLinkWrapper>
          </header>

          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>Role</Table.Header>
                <Table.Header>Description</Table.Header>
                <Table.Header>Permissions</Table.Header>
                <Table.Header>
                  <div className="sr-only">Edit</div>
                </Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {rolesList.map((role) => (
                <Table.Row key={role.id}>
                  <Table.Cell>{role.attributes.name}</Table.Cell>
                  <Table.Cell>{role.attributes.description}</Table.Cell>
                  <Table.Cell>Permissions</Table.Cell>
                  <Table.Cell className="text-right">
                    <PermissionLinkWrapper permission="role:update">
                      <Link
                        to={`${ROUTES.ROLES_ROOT}/${role.id}`}
                        className="text-blue-600 hover:text-blue-500 active:text-blue-700"
                      >
                        Edit
                      </Link>
                    </PermissionLinkWrapper>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </MainLayout>
  )
}
export default RolesList
