import RadioInputOptions from 'components/question-element/RadioInputOptions'
import React, { FunctionComponent, useState } from 'react'
import { PageElementQuestion } from 'types/questionnaire'
import CountryQuestionElement from './CountryQuestionElement'
import { NewApplicationJit } from '../../pages/new_application/shared/new-application-jit'
import dayjs from 'dayjs'
import SelectFieldQuestionElement from './SelectFieldQuestionElement'
import { US_STATES } from './select-field-options/us-states'
import { CLASS_OF_ADMISSION_CODES } from './select-field-options/visa-types'
import { EMBASSY_CITIES } from './select-field-options/embassy-cities'
import CheckboxGroup from './CheckboxGroup'
import PhoneInput from 'react-phone-input-2'

interface QuestionElementProps {
  handleQuestionAnswer: (
    value: string,
    key: string,
    answerId: string | null
  ) => void
  question: PageElementQuestion
  defaultValue?: string
  emailErrorMessage?: string
  /** Used in various input types, determines whether to trigger handleQuestionAnswer in onChange
   * Is set to true for use in KaseEditableAnswer, where we want the onChange to set the value in real time
   * as opposed to just in a blur
   */
  useHandleQuestionAnswerOnChange?: boolean
}

const QuestionElement: FunctionComponent<QuestionElementProps> = ({
  handleQuestionAnswer,
  question,
  emailErrorMessage,
  defaultValue,
  useHandleQuestionAnswerOnChange = false
}) => {
  const questionType = question.attributes.input_type
  const questionId = question.id
  const answerId = question.attributes?.answer_id || null

  const [inputValue, setInputValue] = useState(
    // using ?? instead of || in case the defaultValue is to be a boolean of False
    question.attributes.input_value ?? defaultValue ?? ''
  )
  // Dates have to be treated differently as their actual value and how we save
  // them in the DB/display them are different
  const [dateValue, setDateValue] = useState(
    dayjs(question.attributes.input_value).format('YYYY-MM-DD')
  )
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleEmailField = (
    value: string,
    key: string,
    answerId: string | null
  ) => {
    const str = value.toLowerCase()
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

    const isValid = re.test(str)
    setIsValidEmail(isValid)
    if (isValid) {
      handleQuestionAnswer(value, key, answerId)
    }
  }

  const handleCheckboxField = (
    target: HTMLInputElement,
    key: string,
    answerId: string | null
  ) => {
    const checkboxValue = target.checked ? 'true' : 'false'
    setInputValue(checkboxValue)
    handleQuestionAnswer(checkboxValue, key, answerId)
  }

  /** Most inputs will have useHandleQuestionAnswerOnChange set to true, but the inputs that are string/number
   * inputs will defer to the useHandleQuestionAnswerOnChange prop. This is because in BURP we don't want it
   * saving everytime there is a change, but in KaseEditableAnswer, we have a button before saving each answer
   */
  const handleChange = (
    value: string,
    useHandleQuestionAnswerOnChange: boolean
  ) => {
    setInputValue(value)
    if (useHandleQuestionAnswerOnChange) {
      handleQuestionAnswer(value, questionId, answerId)
    }
  }

  const getQuestionInput = () => {
    switch (questionType) {
      case 'checkbox':
        return (
          <div key={question?.id}>
            <input
              checked={inputValue === 'true'}
              className="mx-2"
              name={questionId}
              onChange={(e) =>
                handleCheckboxField(e.target, questionId, answerId)
              }
              type="checkbox"
            />
            {question.attributes.text}
          </div>
        )

      case 'country':
        return (
          <CountryQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            value={inputValue}
          />
        )

      case 'country_us_only':
        return <p>Cannot edit this, only option is US</p>

      case 'country_no_us':
        return (
          <CountryQuestionElement
            excludeUS
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            value={inputValue}
          />
        )

      case 'date':
        return (
          <div key={question.id}>
            {question.attributes.text}:
            <input
              className={'border-solid border-2 mx-2 px-2 border-gray-300'}
              type="date"
              onChange={(e) => {
                handleChange(dayjs(e.target.value).format('MM/DD/YYYY'), true)
                setDateValue(e.target.value)
              }}
              value={dateValue}
            />
          </div>
        )

      case 'dollar':
      case 'number':
      case 'postal_code':
      case 'unit_number':
        return (
          <div key={question.id}>
            {question.attributes.text}:
            <input
              className="border-solid border-gray-300 border-2 mx-2 px-2"
              type="number"
              onChange={(e) => {
                let value = +e.target.value
                if (value < 0) {
                  value = 0
                }
                handleChange(value.toString(), useHandleQuestionAnswerOnChange)
              }}
              onBlur={(e) =>
                handleQuestionAnswer(e.target.value, questionId, answerId)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuestionAnswer(inputValue, questionId, answerId)
                }
              }}
              min={0}
              value={inputValue}
            />
            {/* // I would like there to be a save button here, but that would require changing 
            how all the other inputs work so that it's more consistent. So for simplicity this 
            will save on an onBlur */}
          </div>
        )

      case 'dollar_negative_allowed':
        return (
          <div key={question.id}>
            {question.attributes.text}:
            <input
              className="border-solid border-gray-300 border-2 mx-2 px-2"
              type="number"
              onChange={(e) =>
                handleChange(e.target.value, useHandleQuestionAnswerOnChange)
              }
              onBlur={(e) =>
                handleQuestionAnswer(e.target.value, questionId, answerId)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuestionAnswer(inputValue, questionId, answerId)
                }
              }}
              value={inputValue}
            />
          </div>
        )

      case 'email':
        return (
          <div key={question.id}>
            {question.attributes.text}:
            <input
              className={`border-solid border-2 mx-2 px-2${
                !isValidEmail ? 'border-red-500' : 'border-gray-300'
              }`}
              type="text"
              onChange={(e) =>
                handleChange(e.target.value, useHandleQuestionAnswerOnChange)
              }
              onBlur={(e) =>
                handleEmailField(e.target.value, questionId, answerId)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuestionAnswer(inputValue, questionId, answerId)
                }
              }}
              value={inputValue}
            />
            {(!isValidEmail || !!emailErrorMessage) && (
              <span className="text-red-500 mx-2">
                {emailErrorMessage || 'Please enter a valid email address'}
              </span>
            )}
          </div>
        )

      case 'embassy_cities':
        return (
          <SelectFieldQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            options={EMBASSY_CITIES['mbgc']}
            value={inputValue}
          />
        )

      case 'horizontal_radio_button':
      case 'radio_button_grid':
      case 'vertical_radio_button':
        return (
          <div
            key={question?.id}
            onChange={(e) =>
              handleChange((e.target as HTMLInputElement).value, true)
            }
          >
            {!!question.attributes.input_options && (
              <>
                <RadioInputOptions
                  options={question.attributes.input_options}
                  name={questionId}
                  selection={question.attributes.input_value}
                />
              </>
            )}
          </div>
        )

      case 'k1_embassy_cities':
        return (
          <SelectFieldQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            options={EMBASSY_CITIES['k1']}
            value={inputValue}
          />
        )

      case 'phone':
        return (
          <PhoneInput
            value={inputValue}
            country="us"
            autoFormat={true}
            defaultMask="................."
            enableAreaCodes={true}
            onChange={(_value, _country, _e, formatValue) =>
              handleChange(formatValue, true)
            }
          />
        )

      case 'state':
        return (
          <SelectFieldQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            options={US_STATES}
            value={inputValue}
          />
        )

      case 'state_dept_countries':
        return (
          <CountryQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            useStateDeptCountries
            value={inputValue}
          />
        )

      case 'textarea':
        return (
          <div key={question.id}>
            {question.attributes.text && `${question.attributes.text}: `}
            <textarea
              className={
                'border-solid border-2 mx-2 my-2 px-2 border-gray-300 w-full h-48'
              }
              onBlur={(e) =>
                handleQuestionAnswer(e.target.value, questionId, answerId)
              }
              onChange={(e) => handleChange(e.target.value, true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuestionAnswer(inputValue, questionId, answerId)
                }
              }}
              value={inputValue}
            />
          </div>
        )

      case 'vertical_checkbox_group':
        return (
          <CheckboxGroup
            name={questionId}
            onChange={(value) => handleChange(value, true)}
            question={question}
            selection={inputValue}
          />
        )

      case 'visa_type':
        return (
          <SelectFieldQuestionElement
            question={question}
            onChange={(e) => handleChange(e.target.value, true)}
            options={CLASS_OF_ADMISSION_CODES}
            value={inputValue}
          />
        )

      case 'yes/no':
        return (
          <div
            className="flex"
            onChange={(e) =>
              handleChange((e.target as HTMLInputElement).value, true)
            }
            key={question?.id}
          >
            <div className="my-2">
              <label>
                <input
                  type="radio"
                  value="yes"
                  name={questionId}
                  defaultChecked={
                    defaultValue === 'yes' ||
                    question.attributes.input_value === 'yes'
                  }
                />
                <span className="mx-2">Yes</span>
              </label>
            </div>
            <div className="my-2">
              <label>
                <input
                  type="radio"
                  value="no"
                  name={questionId}
                  defaultChecked={
                    (!!defaultValue && defaultValue === 'no') ||
                    question.attributes.input_value === 'no'
                  }
                />
                <span className="mx-2">No</span>
              </label>
            </div>
          </div>
        )

      case 'alien_registration_number':
      case 'benefit_entry_name':
      case 'city':
      case 'class_of_admission':
      case 'employer_name':
      case 'institution_entry_name':
      case 'institution_entry_reason':
      case 'limit_280_input':
      case 'job_title':
      case 'name':
      case 'nature_of_organization':
      case 'province':
      case 'ssn':
      case 'state_or_province':
      case 'street_address':
      case 'string':
      default:
        return (
          <div key={question.id}>
            {question.attributes.text && `${question.attributes.text}: `}
            <input
              className={'border-solid border-2 mx-2 px-2 border-gray-300'}
              type="text"
              onBlur={(e) =>
                handleQuestionAnswer(e.target.value, questionId, answerId)
              }
              onChange={(e) =>
                handleChange(
                  (e.target as HTMLInputElement).value,
                  useHandleQuestionAnswerOnChange
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuestionAnswer(inputValue, questionId, answerId)
                }
              }}
              value={inputValue}
            />
          </div>
        )
    }
  }

  return (
    <div>
      <div>{getQuestionInput()}</div>
      <div>
        {question.attributes.question_jit_elements.map((jit, index) => {
          return (
            <NewApplicationJit jit={jit} key={`${question.id}-jit-${index}`} />
          )
        })}
      </div>
    </div>
  )
}

export default QuestionElement as FunctionComponent<QuestionElementProps>
