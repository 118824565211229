import React, { FunctionComponent } from 'react'
import KitchenSinkLayout from 'layouts/KitchenSinkLayout'
import Button from 'components/Button'

const Kitchensink: FunctionComponent = () => {
  return (
    <KitchenSinkLayout>
      <h1 className="text-3xl mb-4">Kitchen sink</h1>
      <h2 className="text-xl mb-2">Buttons</h2>
      <p>
        By default, a button looks like this: <Button>Save Changes</Button>
      </p>
      <p>
        Use the{' '}
        <span className="font-mono inline-block bg-red-100 text-red-900 px-1 rounded-sm">
          slim
        </span>{' '}
        variant in a table or list to avoid increasing the height of the
        container.
      </p>
      <table className="w-full border border-gray-200 mb-6">
        <thead>
          <tr>
            <th>Modifier(s)</th>
            <th className="w-40">Visual</th>
            <th className="w-40">Slim</th>
            <th className="w-40">Disabled</th>
            <th>Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <Button>Save Changes</Button>
            </td>
            <td>
              <Button slim>Save Changes</Button>
            </td>
            <td>
              <Button disabled>Save Changes</Button>
            </td>
            <td>
              This is the default button. Use another style if the button
              requires a different visual weight.
            </td>
          </tr>
          <tr>
            <td>Primary</td>
            <td>
              <Button variant="primary">Save Changes</Button>
            </td>
            <td>
              <Button variant="primary" slim>
                Save Changes
              </Button>
            </td>
            <td>
              <Button variant="primary" disabled>
                Save Changes
              </Button>
            </td>
            <td>
              Used to highlight the most important actions. Use sparingly! Avoid
              showing multiple primary buttons in the same section.
            </td>
          </tr>
          <tr>
            <td>Danger</td>
            <td>
              <Button variant="danger">Delete User</Button>
            </td>
            <td>
              <Button variant="danger" slim>
                Delete User
              </Button>
            </td>
            <td>
              <Button variant="danger" disabled>
                Delete User
              </Button>
            </td>
            <td>
              Use when the action will delete data or be difficult to recover
              from. Destructive buttons should trigger a confirmation prompt
              before the action is completed. Use sparingly! These buttons are
              stressful.
            </td>
          </tr>
          <tr>
            <td>Plain</td>
            <td>
              <Button variant="plain">Save Changes</Button>
            </td>
            <td>
              <Button variant="plain" slim>
                Save Changes
              </Button>
            </td>
            <td>
              <Button variant="plain" disabled>
                Save Changes
              </Button>
            </td>
            <td>For less important or less commonly-used actions.</td>
          </tr>
        </tbody>
      </table>

      <h2 className="text-xl mb-2">Icons</h2>
      <p>
        Icons can be a massive pain. In order to avoid that pain, I'd like to
        follow the rules below:
      </p>
      <ul className="list-disc list-inside">
        <li>
          Icons should fit within a 24x24px box. It's okay (and sometimes
          recommended) to leave some space around the icon
        </li>
        <li>
          Avoid writing icon wrappers in either JS or CSS. This leads to issues
          down the line.
        </li>
        <li>
          Use the attribute{' '}
          <span className="font-mono inline-block bg-red-100 text-red-900 px-1 rounded-sm">
            currentColor
          </span>{' '}
          for fill values. This allows icons to use their container's color.
        </li>
      </ul>

      <h2 className="text-xl mb-2 mt-6">Tables</h2>
      <p>I would like to document tables!</p>
      <p>Tables can have various states:</p>
      <ul className="list-disc list-inside">
        <li>Default (for lack of a better word): there are items to display</li>
        <li>Empty: there are no items</li>
        <li>Loading: we're still loading items</li>
      </ul>
    </KitchenSinkLayout>
  )
}

export default Kitchensink
