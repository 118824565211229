import { createSelector } from '@reduxjs/toolkit'
import { TaskType } from 'constants/tasks'
import { RootState } from 'store/root'

/**
 * Returns the first unresolved task we can find, or null
 */
export const getCurrentOpenTask = createSelector(
  (state: RootState) => state.kaseTasks,
  ({ byId, allIds }) => {
    const openTaskId = allIds.find(
      (id) => byId[id].attributes.state !== 'resolved'
    )

    if (openTaskId) {
      return byId[openTaskId]
    }
    return null
  }
)

/**
 * Returns either an open QA task or the most recent QA task  (by creation date)
 */
export const getOpenOrMostRecentQATask = createSelector(
  (state: RootState) => state.kaseTasks,
  ({ byId }) => {
    const qaTasks = Object.values(byId).filter(
      (task) => task.attributes.kind === TaskType.QA
    )

    if (!qaTasks || qaTasks.length === 0) {
      return null
    }

    // Attempt to find an open task. We're assuming there can only be a SINGLE
    // open task at any moment
    const openTask = qaTasks.find(
      (task) => task.attributes.state !== 'resolved'
    )

    if (openTask) {
      return openTask
    }

    // If there's no open task, return the task that was most-recently created
    let mostRecentTask = qaTasks[0]
    let mostRecentDate = new Date(mostRecentTask.attributes.created_at)
    qaTasks.forEach((task) => {
      const creationDate = new Date(task.attributes.created_at)
      if (creationDate > mostRecentDate) {
        mostRecentTask = task
        mostRecentDate = creationDate
      }
    })

    return mostRecentTask
  }
)
