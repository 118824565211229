import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import RoleForm from './RoleForm'

import { RolePermissions } from 'types/role'
import { addRole } from 'api/roles'
import ROUTES from 'constants/routes'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'

const NewRoleForm: FunctionComponent = ({}) => {
  const { setGlobalError } = useGlobalError()
  const history = useHistory()

  const handleSubmit = (
    attributes: { name: string; description: string },
    permissions: RolePermissions
  ) => {
    addRole(attributes, permissions)
      .then(() => {
        history.push(`${ROUTES.ROLES_ROOT}`)
      })
      .catch((error) => {
        setGlobalError(error)
        // TODO: delete when api endpoints are working
        history.push(`${ROUTES.ROLES_ROOT}`)
      })
  }

  return <RoleForm handleSubmit={handleSubmit} />
}

export default NewRoleForm as FunctionComponent
